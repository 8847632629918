import { useTranslation } from 'react-i18next';
import { ErrorMessage, SuccessMessage, UseMessages } from './types';

export function useMessages(): UseMessages {
  const { t } = useTranslation('Messages');

  const errorMessage: ErrorMessage = (error): string => {
    if (error.code) {
      return t(`error.${error.code}`);
    }
    return error.message as string;
  };

  const successMessage: SuccessMessage = (key) => t(`success.${key}`);

  return {
    errorMessage,
    successMessage,
  };
}

/**
 * List of error messages.
 * t('error.AUTHENTICATION_FAILED')
 * t('error.BLOCKED')
 * t('error.CASHBET_RESET')
 * t('error.CLIENT_UNAUTHORIZED')
 * t('error.DISPLAYNAME_INVALID')
 * t('error.EMAIL_INVALID')
 * t('error.EMAIL_NOT_UNIQUE')
 * t('error.EMAIL_REQUIRED')
 * t('error.ILLEGAL_STATE')
 * t('error.IS_VERIFIED')
 * t('error.UNAUTHORIZED')
 * t('error.ERROR_FROM_CASHBET_8')
 * t('error.ERROR_FROM_CASHBET_9')
 * t('error.ERROR_FROM_CASHBET_10')
 * t('error.ERROR_FROM_CASHBET_13')
 * t('error.ERROR_FROM_CASHBET_14')
 * t('error.ILLEGAL_PAYMENT_METHOD')
 * t('error.ALREADY_VERIFIED')
 * t('error.NO_CB_TOKEN')
 * t('error.USER_NOT_FOUND')
 * t('error.PENDING_INVITES_MAXED')
 * t('error.LEAGUE_NOT_FOUND')
 * t('error.INVALID_TARGET')
 * t('error.FRIEND_LIMIT_EXCEEDED')
 * t('error.FRIEND_LIMIT_OTHER_EXCEEDED')
 * t('error.INVITE_RATELIMIT_EXCEEDED')
 * t('error.MULTIPLE_SESSIONS')
 * t('error.ILLEGAL_ARGUMENT')
 * t('error.EMAIL_NOT_VERIFIED')
 * t('error.NOT_FRIENDS')
 * t('error.REPORTUSER_RATELIMIT_EXCEEDED')
 * t('error.INVALID_ROOMTYPE')
 * t('error.INSUFFICIENT_FUNDS')
 * t('error.NO_ROOMTYPES_GIVEN')
 * t('error.CASHBET_NOT_AVAILABLE')
 * t('error.CONTEST_NOT_FOUND')
 * t('error.NO_CONTESTS_SELECTED')
 * t('error.USER_IS_BLOCKED')
 * t('error.SERVER_FULL')
 * t('error.SERVER_MAINTENANCE')
 * t('error.FEEDBACK_RATELIMIT_EXCEEDED')
 * t('error.FEEDBACK_CAN_NOT_BE_SENT')
 * t('error.ALREADY_HAS_FACEBOOKID')
 * t('error.FACEBOOKID_REQUIRED')
 * t('error.ACCESSTOKEN_REQUIRED')
 * t('error.NOT_VERIFIED')
 * t('error.USER_IS_GUEST')
 * t('error.USER_NOT_JOINED_CONTEST')

 *
 * // League related errors
 * t('error.NOT_PRESENT')
 * t('error.EMPTY_OR_NULL_PARAMETER')
 * t('error.MAX_INVITES_EXCEEDED')
 * t('error.MAX_LEAGUES_REACHED')
 * t('error.NAME_INVALID')
 * t('error.DESCRIPTION_INVALID')
 * t('error.NAME_NOT_UNIQUE')
 * t('error.ALREADY_ACCEPTED')
 */

/**
 * List of success messages.
 * t('success.updateEmail')
 * t('success.updatePlayerName')
 * t('success.reportUser')
 * t('success.userVerification')
 * t('success.resetPassword')
 * t('success.leagueInviteFriends')
 * t('success.leagueInviteFriendSearch')
 * t('success.friendInviteFriendSearch')
 * t('success.leagueCreated')
 * t('success.leagueUpdated')
 * t('success.leagueInviteAccepted')
 * t('success.leagueInviteDeclined')
 * t('success.leaguePromoteMember')
 * t('success.leagueDemoteMember')
 * t('success.leagueKickMember')
 * t('success.friendInvite')
 * t('success.friendCancelInvite')
 * t('success.gameNotificationsEnabled')
 * t('success.gameNotificationsDisabled')
 * t('success.linkFacebookAccount')
 */
