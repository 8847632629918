import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'store/toast/types';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import chatLogoUrl from 'assets/images/chat-logo.png';
import { simpleHtml } from 'utils/regex';
import { HtmlContent } from 'atoms/HtmlContent';
import { DefaultToastStyled, ToastText } from './DefaultToast.styled';

interface Props {
  toast: Toast;
}

export const DefaultToast: React.FC<Props> = ({ toast }) => {
  const { t } = useTranslation('Global');
  const isHtml = simpleHtml.test(toast.message || '');

  return (
    <DefaultToastStyled>
      <Avatar src={toast.avatarUrl || chatLogoUrl} size={AvatarSize.M} border />
      <ToastText warning={!!toast.isWarning}>
        {isHtml ? (
          <HtmlContent dangerousContent={toast.message || ''} />
        ) : (
          toast.message || t('toast.defaultmessage')
        )}
      </ToastText>
    </DefaultToastStyled>
  );
};
