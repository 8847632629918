export enum UserMetaActions {
  TOGGLE_MUTE = '@user/TOGGLE_MUTE',
}

type UserMuted = {
  [userId: string]: {
    mutedAt: number | null;
  };
};

export interface UserMetaState {
  authToken: string;
  sessionKey: string;
  unseenTicketsCount: number;
  pushNotificationPromptedAt: number;
  mutedUsers: UserMuted;
}
