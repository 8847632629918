import styled, { css } from 'styled-components';
import { HeaderActionIcon } from 'atoms/OverlayHeaderAction/OverlayHeaderAction.styled';
import { Colors, FontSize, Gap, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { HelpButton } from 'atoms/HelpButton';

export const Header = styled.header`
  background-color: ${Colors.Black90};
  display: grid;
  gap: ${Gap.S};
  grid-template-rows: repeat(2, auto);
  grid-template-columns: ${pxToRem(48)} 1fr 1fr ${pxToRem(48)};
  padding: ${Padding.XS} ${Padding.Default} ${Padding.Default};

  ${HeaderActionIcon} {
    grid-area: 1/1/2/2;
    justify-self: start;
    left: 0;
    position: relative;
  }

  ${HelpButton} {
    grid-area: 1/4/2/5;
    justify-self: end;
    right: 0;
    position: relative;
  }
`;

export const GameWrapper = styled.section`
  grid-area: 1/2/2/4;
  justify-self: center;
`;

interface DescriptionProps {
  center: boolean;
}

export const Description = styled.section<DescriptionProps>`
  font-size: ${FontSize.MS};

  ${({ center }) =>
    center
      ? css`
          grid-area: 2/1/3/5;
          justify-self: center;
        `
      : css`
          grid-area: 2/1/3/3;
        `};
  align-self: center;
`;

export const DateTime = styled.section`
  color: ${Colors.TextSub};
  font-size: ${FontSize.MS};
  grid-area: 2/3/3/5;
  justify-self: end;
  align-self: start;
`;
