import { createReducer } from '@reduxjs/toolkit';
import { clearUser } from 'store/user/actions';
import { updateFetchedPlayersMovement } from './actions';
import { LeaderBoardMovementState } from './types';
import {
  filterOutExitingPlayers,
  normalizeByFetchedPlayers,
  shouldUpdateAllPlayers,
} from './utils';

const initialState: LeaderBoardMovementState = {};

export const leaderboardsMovementReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(updateFetchedPlayersMovement.fulfilled, (state, { payload }) => {
      const { contestId, roomTypeId, players, user, isOnLeaderboard, userId } = payload;

      const preparedPlayers = shouldUpdateAllPlayers(state, payload)
        ? players
        : filterOutExitingPlayers(state, payload);

      // if user not passed then skip update
      const playersToUpdate = user
        ? preparedPlayers
        : preparedPlayers.filter(({ userId: playerId }) => playerId !== userId);

      state[contestId] = { ...(state[contestId] || {}) };
      state[contestId][roomTypeId] = normalizeByFetchedPlayers(state, {
        contestId,
        roomTypeId,
        isOnLeaderboard,
        user,
        players: playersToUpdate,
      });
    });
});
