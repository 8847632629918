import { GameState, GameType } from 'services/pttv/api/constants';

export enum GameActions {
  CREATE_GAME = '@game/CREATE_GAME',
  RESET_GAME = '@game/RESET_GAME',
  UPDATE_GAME = '@game/UPDATE_GAME',
  PUSH_UPDATE_GAME = '@game/PUSH_UPDATE_GAME',
  REMOVE_GAME = '@game/REMOVE_GAME',
  END_GAME = '@game/END_GAME',
  SUBSCRIBE_SUCCESS = '@game/SUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_SUCCESS = '@game/UNSUBSCRIBE_SUCCESS',
  UPDATE_SUBSCRIPTIONS = '@game/UPDATE_SUBSCRIPTIONS',
  TOGGLE_NOTIFICATION = '@game/TOGGLE_NOTIFICATION',
}

export interface Game {
  gameId: string;
  sportId: string;
  homeTeamId: string;
  awayTeamId: string;
  broadcastDate: number;
  state: GameState;
  subscribed: boolean;
  rewardPenalties: number[];
  description: string;
  isTestGame: boolean;
  gameType: GameType;
  imageUrl?: string;
  name?: string;
}

export type GroupedByDateReducer<T extends Game> = {
  [date: string]: T[];
};

export interface ResetGamePayload {
  gameId: string;
  contestIds: string[];
}

export type GroupedByDate = [string, Array<Game>][];

export type GamesState = Record<string, Game>;
