import { debugLog } from 'utils/log';

export const getUrlParam = (paramName: string, url: string | URL): string | null | undefined => {
  if (!url || !paramName) {
    return undefined;
  }

  try {
    const formattedUrl = new URL(url);
    return formattedUrl.searchParams.get(paramName);
  } catch (e) {
    debugLog('[getUrlParam]', 'Failed to transform url');
  }

  return undefined;
};
