import React, { Suspense, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Page, SlideIn } from 'molecules/PageWrappers';
import { PageLoader } from 'atoms/PageLoader';
import { useAppSelector } from 'store/hooks';
import { getGameById } from 'store/games/selectors';
import { getContestById } from 'store/contests/selectors';
import { LazyPreLoader } from 'utils/LazyPreLoader';
import { withErrorBoundary } from 'hocs/withErrorBoundary';

const lazyLoader = new LazyPreLoader();

const Games = React.lazy(() => import('.'));
const RoomSelection = lazyLoader.lazy(() => import('./[gameId]/roomSelection'));
const ContestPage = lazyLoader.lazy(() => import('./[gameId]/contests/[contestId]'));
const PropositionsPage = lazyLoader.lazy(
  () => import('./[gameId]/contests/[contestId]/proposition'),
);
const RoomLeaderboardPage = lazyLoader.lazy(() => import('./[gameId]/contests/[contestId]/room'));
const RoomTypeLeaderboardPage = lazyLoader.lazy(
  () => import('./[gameId]/contests/[contestId]/roomtype'),
);

export const gamePaths = {
  contestRoomType: '/games/:gameId/contests/:contestId/roomtype/:roomTypeId',
  contestRoom: '/games/:gameId/contests/:contestId/room/:roomId',
  propositions: '/games/:gameId/contests/:contestId/proposition',
  joinMore: '/games/:gameId/contests/:contestId/join-more',
  contest: '/games/:gameId/contests/:contestId',
  roomSelection: '/games/:gameId/room-selection',
  games: '/games',
};

interface Params {
  gameId: string;
  contestId: string;
  roomId: string;
  roomTypeId: string;
}

const GameRoutes: React.FC = () => {
  const match = useRouteMatch<Params>(Object.values(gamePaths));
  const history = useHistory();
  const game = useAppSelector(getGameById(match.params.gameId));
  const contest = useAppSelector(getContestById(match.params.contestId));

  useEffect(() => {
    lazyLoader.preload();
  }, []);

  useEffect(() => {
    if ((!game && match.params.gameId) || (!contest && match.params.contestId)) {
      history.replace('/');
    }
  }, [game, contest]);

  if (!match?.isExact) {
    return null;
  }

  return (
    <>
      <Page>
        <Games />
      </Page>

      <Suspense fallback={<PageLoader />}>
        <SwitchTransition mode="in-out">
          <CSSTransition
            key={match.path}
            timeout={{
              appear: history.action === 'POP' ? 0 : 500,
              enter: history.action === 'POP' ? 0 : 500,
              exit: 500,
            }}
            classNames="slidein"
            unmountOnExit
          >
            <>
              {match.path === gamePaths.roomSelection && (
                <SlideIn zIndex={0}>
                  <RoomSelection gameId={match.params.gameId} />
                </SlideIn>
              )}

              {match.path === gamePaths.joinMore && (
                <SlideIn zIndex={2}>
                  <RoomSelection gameId={match.params.gameId} />
                </SlideIn>
              )}
              {match.path === gamePaths.contest && (
                <SlideIn zIndex={1}>
                  <ContestPage gameId={match.params.gameId} contestId={match.params.contestId} />
                </SlideIn>
              )}
              {match.path === gamePaths.propositions && (
                <SlideIn zIndex={2}>
                  <PropositionsPage contestId={match.params.contestId} />
                </SlideIn>
              )}
              {match.path === gamePaths.contestRoom && (
                <SlideIn zIndex={3}>
                  <RoomLeaderboardPage
                    roomId={match.params.roomId}
                    contestId={match.params.contestId}
                  />
                </SlideIn>
              )}
              {match.path === gamePaths.contestRoomType && (
                <SlideIn zIndex={4}>
                  <RoomTypeLeaderboardPage
                    roomTypeId={match.params.roomTypeId}
                    contestId={match.params.contestId}
                  />
                </SlideIn>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default withErrorBoundary(GameRoutes);
