import Joi from 'joi';
import { getRoomById } from 'store/rooms/selectors';
import { getUser } from 'store/user/selectors';
import { getContestByRoomId } from 'store/contests/selectors';
import { getSportByContestId } from 'store/sports/selectors';
import { getGameByContestId, getGameShortName } from 'store/games/selectors';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { SegmentEvent } from '../types';

interface actionMeta {
  payload: {
    roomId: string;
    text: string;
  };
}

export const chatMessageSubmitted: SegmentEvent = (action, prevState, nextState) => {
  const { payload } = action as unknown as actionMeta;
  const user = getUser(nextState);
  const room = getRoomById(payload.roomId)(nextState);
  const roomType = getRoomTypeById(room.roomTypeId)(nextState);
  const contest = getContestByRoomId(payload.roomId)(nextState);
  const sport = getSportByContestId(contest.contestId)(nextState);
  const game = getGameByContestId(contest.contestId)(nextState);
  const gameName = getGameShortName(game?.gameId || '')(nextState);

  return {
    name: 'chat_message_submitted',
    properties: {
      chat_display_name: user.displayName,
      chat_message: payload.text,
      joined_game_name: gameName,
      joined_game_id: contest.gameId,
      joined_game_sport: sport?.abbreviation,
      joined_room_period: contest.period,
      joined_room_type_name: roomType.originalId,
      joined_room_id: payload.roomId,
    },
  };
};

const chatMessageSubmittedEventSchema = Joi.object({
  chat_display_name: Joi.string().required(),
  chat_message: Joi.string().required(),
  joined_game_name: Joi.string().required(),
  joined_game_id: Joi.string().required(),
  joined_game_sport: Joi.string().required(),
  joined_room_period: Joi.number().integer().required(),
  joined_room_type_name: Joi.string().required(),
  joined_room_id: Joi.string().required(),
});

export const isValidChatMessageSubmittedEvent = (
  event: Record<string, string | number>,
): boolean => {
  const result = chatMessageSubmittedEventSchema.validate(event.properties);
  return !result.error;
};
