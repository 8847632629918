import styled, { css, keyframes } from 'styled-components';
import checkIcon from 'assets/images/checkIcon.png';
import { BorderRadius, BorderWidth, FontFamily, FontWeight, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

type ColorScheme = 'selected' | 'active' | 'default' | 'pending';
type SizeScheme = 'active' | 'default';

export const getColorScheme = (active = false, selected = false, pending = false): ColorScheme => {
  if (selected) {
    return pending ? 'pending' : 'selected';
  }
  return active ? 'active' : 'default';
};

export interface PropositionTabProps {
  $activeTab?: boolean;
  $selected?: boolean;
  $pending?: boolean;
  $finished?: boolean;
  as?: string;
}

interface ExtendedTabProps {
  $colorScheme: ColorScheme;
  $sizeScheme: SizeScheme;
  pointer: string;
}

const popIn = keyframes`
  0% {
    background-size: 0;
    opacity: 1;
  }
  30% {
    background-size: 100%;
    opacity: 1;
  }
  33% {
    background-size: 70%;
    opacity: 1;
  }
  75% {
    background-size: 70%;
    opacity: 1;
  }
  100% {
    background-size: 0;
    opacity: 0;
  }
`;

export const PropositionTab = styled.button.attrs<PropositionTabProps, ExtendedTabProps>(
  ({ $activeTab, $selected, $pending, as }) => ({
    $colorScheme: getColorScheme($activeTab, $selected, $pending),
    $sizeScheme: $activeTab ? 'active' : 'default',
    pointer: as ? 'default' : 'pointer',
  }),
)<PropositionTabProps>`
  align-items: center;
  background-color: ${({ theme, $colorScheme }) =>
    theme.propositionTab[$colorScheme].backgroundColor};
  border: ${BorderWidth.Large} solid
    ${({ theme, $colorScheme }) => theme.propositionTab[$colorScheme].borderColor};
  border-radius: ${BorderRadius.Default};
  box-sizing: border-box;
  color: ${({ theme, $sizeScheme }) => theme.propositionTab[$sizeScheme].color};
  cursor: ${({ pointer }) => pointer};
  display: flex;
  font-family: ${FontFamily.Heading};
  font-size: ${({ theme, $sizeScheme }) => theme.propositionTab[$sizeScheme].fontSize};
  font-weight: ${({ $activeTab }) => ($activeTab ? FontWeight.SemiBold : FontWeight.Regular)};
  height: ${({ theme, $sizeScheme }) => pxToRem(theme.propositionTab[$sizeScheme].height)};
  justify-content: center;
  line-height: ${({ $activeTab }) => ($activeTab ? 1 : 'inherit')};
  overflow: hidden;
  padding: ${({ $activeTab }) => ($activeTab ? Padding.XXS : `calc(${Padding.XXS} / 2)`)} 0 0;
  position: relative;
  scroll-snap-align: center;
  transition: all ease 0.3s;
  width: ${({ theme, $sizeScheme }) => pxToRem(theme.propositionTab[$sizeScheme].width)};

  ${({ pointer, $colorScheme, theme }) =>
    pointer === 'pointer' &&
    css`
      &:hover {
        background-color: ${theme.propositionTab[$colorScheme].hoverBackgroundColor};
        border-color: ${theme.propositionTab[$colorScheme].hoverBorderColor};
      }
    `}

  &::after {
    ${({ $finished, theme, $colorScheme }) =>
      $finished &&
      css`
        animation: ${popIn} 1.5s ease 1;
        background-color: ${theme.propositionTab[$colorScheme].backgroundColor};
      `}
    background-image: url('${checkIcon}');
    background-size: 0;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: ${BorderRadius.Default};
    color: white;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;
