import styled from 'styled-components';
import { Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { LeagueHeaderContainer } from '../LeagueHeader.styled';

interface Props {
  hasHeaderAction?: boolean;
}

interface AttrsProps {
  paddingTop: number;
}

export const LeagueDetailHeaderContainer = styled(LeagueHeaderContainer).attrs<Props, AttrsProps>(
  ({ hasHeaderAction }) => ({
    paddingTop: hasHeaderAction ? 48 : 32,
  }),
)<Props>`
  padding: ${({ paddingTop }) => pxToRem(paddingTop)} ${Padding.Default} ${Padding.Default};
`;
