import { createSelector } from 'reselect';
import { ContestHistoryContest, ContestHistoryRoom } from 'services/pttv/api/contests/types';
import { RoomPlayer } from 'services/pttv/api/rooms/types';
import {
  ContestHistoryState,
  ContestHistoryStateEntry,
  GameWithCancelledStatus,
  NormalizedContestHistoryGames,
  Winnings,
} from 'store/contestHistory/types';
import { Contest } from 'store/contests/types';
import {
  getGameNameByTeamsAndSports,
  getSports,
  getTeams,
  groupByDate,
  sortByDate,
} from 'store/games/selectors';
import { Game } from 'store/games/types';
import type { CurriedSelector, RootState } from 'store/types';
import { getUser, getUserId } from 'store/user/selectors';

export const getContestHistory = (state: RootState): ContestHistoryState => state.contestHistory;

export const getFlatContestHistory = (state: RootState): ContestHistoryStateEntry[] =>
  Object.values(state.contestHistory);

export const getContestHistoryById = (
  contestId: string,
): CurriedSelector<ContestHistoryStateEntry> =>
  createSelector(getContestHistory, (contestHistory) => contestHistory[contestId]);

export const getContestHistoryContestById = (
  contestId: string,
): CurriedSelector<ContestHistoryContest | Contest> =>
  createSelector(getContestHistoryById(contestId), (contestHistory) => contestHistory.contest);

export const getContestHistoryGames = (): CurriedSelector<GameWithCancelledStatus[]> =>
  createSelector(getFlatContestHistory, (contestHistory) => {
    const games = contestHistory.reduce<NormalizedContestHistoryGames>((acc, currentValue) => {
      if (!acc[currentValue.game.gameId]) {
        return {
          ...acc,
          [currentValue.game.gameId]: {
            ...currentValue.game,
            cancelled: !!currentValue.contest.cancelled,
          },
        };
      }
      return acc;
    }, {});

    return Object.values(games) || [];
  });

export const getContestHistoryGameById = (gameId: string): CurriedSelector<Game | undefined> =>
  createSelector(
    getFlatContestHistory,
    (contestHistory) =>
      contestHistory.find((contestHistoryEntry) => contestHistoryEntry.game.gameId === gameId)
        ?.game,
  );

export const getContestsHistoryContestsByGameId = (
  gameId: string,
): CurriedSelector<ContestHistoryStateEntry[]> =>
  createSelector(getFlatContestHistory, (contestHistory) =>
    contestHistory.filter((contestHistoryEntry) => contestHistoryEntry.game.gameId === gameId),
  );

export const getContestHistoryGamesGroupedByDate = createSelector(
  getContestHistoryGames(),
  (games) => groupByDate(games).sort(sortByDate),
);

export const getContestHistoryRoomsByContestId = (
  contestId: string,
): CurriedSelector<ContestHistoryRoom[]> =>
  createSelector(getContestHistory, (contestHistory) => contestHistory[contestId]?.rooms || []);

export const getContestHistoryRoom = (
  contestId: string,
  roomId: string,
): CurriedSelector<ContestHistoryRoom | undefined> =>
  createSelector(getContestHistoryRoomsByContestId(contestId), (rooms) =>
    rooms.find((room) => room.roomId === roomId),
  );

export const getContestHistoryRoomUser = (
  contestId: string,
  roomId: string,
): CurriedSelector<RoomPlayer | undefined> =>
  createSelector(getContestHistoryRoom(contestId, roomId), getUser, (room, user) =>
    room?.players.find((player) => player.userId === user.userId),
  );

export const getContestHistoryRoomPlayers = (
  contestId: string,
  roomId: string,
): CurriedSelector<RoomPlayer[]> =>
  createSelector(
    getContestHistoryRoom(contestId, roomId),
    (room) => [...(room?.players || [])].sort((a, b) => (a.rank < b.rank ? -1 : 1)) || [],
  );

export const getContestHistoryTotalWinnings = (contestId: string): CurriedSelector<Winnings> =>
  createSelector(getContestHistoryRoomsByContestId(contestId), getUserId, (rooms, userId) =>
    rooms.reduce<Winnings>(
      (acc, room) => {
        const roomPlayer = room.players.find((player) => player.userId === userId);
        return {
          tickets: !acc.tickets ? !!roomPlayer?.ticketWon : acc.tickets,
          dollarCents: acc.dollarCents + (roomPlayer?.dollarCentsWon || 0),
        };
      },
      {
        tickets: false,
        dollarCents: 0,
      },
    ),
  );

export const getContestHistoryGameShortName = (
  gameId: string,
): CurriedSelector<string | undefined> =>
  createSelector(getContestHistoryGameById(gameId), getTeams, getSports, (game, teams, sports) =>
    game ? getGameNameByTeamsAndSports(game, teams, sports, game.description) : undefined,
  );
