import styled, { css } from 'styled-components';
import { Colors, FontSize, Gap } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface TeamNameProps {
  hasPadding: boolean;
}

export const TeamName = styled.span<TeamNameProps>`
  color: ${Colors.TextSub};
  grid-area: name;
  font-size: ${FontSize.S};
  align-self: end;
`;

export const TeamLogo = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  grid-area: image;
  justify-content: center;
  max-height: 3.75rem;
  max-width: 4.5rem;

  img {
    max-height: 3.75rem;
    max-width: 4.5rem;
  }
`;

export const TeamAbbreviation = styled.span`
  color: ${Colors.White};
  font-size: ${FontSize.XXL};
  grid-area: abbreviation;
  text-align: center;
  word-break: break-all;
`;

interface TeamWrapperProps {
  horizontal: boolean;
  isHomeTeam: boolean;
}

const getGridColumns = (horizontal: boolean, isHomeTeam: boolean): string => {
  if (!horizontal) {
    return 'auto';
  }
  if (!isHomeTeam) {
    return 'auto 1fr';
  }
  return '1fr auto';
};

const getGridAreas = (horizontal: boolean, isHomeTeam: boolean): string => {
  if (!horizontal) {
    return '"image" "abbreviation" "name"';
  }
  if (!isHomeTeam) {
    return '"image abbreviation"';
  }
  return '"abbreviation image"';
};

export const TeamBlockWrapper = styled.section<TeamWrapperProps>`
  align-items: center;
  display: grid;
  grid-template-rows: ${({ horizontal }) => (horizontal ? 'auto' : '1fr auto')};
  grid-template-columns: ${({ horizontal, isHomeTeam }) => getGridColumns(horizontal, isHomeTeam)};
  grid-template-areas: ${({ horizontal, isHomeTeam }) => getGridAreas(horizontal, isHomeTeam)};
  height: 100%;
  justify-items: center;
  ${({ horizontal, isHomeTeam }) =>
    horizontal &&
    css`
      justify-self: ${isHomeTeam ? 'start' : 'end'};

      ${TeamAbbreviation} {
        font-size: ${FontSize.L};
        margin: 0 ${Gap.XS};
        word-break: normal;
      }

      ${TeamLogo} {
        max-height: ${pxToRem(48)};

        img {
          max-height: ${pxToRem(48)};
        }
      }
    `}
`;
