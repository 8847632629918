import { createReducer } from '@reduxjs/toolkit';
import { clearUser, fetchUserState } from 'store/user/actions';
import { acceptLeagueInvite } from '../leagueInvites/actions';
import {
  createLeague,
  fetchLeague,
  leaveLeague,
  updateLeague,
  leaveLeagueSuccess,
} from './actions';
import { League, LeaguesState } from './types';

const initialState: LeaguesState = {};

export const leaguesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(fetchUserState.fulfilled, (state, { payload }) =>
      payload.leagues.reduce(
        (acc, name) => ({
          ...acc,
          [name]: state[name] || null,
        }),
        {},
      ),
    )
    .addCase(fetchLeague.fulfilled, (state, { payload }) => {
      state[payload.name] = {
        ...payload,
        lastUpdatedAt: Date.now(),
      };
    })
    .addCase(createLeague.fulfilled, (state, { payload }) => {
      state[(payload as League).name] = {
        ...(payload as League),
        lastUpdatedAt: Date.now(),
      };
    })
    .addCase(updateLeague.fulfilled, (state, { payload }) => {
      if (state[(payload as League).name]) {
        state[(payload as League).name] = {
          ...(state[(payload as League).name] as League),
          ...(payload as League),
          lastUpdatedAt: Date.now(),
        };
      }
    })
    .addCase(leaveLeague.fulfilled, (state, { meta: { arg } }) => {
      if (state[arg.leagueName]) {
        delete state[arg.leagueName];
      }
    })
    .addCase(leaveLeagueSuccess, (state, { payload }) => {
      if (state[payload.name]) {
        delete state[payload.name];
      }
    })
    .addCase(acceptLeagueInvite.fulfilled, (state, { payload }) => {
      state[(payload as League).name] = {
        ...(payload as League),
        lastUpdatedAt: Date.now(),
      };
    });
});
