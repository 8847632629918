import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentArea } from 'atoms/ContentArea';
import { autoLoginCashBet } from 'store/cashBet/actions';
import { useAppDispatch } from 'store/hooks';
import { Dialog } from 'atoms/Dialog';
import { ZIndex } from 'style/types';

interface Props {
  isOpen: boolean;
}

export const AutoLoginModal: React.FC<Props> = ({ isOpen }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('UserErrorMessage');
  const [autoLoginTimeout, setAutoLoginTimeout] = useState<number | null>(null);

  const stopAutoLogin = () => {
    if (autoLoginTimeout) {
      window.clearTimeout(autoLoginTimeout);
      setAutoLoginTimeout(null);
    }
  };

  const autoLogin = () => {
    if (isOpen) {
      dispatch(autoLoginCashBet());

      const timeout = window.setTimeout(() => {
        autoLogin();
      }, 10000);

      setAutoLoginTimeout(timeout);
    }
  };

  useEffect(() => {
    if (isOpen && !autoLoginTimeout) {
      autoLogin();
    } else {
      stopAutoLogin();
    }
    return stopAutoLogin;
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} title={t('autoLogin.title')} zIndex={ZIndex.UserError}>
      <ContentArea>{t('autoLogin.description')}</ContentArea>
    </Dialog>
  );
};
