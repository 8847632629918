import { createReducer } from '@reduxjs/toolkit';
import { fetchPlayer } from 'store/players/actions';
import { clearUser } from 'store/user/actions';
import type { playersMetaState } from './types';

const initialState: playersMetaState = {};

export const playersMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(fetchPlayer.pending, (state, { meta: { arg } }) => {
      state[arg] = {
        isLoading: true,
      };
    })
    .addCase(fetchPlayer.fulfilled, (state, { meta: { arg } }) => {
      if (state[arg]) {
        delete state[arg];
      }
    })
    .addCase(fetchPlayer.rejected, (state, { meta: { arg } }) => {
      if (state[arg]) {
        delete state[arg];
      }
    });
});
