import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { ButtonBar } from 'atoms/ButtonBar';
import { ModalPage } from 'atoms/ModalPage';
import { ShareDialog } from 'molecules/ShareDialog';
import { ContestFinishingCard } from 'organisms/ContestFinishingCard';
import { ContestResults } from 'organisms/ContestResults';
import { getContestById } from 'store/contests/selectors';
import { useAppSelector } from 'store/hooks';
import { ButtonBarDirections } from 'style/types';
import { hasTickets } from 'store/tickets/selectors';
import { ContestFinishedModalContentArea } from './ContestFinishedModal.styled';

interface Props {
  contestId: string;
  closeCallback: () => void;
}

interface MatchParams {
  gameId?: string;
  contestId?: string;
}

export const ContestFinishedModal: React.FC<Props> = ({ contestId, closeCallback }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contest = useAppSelector(getContestById(contestId));
  const showTicketsButton = useAppSelector(hasTickets);
  const { t } = useTranslation('ContestModals');
  const { push } = useHistory();
  const match = useRouteMatch<MatchParams>({
    path: '/games/:gameId/contests/:contestId*',
    strict: false,
  });

  useEffect(() => {
    if (contest) {
      setIsOpen(true);
    }
  }, [contest]);

  if (!contest) {
    return null;
  }

  const handleCloseButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
    if (match?.isExact && match?.params?.contestId.split('/')[0] === contestId) {
      push('/');
    }
    closeCallback();
  };

  const handleViewTicketsButtonClick = () => {
    setIsOpen(false);
    push('/profile/tickets');
    closeCallback();
  };

  return (
    <ModalPage
      title={t('contestFinished.pageTitle')}
      isOpen={isOpen}
      onClose={handleCloseButtonClick}
    >
      <ContestFinishedModalContentArea>
        <ContestFinishingCard contestId={contestId}>
          <ContestResults contestId={contestId} />
        </ContestFinishingCard>
        <ButtonBar direction={ButtonBarDirections.Column}>
          <ShareDialog
            title={t('contestFinished.shareDialog.title')}
            shareMessage={{
              url: 'https://www.winviewgames.com/',
              text: t('contestFinished.shareDialog.text'),
            }}
            shareButton={(text, shareCallback) => (
              <Button scheme={ButtonSchemes.Secondary} onClick={shareCallback}>
                {text}
              </Button>
            )}
          />
          {showTicketsButton ? (
            <Button scheme={ButtonSchemes.Secondary} outline onClick={handleViewTicketsButtonClick}>
              {t('contestFinished.viewTicketsButton')}
            </Button>
          ) : null}
        </ButtonBar>
      </ContestFinishedModalContentArea>
    </ModalPage>
  );
};
