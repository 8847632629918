import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import HttpApi from 'i18next-http-backend';
import CashBet from '../public/locales/en-US/CashBet.json';
import ContestModals from '../public/locales/en-US/ContestModals.json';
import SchedulePage from '../public/locales/en-US/SchedulePage.json';
import Global from '../public/locales/en-US/Global.json';
import Cards from '../public/locales/en-US/Cards.json';
import MyGames from '../public/locales/en-US/MyGames.json';
import PropositionResult from '../public/locales/en-US/PropositionResult.json';
import RoomSelection from '../public/locales/en-US/RoomSelection.json';
import SocialLanding from '../public/locales/en-US/SocialLanding.json';
import LeagueDetail from '../public/locales/en-US/LeagueDetail.json';
import LeagueForm from '../public/locales/en-US/LeagueForm.json';
import LocationErrorMessage from '../public/locales/en-US/LocationErrorMessage.json';
import FriendRequestModal from '../public/locales/en-US/FriendRequestModal.json';
import ReportPlayerModal from '../public/locales/en-US/ReportPlayerModal.json';
import ShareModal from '../public/locales/en-US/ShareModal.json';
import ProfileLanding from '../public/locales/en-US/ProfileLanding.json';
import InviteFriend from '../public/locales/en-US/InviteFriend.json';
import TeamAffiliations from '../public/locales/en-US/TeamAffiliations.json';
import Settings from '../public/locales/en-US/Settings.json';
import Leaderboard from '../public/locales/en-US/Leaderboard.json';
import ContactSupport from '../public/locales/en-US/ContactSupport.json';
import LeagueHistory from '../public/locales/en-US/LeagueHistory.json';
import LeagueMemberModal from '../public/locales/en-US/LeagueMemberModal.json';
import Propositions from '../public/locales/en-US/Propositions.json';
import UserErrorMessage from '../public/locales/en-US/UserErrorMessage.json';
import Device from '../public/locales/en-US/Device.json';
import Forms from '../public/locales/en-US/Forms.json';
import RebuyButton from '../public/locales/en-US/RebuyButton.json';
import ServerPush from '../public/locales/en-US/ServerPush.json';
import RoomInformation from '../public/locales/en-US/RoomInformation.json';
import ContestDetail from '../public/locales/en-US/ContestDetail.json';
import Messages from '../public/locales/en-US/Messages.json';
import ServerModals from '../public/locales/en-US/ServerModals.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  'en-US': {
    Cards,
    CashBet,
    ContactSupport,
    ContestModals,
    Device,
    Forms,
    FriendRequestModal,
    Global,
    InviteFriend,
    Leaderboard,
    LeagueDetail,
    LeagueForm,
    LeagueHistory,
    LeagueMemberModal,
    LocationErrorMessage,
    MyGames,
    ProfileLanding,
    PropositionResult,
    Propositions,
    RebuyButton,
    ReportPlayerModal,
    RoomInformation,
    RoomSelection,
    SchedulePage,
    ServerPush,
    Settings,
    ShareModal,
    SocialLanding,
    TeamAffiliations,
    UserErrorMessage,
    ContestDetail,
    Messages,
    ServerModals,
  },
};

export const defaultNS = 'Global';

i18next
  // .use(HttpApi)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS,
    fallbackLng: 'en-US',
    lng: 'en-US',
    supportedLngs: ['en-US'],
    ns: ['SchedulePage', 'Global'],
    nsSeparator: ':',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['p', 'br', 'strong', 'b', 'i'],
    },
  });

export default i18next;
