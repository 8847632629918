export const formatVersion = (version: string): string => {
  const parts = version.split('-');
  let date = parts.pop();
  const versionString = parts.join('-');

  if (date && !date.includes('/')) {
    date = `${parseInt(date.substring(4, 6), 10)}/${parseInt(
      date.substring(6, 8),
      10,
    )}/${date.substring(0, 4)}`;
  }

  return `${versionString}-${date}`;
};
