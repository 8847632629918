import { AnswerCode } from 'services/pttv/api/constants';
import { Bet } from 'store/bets/types';

export enum BetMetaActions {
  UPDATE_BET_META = '@betMeta/UPDATE_BET_META',
  REMOVE_BET_META = '@betMeta/REMOVE_BET_META',
}

export interface UpdateBetMetArgs {
  betPoints: number;
  betVersion: number;
  contestId: string;
  answerCode: AnswerCode;
  oneShot: boolean;
  propositionId: string;
}

export interface RemoveBetMetArgs {
  propositionId: string;
}

export type BetMetaState = {
  [propositionId: string]: Bet;
};
