import React, { useEffect, useState } from 'react';
import { SportSeparator } from 'atoms/SportSeparator';
import { TeamBlock } from 'atoms/Team';
import { useAppSelector } from 'store/hooks';
import { Colors } from 'style/types';
import { GameContainer } from './Game.styled';

interface Props {
  homeTeamId: string;
  awayTeamId: string;
  sportId: string;
  horizontal?: boolean;
}

export const Game: React.FC<Props> = ({ homeTeamId, awayTeamId, sportId, horizontal }) => {
  const homeTeam = useAppSelector((state) => state.teams[homeTeamId]);
  const awayTeam = useAppSelector((state) => state.teams[awayTeamId]);
  const sport = useAppSelector((state) => state.sports[sportId]);
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    if (homeTeam.logoUrl && awayTeam.logoUrl) {
      setShowLogo(true);
    } else {
      setShowLogo(false);
    }
  }, [homeTeam, awayTeam]);

  if (!homeTeam || !awayTeam || !sport) {
    return null;
  }

  return (
    <GameContainer>
      <TeamBlock
        abbreviation={awayTeam.abbreviation}
        logo={showLogo && awayTeam.logoUrl}
        horizontal={horizontal}
      />
      <SportSeparator
        seperator={sport.sportTeamSeparator}
        color={horizontal ? Colors.Primary : Colors.TextSub}
      />
      <TeamBlock
        abbreviation={homeTeam.abbreviation}
        logo={showLogo && homeTeam.logoUrl}
        horizontal={horizontal}
        isHomeTeam
      />
    </GameContainer>
  );
};
