/* eslint-disable import/no-cycle */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { PropositionChangedEventPayloadProposition } from 'hocs/withLongPoll/types/proposition';
import { getBetByPropositionId } from 'store/bets/selectors';
import { getContestInfoByContestId } from 'store/contestInfo/selectors';
import { setOneShotUsed } from 'store/contestInfo/actions';
import { getContestById } from 'store/contests/selectors';
import { getGameByContestId, getGameShortName } from 'store/games/selectors';
import { getSportById } from 'store/sports/selectors';
import type { RootState } from 'store/types';
import { addPropositionToast, addTextToast } from 'store/toast/actions';
import { formatOrdinals } from 'utils/formatters';
import { Contest } from 'store/contests/types';
import { AnswerCode, PropositionState, PropositionType } from 'services/pttv/api/constants';
import { ContestAPI } from 'services/pttv/api/contests';
import { regexPropositionPage } from 'utils/regex';
import { wait } from 'utils/wait';
import i18n from '../../i18n';
import { getPropositionById, isOpenProposition } from './selectors';
import type {
  OpenPropositionArgs,
  OpenPropositionPayload,
  PropositionResolvedPayload,
  StoreProposition,
  UpdatePropositionArgs,
} from './types';
import { PropositionActions, PropositionBetResult } from './types';
import { getPropositionPayout } from './utils';

export const removeProposition = createAction<PropositionChangedEventPayloadProposition>(
  PropositionActions.REMOVE_PROPOSITION,
);

export const fetchPropositions = createAsyncThunk<StoreProposition[], Contest>(
  PropositionActions.FETCH_PROPOSITIONS,
  async (contest) => {
    const { sportId } = contest;

    const { propositions } = await ContestAPI.getPropositions({ contestId: contest.contestId });

    return propositions.map((proposition) => ({
      ...proposition,
      contestId: contest.contestId,
      sportId,
    }));
  },
);

// Open single level proposition dialog
export const openProposition = createAsyncThunk<OpenPropositionPayload, OpenPropositionArgs>(
  PropositionActions.OPEN_PROPOSITION,
  ({ propositionId, silent }, { getState }) => {
    const state = getState() as RootState;
    const proposition = getPropositionById(propositionId)(state);

    return {
      propositionId,
      silent,
      isOpenProposition: isOpenProposition(propositionId)(state),
      openNumber: proposition.openNumber,
    };
  },
);

export const propositionResolved = createAsyncThunk<PropositionResolvedPayload, StoreProposition>(
  PropositionActions.RESOLVE_PROPOSITION,
  (proposition, { dispatch, getState, rejectWithValue }) => {
    // TODO: Convenience methods for all this could be helpful, something like util had..
    let betResult: PropositionBetResult;
    const state = getState() as RootState;
    const bet = getBetByPropositionId(proposition.propositionId)(state);

    if (!bet) {
      return rejectWithValue('No bet available');
    }

    const isUndecided = proposition.correctAnswerCode === AnswerCode.UNDECIDED;
    const isUserCorrect = proposition.correctAnswerCode === bet.answerCode;
    const payoutPoints = getPropositionPayout(proposition, bet);

    const hasWonPoints = payoutPoints >= 0 && !isUndecided && isUserCorrect;
    const isOneShotAndLost = bet.oneShot && !hasWonPoints;
    const payoutPointsToDisplay = isOneShotAndLost ? 0 : Math.abs(payoutPoints);

    if (isUndecided) {
      betResult = PropositionBetResult.Undecided;
    } else if (isUserCorrect) {
      betResult = PropositionBetResult.Correct;
    } else {
      betResult = PropositionBetResult.Inorrect;
    }

    const resolvedPropositionPayload = {
      proposition,
      betResult,
      bet,
      payoutPointsToDisplay,
    };

    dispatch(
      addPropositionToast({
        proposition: {
          description: proposition.description,
          answer: bet.answerCode,
          points: payoutPointsToDisplay,
          result: betResult,
        },
      }),
    );

    return resolvedPropositionPayload;
  },
);

export const updateProposition = createAsyncThunk<
  StoreProposition | undefined,
  UpdatePropositionArgs
>(PropositionActions.UPDATE_PROPOSITION, async ({ proposition, push }, { dispatch, getState }) => {
  const state = getState() as RootState;
  const bet = getBetByPropositionId(proposition.propositionId)(state);
  const prevProposition = getPropositionById(proposition.propositionId)(state);
  const contest = getContestById(proposition.contestId)(state);
  const isInJoinedContest = !!getContestInfoByContestId(proposition.contestId)(state);
  const isOnPropositionPage = regexPropositionPage.test(window.location.hash);

  // Bail out if client does not know the contest or did not join the contest
  if (!contest || !isInJoinedContest) {
    return undefined;
  }

  const stateDidChange = !prevProposition || proposition.state !== prevProposition.state;
  const isOpenAndNotAnsweredSingleLevelProposition =
    proposition.propositionType === PropositionType.SINGLE_LEVEL &&
    proposition.state === PropositionState.OPEN &&
    !bet;

  // Open proposition betting dialog
  if (
    isOpenAndNotAnsweredSingleLevelProposition &&
    stateDidChange &&
    !isOnPropositionPage &&
    push
  ) {
    await wait(300);
    push({
      pathname: `/games/${contest.gameId}/contests/${proposition.contestId}/proposition`,
      state: { propositionId: proposition.propositionId, sound: true },
    });
  }

  // Notify users if any of their propositions resolve
  if (stateDidChange && proposition.state === PropositionState.RESOLVED) {
    dispatch(
      propositionResolved({
        ...proposition,
        sportId: contest.sportId,
        originalCorrectSubAnswerCodes: [],
      }),
    );
  }

  // Reset oneShot when user oneshot prediction is resolved as UNDECIDED
  if (
    bet &&
    bet.oneShot &&
    proposition.state === PropositionState.RESOLVED &&
    proposition.correctAnswerCode === AnswerCode.UNDECIDED
  ) {
    dispatch(
      setOneShotUsed({
        contestId: proposition.contestId,
        oneShotUsed: false,
      }),
    );
  }

  return {
    ...proposition,
    sportId: contest.sportId,
    originalCorrectSubAnswerCodes: [],
  };
});

export const createProposition = createAsyncThunk<
  unknown,
  PropositionChangedEventPayloadProposition
>(PropositionActions.CREATE_PROPOSITION, (proposition, { dispatch, getState }) => {
  const { contests, contestInfo } = getState() as RootState;
  const { contestId } = proposition;

  // Bail out if client does not know the contest
  if (!contests[contestId] || !contestInfo[contestId]) {
    return null;
  }

  return dispatch(updateProposition({ proposition }));
});

export const rollbackProposition = createAsyncThunk<
  unknown,
  PropositionChangedEventPayloadProposition
>(PropositionActions.ROLLBACK_PROPOSITION, (proposition, { dispatch, getState }) => {
  const state = getState() as RootState;
  const contestInfoItem = getContestInfoByContestId(proposition.contestId)(state);

  // Ignore rollback events from other contests
  if (!contestInfoItem) {
    return null;
  }
  const contest = getContestById(proposition.contestId)(state);
  const game = getGameByContestId(proposition.contestId)(state);
  const sport = getSportById(contest.sportId)(state);
  const gameShortName = getGameShortName(game?.gameId || '')(state);

  const bet = getBetByPropositionId(proposition.propositionId)(state);
  const prevProposition = getPropositionById(proposition.propositionId)(state);

  // Update oneShot, could be set as false when proposition was resolved as undecided.
  if (bet && bet.oneShot) {
    dispatch(
      setOneShotUsed({
        contestId: proposition.contestId,
        oneShotUsed: true,
      }),
    );
  }

  dispatch(
    addTextToast({
      message: i18n.t('ServerPush:proposition.rollback', {
        game: `${gameShortName} ${formatOrdinals(contest.period)} ${sport.period}`,
        openNumber: prevProposition.openNumber,
      }),
      to: `/games/${contest.gameId}/contests/${contest.contestId}`,
      isWarning: false,
    }),
  );

  return dispatch(updateProposition({ proposition }));
});
