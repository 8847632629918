import { createAsyncThunk } from '@reduxjs/toolkit';
import { TicketsAPI } from 'services/pttv/api/tickets';
import { GetTicketsResponse } from 'services/pttv/api/tickets/types';
import { TicketsActions } from './types';

export const fetchTickets = createAsyncThunk<GetTicketsResponse, void>(
  TicketsActions.FETCH_TICKETS,
  async () => {
    const result = await TicketsAPI.getTickets();
    return result;
  },
);
