import { DefaultTheme } from 'styled-components';
import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { PropositionAnswerScheme } from 'atoms/Proposition/proposition.theme';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { ShareType } from 'atoms/ShareButton/shareButton.theme';
import { Colors, BorderWidth, FontFamily, FontSize, FontWeight } from './types';

const theme: DefaultTheme = {
  colors: {
    Primary: Colors.Primary,
    PrimaryLight: Colors.PrimaryLight,
    PrimaryDark: Colors.PrimaryDark,
    Secondary: Colors.Secondary,
    SecondaryLight: Colors.SecondaryLight,
    SecondaryDark: Colors.SecondaryDark,
    Tertriary: Colors.Tertriary,
    TertriaryLight: Colors.TertriaryLight,
    TertriaryDark: Colors.TertriaryDark,
    White: Colors.White,
    Black: Colors.Black,
    Black90: Colors.Black90,
    Black80: Colors.Black80,
    Black70: Colors.Black70,
    Black40: Colors.Black40,
    Text: Colors.Text,
    TextSub: Colors.TextSub,
    Error: Colors.Error,
    Success: Colors.Success,
  },
  global: {
    backgroundColor: Colors.Black,
  },
  button: {
    [ButtonSchemes.Primary]: {
      default: {
        backgroundColor: Colors.Primary,
        color: Colors.White,
      },
      hover: {
        backgroundColor: Colors.PrimaryDark,
        color: Colors.White,
      },
      active: {
        backgroundColor: Colors.PrimaryLight,
        color: Colors.White,
      },
      disabled: {
        backgroundColor: Colors.Black80,
        color: Colors.Black40,
      },
    },
    [ButtonSchemes.Secondary]: {
      default: {
        backgroundColor: Colors.Secondary,
        color: Colors.White,
      },
      hover: {
        backgroundColor: Colors.SecondaryDark,
        color: Colors.White,
      },
      active: {
        backgroundColor: Colors.SecondaryLight,
        color: Colors.White,
      },
      disabled: {
        backgroundColor: Colors.Black80,
        color: Colors.Black40,
      },
    },
    [ButtonSchemes.Grey]: {
      default: {
        backgroundColor: Colors.Black40,
        color: Colors.White,
      },
      hover: {
        backgroundColor: Colors.Black50,
        color: Colors.White,
      },
      active: {
        backgroundColor: Colors.Black70,
        color: Colors.White,
      },
      disabled: {
        backgroundColor: Colors.Black80,
        color: Colors.Black40,
      },
    },
    [ButtonSchemes.DarkGrey]: {
      default: {
        backgroundColor: Colors.Black75,
        color: Colors.White,
      },
      hover: {
        backgroundColor: Colors.Black80,
        color: Colors.White,
      },
      active: {
        backgroundColor: Colors.Black70,
        color: Colors.White,
      },
      disabled: {
        backgroundColor: Colors.Black80,
        color: Colors.Black40,
      },
    },
    // TODO: Use correct colors
    [ButtonSchemes.Warning]: {
      default: {
        backgroundColor: Colors.Error,
        color: Colors.White,
      },
      hover: {
        backgroundColor: Colors.Error,
        color: Colors.White,
      },
      active: {
        backgroundColor: Colors.Error,
        color: Colors.White,
      },
      disabled: {
        backgroundColor: Colors.Black40,
        color: Colors.Black40,
      },
    },
  },
  chat: {
    bubble: {
      [ChatBubbleTypes.Winview]: {
        backgroundColor: Colors.Secondary,
        color: Colors.Black,
      },
      [ChatBubbleTypes.Left]: {
        backgroundColor: Colors.Black90,
        color: Colors.Text,
      },
      [ChatBubbleTypes.Right]: {
        backgroundColor: Colors.Primary,
        color: Colors.Text,
      },
    },
    sender: {
      [ChatBubbleTypes.Winview]: {
        color: Colors.Text,
        fontWeight: FontWeight.SemiBold,
      },
      [ChatBubbleTypes.Left]: {
        color: Colors.Black30,
        fontWeight: FontWeight.Medium,
      },
      [ChatBubbleTypes.Right]: {
        color: Colors.Black30,
        fontWeight: FontWeight.Medium,
      },
    },
  },
  layout: {
    container: {
      backgroundColor: Colors.Black90,
    },
    content: {
      backgroundColor: Colors.Black,
      color: Colors.White,
    },
    header: {
      backgroundColor: Colors.Black,
    },
  },
  iconWrap: {
    borderColor: Colors.Primary,
    borderWidth: BorderWidth.Large,
  },
  pointsBanner: {
    backgroundColor: Colors.Black90,
    color: Colors.Secondary,
    fontFamily: FontFamily.Heading,
  },
  contestTab: {
    default: {
      backgroundColor: Colors.Black80,
      color: Colors.Text,
      fontSize: FontSize.M,
      hoverBorderColor: Colors.Black70,
      statusFontSize: FontSize.S,
      statusColor: Colors.Secondary,
      height: 40,
      width: 64,
    },
    active: {
      backgroundColor: Colors.Primary,
      color: Colors.Text,
      fontSize: FontSize.XL,
      hoverBorderColor: Colors.Primary,
      statusFontSize: FontSize.MS,
      statusColor: Colors.Text,
      height: 50,
      width: 80,
    },
  },
  proposition: {
    answer: {
      [PropositionAnswerScheme.Pending]: {
        backgroundColor: Colors.Black75,
        borderColor: Colors.Black75,
        multiplyColor: Colors.Text,
      },
      [PropositionAnswerScheme.Open]: {
        backgroundColor: Colors.Black90,
        borderColor: Colors.ActiveBorder,
        multiplyColor: 'hsl(227, 40%, 68%)',
      },
      [PropositionAnswerScheme.Selected]: {
        backgroundColor: Colors.Primary,
        borderColor: Colors.Primary,
        multiplyColor: Colors.ActiveBackground,
      },
    },
    pointsHeader: {
      color: Colors.Black70,
    },
  },
  propositionTab: {
    default: {
      backgroundColor: Colors.Black,
      hoverBackgroundColor: Colors.Black,
      color: Colors.Text,
      fontSize: FontSize.L,
      borderColor: Colors.ActiveBorder,
      hoverBorderColor: Colors.Black80,
      height: 36,
      width: 36,
    },
    active: {
      backgroundColor: Colors.Black,
      hoverBackgroundColor: Colors.Black,
      color: Colors.Text,
      fontSize: FontSize.XXXL,
      borderColor: Colors.Primary,
      hoverBorderColor: Colors.PrimaryDark,
      height: 46,
      width: 46,
    },
    selected: {
      backgroundColor: Colors.Primary,
      hoverBackgroundColor: Colors.PrimaryDark,
      color: Colors.Text,
      fontSize: FontSize.L,
      borderColor: Colors.Primary,
      hoverBorderColor: Colors.PrimaryDark,
      height: 36,
      width: 36,
    },
    pending: {
      backgroundColor: Colors.Black75,
      hoverBackgroundColor: Colors.Black80,
      color: Colors.Text,
      fontSize: FontSize.L,
      borderColor: Colors.Black75,
      hoverBorderColor: Colors.Black80,
      height: 36,
      width: 36,
    },
  },
  shareButton: {
    [ShareType.Twitter]: {
      backgroundColor: Colors.Twitter,
      color: Colors.White,
    },
    [ShareType.Facebook]: {
      backgroundColor: Colors.Facebook,
      color: Colors.White,
    },
  },
};

export default theme;
