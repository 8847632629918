import React from 'react';
import { RoomLeaderboardHeader } from 'molecules/RoomLeaderboard';
import { ContestFinishingCardContainer } from './ContestFinishingCard.styled';

interface Props {
  contestId: string;
}

export const ContestFinishingCard: React.FC<Props> = ({ contestId, children }) => (
  <ContestFinishingCardContainer>
    <RoomLeaderboardHeader showTime={false} contestId={contestId} />
    {children}
  </ContestFinishingCardContainer>
);
