import React, { MouseEvent } from 'react';
import { ModalPage } from 'atoms/ModalPage';
import { FriendInfo } from 'organisms/UserInfo';
import { ReportPlayerModal } from 'organisms/ReportPlayerModal';
import { ReportType } from 'services/pttv/api/users/types';
import { FriendRequestModalContentArea } from './FriendRequestModal.styled';

interface Props {
  userId: string;
  title: string;
  isOpen: boolean;
  handleCloseButtonClick: (event: MouseEvent<HTMLButtonElement>) => void;
  handleSelectButtonClick: () => void;
  reportPlayerCallback: () => void;
}

export const FriendModal: React.FC<Props> = ({
  title,
  isOpen,
  handleCloseButtonClick,
  handleSelectButtonClick,
  reportPlayerCallback,
  userId,
}) => (
  <ModalPage title={title} isOpen={isOpen} onClose={handleCloseButtonClick}>
    <FriendRequestModalContentArea
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <FriendInfo userId={userId} callback={handleSelectButtonClick} />
      <ReportPlayerModal
        userId={userId}
        type={ReportType.FRIENDS}
        onReport={reportPlayerCallback}
      />
    </FriendRequestModalContentArea>
  </ModalPage>
);
