import { playToTv } from 'services/pttv';
import { EmptyResponse } from '../types';

export const GamesAPI = {
  gameSubscribe: (gameId: string): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse>(`winview/games/${gameId}/subscribe`, {}),

  gameUnSubscribe: (gameId: string): Promise<EmptyResponse> =>
    playToTv.delete<EmptyResponse>(`winview/games/${gameId}/unsubscribe`),
};
