import { DateFormats } from './types';
import { isToday } from './utils';

const dateFormatOptions: Record<DateFormats, Intl.DateTimeFormatOptions> = {
  [DateFormats.Short]: {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
  },
};

export const formatDate = (date: number, type: DateFormats = DateFormats.Short): string => {
  if (!isToday(date)) {
    return new Intl.DateTimeFormat('en-US', dateFormatOptions[type]).format(new Date(date));
  }
  return 'Today';
};
