export enum LeaguesMetaActions {
  UPDATE_LEAGUE_TOAST_BROADCAST_DATE = '@leaguesMeta/UPDATE_LEAGUE_TOAST_BROADCAST_DATE',
}

export interface UpdateLeaguesMetaBroadcastdate {
  leagueName: string;
  lastToastBroadcastDate: number;
}

export interface LeaguesMeta {
  lastToastBroadcastDate: number;
}

export type LeaguesMetaState = Record<string, LeaguesMeta>;
