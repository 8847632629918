import React, { useEffect, useRef, useState } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { remToPx } from 'utils/remToPx';
import { TabContent } from '../types';
import { ExtendedPropositionTab } from './ExtendedPropositionTab';
import { ScrollContainer, StyledTabBar } from './PropositionsTabsBar.styled';

interface Props {
  setIndex: (index: number) => void;
  index: number;
  tabs: TabContent[];
}

const TAB_WIDTH = 3;

export const PropositionTabBar: React.FC<Props> = ({ setIndex, index, tabs }) => {
  const tabBarRef = useRef<HTMLElement>();
  const [deltaOffset, setDeltaOffset] = useState(0);
  const [totalOffset, setTotalOffset] = useState(0);

  const getMaxOffset = (negative = false) =>
    remToPx(TAB_WIDTH) * (tabs.length - 1) * (negative ? -1 : 1);

  const getOffsetByNbOfTabs = (numberOfTabs: number, negative = false) =>
    remToPx(TAB_WIDTH) * numberOfTabs * (negative ? -1 : 1);

  const getTabsCountByTotalOffset = (type: 'round' | 'floor' = 'floor') =>
    Math[type](deltaOffset / remToPx(TAB_WIDTH));

  useEffect(() => {
    if (tabBarRef.current) {
      setTotalOffset(getOffsetByNbOfTabs(index, true));
      const left = `${getOffsetByNbOfTabs(index, true)}px`;
      tabBarRef.current.style.setProperty('--proposition-tabs-left', left);
    }
  }, [index]);

  useEffect(() => {
    // When the deltaOffset is set to 0, the swipe has ended. In that case we want a transition.
    // In all other cases the swipe is going on and should it respond instant
    const duration = deltaOffset === 0 ? '0.3s' : '0';
    const left = deltaOffset === 0 ? totalOffset : deltaOffset;
    if (tabBarRef?.current) {
      tabBarRef.current.style.setProperty('--proposition-tabs-left-duration', duration);
      tabBarRef.current.style.setProperty('--proposition-tabs-left', `${left}px`);
    }
  }, [deltaOffset]);

  const handleSwipeLeftEnd = () => {
    if (getTabsCountByTotalOffset('round') <= 1 - tabs.length && deltaOffset < 0) {
      setTotalOffset(getMaxOffset(true));
      setDeltaOffset(0);
      return;
    }
    setTotalOffset(getOffsetByNbOfTabs(getTabsCountByTotalOffset()));
    setDeltaOffset(0);
  };

  const handleSwipeRightEnd = () => {
    if (deltaOffset >= 0) {
      setTotalOffset(0);
      setDeltaOffset(0);
      return;
    }
    setTotalOffset(getOffsetByNbOfTabs(getTabsCountByTotalOffset() + 1));
    setDeltaOffset(0);
  };

  const handleSwiping = (event: SwipeEventData) => {
    // Ignore vertical scrolling.
    // This also fixes an issue with clicking items in the swipable area
    if (event.dir === 'Down' || event.dir === 'Up') {
      return;
    }

    if (deltaOffset > 0 && event.dir === 'Right') {
      const change = Math.abs(totalOffset + event.deltaX);
      setDeltaOffset(Math.min(0, deltaOffset) + Math.sqrt(change) * 2);
      return;
    }
    if (Math.abs(deltaOffset) >= getMaxOffset() && event.dir === 'Left') {
      const change = Math.abs(event.deltaX + totalOffset + getMaxOffset());
      setDeltaOffset(Math.max(getMaxOffset(true), deltaOffset) - Math.sqrt(change) * 2);
      return;
    }
    setDeltaOffset(totalOffset + event.deltaX);
  };

  const tabHandlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwipedLeft: handleSwipeLeftEnd,
    onSwipedRight: handleSwipeRightEnd,
    trackMouse: false,
    delta: 1,
  });

  // Make the ref also availableoutside Swipable
  const refPassthrough = (element: HTMLElement) => {
    tabHandlers.ref(element);
    tabBarRef.current = element;
  };

  return (
    <ScrollContainer>
      <StyledTabBar ref={refPassthrough} $tabsCount={tabs.length}>
        {tabs.map(({ label, propositionId }, i) => (
          <ExtendedPropositionTab
            propositionId={propositionId}
            key={`tab-${label}`}
            activeTab={i === index}
            onClick={() => setIndex(i)}
          >
            {label}
          </ExtendedPropositionTab>
        ))}
      </StyledTabBar>
    </ScrollContainer>
  );
};
