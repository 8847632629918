import styled, { keyframes } from 'styled-components';

const swell = keyframes`
  0% {
    transform: scale(0.6);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.6);
  }
`;

interface BubbleProps {
  delay: string;
}

export const Bubble = styled.div<BubbleProps>`
  animation: ${swell} 0.5s ease-in-out infinite;
  animation-delay: ${({ delay }) => `-${delay}`};
  background-color: currentColor;
  border-radius: 50%;
  height: 100%;
  transform: scale(0.6);
  width: 100%;
`;

interface Props {
  size: string;
}

export const BubbleLoaderContainer = styled.div<Props>`
  display: inline-flex;
  height: ${({ size }) => size};
  width: calc(3 * ${({ size }) => size});
`;
