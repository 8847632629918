import { LoginResponse } from 'services/pttv/api/users/types';
import { addRoomChatMessage } from 'store/roomChat/actions';
import {
  addRoom,
  contestCancelled,
  roomCancelled,
  updateContestInfo,
  updatePoints,
} from 'store/contestInfo/actions';
import { RoomCancelledPayload } from 'store/contestInfo/types';
import {
  addContestRoomResults,
  addContestRoomTypeLeaderboardResults,
} from 'store/contestResults/actions';
import {
  contestProblem,
  updateContest,
  updateRoomTypeCount,
  updateRoomTypeCountByRoomTypeUpdate,
} from 'store/contests/actions';
import {
  fetchFriends,
  handleFriendDeleted,
  handleFriendRequestAccepted,
  handleFriendRequestCancelled,
  handleFriendRequestDeclined,
  handleFriendRequestReceived,
} from 'store/friends/actions';
import {
  createGame,
  pushUpdateGame,
  removeGameById,
  resetGame,
  updateSubscriptions,
} from 'store/games/actions';
import { updateUserMovement } from 'store/leaderboardMovement/actions';
import { addLeagueChatMessage } from 'store/leagueChat/actions';
import { addLeagueInvitation, leagueInviteCancelled } from 'store/leagueInvites/actions';
import { fetchLeague, fetchAllLeagues, leaveLeagueSuccess } from 'store/leagues/actions';
import {
  addModal,
  addModalFromContestUpdatedEvent,
  addServerMaintenanceModal,
} from 'store/modals/actions';
import { ModalType } from 'store/modals/types';
import { addProducerMessage } from 'store/producerMessage/actions';
import {
  createProposition,
  removeProposition,
  rollbackProposition,
  updateProposition,
} from 'store/propositions/actions';
import { fetchOrUpdateRoom, fetchOrUpdateRoomScores } from 'store/rooms/actions';
import { updateRoomTypeLeaderboard } from 'store/roomTypeLeaderboard/actions';
import {
  updateRoomTypeLeaderboardCount,
  updateRoomTypeLeaderboardMeta,
  updateRoomTypeLeaderboardPrizePerRankDollarCents,
} from 'store/roomTypeLeaderboardMeta/actions';
import { addActiveRoomTypes, updateRoomType } from 'store/roomTypes/actions';
import { removeTeam, updateTeam } from 'store/teams/actions';
import {
  toggleTeamNotifications,
  updateDollarCents,
  updateUser,
  updateWincoins,
} from 'store/user/actions';
import { debugLog } from 'utils/log';
import { store } from 'store';
import { PttvError } from 'services/pttv/types';
import { Event } from './types';
import { ChatEventPayload } from './types/chat';
import {
  ContestCancelledEventPayload,
  ContestChangedEventPayload,
  ContestProblemAddedEventPayload,
  PointsUpdatedEventPayload,
} from './types/contest';
import {
  FriendDeletedEventPayload,
  FriendInvitationAcceptedEventPayload,
  FriendInvitationCancelledEventPayload,
  FriendInvitationDeclinedEventPayload,
  FriendInvitationEventPayload,
} from './types/friend';
import {
  GameChangedEventPayload,
  GameReminderNotificationsUpdatedEventPayload,
  GameResetEventPayload,
} from './types/game';
import {
  LeagueInvitationEventPayload,
  LeagueKickedEventPayload,
  LeagueChatEventPayload,
  LeagueUpdatedEventPayload,
  LeagueUserJoinedContestEventPayload,
} from './types/league';
import { PropositionChangedEventPayload } from './types/proposition';
import { RoomResultsPayload, RoomScoresUpdatedPayload, RoomUpdatedPayload } from './types/room';
import {
  ActiveRoomTypesAddedEventPayload,
  RoomTypeLeaderboardCountEventPayload,
  RoomTypeLeaderboardResultsPayload,
  RoomTypeLeaderboardUpdatesEventPayload,
  RoomTypeUpdatedEventPayload,
} from './types/roomType';
import { ProducerMessageEventPayload } from './types/server';
import {
  TeamNotificationsUpdatedEventPayload,
  TeamRemovedEventPayload,
  TeamUpdateEventPayload,
} from './types/team';
import { DollarCentsUpdatedEventPayload, WincoinsUpdatedEventPayload } from './types/user';

// Keep delegation simple, handle checks somewhere else!
export const delegatePushEvent = (
  { id, type, ...payload }: Event,
  push: (location: string | Record<string, unknown>) => void,
): unknown => {
  const { dispatch } = store;
  debugLog('[Received push event]', `Type: ${type}`, payload);

  switch (type) {
    // The Game Events
    case 'GAME_CREATED':
      return dispatch(createGame((payload as GameChangedEventPayload).game));
    case 'GAME_RESET': {
      push('/');
      return dispatch(resetGame((payload as GameResetEventPayload).gameId as string));
    }
    case 'GAME_UPDATED':
      return dispatch(pushUpdateGame((payload as GameChangedEventPayload).game));
    case 'GAME_REMINDER_NOTIFICATIONS_UPDATED':
      return dispatch(updateSubscriptions(payload as GameReminderNotificationsUpdatedEventPayload));
    case 'GAME_REMOVED':
      return dispatch(removeGameById((payload as GameChangedEventPayload).game.gameId));

    // The Propositions Events
    case 'PROPOSITION_CREATED':
      return dispatch(createProposition((payload as PropositionChangedEventPayload).proposition));
    case 'PROPOSITION_UPDATED':
      return dispatch(
        updateProposition({
          proposition: { ...(payload as PropositionChangedEventPayload).proposition },
          push,
        }),
      );
    case 'PROPOSITION_ROLLBACK':
      return dispatch(rollbackProposition((payload as PropositionChangedEventPayload).proposition));
    case 'PROPOSITION_REMOVED':
      return dispatch(removeProposition((payload as PropositionChangedEventPayload).proposition));

    // The Contest Events
    case 'CONTEST_UPDATED':
      dispatch(addModalFromContestUpdatedEvent((payload as ContestChangedEventPayload).contest));
      dispatch(updateContestInfo((payload as ContestChangedEventPayload).contest));
      return dispatch(updateContest((payload as ContestChangedEventPayload).contest));
    case 'CONTEST_PROBLEM':
      return dispatch(
        contestProblem({
          ...(payload as ContestProblemAddedEventPayload),
          force: false,
        }),
      );
    case 'CONTEST_CANCELLED':
      return dispatch(contestCancelled(payload as ContestCancelledEventPayload));
    case 'POINTS_UPDATED':
      return dispatch(updatePoints(payload as PointsUpdatedEventPayload));

    // The User Events
    case 'APPSFLYER_SET':
    case 'AVATAR_URL_UPDATED':
    case 'EMAIL_UPDATED':
    case 'EMAIL_VERIFIED':
    case 'PAID_CONTESTS_JOINED_UPDATED':
    case 'KYC_PASS_UPDATED':
    case 'KYC_INFO_UPDATED':
    case 'ACCOUNTTYPE_UPDATED':
      return dispatch(updateUser(payload as LoginResponse));
    case 'WINCOINS_UPDATED':
      return dispatch(updateWincoins((payload as WincoinsUpdatedEventPayload).wincoins));
    case 'DOLLARCENTS_UPDATED':
      return dispatch(updateDollarCents((payload as DollarCentsUpdatedEventPayload).dollarCents));

    // The Team Events
    case 'TEAM_NOTIFICATIONS_UPDATED':
      return dispatch(toggleTeamNotifications(payload as TeamNotificationsUpdatedEventPayload));
    case 'TEAM_UPDATED':
      return dispatch(updateTeam((payload as TeamUpdateEventPayload).team));
    case 'TEAM_REMOVED':
      return dispatch(removeTeam((payload as TeamRemovedEventPayload).teamId));

    // The league Events
    case 'LEAGUE_INVITATION':
      return dispatch(addLeagueInvitation(payload as LeagueInvitationEventPayload));
    case 'LEAGUE_INVITE_CANCELLED':
      return dispatch(leagueInviteCancelled(payload as LeagueKickedEventPayload));
    case 'LEAGUE_CHAT':
      return dispatch(addLeagueChatMessage((payload as LeagueChatEventPayload).message));
    case 'LEAGUE_UPDATED':
      return dispatch(fetchLeague((payload as LeagueUpdatedEventPayload).leagueName));
    case 'LEAGUE_USER_JOINED_CONTEST':
      return dispatch(fetchLeague((payload as LeagueUserJoinedContestEventPayload).leagueName));
    case 'LEAGUE_LEADERBOARD_UPDATED':
      return dispatch(fetchAllLeagues());
    case 'LEAGUE_KICKED':
      return dispatch(leaveLeagueSuccess(payload as LeagueKickedEventPayload));

    // The Rooms Events
    case 'ROOM_RESULTS':
      return dispatch(addContestRoomResults((payload as RoomResultsPayload).payload));
    case 'ROOM_CANCELLED':
      dispatch(
        addModal({
          id: (payload as RoomCancelledPayload).contestId,
          type: ModalType.RoomCancelled,
          expirationTime: Date.now(),
          unreadMessageId: (payload as RoomCancelledPayload).unreadMessageId,
          payload: {
            roomTypeId: (payload as RoomCancelledPayload).roomTypeId,
          },
        }),
      );
      return dispatch(roomCancelled(payload as RoomCancelledPayload));
    case 'ROOM_UPDATED':
      dispatch(fetchFriends());
      dispatch(addRoom((payload as RoomUpdatedPayload).room));
      return dispatch(
        fetchOrUpdateRoom({
          room: (payload as RoomUpdatedPayload).room,
          fromEvent: true,
        }),
      );
    case 'ROOM_SCORES_UPDATED': {
      const { rooms, contestId } = payload as RoomScoresUpdatedPayload;
      dispatch(fetchFriends());
      rooms.forEach((room) => {
        dispatch(fetchOrUpdateRoomScores({ room, contestId }));
      });
      return null;
    }

    // The RoomTypes Events
    case 'ROOMTYPE_LEADERBOARD_RESULTS':
      return dispatch(
        addContestRoomTypeLeaderboardResults(
          (payload as RoomTypeLeaderboardResultsPayload).payload,
        ),
      );
    case 'ROOMTYPE_UPDATED':
      return dispatch(updateRoomType((payload as RoomTypeUpdatedEventPayload).roomType));
    case 'ACTIVE_ROOMTYPE_ADDED':
      return dispatch(addActiveRoomTypes((payload as ActiveRoomTypesAddedEventPayload).roomTypes));
    case 'ROOMTYPE_LEADERBOARD_UPDATES': {
      const { contestId, leaderboardUpdates } = payload as RoomTypeLeaderboardUpdatesEventPayload;
      dispatch(fetchFriends());

      leaderboardUpdates.forEach(
        ({ roomTypeId, prizePerRankDollarCents, roomTypeLeaderboardData }) => {
          const { user } = roomTypeLeaderboardData;
          dispatch(updateRoomTypeLeaderboardMeta({ contestId, roomTypeId, eventId: id }));
          dispatch(
            updateRoomTypeCount({
              contestId,
              roomTypeId,
              count: roomTypeLeaderboardData.totalPlayers,
            }),
          );
          dispatch(
            updateRoomTypeLeaderboardPrizePerRankDollarCents({
              contestId,
              roomTypeId,
              prizePerRankDollarCents,
            }),
          );
          dispatch(updateUserMovement({ contestId, roomTypeId, user }));

          return dispatch(
            updateRoomTypeLeaderboard({ contestId, roomTypeId, ...roomTypeLeaderboardData }),
          );
        },
      );
      return null;
    }
    case 'ROOMTYPELEADERBOARD_COUNT':
      dispatch(updateRoomTypeLeaderboardCount(payload as RoomTypeLeaderboardCountEventPayload));
      return dispatch(
        updateRoomTypeCountByRoomTypeUpdate(payload as RoomTypeLeaderboardCountEventPayload),
      );

    // The RoomTypes Events
    case 'FRIEND_INVITATION':
      return dispatch(handleFriendRequestReceived(payload as FriendInvitationEventPayload));
    case 'FRIEND_INVITATION_ACCEPTED':
      return dispatch(handleFriendRequestAccepted(payload as FriendInvitationAcceptedEventPayload));
    case 'FRIEND_INVITATION_CANCELLED':
      return dispatch(
        handleFriendRequestCancelled(payload as FriendInvitationCancelledEventPayload),
      );
    case 'FRIEND_INVITATION_DECLINED':
      return dispatch(handleFriendRequestDeclined(payload as FriendInvitationDeclinedEventPayload));
    case 'FRIEND_DELETED':
      return dispatch(handleFriendDeleted(payload as FriendDeletedEventPayload));
    case 'PENDING_FRIEND_INVITATION_COUNT_CHANGED':
      return dispatch(fetchFriends());

    // The Chat Events
    case 'ROOM_CHAT':
      return dispatch(addRoomChatMessage((payload as ChatEventPayload).message));

    // The Server Events
    case 'MAINTENANCE':
      return dispatch(addServerMaintenanceModal(payload as PttvError));
    case 'PRODUCER_MESSAGE':
      return dispatch(addProducerMessage((payload as ProducerMessageEventPayload).message));

    default:
      return null;
  }
};
