import React from 'react';
import { PageWrapper } from './Page.styled';

interface Props {
  showHeader?: boolean;
}

export const Page: React.FC<Props> = ({ children, showHeader = true }) => (
  <PageWrapper showHeader={showHeader}>{children}</PageWrapper>
);
