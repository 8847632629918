import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetGenericContentByCategoryResponse } from 'services/pttv/api/genericContent/types';
import { GenericContentAPI } from 'services/pttv/api/genericContent';
import { GenericContentActions } from './types';

export const FETCH_PLAYER = '@player/FETCH_PLAYER';

export const fetchGenericContentByCategory = createAsyncThunk<
  GetGenericContentByCategoryResponse,
  string
>(GenericContentActions.FETCH_GENERIC_CONTENT, async (category) => {
  const content = await GenericContentAPI.getGenericContentBycategory(category);

  return content;
});
