import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContentArea } from 'atoms/ContentArea';
import { useAppDispatch } from 'store/hooks';
import { autoLoginCashBet } from 'store/cashBet/actions';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { clearUser } from 'store/user/actions';
import { wait } from 'utils/wait';
import { Dialog, DialogFooter } from 'atoms/Dialog';
import { ZIndex } from 'style/types';
import { UnauthorizedErrorType, UnauthorizedType } from './types';
import { getTranslations } from './utils';

interface Props {
  isOpen: boolean;
  isUserBlocked?: boolean;
  message?: string;
  type?: UnauthorizedErrorType;
}

export const UnauthorizedModal: React.FC<Props> = ({ isOpen, isUserBlocked, message, type }) => {
  const dispatch = useAppDispatch();
  const { replace } = useHistory();
  const { t } = useTranslation('UserErrorMessage');
  const [isRetrying, setIsRetrying] = useState(false);
  const texts = getTranslations(type, message);

  const autoLogin = async () => {
    if (isRetrying) {
      return;
    }

    dispatch(autoLoginCashBet());

    setIsRetrying(true); // Disable retry button to give user visual feedback.
    await wait(3000);
    setIsRetrying(false);
  };

  const buttonAction = () => {
    dispatch(clearUser());

    if (isUserBlocked) {
      replace('/contact-support');
    }
  };

  return (
    <Dialog isOpen={isOpen} title={texts.title} zIndex={ZIndex.UserError}>
      <ContentArea>{texts.description}</ContentArea>
      <DialogFooter>
        {type === UnauthorizedType.AUTOLOGIN_FAILED && (
          <Button scheme={ButtonSchemes.Secondary} onClick={autoLogin} disabled={isRetrying}>
            {isRetrying
              ? t('unauthorized.autoLoginFailed.retryingButton')
              : t('unauthorized.autoLoginFailed.retryButton')}
          </Button>
        )}
        <Button scheme={ButtonSchemes.Secondary} onClick={buttonAction}>
          {texts.button}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
