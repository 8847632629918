import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContestInfoByContestId } from 'store/contestInfo/selectors';
import { ProducerMessage } from 'hocs/withLongPoll/types/server';
import { addTextToast } from 'store/toast/actions';
import { RootState } from 'store/types';

import { ProducerMessageActions, StoreProducerMessage } from './types';

export const addProducerMessage = createAsyncThunk<StoreProducerMessage, ProducerMessage>(
  ProducerMessageActions.ADD_PRODUCER_MESSAGE,
  (payload, { dispatch, getState, rejectWithValue }) => {
    const contestInfo = getContestInfoByContestId(payload.contestId)(getState() as RootState);

    if (contestInfo) {
      dispatch(addTextToast({ message: payload.text }));
      return {
        ...payload,
        id: payload.messageId,
        seen: false,
      };
    }
    return rejectWithValue('No contestInfo found');
  },
);
