const formatData = (dataList: unknown[]) =>
  dataList.map((data) => JSON.stringify(data, null, '\t')).join('\n\n');

export class DataError extends Error {
  name = 'DataError';

  constructor(message: string, ...data: unknown[]) {
    super();

    if (data.length) {
      this.message = `${message} - ${formatData(data)}`;
    } else {
      this.message = `${message}`;
    }
  }
}
