export enum GameState {
  NEW = 'NEW',
  OPEN = 'OPEN',
  FINISHED = 'FINISHED',
  CLOSED = 'CLOSED',
}

export enum GameType {
  PRESHOW = 'PRESHOW',
  GAME = 'GAME',
}

export enum PropositionState {
  NEW = 'NEW',
  OPEN = 'OPEN',
  LOCKED = 'LOCKED',
  RESOLVING = 'RESOLVING',
  RESOLVED = 'RESOLVED',
  ROLLING_BACK = 'ROLLING_BACK',
}

export enum PropositionType {
  SINGLE_LEVEL = 'SINGLE_LEVEL',
  MULTI_LEVEL = 'MULTI_LEVEL',
}

export enum PropositionBetType {
  POINTS_MULTIPLIER = 'POINTS_MULTIPLIER',
  POINTS = 'POINTS',
  ODDS = 'ODDS',
}

export enum AnswerCode {
  PENDING = 'PENDING',
  YES = 'YES',
  NO = 'NO',
  UNDECIDED = 'UNDECIDED',
}

export enum ContestState {
  NEW = 'NEW',
  UPCOMING = 'UPCOMING',
  OPEN = 'OPEN',
  FINISHING = 'FINISHING',
  FINISHED = 'FINISHED',
  CLOSED = 'CLOSED',
}

export enum PaymentMethod {
  TICKETS = 'TICKETS',
  DOLLARCENTS = 'DOLLARCENTS',
}

export enum FriendState {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  DELETED = 'DELETED',
}

export enum FriendPendingSubState {
  INVITING = 'INVITING',
  BEING_INVITED = 'BEING_INVITED',
}

export enum LeagueMemberInvited {
  INVITED = 'INVITED',
  ACCEPTED = 'ACCEPTED',
}

export enum LeagueMemberStatus {
  USER = 'USER',
  OFFICER = 'OFFICER',
  COMMISSIONER = 'COMMISSIONER',
}

export enum TicketCategory {
  WALLET = 'WALLET',
  PROMOTIONAL = 'PROMOTIONAL',
}
