import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeaguesAPI } from 'services/pttv/api/leagues';
import { LeagueAvatar } from 'services/pttv/api/leagues/types';
import { LeagueAvatarsActions } from './types';

export const fetchLeagueAvatars = createAsyncThunk<LeagueAvatar[], void>(
  LeagueAvatarsActions.FETCH_LEAGUE_AVATARS,
  async () => {
    const { avatars } = await LeaguesAPI.getLeagueAvatars();
    return avatars;
  },
);
