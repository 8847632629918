import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store/types';
import { AppActions } from './types';

export const appUpdateFirstLaunchDate = createAction<number>(
  AppActions.APP_UPDATE_FIRST_LAUNCH_DATE,
);

export const appFirstLaunch = createAsyncThunk<number | undefined, void>(
  AppActions.APP_FIRST_LAUNCH,
  async (_, { dispatch, getState }) => {
    const {
      webApp: { firstLaunchDate, pristine },
    } = getState() as RootState;

    // NOTE: This is for client that don't have a first launch date stored
    // but already have launched the app before.
    if (!pristine && !firstLaunchDate) {
      dispatch(appUpdateFirstLaunchDate(Date.now()));
    }

    if (pristine) {
      return Date.now();
    }
    return undefined;
  },
);

export const appOpened = createAction(AppActions.APP_OPENED);
