import React from 'react';
import { getGameByContestId } from 'store/games/selectors';
import { GameHeader } from 'molecules/GameHeader';
import { getContestById } from 'store/contests/selectors';
import { getSportByContestId } from 'store/sports/selectors';
import { useAppSelector } from 'store/hooks';
import { Divider } from 'atoms/Divider';
import { formatOrdinals } from 'utils/formatters';
import { GameType } from 'services/pttv/api/constants';

interface Props {
  contestId: string;
  showTime?: boolean;
}

export const RoomLeaderboardHeader: React.FC<Props> = ({ contestId, showTime = true }) => {
  const contest = useAppSelector(getContestById(contestId));
  const game = useAppSelector(getGameByContestId(contestId));
  const sport = useAppSelector(getSportByContestId(contestId));

  if (!contest) {
    return null;
  }

  return (
    <header>
      <GameHeader gameId={contest.gameId} showTime={showTime} />
      {sport && sport.maxPeriod > 1 && game?.gameType !== GameType.PRESHOW && (
        <Divider textAlign="center" small>{`${formatOrdinals(contest.period)} ${
          sport.period
        }`}</Divider>
      )}
    </header>
  );
};
