import type { Target } from 'redux-beacon';

export const appsFlyer = (): Target => (events) => {
  if (!window) {
    return;
  }

  if (!window.AF) {
    return;
  }

  events.forEach((event) => {
    switch (event.type) {
      case 'event': {
        window.AF('pba', 'event', {
          eventType: 'EVENT',
          eventValue: event.properties,
          eventName: event.name,
        });
        break;
      }
      default:
        break;
    }
  });
};

export { appsFlyerEventsMap } from './events';
