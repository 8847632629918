import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { PlayerCount } from 'atoms/PlayerCount';
import { LeagueInviteModal } from 'organisms/LeagueInviteModal';
import { LeagueInvite } from 'store/leagueInvites/types';
import {
  InvitedBy,
  InvitedByLabel,
  LeagueDescription,
  LeagueInfo,
  LeagueInvitedCardContainer,
  LeagueName,
} from './LeagueInvitedCard.styled';

export interface LeagueInvitedCardProps {
  leagueInvite: LeagueInvite;
}

export const LeagueInvitedCard: React.FC<LeagueInvitedCardProps> = ({
  leagueInvite: { invitedBy, ...league },
}) => {
  const { t } = useTranslation('LeagueDetail');

  return (
    <LeagueInvitedCardContainer>
      <LeagueInfo>
        <Avatar src={league.avatarUrl} size={AvatarSize.ML} />
        <LeagueName>{league.name}</LeagueName>
        <LeagueDescription>{league.description}</LeagueDescription>
        <PlayerCount count={league.activeMemberCount} />
        <LeagueInviteModal
          leagueName={league.name}
          title={t('leagueInvited.title')}
          buttonText={t('leagueInvited.viewButton')}
        />
      </LeagueInfo>
      <InvitedBy>
        <InvitedByLabel>{t('leagueInvited.invitedBy')}</InvitedByLabel>
        <Avatar src={invitedBy.avatarUrl || ''} size={AvatarSize.XS} />
        {invitedBy.displayName}
      </InvitedBy>
    </LeagueInvitedCardContainer>
  );
};
