import React, { useEffect, useState } from 'react';
import defaultAvatar from 'assets/images/default-avatar.png';
import { StyledAvatar } from './Avatar.styled';
import { AvatarSize } from './types';

interface Props {
  size: AvatarSize;
  src?: string;
  border?: boolean;
}

export const Avatar: React.FC<Props> = ({ size, src = defaultAvatar, border = false }) => {
  const [source, setSource] = useState(src);

  useEffect(() => {
    setSource(src);
  }, [src]);

  const handleImageError = () => {
    setSource(defaultAvatar);
  };

  return (
    <StyledAvatar size={size} border={border}>
      <img src={source} alt="User" onError={handleImageError} />
    </StyledAvatar>
  );
};
