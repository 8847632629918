import { createReducer } from '@reduxjs/toolkit';
import { fetchUserState } from 'store/user/actions';
import {
  updateRoomTypeLeaderboardCount,
  updateRoomTypeLeaderboardMeta,
  updateRoomTypeLeaderboardPrizePerRankDollarCents,
} from './actions';
import { UpdateRoomTypeLeaderboardMetaState } from './types';

const initialState: UpdateRoomTypeLeaderboardMetaState = {};

export const roomTypeLeaderboardMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) => {
      const { roomTypeLeaderboardState } = payload;
      if (!roomTypeLeaderboardState) {
        return;
      }

      roomTypeLeaderboardState.forEach(
        ({ contestId, roomTypeId, prizePoolDollarCents, prizePerRankDollarCents = [] }) => {
          state[contestId] = state[contestId] || {};
          const roomTypeLeaderboardData = state[contestId] && state[contestId][roomTypeId];
          state[contestId][roomTypeId] = {
            ...roomTypeLeaderboardData,
            prizePoolDollarCents,
            prizePerRankDollarCents,
          };
        },
      );
    })
    .addCase(updateRoomTypeLeaderboardMeta, (state, { payload }) => {
      const { contestId, roomTypeId, eventId } = payload;
      state[contestId] = state[contestId] || { [roomTypeId]: {} };
      state[contestId][roomTypeId].lastUpdateId = eventId;
    })
    .addCase(updateRoomTypeLeaderboardPrizePerRankDollarCents, (state, { payload }) => {
      const { contestId, roomTypeId, prizePerRankDollarCents } = payload;
      state[contestId] = state[contestId] || { [roomTypeId]: {} };
      state[contestId][roomTypeId].prizePerRankDollarCents = prizePerRankDollarCents;
    })
    .addCase(updateRoomTypeLeaderboardCount, (state, { payload }) => {
      payload.contests.forEach(({ contestId, roomTypeLeaderboards }) => {
        state[contestId] = state[contestId] || {};
        roomTypeLeaderboards.forEach(
          ({ roomTypeId, prizePoolDollarCents, prizePerRankDollarCents = [] }) => {
            state[contestId][roomTypeId] = state[contestId][roomTypeId] || {};
            state[contestId][roomTypeId].prizePoolDollarCents = prizePoolDollarCents;
            state[contestId][roomTypeId].prizePerRankDollarCents = prizePerRankDollarCents;
          },
        );
      });
    });
});
