import styled from 'styled-components';
import { Gap, Padding } from 'style/types';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';

export const ContestFinishingModalContentArea = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${Padding.Default};

  ${ButtonBarContainer}+${ButtonBarContainer} {
    margin-top: ${Gap.ML};
  }
`;

export const ContestFinishingContent = styled.section`
  display: grid;
  justify-content: center;
  gap: ${Gap.Default};
  padding: ${Padding.XXXL} ${Padding.Default} ${Padding.XXXL};
  text-align: center;
  overflow: scroll;
`;
