import { createReducer } from '@reduxjs/toolkit';
import { fetchGlobalState } from '../user/actions';
import { removeTeam, updateTeam } from './actions';
import { TeamsState } from './types';

export const initialState = {} as TeamsState;

export const teamsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchGlobalState.fulfilled, (state, { payload }) =>
      payload.teams.reduce<TeamsState>(
        (acc, team) => ({
          ...acc,
          [team.teamId]: team,
        }),
        {},
      ),
    )
    .addCase(updateTeam, (state, { payload }) => {
      state[payload.teamId] = payload;
    })
    .addCase(removeTeam, (state, { payload }) => {
      if (state[payload]) {
        delete state[payload];
      }
    });
});
