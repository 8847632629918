import styled from 'styled-components';
import { pxToRem } from 'utils/pxToRem';
import { Colors, Padding } from 'style/types';

interface TabBarProps {
  $tabsCount: number;
}

export const StyledTabBar = styled.section<TabBarProps>`
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(${({ $tabsCount }) => $tabsCount}, 3rem);
  justify-items: center;
  left: var(--proposition-tabs-left);
  overflow: hidden;
  padding: ${Padding.S} calc(50% - 1.5rem);
  position: absolute;
  scrollbar-width: none;
  transition: left var(--proposition-tabs-left-duration) ease-out;

  @supports (left: constant(--proposition-tabs-left)) {
    left: constant(--propositions-left);
    transition: left constant(--proposition-tabs-left-duration) ease-out;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollContainer = styled.div`
  background-color: ${Colors.Black};
  height: ${pxToRem(74)};

  position: relative;
  width: 100%;
`;
