import { UnreadMessage } from 'services/pttv/api/unreadMessages/types';
import type { RootState } from 'store/types';

export enum ModalActions {
  ADD_MODAL = '@modal/ADD_MODAL',
  ADD_MODAL_FROM_CONTEST = '@modal/ADD_MODAL_FROM_CONTEST',
  REMOVE_MODAL = '@modal/REMOVE_MODAL',
  CLEAR_MODALS = '@modal/CLEAR_MODALS',
  ADD_SERVER_FULL_MESSAGE = '@modal/ADD_SERVER_FULL_MESSAGE',
  ADD_SERVER_MAINTENANCE_MESSAGE = '@modal/ADD_SERVER_MAINTENANCE_MESSAGE',
  ADD_MODAL_FROM_UNREAD_MESSAGES = '@modal/ADD_MODAL_FROM_UNREAD_MESSAGES',
}

export enum ModalType {
  ContestFinishing = 'contestFinishing',
  ContestFinished = 'contestFinished',
  ContestCancelled = 'contestCancelled',
  RoomCancelled = 'roomCancelled',
  ServerFullMessage = 'serverFullMessage',
  ServerMaintenanceMessage = 'serverMaintenanceMessage',
}

export interface AddModalsFromUnreadMessagesArgs {
  unreadMessages: UnreadMessage[];
  previousState: RootState;
}

export interface ModalArgs {
  type: ModalType;
  id: string;
  expirationTime: number;
  unreadMessageId?: string;
  payload?: Record<string, string | number | boolean>;
}

export interface Modal {
  id: string;
  type: ModalType;
  expirationTime: number;
  payload?: Record<string, string | number | boolean>;
}

export type ModalsState = Modal[];
