import React from 'react';
import styled from 'styled-components';
import { Colors, FontFamily, FontSize, Gap } from 'style/types';

const TeamWrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${Gap.S};
  justify-content: center;
`;

const SportAvatar = styled.img`
  height: 1rem;
  filter: brightness(0%) invert(0.4);
`;

const TeamLogo = styled.img`
  max-height: 2rem;
  max-width: 2rem;
`;

const TeamAbbreviation = styled.div`
  align-items: center;
  display: flex;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.ML};
  height: 2rem;
  justify-content: space-around;
  min-width: 2rem;
  text-align: center;
`;

const TeamCity = styled.span`
  color: ${Colors.Black20};
  font-size: ${FontSize.MS};
`;

interface Props {
  sportAvatar: string;
  teamLogo?: string;
  abbreviation: string;
  cityName: string;
}

export const CarouselTeam: React.FC<Props> = ({
  sportAvatar,
  teamLogo,
  abbreviation,
  cityName,
}) => (
  <TeamWrapper>
    <SportAvatar src={sportAvatar} alt="" />
    <TeamAbbreviation>
      {teamLogo ? <TeamLogo src={sportAvatar} alt="" /> : abbreviation}
    </TeamAbbreviation>
    <TeamCity>{cityName}</TeamCity>
  </TeamWrapper>
);
