import styled, { css } from 'styled-components';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
  Padding,
} from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  hasError?: boolean;
}

export const TextArea = styled.textarea.attrs<Props>({
  rows: '4',
})<Props>`
  background-color: ${Colors.ActiveBackground};
  border: ${BorderWidth.Large} solid ${Colors.ActiveBorder};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.Text};
  font-family: ${FontFamily.Regular};
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.SemiBold};
  line-height: ${pxToRem(24)};
  padding: ${Padding.Default};
  width: 100%;

  &::placeholder {
    font-size: ${FontSize.ML};
    font-weight: ${FontWeight.SemiBold};
    color: ${Colors.ActiveBorder};
  }

  &:disabled {
    background-color: ${Colors.InactiveBackground};
    color: ${Colors.TextSub};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${Colors.Error};
    `}
`;
