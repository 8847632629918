import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { leaveRoom } from 'store/rooms/actions';
import { clearUser, fetchUserState, signInSuccess } from 'store/user/actions';
import { debugLog } from 'utils/log';
import { fetchRoomTypeLeaderboard } from './actions';
import {
  RoomTypeLeaderboardActions,
  RoomTypeLeaderboardState,
  StoreRoomTypeLeaderboard,
} from './types';

export const initialState: RoomTypeLeaderboardState = {};

const shouldLeaderboardUpdate = (
  action: AnyAction,
): action is PayloadAction<StoreRoomTypeLeaderboard> =>
  [
    `${RoomTypeLeaderboardActions.FETCH_ROOM_TYPE_LEADERBOARD}/fulfilled`,
    RoomTypeLeaderboardActions.UPDATE_ROOM_TYPE_LEADERBOARD,
  ].includes(action.type);

export const roomTypeLeaderboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(signInSuccess, () => initialState)
    .addCase(fetchUserState.fulfilled, (state, { payload }) => {
      const { contests } = (payload.currentGamesInfo && payload.currentGamesInfo[0]) || {};

      if (!contests) {
        return initialState;
      }

      return contests.reduce(
        (acc, contest) => ({
          ...acc,
          [contest.contestId]: contest.roomTypeLeaderboards.reduce(
            (boards, leaderboard) => ({
              ...boards,
              [leaderboard.roomTypeId]: leaderboard,
            }),
            {},
          ),
        }),
        { ...state },
      );
    })
    .addCase(leaveRoom.fulfilled, (state, { payload }) => {
      const { contestId, roomTypeId } = payload;
      if (!state[contestId]) {
        return;
      }

      if (Object.keys(state[contestId]).length > 1) {
        delete state[contestId][roomTypeId];
      } else {
        delete state[contestId];
      }
    })
    .addMatcher(shouldLeaderboardUpdate, (state, { payload }) => {
      const { contestId, roomTypeId, fetched, ...roomTypeLeaderboardData } = payload;
      const { user, top } = roomTypeLeaderboardData;

      if (!user) {
        debugLog(
          `[${fetchRoomTypeLeaderboard.fulfilled}]`,
          'Action UPDATE_ROOM_TYPE_LEADERBOARD no user data',
          payload,
        );
        return;
      }

      const userOnLeaderboard = top.some((topUser) => topUser.userId === user.userId);
      if (!userOnLeaderboard) {
        top.push(roomTypeLeaderboardData.user);
      }

      state[contestId] = {
        ...state[contestId],
        [roomTypeId]: {
          contestId,
          roomTypeId,
          top,
          user,
          fetched,
        },
      };
    });
});
