import React from 'react';
import { Page } from 'molecules/PageWrappers';
import { PageNotFound } from 'molecules/PageNotFound';

const NotFound: React.FC = () => (
  <Page>
    <PageNotFound />
  </Page>
);

export default NotFound;
