/* eslint-disable @typescript-eslint/no-use-before-define */

import { GetServerResponse } from './lib/types';
import { Pttv, PttvError, PttvErrorCode, PttvErrorHandler } from './types';

const pttv = window.playtotv;

const serverFromQuery = () => {
  const { search } = window.location;
  const match = search.match(/[?|&]server=([^&]*)+/);

  if (!match) {
    return false;
  }

  const [, server] = match;

  return server;
};

let server: GetServerResponse;
let gatekeeperSessionKey: string | null = null;

export const setGatekeeperSessionKey = (sessionKey: string | null): void => {
  gatekeeperSessionKey = sessionKey;
};

const getServer = () => {
  if (!server) {
    const url = serverFromQuery() || window.emconfig.backendHost;

    // HACK: Workaround for https://exmachina.atlassian.net/browse/WV2-7263. Gatekeeper request needs to include user session id.
    // The playtotv lib doesn't support it external so .call method has to be intercepted.
    const original = pttv.server.Server.prototype.call;
    pttv.server.Server.prototype.call = function serverCall(
      method: Record<string, string>,
      params: Record<string, string>,
      settings: Record<string, unknown>,
    ) {
      if (method.gatekeeper && gatekeeperSessionKey) {
        return original.call(
          this,
          {
            ...method,
            queryKeys: ['session'],
          },
          {
            ...params,
            session: gatekeeperSessionKey,
          },
          settings,
        );
      }
      return original.call(this, method, params, settings);
    };

    server = new pttv.server.Server({ url });
  }

  return server;
};

/**
 * Request overwrite, we're using error response a lot :)
 */
const request = <T>(
  type: string,
  url: string,
  sessionKey: string | null,
  requestData = {},
): Promise<T> =>
  new Promise((resolve, reject) => {
    getServer()
      .call(
        {
          label: `${type} ${url}`,
          type,
          url: `1/${url}`,
          queryKeys: ['session'],
        },
        {
          session: sessionKey || undefined,
        },
        {
          data: requestData,
        },
      )
      .then((payload) => resolve(payload as T))
      .catch((reason) => {
        // Resolve with error data like fetch() would
        // Note: Server should return error data as { error: { .. } }
        const { data } = reason;

        if (data && data.error) {
          const { error }: { error: PttvError } = data;

          if (
            error.statusCode === 400 &&
            error.humanError === false &&
            playToTv.nonHumanError400Handler
          ) {
            playToTv.nonHumanError400Handler(error, {
              request: requestData,
              responseError: data.error,
            });
          }

          if (error.code === PttvErrorCode.MULTIPLE_SESSIONS && playToTv.multipleSessionHandler) {
            playToTv.multipleSessionHandler(error);
          }

          if (error.code === PttvErrorCode.BLOCKED && playToTv.userBlockedHandler) {
            playToTv.userBlockedHandler(error);
          }

          if (error.code === PttvErrorCode.USER_IS_BLOCKED && playToTv.userBlockedHandler) {
            playToTv.userBlockedHandler(error);
          }

          if (
            error.code === PttvErrorCode.CLIENT_UNAUTHORIZED &&
            playToTv.clientUnauthorizedHandler
          ) {
            playToTv.clientUnauthorizedHandler(error);
          }

          if (error.code === PttvErrorCode.CASHBET_RESET && playToTv.cashBetResetHandler) {
            playToTv.cashBetResetHandler(error);
          }

          if (error.code === PttvErrorCode.SERVER_FULL && playToTv.serverFullHandler) {
            playToTv.serverFullHandler(error);
          }

          if (
            error.code === PttvErrorCode.SERVER_MAINTENANCE &&
            playToTv.serverMaintenanceHandler
          ) {
            playToTv.serverMaintenanceHandler(error);
          }

          reject(error);
        } else {
          reject(reason);
        }
      });
  });

export const playToTv: Pttv = {
  multipleSessionHandler: undefined,
  userBlockedHandler: undefined,
  clientUnauthorizedHandler: undefined,
  serverFullHandler: undefined,
  serverMaintenanceHandler: undefined,
  nonHumanError400Handler: undefined,
  cashBetResetHandler: undefined,
  sessionKey: null,

  get<T>(url: string): Promise<T> {
    return request<T>('GET', url, this.sessionKey);
  },

  post<T, S = Record<string, unknown>>(url: string, data: S): Promise<T> {
    return request<T>('POST', url, this.sessionKey, data);
  },

  put<T, S = Record<string, unknown>>(url: string, data: S): Promise<T> {
    return request<T>('PUT', url, this.sessionKey, data);
  },

  delete<T>(url: string): Promise<T> {
    return request<T>('DELETE', url, this.sessionKey);
  },

  onMultipleSessions(handler: PttvErrorHandler) {
    this.multipleSessionHandler = handler;
  },

  onUserBlocked(handler: PttvErrorHandler) {
    this.userBlockedHandler = handler;
  },

  onClientUnauthorizedHandler(handler: PttvErrorHandler) {
    this.clientUnauthorizedHandler = handler;
  },

  onCashBetResetHandler(handler: PttvErrorHandler) {
    this.cashBetResetHandler = handler;
  },

  onServerFullHandler(handler: PttvErrorHandler) {
    this.serverFullHandler = handler;
  },

  onServerMaintenanceHandler(handler: PttvErrorHandler) {
    this.serverMaintenanceHandler = handler;
  },

  onNonHumanError400(handler: PttvErrorHandler) {
    this.nonHumanError400Handler = handler;
  },

  getClientTime(serverTime: number) {
    const srvr = getServer();
    if (srvr.toClientTime) {
      return srvr.toClientTime(serverTime);
    }
    return 0;
  },

  getServerTime(clientTime: number = Date.now()) {
    const srvr = getServer();
    if (srvr.toServerTime) {
      return srvr.toServerTime(clientTime);
    }
    return 0;
  },

  setSessionKey(value: string | null) {
    this.sessionKey = value;
    setGatekeeperSessionKey(value);
  },
};
