import { TicketType } from 'services/pttv/api/tickets/types';

export enum TicketTypesActions {
  FETCH_TICKET_TYPES = '@ticketTypes/FETCH_TICKET_TYPES',
  FETCH_TICKET_TYPES_BY_ROOMTYPES = '@ticketTypes/FETCH_TICKET_TYPES_BY_ROOMTYPES',
  FETCH_TICKET_TYPES_BY_HASHMAP = '@ticketTypes/FETCH_TICKET_TYPES_BY_HASHMAP',
}

export type TicketTypesState = Record<string, TicketType>;

export type RoomBuyinTicket = {
  ticketTypeId: string;
  displayName: string;
  count: number;
};

export type RoomBuyinTickets = RoomBuyinTicket[];
