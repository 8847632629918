import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Divider } from 'atoms/Divider';
import { NoResultsCard } from 'molecules/NoResultsCard';
import { Friend } from 'services/pttv/api/friends/types';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/types';
import { FriendListItem } from 'organisms/UserListItem';
import { getUserId } from 'store/user/selectors';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import ScheduleIcon from 'assets/icons/ScheduleIcon.svg';
import PlusIcon from 'assets/icons/PlusIcon.svg';
import { ContentArea } from 'atoms/ContentArea';
import { UserListContainer } from '../UserList.styled';

type Props = {
  selector: (state: RootState) => Friend[];
  label?: (items: number) => string;
  showInviteButton?: boolean;
  showNoResultMessage?: boolean;
};

export const FriendList: React.FC<Props> = ({
  selector,
  label,
  showNoResultMessage,
  showInviteButton,
}) => {
  const friends: Friend[] = useAppSelector(selector);
  const userId = useAppSelector(getUserId);
  const { push } = useHistory();
  const { t } = useTranslation('SocialLanding');

  const handleInviteButtonClick = () => {
    push('/social/invite-friend');
  };

  return (
    <>
      {label && (friends.length > 0 || showNoResultMessage) && (
        <Divider
          sticky
          noMargin
          action={
            showInviteButton ? (
              <Button
                onClick={handleInviteButtonClick}
                scheme={ButtonSchemes.Secondary}
                size={ButtonSize.Tiny}
              >
                <PlusIcon width="0.5rem" />
                {t('friendList.inviteButton')}
              </Button>
            ) : undefined
          }
        >
          {label(friends.length)}
        </Divider>
      )}
      {friends.length ? (
        <UserListContainer>
          {friends.map((friend) => (
            <FriendListItem key={friend.userId} friend={friend} isUser={friend.userId === userId} />
          ))}
        </UserListContainer>
      ) : (
        <>
          {showNoResultMessage && (
            <ContentArea>
              <NoResultsCard
                title={t('friendList.noFriendsCard.title')}
                icon={<ScheduleIcon />}
                buttonText={t('friendList.noFriendsCard.button')}
                onButtonClick={handleInviteButtonClick}
              >
                {t('friendList.noFriendsCard.description')}
              </NoResultsCard>
            </ContentArea>
          )}
        </>
      )}
    </>
  );
};
