import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUser } from 'store/user/selectors';
import wrapper from 'services/wrapper';
import { AnalyticsActions } from './types';

export const useAnalytics = (): void => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const [path, setPath] = useState('');
  const [identified, setIdentified] = useState(false);

  useEffect(() => {
    if (location.pathname === path) {
      return;
    }
    switch (location.pathname) {
      case '/':
        dispatch({ type: AnalyticsActions.VIEW_SCHEDULE });
        break;
      case '/social':
        dispatch({ type: AnalyticsActions.VIEW_SOCIAL });
        break;
      default:
    }
    setPath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!identified && user.userId) {
      dispatch({ type: AnalyticsActions.IDENTIFY });
      setIdentified(true);
    }
    if (!user?.userId) {
      wrapper.segmentReset();
    }
  }, [user]);
};
