import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { LeaguesAPI } from 'services/pttv/api/leagues';
import { CreateLeagueRequest, GetLeagueResponse } from 'services/pttv/api/leagues/types';
import { EmptyResponse } from 'services/pttv/api/types';
import { PttvError } from 'services/pttv/types';
import { getLeagueAvatarById } from 'store/leagueAvatars/selectors';
import { LeagueKickedEventPayload } from 'hocs/withLongPoll/types/league';
import { addTextToast } from 'store/toast/actions';
import type { RootState } from 'store/types';
import { getLeagueNames } from './selectors';
import {
  DemoteLeagueMemberArgs,
  KickLeagueMemberArgs,
  League,
  LeagueActions,
  LeaveLeagueArgs,
  PromoteLeagueMemberArgs,
  UpdateLeaguePayload,
  UpdateLeagueResponse,
} from './types';

export const createLeague = createAsyncThunk<
  League | PttvError,
  UpdateFieldsArgs<CreateLeagueRequest>
>(
  LeagueActions.CREATE_LEAGUE,
  async ({ request, successMessage, errorMessage }, { getState, dispatch, rejectWithValue }) => {
    try {
      const avatar = getLeagueAvatarById(request.avatarId)(getState() as RootState);
      const league = await LeaguesAPI.createLeague(request);

      dispatch(
        addTextToast({
          message: successMessage('leagueCreated'),
        }),
      );

      return {
        ...league,
        avatarUrl: avatar.url,
      };
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const updateLeague = createAsyncThunk<
  UpdateLeagueResponse | PttvError,
  UpdateFieldsArgs<UpdateLeaguePayload>
>(
  LeagueActions.UPDATE_LEAGUE,
  async ({ request, successMessage, errorMessage }, { getState, dispatch, rejectWithValue }) => {
    try {
      const avatar = getLeagueAvatarById(request.avatarId)(getState() as RootState);
      const { name, ...rest } = request;
      await LeaguesAPI.updateLeague(name, rest);

      dispatch(
        addTextToast({
          message: successMessage('leagueUpdated'),
        }),
      );
      return {
        ...request,
        avatarUrl: avatar.url,
      };
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const fetchLeague = createAsyncThunk<GetLeagueResponse, string>(
  LeagueActions.FETCH_LEAGUE,
  async (leagueName) => {
    const league = await LeaguesAPI.getLeague({ leagueName });
    return league;
  },
);

export const fetchAllLeagues = createAsyncThunk<void, void>(
  LeagueActions.FETCH_ALL_LEAGUES,
  (_, { dispatch, getState }) => {
    getLeagueNames(getState() as RootState).forEach((leagueName) =>
      dispatch(fetchLeague(leagueName)),
    );
  },
);

export const leaveLeague = createAsyncThunk<EmptyResponse, LeaveLeagueArgs>(
  LeagueActions.LEAVE_LEAGUE,
  async ({ leagueName, newCommissionerId }) => {
    const league = await LeaguesAPI.leaveLeague(leagueName, { newCommissionerId });
    return league;
  },
);

export const promoteLeagueMember = createAsyncThunk<
  EmptyResponse | PttvError,
  UpdateFieldsArgs<PromoteLeagueMemberArgs>
>(
  LeagueActions.LEAVE_LEAGUE,
  async (
    { request: { leagueName, subjectId }, successMessage, errorMessage },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const league = await LeaguesAPI.promoteMember(leagueName, { subjectId });
      dispatch(
        addTextToast({
          message: successMessage('leaguePromoteMember'),
        }),
      );
      dispatch(fetchLeague(leagueName));
      return league;
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const demoteLeagueMember = createAsyncThunk<
  EmptyResponse | PttvError,
  UpdateFieldsArgs<DemoteLeagueMemberArgs>
>(
  LeagueActions.LEAVE_LEAGUE,
  async (
    { request: { leagueName, subjectId }, successMessage, errorMessage },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const league = await LeaguesAPI.demoteMember(leagueName, { subjectId });
      dispatch(
        addTextToast({
          message: successMessage('leagueDemoteMember'),
        }),
      );
      dispatch(fetchLeague(leagueName));
      return league;
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const kickLeagueMember = createAsyncThunk<
  EmptyResponse | PttvError,
  UpdateFieldsArgs<KickLeagueMemberArgs>
>(
  LeagueActions.LEAVE_LEAGUE,
  async (
    { request: { leagueName, subjectId }, successMessage, errorMessage },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const league = await LeaguesAPI.kickMember(leagueName, { subjectId });
      dispatch(
        addTextToast({
          message: successMessage('leagueKickMember'),
        }),
      );
      dispatch(fetchLeague(leagueName));
      return league;
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const leaveLeagueSuccess = createAction<LeagueKickedEventPayload>(
  LeagueActions.LEAVE_LEAGUE_SUCCESS,
);
