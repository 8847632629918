import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import compare from 'just-compare';
import debounce from 'just-debounce-it';
import i18n from 'i18next';
import { debugLog } from 'utils/log';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { FriendsAPI } from 'services/pttv/api/friends';
import type { Friend } from 'services/pttv/api/friends/types';
import { EmptyResponse } from 'services/pttv/api/types';
import { getContestInfoIds } from 'store/contestInfo/selectors';
import { getFacebookFriendIds } from 'store/facebook/selectors';
import { getUser } from 'store/user/selectors';
import {
  FriendDeletedEventPayload,
  FriendInvitationAcceptedEventPayload,
  FriendInvitationCancelledEventPayload,
  FriendInvitationDeclinedEventPayload,
  FriendInvitationEventPayload,
} from 'hocs/withLongPoll/types/friend';
import { addTextToast } from 'store/toast/actions';
import type { AppDispatch, RootState } from 'store/types';
import { PttvError } from 'services/pttv/types';
import { getFriends } from './selectors';
import { FriendsActions } from './types';

export const updateFriends = createAction<Friend[]>(FriendsActions.UPDATE_FRIENDS);

// Remove debounce when server optimised their end
const requestFriendsLeaderboard = debounce(
  async (dispatch: AppDispatch, contestId: string, facebookFriendIds: string[]) => {
    const friends = await FriendsAPI.getFriendsLeaderboard({
      contestId,
      facebookFriendIds,
    });

    if (friends.length) {
      dispatch(updateFriends(friends));
    }

    return friends;
  },
  1000,
);

export const fetchFriends = createAsyncThunk<Friend[]>(
  FriendsActions.ADD_FRIENDS,
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const joinedContests = getContestInfoIds(state);
    const facebookFriendIds = getFacebookFriendIds(state);
    const friends = getFriends(state);

    const getFriendsResponse = await FriendsAPI.getFriends({ facebookFriendIds });

    if (!getFriendsResponse.length) {
      return [];
    }

    const filteredFriends = getFriendsResponse.filter((newFriend) => {
      const friend = friends[newFriend.userId] || {};
      return !compare(friend, newFriend);
    });

    // When in a contest use get friends leaderboard to get additional friends data.
    if (joinedContests.length) {
      joinedContests.forEach((contestId) =>
        requestFriendsLeaderboard(dispatch as AppDispatch, contestId, facebookFriendIds),
      );
    }

    return filteredFriends || [];
  },
);

// Show notification and refresh friends list.
export const handleFriendRequestReceived = createAsyncThunk<void, FriendInvitationEventPayload>(
  FriendsActions.FRIEND_REQUEST_RECEIVED,
  ({ displayName, avatarUrl }, { dispatch }) => {
    dispatch(
      addTextToast({
        message: i18n.t('ServerPush:friends.requestReceived', {
          displayName,
        }),
        to: '/profile/friends',
        avatarUrl,
      }),
    );
    dispatch(fetchFriends());
  },
);

// Show notification and refresh friends list.
export const handleFriendRequestAccepted = createAsyncThunk<
  void,
  FriendInvitationAcceptedEventPayload
>(FriendsActions.FRIEND_REQUEST_ACCEPTED, ({ displayName, avatarUrl }, { dispatch }) => {
  dispatch(
    addTextToast({
      message: i18n.t('ServerPush:friends.requestAccepted', {
        displayName,
      }),
      to: '/profile/friends',
      avatarUrl,
    }),
  );
  dispatch(fetchFriends());
});

export const handleFriendRequestCancelled = createAsyncThunk<
  void,
  FriendInvitationCancelledEventPayload
>(FriendsActions.FRIEND_REQUEST_CANCELLED, (payload, { dispatch }) => {
  debugLog('[handleFriendRequestCancelled]', '', payload);
  dispatch(fetchFriends());
});

export const handleFriendRequestDeclined = createAsyncThunk<
  void,
  FriendInvitationDeclinedEventPayload
>(FriendsActions.FRIEND_REQUEST_DECLINED, (payload, { dispatch }) => {
  debugLog('[handleFriendRequestDeclined]', '', payload);
  dispatch(fetchFriends());
});

export const handleFriendDeleted = createAsyncThunk<void, FriendDeletedEventPayload>(
  FriendsActions.FRIEND_DELETED,
  (payload, { dispatch }) => {
    debugLog('[handleFriendDeleted]', '', payload);
    dispatch(fetchFriends());
  },
);

export const acceptFriendInvite = createAsyncThunk<Friend, string>(
  FriendsActions.FRIEND_REQUEST_ACCEPT,
  async (userId) => {
    const result = await FriendsAPI.acceptFriendInvite(userId);
    return result;
  },
);

export const cancelFriendInvite = createAsyncThunk<
  EmptyResponse | PttvError,
  UpdateFieldsArgs<string>
>(
  FriendsActions.FRIEND_REQUEST_CANCEL,
  async ({ request, errorMessage, successMessage }, { dispatch, rejectWithValue }) => {
    try {
      const result = await FriendsAPI.cancelFriendInvite(request);
      dispatch(
        addTextToast({
          message: successMessage('friendCancelInvite'),
        }),
      );
      return result;
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const inviteFriend = createAsyncThunk<Friend | PttvError, UpdateFieldsArgs<string>>(
  FriendsActions.FRIEND_INVITE,
  async ({ request, errorMessage, successMessage }, { dispatch, rejectWithValue }) => {
    try {
      const result = await FriendsAPI.inviteFriend(request);
      dispatch(
        addTextToast({
          message: successMessage('friendInvite'),
        }),
      );
      return result;
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const notifyFriendsOnFirstLogin = createAsyncThunk(
  FriendsActions.NOTIFY_FRIENDS,
  (_, { getState }) => {
    const { firstLogin, facebookId } = getUser(getState() as RootState);
    const friendIds = getFacebookFriendIds(getState() as RootState);

    if (firstLogin && facebookId && friendIds.length) {
      FriendsAPI.notifyFriends(friendIds || '');
    }
  },
);
