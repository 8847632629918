import styled from 'styled-components';

import { BorderWidth, Colors } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const FooterStyle = styled.footer`
  background-color: ${Colors.Black};
  border-top: ${BorderWidth.Default} solid ${Colors.Black90};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(70)};
`;
