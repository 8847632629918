import styled from 'styled-components';
import { Colors } from 'style/types';

export const TabContentWrapper = styled.div`
  height: 100%;
  position: relative;
  overflow: visible;
`;

export const PropositionTabContainer = styled.section`
  background-color: ${Colors.Black90};
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  overflow: hidden;
`;

interface ContainerProps {
  activeIndex: number;
  $tabCount: number;
}

const tabContentWidth = 80;

export const CarouselContainer = styled.div<ContainerProps>`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(${({ $tabCount }) => $tabCount}, 1fr);
  position: absolute;
  height: 100%;
  justify-items: center;
  left: calc(10% + var(--propositions-left));
  transition: left var(--propositions-left-duration) ease-out;

  @supports (left: constant(--propositions-left)) {
    left: constant(--propositions-left);
    transition: left constant(--propositions-left-duration) ease-out;
  }

  width: ${({ $tabCount }) => `${$tabCount * tabContentWidth}%`};
  overflow: hidden;

  > * {
    filter: blur(1px);
    opacity: 0.8;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: scale(0.9);
    width: 100%;
  }

  > *:nth-child(${({ activeIndex }) => activeIndex + 1}) {
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
  }
`;
