import styled, { css } from 'styled-components';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Padding } from 'style/types';
import selectIcon from 'assets/images/select-icon.svg';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  hasLogo?: boolean;
  hasError?: boolean;
}

export const Select = styled.select<Props>`
  appearance: none;
  background-color: ${Colors.ActiveBackground};
  background-image: url(${selectIcon});
  background-size: 1rem 0.5rem;
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  border: ${BorderWidth.Large} solid ${Colors.ActiveBorder};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.Text};
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.SemiBold};
  line-height: ${pxToRem(40)};
  padding: ${Padding.XXS} ${Padding.XXL} ${Padding.XXS} ${Padding.Default};
  ${({ hasLogo }) =>
    hasLogo &&
    css`
      padding-left: ${pxToRem(48)};
    `}

  &::placeholder {
    font-size: ${FontSize.ML};
    font-weight: ${FontWeight.SemiBold};
    color: ${Colors.ActiveBorder};
  }

  &:disabled {
    background-color: ${Colors.InactiveBackground};
    color: ${Colors.TextSub};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${Colors.Error};
    `}
`;
