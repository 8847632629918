export enum AppActions {
  APP_FIRST_LAUNCH = '@app/APP_FIRST_LAUNCH',
  APP_OPENED = '@app/APP_OPENED',
  APP_UPDATE_FIRST_LAUNCH_DATE = '@app/APP_UPDATE_FIRST_LAUNCH_DATE',
}

export interface AppState {
  deviceId: string | null;
  firstLaunchDate: number | null;
  pristine: boolean;
}
