import { createReducer } from '@reduxjs/toolkit';
import type { TicketTypesState } from './types';
import { fetchTicketTypesByIds } from './actions';

const initialState: TicketTypesState = {};

export const ticketTypesReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchTicketTypesByIds.fulfilled, (state, { payload = [] }) => ({
    ...state,
    ...payload.reduce<TicketTypesState>(
      (acc, ticketType) => ({
        ...acc,
        [ticketType.ticketTypeId]: ticketType,
      }),
      {},
    ),
  }));
});
