import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { LeagueInvitedCard } from 'molecules/SocialInvited/LeagueInvitedCard';
import { getFlatLeagueInvites } from 'store/leagueInvites/selectors';
import { SocialInvitedList } from 'molecules/SocialInvited/SocialInvitedList';

export const LeagueInvitedList: React.FC = () => {
  const leagueInvites = useAppSelector(getFlatLeagueInvites);
  const { t } = useTranslation('SocialLanding');

  return (
    <>
      {leagueInvites.length > 0 && (
        <SocialInvitedList title={t('leagueInvitedList.title')}>
          {leagueInvites.map((invite) => (
            <LeagueInvitedCard key={invite.leagueId} leagueInvite={invite} />
          ))}
        </SocialInvitedList>
      )}
    </>
  );
};
