import React, { MouseEvent } from 'react';
import { ArrowAction, CrossAction, HeaderActionIcon } from './OverlayHeaderAction.styled';
import { OverlayHeaderActions } from './types';

interface Props {
  type: OverlayHeaderActions;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const OverlayHeaderAction: React.FC<Props> = ({ type, onClose }) => (
  <>
    {type === OverlayHeaderActions.Chevron && <ArrowAction onClick={onClose} />}
    {type === OverlayHeaderActions.Cross && <CrossAction onClick={onClose} />}
    {type === OverlayHeaderActions.Text && (
      <HeaderActionIcon onClick={onClose}>Done</HeaderActionIcon>
    )}
  </>
);
