import React from 'react';
import { ContestFinishingModal } from 'organisms/Modals/ContestFinishingModal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { removeModal } from 'store/modals/actions';
import { getModals } from 'store/modals/selectors';
import { ModalType } from 'store/modals/types';
import { wait } from 'utils/wait';
import { AnalyticsActions } from 'hooks/useAnalytics/types';
import { ContestFinishedModal } from './ContestFinishedModal';
import { ContestCancelledModal } from './ContestCancelledModal';
import { RoomCancelledModal } from './RoomCancelledModal';
import { ServerFullModal } from './ServerFullModal';
import { ServerMaintenanceModal } from './ServerMaintenanceModal';

export const Modals: React.FC = () => {
  const modals = useAppSelector(getModals);
  const dispatch = useAppDispatch();
  let timeoutId: NodeJS.Timeout;

  const closeCallback = async (id: string) => {
    await wait(500);
    dispatch(removeModal(id));
    clearTimeout(timeoutId);
  };

  return (
    <>
      {modals.map((modal) => {
        switch (modal.type) {
          case ModalType.ContestFinishing:
            return (
              <ContestFinishingModal
                key={modal.payload?.id as string}
                contestId={modal.payload?.id as string}
                closeCallback={() => {
                  dispatch({ type: AnalyticsActions.CONTEST_ENDED });
                  closeCallback(modal.id);
                }}
              />
            );

          case ModalType.ContestFinished:
            return (
              <ContestFinishedModal
                key={modal.payload?.id as string}
                contestId={modal.payload?.id as string}
                closeCallback={() => {
                  dispatch({ type: AnalyticsActions.CONTEST_ENDED });
                  closeCallback(modal.id);
                }}
              />
            );

          case ModalType.ContestCancelled:
            return (
              <ContestCancelledModal
                key={modal.payload?.id as string}
                contestId={modal.payload?.id as string}
                sportId={modal.payload?.sportId as string}
                gameId={modal.payload?.gameId as string}
                period={modal.payload?.period as number}
                cancelledReason={modal.payload?.cancelledReason as string}
                closeCallback={() => closeCallback(modal.id)}
              />
            );

          case ModalType.RoomCancelled:
            return (
              <RoomCancelledModal
                key={modal.payload?.id as string}
                contestId={modal.payload?.id as string}
                roomTypeId={modal.payload?.roomTypeId as string}
                closeCallback={() => closeCallback(modal.id)}
              />
            );

          case ModalType.ServerFullMessage:
            return (
              <ServerFullModal
                key={modal.id as string}
                message={modal.payload?.message as string}
                closeCallback={() => closeCallback(modal.id)}
              />
            );

          case ModalType.ServerMaintenanceMessage:
            return (
              <ServerMaintenanceModal
                key={modal.id as string}
                message={modal.payload?.message as string}
                closeCallback={() => closeCallback(modal.id)}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
};
