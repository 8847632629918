import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FriendList } from 'organisms/UserList';
import { DefaultTabs, DefaultTab } from 'molecules/Tabs';
import { getAcceptedFriends, getFriendInvites, getInvitedFriends } from 'store/friends/selectors';
import { FriendInvitedList, LeagueInvitedList } from 'organisms/UserInvitedList';
import { useAppSelector } from 'store/hooks';
import { getFlatLeagueInvites } from 'store/leagueInvites/selectors';
import { JoinedLeagueList } from 'organisms/JoinedLeagueList';
import { SocialLandingWrapper } from './SocialLanding.styled';

export const SocialLanding: React.FC = () => {
  const { t } = useTranslation('SocialLanding');
  const contentRef = useRef<HTMLDivElement>(null);
  const friendInvites = useAppSelector(getFriendInvites);
  const leagueInvites = useAppSelector(getFlatLeagueInvites);

  return (
    <SocialLandingWrapper>
      <DefaultTabs id="social-landing" contentRef={contentRef}>
        <DefaultTab label={t('tabs.friends')} showNotice={!!friendInvites.length}>
          <FriendInvitedList />
          <FriendList
            selector={getAcceptedFriends}
            label={(items) => t('friendsTab.acceptedFriends.label', { count: items })}
            showNoResultMessage
            showInviteButton
          />
          <FriendList
            selector={getInvitedFriends}
            label={(items) => t('friendsTab.invitedFriends.label', { count: items })}
          />
        </DefaultTab>
        <DefaultTab label={t('tabs.leagues')} showNotice={!!leagueInvites.length}>
          <LeagueInvitedList />
          <JoinedLeagueList />
        </DefaultTab>
      </DefaultTabs>
    </SocialLandingWrapper>
  );
};
