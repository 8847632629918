import { createSelector } from 'reselect';
import type { CurriedSelector, RootState } from 'store/types';
import type { Team, TeamsGroupedBySportId, TeamsState } from './types';

export const getTeams = (state: RootState): TeamsState => state.teams;

export const getFlatTeams = (state: RootState): Team[] => Object.values(state.teams);

export const getTeamsGroupedBySport = (state: RootState): TeamsGroupedBySportId =>
  Object.values(state.teams).reduce<TeamsGroupedBySportId>(
    (acc, team) => ({
      ...acc,
      [team.sportId]: {
        ...(acc[team.sportId] || {}),
        [team.teamId]: team,
      },
    }),
    {},
  );

export const getTeamById = (teamId: string): CurriedSelector<Team> =>
  createSelector(getTeams, (teams) => teams[teamId]);

export const getTeamsBySportId = (sportId: string): CurriedSelector<TeamsState> =>
  createSelector(getFlatTeams, (teams) =>
    teams.reduce((acc, team) => {
      if (team.sportId === sportId) {
        return {
          ...acc,
          [team.teamId]: team,
        };
      }
      return acc;
    }, {}),
  );
