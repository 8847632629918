import { createAction } from '@reduxjs/toolkit';
import { WrapperPayload, WrapperActions } from './types';

export const wrapperUUIDUpdated = createAction<Partial<WrapperPayload>>(
  WrapperActions.UUID_UPDATED,
);
export const wrapperInitSuccess = createAction<WrapperPayload>(WrapperActions.INIT_SUCCESS);
export const wrapperPropertyUpdated = createAction<Partial<WrapperPayload>>(
  WrapperActions.PROPERTY_UPDATED,
);
