import { createReducer } from '@reduxjs/toolkit';
import { sendFeedback } from './actions';
import { FeedbackState } from './types';

export const initialState = {
  loading: false,
} as FeedbackState;

export const feedbackReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(sendFeedback.pending, (state) => {
      state.loading = true;
    })
    .addCase(sendFeedback.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(sendFeedback.rejected, (state) => {
      state.loading = false;
    });
});
