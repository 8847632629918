import styled from 'styled-components';
import { CheckMarkWrapper } from 'atoms/CheckMark';
import { UserListItemWrapper } from '../UserListItem.styled';

export const AddFriendListItemWrapper = styled(UserListItemWrapper)`
  > section {
    grid-template-columns: 1rem 2.75rem auto 1fr auto;
    grid-template-areas: '. check avatar name action';

    ${CheckMarkWrapper} {
      grid-area: check;
    }
  }
`;
