import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
  Padding,
} from 'style/types';

export const WalletContainer = styled.div`
  align-items: center;
  color: ${Colors.White};
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
`;

export const Balance = styled.div`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.Medium};
  line-height: 1.1;
`;

export const ToBalance = styled(Link)`
  background-color: ${Colors.Secondary};
  border: ${BorderWidth.Default} solid transparent;
  border-radius: ${BorderRadius.S};
  color: ${Colors.Text};
  padding: ${Padding.XXS} ${Padding.XS} ${Padding.XXXS};
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.MS};
  letter-spacing: 0.57px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${Colors.SecondaryDark};
  }
`;
