import React from 'react';
import styled, { css } from 'styled-components';
import CheckIcon from 'assets/icons/checkIcon.svg';
import LockIcon from 'assets/icons/LockIcon.svg';
import { BorderWidth, Colors } from 'style/types';

interface Props {
  checked: boolean;
  locked?: boolean;
  size?: string;
}

export const CheckMarkWrapper = styled.div<Props>`
  background-color: ${({ checked }) => (checked ? Colors.Secondary : Colors.Black90)};
  border: ${BorderWidth.Large} solid ${Colors.Secondary};
  border-radius: 50%;
  align-items: center;
  display: flex;
  height: ${({ size }) => size};
  justify-content: center;
  transition: background-color 0.2s ease;
  transition-delay: ${({ checked }) => (checked ? '' : '.1s')};
  width: ${({ size }) => size};

  svg {
    color: ${Colors.White};
    opacity: ${({ checked, locked }) => (checked || locked ? '1' : '0')};
    transition: all 0.2s cubic-bezier(0.25, 0.1, 0.3, 1.85);
    transition-delay: ${({ checked }) => (checked ? '.1s' : '')};
    width: ${({ checked, size, locked }) => (checked || locked ? `calc(${size} * 0.6)` : '0')};
    ${({ locked }) =>
      locked &&
      css`
        transform: scale(0.75);
      `}
  }
`;

export const CheckMark: React.FC<Props> = ({ checked = false, size = '2rem', locked = false }) => (
  <CheckMarkWrapper checked={checked} size={size} locked={locked}>
    {!locked && <CheckIcon />}
    {locked && <LockIcon />}
  </CheckMarkWrapper>
);
