import { createReducer } from '@reduxjs/toolkit';
import { endGame, resetGame } from 'store/games/actions';
import { setPropositionViewed } from './actions';
import { PropositionsMetaState } from './types';

const initialState: PropositionsMetaState = {};

export const propositionsMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPropositionViewed, (state, { payload }) => ({
      ...state,
      [payload.propositionId]: {
        ...payload,
        viewed: true,
      },
    }))
    .addCase(endGame, () => initialState)
    .addCase(resetGame.fulfilled, (state, { payload }) => {
      const { contestIds } = payload;

      Object.keys(state).forEach((propositionId) => {
        if (contestIds.includes(state[propositionId].contestId)) {
          delete state[propositionId];
        }
      });
    });
});
