import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WifiOffIcon from 'assets/icons/WifiOffIcon.svg';
import OrientationIcon from 'assets/icons/OrientationIcon.svg';
import { useDialog } from 'hooks/useDialog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUserState } from 'store/user/actions';
import { getUserAuthToken } from 'store/user/selectors';
import { DeviceErrorMessage } from 'molecules/DeviceErrorMessage';

export function useDeviceEvents(): void {
  const { t } = useTranslation('Device');
  const authToken = useAppSelector(getUserAuthToken);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isPortrait, setIsPortrait] = useState(
    window?.orientation ? window?.orientation === 0 : true,
  );
  const { showAlert, closeAlert } = useDialog();
  const dispatch = useAppDispatch();

  const onConnectionChangeAction = async (event?: Event) => {
    if (!!event && authToken && navigator.onLine) {
      await dispatch(fetchUserState());
    }

    setIsOnline(navigator.onLine);
  };

  const orientationChangeAction = async () => {
    setIsPortrait(window?.orientation === 0);
  };

  useEffect(() => {
    // Connection Events
    onConnectionChangeAction();
    window.addEventListener('online', onConnectionChangeAction);
    window.addEventListener('offline', onConnectionChangeAction);

    // Orientation Event
    window.addEventListener('orientationchange', orientationChangeAction);

    return () => {
      window.removeEventListener('online', onConnectionChangeAction);
      window.removeEventListener('offline', onConnectionChangeAction);
      window.removeEventListener('orientationchange', orientationChangeAction);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      showAlert(
        t('offlineModal.title'),
        <DeviceErrorMessage icon={<WifiOffIcon />}>
          {t('offlineModal.description')}
        </DeviceErrorMessage>,
      );
    } else {
      closeAlert();
    }
  }, [isOnline]);

  useEffect(() => {
    if (!isPortrait) {
      showAlert(
        t('orientationModal.title'),
        <DeviceErrorMessage icon={<OrientationIcon />}>
          {t('orientationModal.description')}
        </DeviceErrorMessage>,
      );
    } else {
      closeAlert();
    }
  }, [isPortrait]);
}
