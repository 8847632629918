import { RoomType } from 'services/pttv/api/rooms/types';
import { RoomTypeState } from './types';

export const hasTicketsPayoutModel = (roomType: RoomType): boolean =>
  !!(roomType.ticketPayoutModel && roomType.ticketPayoutModel.ticketPerRank);

export const getTicketTypeIdsFromPayoutModel = (roomType: RoomType): string[] =>
  hasTicketsPayoutModel(roomType)
    ? Object.values(roomType?.ticketPayoutModel?.ticketPerRank || {}).filter(
        (ticketTypeId) => !!ticketTypeId,
      )
    : [];

export const sortRoomtypes = (roomTypeIds: string[], roomTypes: RoomTypeState): string[] => {
  const groupedRoomTypes = roomTypeIds.reduce<Record<string, string[]>>(
    (acc, roomTypeId) => {
      if (roomTypes[roomTypeId].isPromoRoom) {
        acc.promo.push(roomTypeId);
      } else {
        acc.regular.push(roomTypeId);
      }
      return acc;
    },
    { promo: [], regular: [] },
  );
  return [...groupedRoomTypes.promo, ...groupedRoomTypes.regular];
};
