import styled from 'styled-components';
import React from 'react';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Gap, Padding } from 'style/types';

export const Name = styled.h3`
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.M};
  margin: 0;
`;

interface Props {
  children: JSX.Element;
}

export const FriendInvitedCardContainer = styled.li.attrs<Props>(({ children }) => ({
  children: <article>{children}</article>,
}))`
  list-style: none;

  > article {
    border: ${BorderWidth.Large} solid ${Colors.Black75};
    border-radius: ${BorderRadius.Default};
    padding: ${Padding.Default};

    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: ${Gap.L};
  }
`;
