import { createReducer } from '@reduxjs/toolkit';
import { fetchContestHistoryByContestId } from 'store/contestHistory/actions';
import { fetchUserState } from '../user/actions';
import {
  removeContest,
  updateContest,
  updateRoomTypeCount,
  updateRoomTypeCountByRoomTypeUpdate,
} from './actions';
import { ContestsState, RoomTypeCount } from './types';

export const initialState = {} as ContestsState;

export const contestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateContest.fulfilled, (state, { payload }) => {
      state[payload.contestId] = payload;
    })
    .addCase(removeContest, (state, { payload }) => {
      delete state[payload];
    })
    .addCase(fetchUserState.fulfilled, (state, { payload }) =>
      payload.contests.reduce<ContestsState>(
        (acc, contest) => ({
          ...acc,
          [contest.contestId]: contest,
        }),
        state,
      ),
    )
    .addCase(updateRoomTypeCount, (state, { payload: { roomTypeId, contestId, count } }) => {
      if (!state[contestId]) {
        return;
      }
      state[contestId].roomTypeCounts = [...(state[contestId].roomTypeCounts || [])];

      const index = (state[contestId].roomTypeCounts || []).findIndex(
        (_roomType) => _roomType.roomTypeId === roomTypeId,
      );

      if (index !== -1) {
        (state[contestId].roomTypeCounts as RoomTypeCount[])[index].count = count;
      } else {
        (state[contestId].roomTypeCounts as RoomTypeCount[]).push({ roomTypeId, count });
      }
    })
    .addCase(updateRoomTypeCountByRoomTypeUpdate, (state, { payload }) => {
      payload.contests.forEach((contest) => {
        if (!state[contest.contestId]) {
          return;
        }

        if (!contest.roomTypeLeaderboards.length) {
          state[contest.contestId].roomTypeCounts = [];
        }

        contest.roomTypeLeaderboards.forEach((roomType) => {
          state[contest.contestId].roomTypeCounts = state[contest.contestId].roomTypeCounts || [];
          const { roomTypeCounts } = state[contest.contestId];
          const index = roomTypeCounts.findIndex(
            ({ roomTypeId }) => roomTypeId === roomType.roomTypeId,
          );

          if (index !== -1) {
            state[contest.contestId].roomTypeCounts[index].count = roomType.count;
          } else if (roomType.count) {
            state[contest.contestId].roomTypeCounts.push({
              roomTypeId: roomType.roomTypeId,
              count: roomType.count,
            });
          }
        });
      });
    })
    .addCase(fetchContestHistoryByContestId.fulfilled, (state, { payload }) => {
      state[payload.contest.contestId] = {
        ...state[payload.contest.contestId],
        ...payload.contest,
      };
    });
});
