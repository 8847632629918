export enum GenericContentActions {
  FETCH_GENERIC_CONTENT = '@genericContent/FETCH_GENERIC_CONTENT',
}

export type GenericContent = {
  category: string;
  categoryName: string;
  categoryBodyHtml: string;
  ownerCms: boolean;
};

export type GenericContentState = {
  [category: string]: GenericContent;
};
