import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentContestInfo } from 'store/contestInfo/selectors';
import { getUser } from 'store/user/selectors';
import { getWrapper } from 'store/wrapper/selectors';
import type { RootState } from 'store/types';
import { FeedbackRequest } from 'services/pttv/api/feedback/types';
import { FeedbackAPI } from 'services/pttv/api/feedback';
import { addTextToast } from 'store/toast/actions';
import { PttvError } from 'services/pttv/types';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { version as clientVersion } from '../../../version';
import { ContactSupportPayload, FeedbackActions } from './types';

export const sendFeedback = createAsyncThunk<void, UpdateFieldsArgs<ContactSupportPayload>>(
  FeedbackActions.SEND_FEEDBACK,
  async ({ errorMessage, request }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { contestId, gameId, rooms } = getCurrentContestInfo(state) || {};
    const { userId } = getUser(state);
    const { deviceModel: deviceType, osVersion, version: applicationVersion } = getWrapper(state);

    const data: FeedbackRequest = {
      ...request,
      clientVersion,
      applicationVersion: `${applicationVersion}`,
      osVersion: osVersion || '',
      deviceType: deviceType || '',
    };

    if (contestId) {
      data.currentContestId = contestId;
    }

    if (gameId) {
      data.currentGameId = gameId;
    }

    if (rooms) {
      data.currentRoomIds = [...rooms];
    }

    if (userId) {
      try {
        await FeedbackAPI.sendFeedback(data);
      } catch (error: unknown) {
        dispatch(
          addTextToast({
            message: errorMessage(error as PttvError),
          }),
        );
      }
    }

    try {
      await FeedbackAPI.sendUnauthenticatedFeedback(data);
    } catch (error: unknown) {
      dispatch(
        addTextToast({
          message: errorMessage(error as PttvError),
        }),
      );
    }
  },
);
