import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { ErrorModal } from 'organisms/ErrorModal';

export const withErrorBoundary =
  <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) =>
  (props: P): JSX.Element =>
    (
      <ErrorBoundary fallback={<ErrorModal />}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
