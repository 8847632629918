import { Dispatch } from 'redux';
import type { RootState } from 'store/types';
import type { WrapperPayload } from 'store/wrapper/types';
import { getUrlParam } from 'utils/url';
import NativeWrapper from './native-wrapper';
import {
  Coordinates,
  FacebookGraphRequestArgs,
  FacebookLoginArgs,
  FacebookLoginResult,
  NativeProps,
  Subscription,
  SubscriptionHandler,
  WebProps,
} from './types';
import WebWrapper from './web-wrapper';

class Wrapper {
  state: { segmentUserId: string | null };

  isNative: boolean;

  impl: NativeWrapper | WebWrapper;

  constructor() {
    const url = window.location.href;
    this.isNative = Boolean(getUrlParam('wrapper', url));

    if (this.isNative) {
      this.impl = new NativeWrapper();
    } else {
      this.impl = new WebWrapper();
    }

    this.state = {
      // native doesn't have method to obtain userId - we will store it during initialization
      segmentUserId: null,
    };

    if (__DEV__) {
      window.devWrapper = this.impl;
    }
  }

  onPropUpdated(callback: (props: Partial<WrapperPayload>) => void): void {
    this.impl.onPropUpdated(callback);
  }

  onReady(callback: (props: WebProps | NativeProps) => void): void {
    this.impl.onReady(callback);
  }

  // Web only
  setupUUID(dispatch: Dispatch, getState: () => RootState): void {
    if (!this.impl.setupUUID) {
      return;
    }

    this.impl.setupUUID(dispatch, getState);
  }

  onAppPause(handler: SubscriptionHandler<unknown>): Subscription | void {
    return this.impl.onAppPause(handler);
  }

  onAppResume<T>(handler: SubscriptionHandler<T>): Subscription | SubscriptionHandler<T> {
    return this.impl.onAppResume<T>(handler);
  }

  onAppStop(handler: SubscriptionHandler<unknown>): Subscription | void {
    return this.impl.onAppStop(handler);
  }

  removeAppPauseListener(subscription: Subscription): void {
    this.impl.removeAppPauseListener(subscription);
  }

  removeAppResumeListener(subscription: (() => void) | Subscription): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.impl.removeAppResumeListener(subscription);
  }

  removeAppStopListener(subscription: Subscription): void {
    this.impl.removeAppStopListener(subscription);
  }

  onBackButton(handler: SubscriptionHandler<unknown>): Subscription | void {
    return this.impl.onBackButton(handler);
  }

  removeBackButtonListener(subscription: Subscription): void {
    this.impl.removeBackButtonListener(subscription);
  }

  appsFlyerTrackEvent(event: string, properties: Record<string, unknown> = {}): Promise<void> {
    return this.impl.appsFlyerTrackEvent(event, properties);
  }

  appSettingsOpen(): Promise<void> {
    return this.impl.appSettingsOpen();
  }

  deviceGeoLocationSettingsOpen(): Promise<void> {
    return this.impl.deviceGeoLocationSettingsOpen();
  }

  isLocationServiceEnabled() {
    return this.impl.isLocationServiceEnabled();
  }

  getCurrentCoordinates(): Promise<Coordinates> {
    return this.impl.getCurrentCoordinates();
  }

  openUrl(url: string) {
    this.impl.openUrl(url);
  }

  openOverlay(url: string) {
    this.impl.openOverlay(url);
  }

  /**
   * @param {Object} params - Params of doing a graph request.
   * @param {string} params.path - URL that is being shared.
   * @param {string} [params.params] - Params passed along with the graph request (see https://developers.facebook.com/docs/graph-api/reference).
   * @param {string} [params.method=get] - HTTP method (get, post, delete).
   */
  facebookGraphRequest<T>(params: FacebookGraphRequestArgs): Promise<T> {
    return this.impl.facebookGraphRequest(params);
  }

  /**
   * @param {Object} params - Login params.
   * @param {boolean} params.interactive - Should the login be silent or show UI (Native only).
   * @param {Array} params.permissions - Permissions required for the login (see https://developers.facebook.com/docs/facebook-login/permissions/).
   */
  facebookLogin(params: FacebookLoginArgs): Promise<FacebookLoginResult> {
    return this.impl.facebookLogin(params);
  }

  facebookLogout(): Promise<void> {
    return this.impl.facebookLogout();
  }

  /**
   * @param {Object} params - Share params.
   * @param {string} params.contentURL - URL that is being shared.
   * @param {string} [params.contentTitle] - Title of the share post (Native only).
   * @param {string} [params.contentDescription] - Description of the share post (Native only).
   */
  facebookShare(params: Record<string, string>): Promise<unknown> {
    return this.impl.facebookShare(params);
  }

  getNotificationStatus(): Promise<Record<string, boolean>> {
    return this.impl.getNotificationStatus() as unknown as Promise<Record<string, boolean>>;
  }

  getStorageItem(key: string): Promise<Record<string, unknown> | void> {
    return this.impl.getStorageItem(key);
  }

  removeStorageItem(key: string): Promise<Record<string, unknown> | void> {
    return this.impl.removeStorageItem(key);
  }

  setStorageItem(key: string, value: string): Promise<Record<string, unknown> | void> {
    return this.impl.setStorageItem(key, value);
  }

  locationRequestAuthorization() {
    return this.impl.locationRequestAuthorization();
  }

  /**
   * @return Promise<"granted"|"denied"|"default">
   */
  getNotificationPermissionState() {
    return this.impl.getNotificationPermissionState();
  }

  closeApp() {
    return this.impl.closeApp();
  }

  /**
   * @returns {Promise<void, Error>}
   */
  segmentReady() {
    return this.impl.segmentReady();
  }

  /**
   * @returns {Promise<string|null>}
   */
  segmentGetUserId() {
    return new Promise((resolve) => {
      resolve(this.state.segmentUserId);
    });
  }

  /**
   * @returns {Promise<string, Error>}
   */
  segmentGetAnonymousId() {
    return this.impl.segmentGetAnonymousId();
  }

  segmentGetUserOrAnonymousId() {
    return new Promise((resolve, reject) => {
      this.segmentGetUserId().then((userId) => {
        if (userId) {
          resolve(userId);
          return;
        }

        this.segmentGetAnonymousId().then(resolve, reject);
      });
    });
  }

  /**
   * @param userId - string
   * @param props - object
   * @returns {Promise<void, Error>}
   */
  segmentIdentify(userId: string, props: Record<string, unknown>): Promise<void> {
    this.state.segmentUserId = null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.impl.segmentIdentify(userId, props).then(
      () => {
        this.state.segmentUserId = userId;
      },
      (e) => Promise.reject(e),
    );
  }

  /**
   * @param segmentName - string
   * @param segmentProperties - object
   * @returns {Promise<void, Error>}
   */
  segmentTrack(segmentName: string, segmentProperties: Record<string, string>) {
    return this.impl.segmentTrack(segmentName, segmentProperties);
  }

  /**
   * @returns {Promise<void, Error>}
   */
  segmentReset() {
    this.state.segmentUserId = null;
    return this.impl.segmentReset();
  }

  socialShare(params: Record<string, string | number>, fallback?: () => void): Promise<void> {
    return this.impl.socialShare(params, fallback);
  }

  vibrate(duration: Iterable<number>) {
    this.impl.vibrate(duration);
  }
}

export default new Wrapper();
