import React from 'react';
import { BuyInText } from 'molecules/BuyInText';
import { TableCell, TableRow } from 'atoms/Table';
import { RoomType } from 'services/pttv/api/rooms/types';
import { TicketType } from 'services/pttv/api/tickets/types';
import { TypeListTable } from './RoomTypesList.styled';

interface Props {
  roomTypes: RoomType[];
  buyInTickets: Record<string, TicketType>;
}

export const RoomTypesList: React.FC<Props> = ({ roomTypes = [], buyInTickets }) => (
  <TypeListTable>
    <tbody>
      {roomTypes.map((roomType) => {
        const ticketType = (!!roomType.ticketBuyIn && buyInTickets[roomType.ticketBuyIn]) || null;

        return (
          <TableRow key={roomType.roomTypeId}>
            <TableCell>{roomType.name}</TableCell>
            <TableCell align="right">
              <BuyInText roomType={roomType} ticketName={ticketType?.displayName} />
            </TableCell>
          </TableRow>
        );
      })}
    </tbody>
  </TypeListTable>
);
