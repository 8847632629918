import React, { useState, useEffect, RefObject } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { useMemoizedTabs } from 'hooks/useMemoizedTabs';
import { Direction, ContestTabProps } from '../types';
import {
  CarouselContainer,
  ContestTabContainer,
  ExtendedContestTab,
  StyledTabBar,
  TabBarTitle,
  TabContentWrapper,
} from './ContestTabs.styled';

interface Props {
  children: React.ReactElement<ContestTabProps>[];
  extraContent?: React.ReactElement | string;
  title?: string;
  contentRef: RefObject<HTMLDivElement> | undefined;
}

interface TabContent {
  label: string;
  status?: string | undefined;
}

export const ContestTabs: React.FC<Props> = ({ children, extraContent, title, contentRef }) => {
  const [offset, setOffset] = useState(0);
  const [tabs, setTabs] = useState<TabContent[]>([]);
  const [index, setIndex] = useMemoizedTabs('contest');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
    const content = children.map(({ props: { children, ...rest } }) => rest);
    setTabs(content);
  }, [children]);

  const handleTabClick = (tabIndex: number) => {
    setOffset(0);
    setIndex(tabIndex);
  };

  const handleSwipeEnd = (dir: Direction) => {
    setOffset(0);
    if (
      (index > 0 && dir === Direction.Right) ||
      (index < tabs.length - 1 && dir === Direction.Left)
    ) {
      setIndex(index + dir);
    }
  };

  const handleSwiping = (event: SwipeEventData) => {
    // Ignore vertical scrolling.
    // This also fixes an issue with clicking items in the swipable area
    if (event.dir === 'Down' || event.dir === 'Up') {
      return;
    }
    if (index === 0 && event.dir === 'Right') {
      setOffset(Math.sqrt(event.deltaX) * 2);
      return;
    }
    if (index === tabs.length - 1 && event.dir === 'Left') {
      setOffset(-1 * Math.sqrt(event.absX) * 2);
      return;
    }
    if (Math.abs(Math.abs(offset) - event.absX) > 5) {
      setOffset(event.deltaX);
    }
  };

  const handlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: () => setOffset(0),
    onSwipedLeft: () => handleSwipeEnd(Direction.Left),
    onSwipedRight: () => handleSwipeEnd(Direction.Right),
    trackMouse: false,
    delta: 10,
  });

  useEffect(() => {
    // When the offset is set to 0, the swipe has ended. In that case we want a transition.
    // In all other cases the swipe is going on and should it respond instant
    const duration = offset === 0 ? '0.3s' : '0';
    const left = `calc(${offset}px - ${index * 100}%)`;
    if (contentRef?.current) {
      contentRef.current.style.setProperty('--contests-left-duration', duration);
      contentRef.current.style.setProperty('--contests-left', left);
    }
  }, [index, offset]);

  useEffect(() => {
    if (index > tabs.length - 1) {
      setIndex(0);
    }
  }, [tabs]);

  return (
    <ContestTabContainer>
      <section>
        {title && <TabBarTitle>{title}</TabBarTitle>}
        <StyledTabBar tabsCount={tabs.length}>
          {tabs.map(({ label, status }, i) => (
            <ExtendedContestTab
              layoutId={`tab-${label}`}
              key={`tab-${label}`}
              $activeTab={i === index}
              index={i - index}
              onClick={() => handleTabClick(i)}
              transition={{ duration: 0.4, type: 'tween' }}
              scheme={i === index ? 'active' : 'default'}
            >
              {label}
              <div>{status}</div>
            </ExtendedContestTab>
          ))}
        </StyledTabBar>
        {extraContent}
      </section>
      <TabContentWrapper {...handlers}>
        <CarouselContainer ref={contentRef} $tabCount={children.length}>
          {children}
        </CarouselContainer>
      </TabContentWrapper>
    </ContestTabContainer>
  );
};
