import React, { Suspense } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Page, SlideIn } from 'molecules/PageWrappers';
import { PageLoader } from 'atoms/PageLoader';
import { withErrorBoundary } from 'hocs/withErrorBoundary';

const Home = React.lazy(() => import('.'));
const RoomSelection = React.lazy(() => import('./roomSelection'));

export const schedulePaths = {
  roomSelection: '/schedule/:gameId/room-selection',
};

interface Params {
  gameId: string;
  contestId: string;
  roomId: string;
  roomTypeId: string;
}

const ScheduleRoutes: React.FC = withErrorBoundary(() => {
  const match = useRouteMatch<Params>(Object.values(schedulePaths));
  const history = useHistory();

  if (!match?.isExact) {
    return null;
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Page>
        <Home />
      </Page>
      <SwitchTransition mode="in-out">
        <CSSTransition
          key={match.path}
          timeout={{
            appear: history.action === 'POP' ? 0 : 500,
            enter: history.action === 'POP' ? 0 : 500,
            exit: 500,
          }}
          classNames="slidein"
          unmountOnExit
        >
          <>
            {match.path === schedulePaths.roomSelection && (
              <SlideIn zIndex={0}>
                <RoomSelection gameId={match.params.gameId} />
              </SlideIn>
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
    </Suspense>
  );
});

export default withErrorBoundary(ScheduleRoutes);
