import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store/types';
import { getUserId, isUserOnLeaderboard } from 'store/user/selectors';
import {
  LeaderboardMovementActions,
  UpdateFetchedPlayersMovementArgs,
  UpdateFetchedPlayersMovementPayload,
  UpdateUserMovementArgs,
  UpdateUserMovementPayload,
} from './types';

export const updateFetchedPlayersMovement = createAsyncThunk<
  UpdateFetchedPlayersMovementPayload,
  UpdateFetchedPlayersMovementArgs
>(
  LeaderboardMovementActions.UPDATE_FETCHED_PLAYERS_MOVEMENT,
  ({ players, contestId, roomTypeId, user }, { getState }) => {
    const state = getState() as RootState;
    const userId = getUserId(state);
    const isOnLeaderboard = isUserOnLeaderboard(state);

    return { players, isOnLeaderboard, userId, user, contestId, roomTypeId };
  },
);

export const updateUserMovement = createAsyncThunk<
  UpdateUserMovementPayload,
  UpdateUserMovementArgs
>(
  LeaderboardMovementActions.UPDATE_USER_MOVEMENT,
  ({ user, contestId, roomTypeId }, { getState }) => {
    const state = getState() as RootState;
    const userId = getUserId(state);
    const isOnLeaderboard = isUserOnLeaderboard(state);

    return { isOnLeaderboard, userId, user, contestId, roomTypeId };
  },
);
