import styled from 'styled-components';
import { BorderWidth, Colors, FontFamily, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';
import { pxToRem } from 'utils/pxToRem';
import { HeaderActionIcon } from 'atoms/OverlayHeaderAction/OverlayHeaderAction.styled';

export const LeagueHeaderContainer = styled.header`
  align-items: center;
  background-color: ${Colors.Black};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'members avatar sport' 'description description description';
  justify-items: center;
  padding: ${Padding.Default};

  position: relative;

  > ${StyledAvatar} {
    grid-area: avatar;
    border: ${BorderWidth.Large} solid ${Colors.Primary};
  }

  > ${HeaderActionIcon} {
    top: 1.5rem;
  }
`;

export const Members = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${FontFamily.Heading};
  grid-area: members;
  font-size: ${pxToRem(36)};
  line-height: 1;

  > div {
    color: ${Colors.Text};
    text-align: center;
  }

  > button {
    margin-top: ${Gap.XS};
  }

  svg {
    height: 0.6em;
  }
`;

export const MembersLabel = styled.span`
  color: ${Colors.Black20};
  font-size: ${FontSize.M};
  letter-spacing: 0.5px;
  margin-top: ${Padding.XXS};
  text-transform: uppercase;
`;

export const Sport = styled.div`
  grid-area: sport;
  letter-spacing: 0.7px;
  line-height: 1;
  text-align: center;
`;

export const SportLogo = styled.img`
  max-height: ${pxToRem(32)};
  max-width: ${pxToRem(32)};
  filter: brightness(0%) invert(0.4);
  margin-bottom: ${Padding.XS};
`;

export const TeamAbbreviation = styled.div`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.L};
  text-transform: uppercase;
`;

export const TeamCity = styled.div`
  color: ${Colors.Black20};
  font-size: ${FontSize.MS};
`;

export const LeagueDescription = styled.div`
  grid-area: description;
  padding-top: ${Padding.S};
  text-align: center;
`;

export const LeagueName = styled.h2`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XL};
  font-weight: ${FontWeight.Medium};
  letter-spacing: 1px;
  line-height: 1.1;
  margin: 0;
  text-transform: uppercase;
`;

export const Description = styled.div`
  color: ${Colors.Black20};
`;

export const Edit = styled.div`
  padding-top: ${Padding.S};
`;
