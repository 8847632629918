import React from 'react';
import TicketsIcon from 'assets/icons/TicketsIcon.svg';
import { useAppSelector } from 'store/hooks';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { formatCurrency } from 'utils/formatters';
import { RoomType } from 'services/pttv/api/rooms/types';

interface Props {
  roomTypeId?: string;
  roomType?: RoomType;
  ticketName?: string;
}

export const BuyInText: React.FC<Props> = ({
  roomTypeId,
  roomType: roomTypeArg,
  ticketName = '',
}) => {
  const roomType = roomTypeArg || useAppSelector(getRoomTypeById(roomTypeId || ''));

  return (
    <>
      {!!roomType.dollarCentsBuyIn && (
        <>{formatCurrency(roomType.dollarCentsBuyIn / 100, 2, true)}</>
      )}
      {!!roomType.ticketBuyIn && (
        <>
          <TicketsIcon style={{ maxHeight: '1em' }} /> {!!ticketName && ticketName}
        </>
      )}
      {!roomType.dollarCentsBuyIn && !roomType.ticketBuyIn && <>Free</>}
    </>
  );
};
