import { useTranslation } from 'react-i18next';
import { PttvErrorType } from 'services/pttv/types';
import { UnauthorizedType } from './types';

export interface Translation {
  title?: string;
  description?: string;
  button: string;
}

export const getTranslations = (type?: string, message?: string): Translation => {
  const { t } = useTranslation('UserErrorMessage');

  switch (type) {
    case PttvErrorType.AUTHENTICATION_FAILED:
      return {
        title: t('unauthorized.authenticationFailed.title'),
        description: t('unauthorized.authenticationFailed.description'),
        button: t('unauthorized.authenticationFailed.button'),
      };
    case UnauthorizedType.AUTOLOGIN_FAILED:
      return {
        title: t('unauthorized.autoLoginFailed.title'),
        description: t('unauthorized.autoLoginFailed.description'),
        button: t('unauthorized.autoLoginFailed.button'),
      };
    case PttvErrorType.MULTIPLE_SESSIONS:
      return {
        title: t('unauthorized.multipleSessions.title'),
        description: t('unauthorized.multipleSessions.description'),
        button: t('unauthorized.multipleSessions.button'),
      };
    case UnauthorizedType.REALLY_UNAUTHORIZED:
      return {
        title: t('unauthorized.reallyUnauthorized.title'),
        description: t('unauthorized.reallyUnauthorized.description'),
        button: t('unauthorized.reallyUnauthorized.button'),
      };
    case PttvErrorType.UNAUTHORIZED:
      return {
        title: t('unauthorized.unauthorized.title'),
        description: t('unauthorized.unauthorized.description'),
        button: t('unauthorized.unauthorized.button'),
      };
    default:
      return {
        title: type,
        description: message,
        button: t('unauthorized.default.button'),
      };
  }
};
