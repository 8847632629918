import { playToTv } from 'services/pttv';
import { GetTicketByTypeIdRequest, GetTicketByTypeIdResponse, GetTicketsResponse } from './types';

export const TicketsAPI = {
  getTickets: (): Promise<GetTicketsResponse> =>
    playToTv.get<GetTicketsResponse>('winview/tickets'),

  getTicketByTypeId: ({
    ticketTypeId,
  }: GetTicketByTypeIdRequest): Promise<GetTicketByTypeIdResponse> =>
    playToTv.get<GetTicketByTypeIdResponse>(`winview/tickets/${ticketTypeId}`),
};
