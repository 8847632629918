import styled, { css } from 'styled-components';
import { BorderWidth, Colors, FontFamily, FontSize, FontWeight, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  stickyHead?: boolean;
}
export const Table = styled.table<Props>`
  border-collapse: collapse;
  width: 100%;

  ${({ stickyHead }) =>
    stickyHead &&
    css`
      positioon: sticky;
    `}
`;

export const TableRow = styled.tr`
  border-bottom: ${BorderWidth.Default} solid ${Colors.Black75};
  height: ${pxToRem(60)};
  padding: 0 ${Padding.Default};

  thead & {
    border-top: ${BorderWidth.Default} solid ${Colors.Black75};
    height: ${pxToRem(30)};
  }
`;

interface HeaderCellProps {
  align?: 'left' | 'right' | 'center';
  width?: string;
}

export const TableHeadCell = styled.th<HeaderCellProps>`
  color: ${Colors.TextSub};
  text-align: ${({ align }) => align || 'left'};
  font-size: ${FontSize.S};
  font-weight: ${FontWeight.Medium};
  vertical-align: middle;
  width: ${({ width }) => width};

  &:first-of-type {
    padding-left: ${Padding.Default};
  }

  &:last-of-type {
    padding-right: ${Padding.Default};
  }
`;

interface CellProps {
  align?: 'left' | 'right' | 'center';
}

export const TableCell = styled.td<CellProps>`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.ML};
  text-align: ${({ align }) => align || 'left'};
  vertical-align: middle;

  &:first-of-type {
    padding-left: ${Padding.Default};
  }

  &:last-of-type {
    padding-right: ${Padding.Default};
  }
`;
