import type { SegmentEvent } from '../types';
import { version as clientVersion } from '../../../../../version';

export const viewSchedule: SegmentEvent = (action, prevState, nextState) => ({
  name: 'view_schedule',
  properties: {
    em_winview_id: nextState.user.userId,
    em_actor_id: nextState.user.playerId,
    native_version: nextState.wrapper.storeVersion,
    em_client_version: clientVersion,
  },
});
