import styled from 'styled-components';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  $size: number;
}

export const CircularProgressWrapper = styled.section<Props>`
  height: ${({ $size }) => pxToRem($size)};
  position: relative;
  width: ${({ $size }) => pxToRem($size)};

  > svg {
    height: 100%;
    position: absolute;
    transform: rotate(-90deg);
    width: 100%;

    circle {
      transition: all 0.3s ease;
    }
  }
  z-index: 0;
`;

export const CircularProgressContent = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
`;
