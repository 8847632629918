import styled from 'styled-components';
import { Colors, FontSize, Padding } from 'style/types';

export const ReportPlayerModalContentArea = styled.section`
  align-items: center;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  padding: ${Padding.Default};
`;

export const ReportButton = styled.button`
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: ${Colors.Secondary};
  display: inine-flex;
  font-size: ${FontSize.S};
  text-decoration: underline;

  > svg {
    margin: 0 0.5rem 0 0;
    transform: translateY(0.25rem);
    width: 1rem;
  }
`;
