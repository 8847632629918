import { createReducer } from '@reduxjs/toolkit';
import { addTextToast, addPropositionToast, clearToasts, removeToast } from './actions';
import { ToastsState } from './types';

export const initialState: ToastsState = [];

export const toastReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addPropositionToast, (state, { payload }) => {
      state.push({
        ...payload,
      });
    })
    .addCase(addTextToast, (state, { payload }) => {
      state.push({
        ...payload,
        message: payload.message,
      });
    })
    .addCase(removeToast, (state) => {
      state.splice(0, 1);
    })
    .addCase(clearToasts, () => initialState);
});
