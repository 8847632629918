import React from 'react';
import { Experience, ExperienceChipWrapper, SportIcon } from './ExperienceChip.styled';

interface Props {
  sportIcon: string;
  rank: number;
  experience: number;
}

export const ExperienceChip: React.FC<Props> = ({ sportIcon, rank, experience }) => (
  <ExperienceChipWrapper>
    <SportIcon src={sportIcon} alt="" />
    <Experience>{`T${rank} / ${experience}`}</Experience>
  </ExperienceChipWrapper>
);
