export const formatCurrency = (amount: number, nbOfDigits = 2, noZeroDecimals = false): string => {
  let digits = noZeroDecimals ? 0 : nbOfDigits;
  if (amount % 1 !== 0 && noZeroDecimals) {
    digits = nbOfDigits;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(amount);
};
