import styled from 'styled-components';
import React from 'react';
import ChevronIcon from 'assets/icons/chevron.svg';

interface ItemWrapperProps {
  itemCount: number;
  itemWidth: string;
  visibleItems: number;
  activeIndex: number;
}

export const CarouselItemWrapper = styled.div<ItemWrapperProps>`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(${({ itemCount }) => itemCount}, 6rem);
  position: relative;
  height: 100%;
  justify-items: center;
  transform: translateX(
    ${({ activeIndex, itemWidth }) => `calc(-1 * ${activeIndex} * ${itemWidth})`}
  );
  transition: transform 0.3s ease;
  width: ${({ itemCount, itemWidth }) => `calc(${itemCount} * ${itemWidth})`};
  overflow: hidden;
`;

interface CarouselContainerProps {
  itemWidth: string;
  visibleItems: number;
}

export const CarouselContainer = styled.section<CarouselContainerProps>`
  display: grid;
  grid-template-columns: 1fr ${({ itemWidth, visibleItems }) =>
      `calc(${itemWidth} * ${visibleItems})`} 1fr;
  overflow: hidden;
`;

const Button = styled.button.attrs({
  type: 'button',
  children: <ChevronIcon />,
})`
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  z-index: 1;

  > svg {
    height: 1rem;
    transition: transform 0.3s ease;
  }

  &:disabled {
    > svg {
      opacity: 0.5;
    }
  }
`;

export const NextButton = styled(Button)`
  background-image: linear-gradient(-90deg, #000, #000 50%, transparent);
`;

export const PrevButton = styled(Button)`
  background-image: linear-gradient(90deg, #000, #000 50%, transparent);

  > svg {
    transform: rotate(180deg);
  }
`;
