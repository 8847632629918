import React from 'react';
import { Game } from 'molecules/Game';
import { useAppSelector } from 'store/hooks';
import { formatDateTime } from 'utils/formatters';
import { OverlayHeaderAction } from 'atoms/OverlayHeaderAction';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { Game as GameType } from 'store/games/types';
import { getContestHistoryGameById } from 'store/contestHistory/selectors';
import { getGameById } from 'store/games/selectors';
import { HelpPages } from 'store/clientProperties/types';
import { PageHelpModal } from 'organisms/PageHelpModal';
import { DateTime, Description, GameWrapper, Header } from './GameHeader.styled';

interface Props {
  gameId?: string;
  game?: GameType;
  type?: OverlayHeaderActions;
  onClose?: () => void;
  showTime?: boolean;
  isContestHistory?: boolean;
  helpPageId?: HelpPages;
}

export const GameHeader: React.FC<Props> = ({
  gameId,
  onClose,
  type,
  showTime = true,
  game: propGame,
  isContestHistory,
  helpPageId,
}) => {
  const selector = isContestHistory ? getContestHistoryGameById : getGameById;

  const game = useAppSelector((state) => propGame || selector(gameId || '')(state));

  if (!game) {
    return null;
  }

  const onActionClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Header>
      {!!type && <OverlayHeaderAction type={type} onClose={onActionClick} />}
      {!!helpPageId && <PageHelpModal helpPageId={helpPageId} />}
      <GameWrapper>
        <Game
          sportId={game.sportId}
          homeTeamId={game.homeTeamId}
          awayTeamId={game.awayTeamId}
          horizontal
        />
      </GameWrapper>
      <Description center={!showTime}>{game.name}</Description>
      {showTime && <DateTime>{formatDateTime(game.broadcastDate)}</DateTime>}
    </Header>
  );
};
