import React from 'react';
import PlayersIcon from 'assets/icons/PlayersIcon.svg';
import { PlayerCountContainer } from './PlayerCount.styled';

interface PlayerCountProps {
  count: number;
  from?: number;
}
export const PlayerCount: React.FC<PlayerCountProps> = ({ count, from }) => (
  <PlayerCountContainer>
    {Intl.NumberFormat('en', { notation: 'compact' }).format(count)}
    {from && `/${Intl.NumberFormat('en', { notation: 'compact' }).format(from)}`}
    <PlayersIcon />
  </PlayerCountContainer>
);
