import { createReducer } from '@reduxjs/toolkit';
import { endGame, resetGame } from 'store/games/actions';
import { clearUser } from 'store/user/actions';
import { fetchPropositions, removeProposition, updateProposition } from './actions';
import type { PropositionsState } from './types';

export const initialState = {} as PropositionsState;

export const propositionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPropositions.fulfilled, (state, { payload }) =>
      payload.forEach((proposition) => {
        state[proposition.propositionId] = proposition;
      }),
    )
    .addCase(updateProposition.fulfilled, (state, { payload }) => {
      if (payload) {
        state[payload.propositionId] = {
          ...state[payload.propositionId],
          ...payload,
        };
      }
    })
    .addCase(removeProposition, (state, { payload }) => {
      if (state[payload.propositionId]) {
        delete state[payload.propositionId];
      }
    })
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(endGame, () => initialState)
    .addCase(resetGame.fulfilled, (state, { payload }) => {
      const { contestIds } = payload;

      Object.keys(state).forEach((propositionId) => {
        if (contestIds.includes(state[propositionId].contestId)) {
          delete state[propositionId];
        }
      });
    });
});
