/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { v4 as uuidv4 } from 'uuid';
import { Dispatch } from 'redux';
import { initFacebookSDK } from 'utils/trackers/facebook';
import { initSegmentSDK } from 'utils/trackers/segment';
import { wrapperUUIDUpdated } from 'store/wrapper/actions';
import { debugLog } from 'utils/log';
// import { vibrateViaWebApi } from 'utils/vibrateViaWebApi';
import { getPermissionStateForGeoLocation } from 'utils/getPermissionStateForGeoLocation';
import type { RootState } from 'store/types';
import { isMobile } from 'utils/device';
import { ClientType } from 'services/pttv/api';
import {
  Coordinates,
  FacebookGraphRequestArgs,
  FacebookLoginArgs,
  FacebookLoginResult,
  Subscription,
  WebProps,
  Wrapper,
} from './types';

interface Props {
  os: ClientType;
  uuid: string;
  osVersion: string;
  deviceModel: string;
  version: number;
}

class WebWrapper implements Wrapper<WebProps> {
  props: Props;

  constructor() {
    this.props = {
      os: ClientType.WEB,
      uuid: 'UUID-browser', // TODO: Generate uuid?
      osVersion: window.navigator.appVersion,
      deviceModel: 'Browser',
      version: 4.0, // TODO: Use VERSION
    };

    initFacebookSDK();
    initSegmentSDK();
  }

  onPropUpdated(): void {
    debugLog('[Web Wrapper]', 'No implementation for onPropUpdated');
  }

  onReady(callback: (props: WebProps) => void): void {
    callback({
      ...this.props,
    });
  }

  // TODO: Make implementation without dispatch/getState
  // (using it still because UUID's have already been stored in the Redux store)
  setupUUID(dispatch: Dispatch, getState: () => RootState): void {
    const { webApp } = getState();
    let { deviceId } = webApp;

    if (!deviceId) {
      deviceId = uuidv4();
    }

    dispatch(wrapperUUIDUpdated({ deviceId }));
  }

  onAppPause(): void {
    debugLog('[Web Wrapper]', 'No implementation for onAppPause');
  }

  onAppResume(): Subscription {
    /**
     * We disabled appresume because we have issues with desktop version where apppause event is
     * not implemented and during appresume we update local user state and miss some data like
     * unread messages.
     * If we implement both appresume and apppause in web version we would lose notification
     * possibilities that we have on mobile version
     */
    debugLog('[Web Wrapper]', 'No implementation for onAppResume');

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const subscription = () => {};

    document.addEventListener('visibilitychange', subscription);

    return subscription as unknown as Subscription;
  }

  onAppStop(): void {
    debugLog('[Web Wrapper]', 'No implementation for onAppStop');
  }

  removeAppPauseListener(): void {
    debugLog('[Web Wrapper]', 'No implementation for removeAppPauseListener');
  }

  removeAppResumeListener(listener: () => void): void {
    document.addEventListener('visibilitychange', listener);
  }

  removeAppStopListener(): void {
    debugLog('[Web Wrapper]', 'No implementation for removeAppStopListener');
  }

  onBackButton(): void {
    debugLog('[Web Wrapper]', 'No implementation for onBackButton');
  }

  removeBackButtonListener(): void {
    debugLog('[Web Wrapper]', 'No implementation for removeBackButtonListener');
  }

  appsFlyerTrackEvent(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for appsFlyerTrackEvent');
    });
  }

  appSettingsOpen(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for AppSettingsOpen');
    });
  }

  deviceGeoLocationSettingsOpen(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for DeviceGeoLocationSettingsOpen');
    });
  }

  isLocationServiceEnabled(): Promise<void> {
    return getPermissionStateForGeoLocation().then((permissionState) =>
      permissionState === 'granted'
        ? Promise.resolve()
        : Promise.reject(new Error('permission_denied_web')),
    );
  }

  locationRequestAuthorization(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Same options as was configured in geo verify sdk.
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      navigator.geolocation.getCurrentPosition(
        resolve as unknown as PositionCallback,
        reject,
        options,
      );
    });
  }

  getCurrentCoordinates(): Promise<Coordinates> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (pos) => resolve(pos),
        (err) => reject(Error(resolvePositionError(err))),
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 1000 * 60 * 5,
        },
      );
    });
  }

  openUrl(url: string): void {
    window.open(url);
  }

  openOverlay(url: string): void {
    window.open(url, undefined, 'width=600,height=600,resizable,scrollbars=yes,status=1');
  }

  facebookGraphRequest<T>({ path, method = 'get', params }: FacebookGraphRequestArgs): Promise<T> {
    return new Promise((resolve) =>
      window.FB.api(path, method, params, (response: T) => resolve(response)),
    );
  }

  facebookLogin(params: FacebookLoginArgs): Promise<FacebookLoginResult> {
    let loginParams = { scope: '' };
    if (params?.permissions) {
      loginParams = { scope: params.permissions.join(',') };
    }

    return new Promise((resolve, reject) => {
      window.FB.login((response: Record<string, unknown>) => {
        if (response.authResponse) {
          const { accessToken } = window.FB.getAuthResponse();
          resolve({ token: (accessToken as string) || undefined });
        } else {
          reject(new Error('User cancelled login or did not fully authorize.'));
        }
      }, loginParams);
    });
  }

  facebookLogout(): Promise<void> {
    return new Promise((resolve) => window.FB.logout(() => resolve()));
  }

  facebookShare({ contentURL }: Record<string, string>): Promise<unknown> {
    return new Promise((resolve) =>
      window.FB.ui(
        {
          method: 'share',
          href: contentURL,
        },
        (response: unknown) => resolve(response),
      ),
    );
  }

  getNotificationStatus(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for getNotificationStatus');
    });
  }

  getStorageItem(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for getStorageItem');
    });
  }

  removeStorageItem(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for removeStorageItem');
    });
  }

  setStorageItem(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for setStorageItem');
    });
  }

  closeApp(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for closeApp');
    });
  }

  getNotificationPermissionState(): Promise<unknown> {
    return new Promise((resolve) => {
      const { Notification } = window;

      return resolve(Notification ? Notification.permission : 'denied');
    });
  }

  /**
   * @returns {Promise<void>}
   */
  segmentReady(): Promise<void> {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('Segment loading failed'));
      }, 500 /* empirically obtained value */);

      window.analytics.ready(() => {
        clearTimeout(timer);
        resolve();
      });
    });
  }

  /**
   * @returns {Promise<string, Error>}
   */
  segmentGetAnonymousId(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!window.analytics || !window.analytics.identify) {
        reject(new Error('Segment loading not ready'));
      }

      resolve(window.analytics.user().anonymousId());
    });
  }

  /**
   * @param userId - string
   * @param props - object
   * @returns {Promise<void, Error>}
   */
  segmentIdentify(userId: string, props: Props): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!window.analytics || !window.analytics.identify) {
        reject(new Error('Segment loading not ready'));
      }

      window.analytics.identify(userId, { ...props });

      resolve();
    });
  }

  /**
   * @param segmentName - string
   * @param segmentProperties - object
   * @returns {Promise<void, Error>}
   */
  segmentTrack(segmentName: string, segmentProperties: Record<string, string>): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!window.analytics || !window.analytics.track) {
        reject(new Error('Segment loading not ready'));
      }

      window.analytics.track(segmentName, segmentProperties);

      resolve();
    });
  }

  /**
   * @returns {Promise<void, Error>}
   */
  segmentReset(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!window.analytics || !window.analytics.reset) {
        reject(new Error('Segment loading not ready'));
      }

      window.analytics.reset();

      resolve();
    });
  }

  socialShare(params: Record<string, string | number>, fallback?: () => void): Promise<void> {
    if (navigator.share !== undefined && isMobile()) {
      return navigator.share(params);
    }
    if (fallback) {
      fallback();
      return new Promise(() => {
        debugLog('[Web Wrapper]', 'used the fallback for socialShare');
      });
    }

    return new Promise(() => {
      debugLog(
        '[Web Wrapper]',
        'Navigator.share is not supported and a fallback has not been provided',
      );
    });
  }

  vibrate(): Promise<void> {
    return new Promise(() => {
      debugLog('[Web Wrapper]', 'No implementation for vinbrate');
    });
  }
}

const resolvePositionError = (error: GeolocationPositionError) => {
  const { code, message = 'unknown' } = error || {};

  switch (code) {
    case 1:
      return 'permission_denied_web';
    case 2:
      return 'gps_unavailable';
    case 3:
      return 'gps_timeout';
    default:
      return message;
  }
};

export default WebWrapper;
