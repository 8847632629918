import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Message } from 'hocs/withLongPoll/types/chat';
import { RoomsAPI } from 'services/pttv/api/rooms';
import type { RootState } from 'store/types';
import { getUser } from 'store/user/selectors';
import { RoomChatActions, SendRoomChatArgs } from './types';

export const addRoomChatMessage = createAction<Message>(RoomChatActions.ADD_ROOM_CHAT_MESSAGE);
export const addNewRoomChatMessage = createAction<Message>(
  RoomChatActions.ADD_NEW_ROOM_CHAT_MESSAGE,
);
export const removeRoomChatMessage = createAction<string>(RoomChatActions.REMOVE_ROOM_CHAT_MESSAGE);

export const sendRoomChatMessage = createAsyncThunk<SendRoomChatArgs, SendRoomChatArgs>(
  RoomChatActions.SEND_ROOM_CHAT_MESSAGE,
  ({ roomId, text }, { getState, dispatch }) => {
    const user = getUser(getState() as RootState);

    dispatch(
      addNewRoomChatMessage({
        roomId,
        text,
        senderId: user.userId,
        timestamp: Date.now(),
      }),
    );
    RoomsAPI.sendMessage(roomId, { text });

    return {
      roomId,
      text,
    };
  },
);

export const setSeenRoomChatMessagesByRoom = createAction<string>(
  RoomChatActions.SET_MESSAGES_SEEN_BY_ROOM,
);
