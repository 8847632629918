import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { PageLoader } from 'atoms/PageLoader';
import { FadeInRoute, PageRoute } from 'molecules/RouteTransitions';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import GameRoutes, { gamePaths } from './games/routes';
import ScheduleRoutes, { schedulePaths } from './schedule/routes';
import SocialRoutes, { socialPaths } from './social/routes';
import ProfileRoutes, { profilePaths } from './profile/routes';
import NotFound from './notFound';
import { contestHistoryPaths } from './profile/contestHistory/routes';
import { leaguePaths } from './social/[leagueName]/routes';

const Home = React.lazy(() => import('pages/schedule/index'));
const SignIn = React.lazy(() => import('pages/signIn'));

const rootPaths = {
  profile: '/profile',
  social: '/social',
  games: '/games',
  schedule: '/schedule',
  home: '/',
};

const allPaths = [
  // /profile
  ...Object.values(contestHistoryPaths),
  ...Object.values(profilePaths),
  // /games
  ...Object.values(gamePaths),
  // /schedule
  ...Object.values(schedulePaths),
  // /social
  ...Object.values(leaguePaths),
  ...Object.values(socialPaths),
  // /root
  ...Object.values(rootPaths),
];

const Routes: React.FC = () => {
  const match = useRouteMatch(Object.values(allPaths));

  const autoLogin = useAppSelector((state) => state.cashBet.autoLoginCashBet);
  const { isAuthenticated } = useAppSelector((state) => state.user);

  return (
    <Suspense fallback={<PageLoader />}>
      {isAuthenticated && (
        <>
          {match?.isExact && (
            <Switch>
              <PageRoute path="/" exact>
                <Home />
              </PageRoute>
              <Route path="/schedule">
                <ScheduleRoutes />
              </Route>
              <Route path="/games">
                <GameRoutes />
              </Route>
              <Route path="/social">
                <SocialRoutes />
              </Route>
              <Route path="/profile">
                <ProfileRoutes />
              </Route>
            </Switch>
          )}

          {!match?.isExact && <NotFound />}
        </>
      )}
      {!isAuthenticated && !autoLogin && (
        <FadeInRoute path="/">
          <SignIn />
        </FadeInRoute>
      )}
    </Suspense>
  );
};

export default withErrorBoundary(Routes);
