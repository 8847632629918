import React from 'react';
import { LeagueMemberListItem } from 'organisms/UserListItem/LeagueMemberListItem';
import { Divider } from 'atoms/Divider';
import { useAppSelector } from 'store/hooks';
import { getLeagueByName, isUserCommissioner, getPendingMembers } from 'store/leagues/selectors';
import { UserListContainer } from '../UserList.styled';

type Props = {
  leagueName: string;
  label?: (items: number) => string;
};

export const LeaguePendingMemberList: React.FC<Props> = ({ leagueName, label }) => {
  const league = useAppSelector(getLeagueByName(leagueName));
  const isCommmissioner = useAppSelector(isUserCommissioner(leagueName));
  const pendingMembers = useAppSelector(getPendingMembers(leagueName));

  if (!isCommmissioner || !league || pendingMembers.length === 0) {
    return null;
  }

  return (
    <>
      {label && pendingMembers.length > 0 && (
        <Divider sticky noMargin>
          {label(pendingMembers.length)}
        </Divider>
      )}
      <UserListContainer>
        {pendingMembers.map((member) => (
          <LeagueMemberListItem key={member.userId} member={member} leagueName={leagueName} />
        ))}
      </UserListContainer>
    </>
  );
};
