/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getDeviceIp } from 'store/device/selectors';
import { RoomsAPI } from 'services/pttv/api/rooms';
import { JoinRoomRequest, JoinRoomsRequest, RoomQueueEntry } from 'services/pttv/api/rooms/types';
import { joinContestComplete } from 'store/contests/actions';
import { getContestById } from 'store/contests/selectors';
import {
  updateFetchedPlayersMovement,
  updateUserMovement,
} from 'store/leaderboardMovement/actions';
import { getRoomSelection, getServerJoinRoomsPayload } from 'store/roomSelection/selectors';
import type { RootState } from 'store/types';
import { getUser, isUserOnLeaderboard } from 'store/user/selectors';
import { debugLog } from 'utils/log';
import { getContestInfoByContestId } from 'store/contestInfo/selectors';
import { removeContestInfo } from 'store/contestInfo/actions';
import { removeBets } from 'store/bets/actions';
import { ContestState } from 'services/pttv/api/constants';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { addTextToast } from 'store/toast/actions';
import { PttvError } from 'services/pttv/types';
import {
  FetchOrUpdateRoomArgs,
  FetchOrUpdateRoomScoresArgs,
  FetchOrUpdateRoomScoresPayload,
  JoinContestAndRoomsArgs,
  JoinContestAndRoomsCompleteArgs,
  JoinContestPayload,
  JoinContestsAndRoomsArgs,
  LeaveRoomArgs,
  LeaveRoomPayload,
  RoomActions,
  StoreRoom,
  TrackRoomProperties,
} from './types';

export const trackJoinRoom = createAction<TrackRoomProperties>(RoomActions.TRACK_JOIN_ROOM);

export const joinContestAndRoom = createAsyncThunk<
  boolean,
  UpdateFieldsArgs<JoinContestAndRoomsArgs>
>(
  RoomActions.JOIN_CONTEST_AND_ROOMS,
  async ({ request, errorMessage }, { dispatch, rejectWithValue, getState }) => {
    const {
      contestId,
      roomTypeIdsToPaymentMethodMap,
      geoPosition: { latitude, longitude, accuracy },
    } = request;
    const roomTypeIdPaymentMethodEntries = Object.entries(roomTypeIdsToPaymentMethodMap);
    const payload: JoinRoomRequest = {
      request: {
        contestId,
        roomTypes: roomTypeIdPaymentMethodEntries.map(([roomTypeId, paymentMethod]) => ({
          roomTypeId,
          count: 1,
          paymentMethod,
        })),
      },
      latitude,
      longitude,
      geoAccuracy: accuracy,
      requestId: new Date().getTime(),
    };

    try {
      const response = await RoomsAPI.joinRoom(payload);
      const { geoAccuracy, ...rest } = payload;
      const completedArgs = {
        ...rest,
        accuracy: geoAccuracy,
      };
      dispatch(joinContestAndRoomsComplete({ response, payload: completedArgs }));
    } catch (error) {
      const state = getState() as RootState;
      const ip = getDeviceIp(state);
      const contest = getContestById(contestId)(state);

      const gameId = contest ? contest.gameId : null;

      dispatch(
        addTextToast({
          message: errorMessage(error as PttvError),
          isWarning: true,
        }),
      );

      dispatch(
        joinRoomsError({
          contestId,
          gameId,
          error,
          roomQueueEntries: payload.request.roomTypes,
          latitude,
          longitude,
          accuracy,
          ip,
        }),
      );

      return rejectWithValue(error);
    }
    return true;
  },
);

export const joinContestsAndRooms = createAsyncThunk<boolean, JoinContestsAndRoomsArgs>(
  RoomActions.JOIN_CONTESTS_AND_ROOMS,
  async ({ latitude, longitude, accuracy }, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const roomSelection = getRoomSelection(state);
    const firstContestId = Object.keys(roomSelection)[0];

    const payload: JoinRoomsRequest = {
      requests: getServerJoinRoomsPayload(state),
      latitude,
      longitude,
      geoAccuracy: accuracy,
      requestId: new Date().getTime(),
    };

    if (payload.requests.length === 0) {
      const error = {
        code: 'NO_CONTESTS_SELECTED',
        message: 'No contests have been selected to be joined',
      }; // Hardcoded error as sign in error tracking expects error code and message.
      return rejectWithValue(error);
    }

    try {
      const response = await RoomsAPI.joinMultiContest(payload);

      response.forEach((responseItem, index) =>
        dispatch(
          joinContestAndRoomsComplete({
            response: responseItem,
            payload: {
              request: payload.requests[index],
              latitude,
              longitude,
              accuracy,
            },
          }),
        ),
      );
      return true;
    } catch (error) {
      debugLog('[JOIN Rooms ERROR]', '', error);
      const {
        contests,
        device: { ip },
      } = getState() as RootState;
      const contest = contests[firstContestId];
      const gameId = contest ? contest.gameId : null;

      payload.requests.forEach((entry) =>
        dispatch(
          joinRoomsError({
            contestId: entry.contestId,
            gameId,
            error,
            roomQueueEntries: entry.roomTypes,
            latitude,
            longitude,
            accuracy,
            ip,
          }),
        ),
      );
      return rejectWithValue(error);
    }
  },
);

export const joinContestAndRoomsComplete = createAsyncThunk<
  JoinContestPayload,
  JoinContestAndRoomsCompleteArgs
>(
  RoomActions.JOIN_CONTESTS_AND_ROOMS_COMPLETED,
  async (
    {
      response,
      payload: {
        request: { contestId, roomTypes },
        latitude,
        longitude,
        accuracy,
      },
    },
    { dispatch, getState },
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { points, roomQueueEntries, roomTypeLeaderboardUpdates } = response;
    const state = getState() as RootState;
    const contest = getContestById(contestId)(state);
    const ip = getDeviceIp(state);
    const { gameId } = contest;
    const preparedQueueEntries: RoomQueueEntry[] =
      contest.state === ContestState.UPCOMING
        ? roomQueueEntries
        : (roomTypes as unknown as RoomQueueEntry[]);

    await dispatch(joinContestComplete({ contestId, points, roomTypeLeaderboardUpdates }));
    const payload = {
      gameId,
      ...response,
      roomQueueEntries: preparedQueueEntries,
      latitude,
      longitude,
      accuracy,
      ip,
    };

    preparedQueueEntries.forEach((preparedQueueEntry) => {
      dispatch(
        trackJoinRoom({
          ...payload,
          preparedQueueEntry,
        }),
      );
    });

    return payload;
  },
);

export const joinRoomsError = createAction(RoomActions.JOIN_ROOMS_ERROR, (payload) => ({
  payload,
  meta: {
    // analytics: trackJoinRooms(payload),
  },
}));

export const fetchRoom = createAsyncThunk<StoreRoom, FetchOrUpdateRoomArgs>(
  RoomActions.FETCH_ROOM,
  async ({ room, fromEvent }, { getState, rejectWithValue }) => {
    try {
      const response = await RoomsAPI.getRoomById({ roomId: room.roomId });
      const { players, user: userFromResponse } = response;
      const {
        user: { userId },
      } = getState() as RootState;
      const user = userFromResponse || players.find((player) => player.userId === userId);
      return {
        ...response,
        fromEvent,
        user,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchOrUpdateRoom = createAsyncThunk<FetchOrUpdateRoomArgs, FetchOrUpdateRoomArgs>(
  RoomActions.UPDATE_ROOM,
  (payload, { dispatch, getState, rejectWithValue }) => {
    const isOnLeaderboardDetails = isUserOnLeaderboard(getState() as RootState);

    if (isOnLeaderboardDetails) {
      dispatch(fetchRoom(payload));
      return rejectWithValue('Need to fetch the room first');
    }
    return payload;
  },
);

export const fetchOrUpdateRoomScores = createAsyncThunk<
  FetchOrUpdateRoomScoresPayload,
  FetchOrUpdateRoomScoresArgs
>(RoomActions.UPDATE_ROOM_SCORES, async (payload, { dispatch, getState }) => {
  const { user, roomId } = payload.room;
  const { isOnLeaderboardDetails, userId } = getUser(getState() as RootState);

  if (isOnLeaderboardDetails) {
    const response = await RoomsAPI.getRoomScores({ roomId });
    const { players, roomTypeId, contestId } = response;
    const player = players.find(({ userId: playerId }) => playerId === userId) || user;

    dispatch(updateUserMovement({ contestId, roomTypeId, user: player }));
    dispatch(updateFetchedPlayersMovement({ players, contestId, roomTypeId, user: player }));
    return { ...response, user: player, fetched: true };
  }

  dispatch(
    updateUserMovement({ contestId: payload.contestId, roomTypeId: payload.room.roomTypeId, user }),
  );
  return payload.room;
});

export const leaveRoom = createAsyncThunk<LeaveRoomPayload, LeaveRoomArgs>(
  RoomActions.LEAVE_ROOM,
  async ({ roomTypeId, contestId }, { dispatch, getState, rejectWithValue }) => {
    const contest = getContestInfoByContestId(contestId)(getState() as RootState);

    if (!contest) {
      return rejectWithValue('No contest available');
    }

    const { roomQueueEntries } = contest;
    const entry = roomQueueEntries.find(
      (roomQueueEntry) => roomQueueEntry.roomTypeId === roomTypeId,
    );

    if (!entry) {
      return rejectWithValue('No entry available');
    }

    await RoomsAPI.leaveRoom({ roomQueueId: entry.roomQueueId, contestId });

    if (roomQueueEntries.length <= 1) {
      dispatch(removeContestInfo(contestId));
      dispatch(removeBets(contestId));
    }

    return { contestId, roomQueueId: entry.roomQueueId, roomTypeId };
  },
);
