import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;

  > select {
    width: 100%;
  }

  > img {
    left: 0.5rem;
    max-height: 2rem;
    max-width: 2rem;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }
`;
