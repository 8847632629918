import styled from 'styled-components';
import { DollarCentsAndTicketsWrapper } from 'atoms/DollarCentsAndTickets/DollarCentsAndTickets.styled';
import { ParagraphStyles } from 'atoms/Paragraph';
import { Colors, FontFamily, FontSize, FontWeight, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const ContestResultContainer = styled.section`
  background-color: ${Colors.Black};
  overflow: scroll;
  ${DollarCentsAndTicketsWrapper} {
    color: ${Colors.Secondary};
  }
`;

export const Rank = styled.span`
  color: ${Colors.Black50};
  font-size: ${FontSize.ML};
`;

export const RoomName = styled.span`
  font-family: ${FontFamily.Regular};
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
`;

export const PointsSection = styled.section`
  text-align: center;
  padding: ${Padding.L} ${pxToRem(89)} ${Padding.L};
`;

export const Points = styled.h2`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XXXL};
  color: ${Colors.Secondary};
  font-weight: ${FontWeight.Regular};
  letter-spacing: 0.75px;
  margin: 0;
`;

export const PointsDescription = styled.p`
  ${ParagraphStyles}
  font-size: ${FontSize.MS};
`;
