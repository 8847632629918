export enum TeamActions {
  UPDATE_TEAM = '@room/UPDATE_TEAM',
  REMOVE_TEAM = '@room/REMOVE_TEAM',
}

export interface Team {
  notSelectableByClient: boolean;
  teamId: string;
  sportId: string;
  cityName: string;
  abbreviation: string;
  mainTeamColor: string;
  accentTeamColor: string;
  logoUrl: string;
}

export type TeamsGroupedBySportId = {
  [sportId: string]: {
    [teamId: string]: Team;
  };
};

export type TeamsState = Record<string, Team>;
