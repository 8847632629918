import { playToTv } from 'services/pttv';
import { EmptyResponse } from '../types';
import {
  LoginWithCashBetRequest,
  LoginResponse,
  User,
  ReportUserRequest,
  FoundUser,
  UpdateProfileRequest,
  UpdateActionNotificationsRequest,
  UpdateGlobalNotificationsRequest,
  UpdateEmailRequest,
  UpdateClientDataRequest,
  UpdateDisplayNameRequest,
  ForgotPasswordRequest,
  LinkFacebookRequest,
} from './types';

export const UsersAPI = {
  getUser: (userId: string): Promise<User> => playToTv.get<User>(`winview/users/${userId}`),

  logout: (): Promise<EmptyResponse> =>
    playToTv.delete<EmptyResponse>('winview/users/sessions/logout'),

  loginWithCashBet: (request: LoginWithCashBetRequest): Promise<LoginResponse> =>
    playToTv.put<LoginResponse, LoginWithCashBetRequest>(
      'winview/users/sessions/login/cashbet',
      request,
    ),

  reportUser: (request: ReportUserRequest): Promise<EmptyResponse> =>
    playToTv.post<EmptyResponse, ReportUserRequest>(`winview/users/reportuser`, request),

  searchUser: (criteria: string): Promise<FoundUser> =>
    playToTv.get<FoundUser>(`winview/users/search/${criteria}`),

  updateProfile: (request: UpdateProfileRequest): Promise<User> =>
    playToTv.put<User, UpdateProfileRequest>(`winview/users/updateprofile`, request),

  updateActionNotifications: (request: UpdateActionNotificationsRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, UpdateActionNotificationsRequest>(
      `winview/users/actionnotifications`,
      request,
    ),

  updateGlobalNotifications: (request: UpdateGlobalNotificationsRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, UpdateGlobalNotificationsRequest>(
      `winview/users/globalnotifications`,
      request,
    ),

  changeEmail: (request: UpdateEmailRequest): Promise<User> =>
    playToTv.put<User, UpdateEmailRequest>(`winview/users/changeemail`, request),

  updateDisplayName: (request: UpdateDisplayNameRequest): Promise<User> =>
    playToTv.put<User, UpdateDisplayNameRequest>(`winview/users/updateDisplayName`, request),

  updateClientDataStore: (request: UpdateClientDataRequest): Promise<User> =>
    playToTv.put<User, UpdateClientDataRequest>(`winview/users/updateclientdatastore`, request),

  requestUserVerification: (): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, EmptyResponse>(`winview/users/requestuserverification`, {}),

  forgotPassword: (request: ForgotPasswordRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, ForgotPasswordRequest>(
      `winview/users/forgotpassword/email`,
      request,
    ),

  linkFacebook: (request: LinkFacebookRequest): Promise<EmptyResponse> =>
    playToTv.post<EmptyResponse, LinkFacebookRequest>(
      'winview/users/credentials/facebook',
      request,
    ),
};
