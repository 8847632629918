import React from 'react';
import CheckIcon from 'assets/icons/checkIcon.svg';
import CrossIcon from 'assets/icons/CrossIcon.svg';
import MinusIcon from 'assets/icons/MinusIcon.svg';
import { Icon, IconWrapper } from './PropositionToast.styled';

interface Props {
  result: 'correct' | 'incorrect' | 'undecided';
}

export const PropositionIcon: React.FC<Props> = ({ result }) => (
  <IconWrapper result={result}>
    <Icon result={result}>
      {result === 'correct' && <CheckIcon className={result} />}
      {result === 'incorrect' && <CrossIcon className={result} />}
      {result === 'undecided' && <MinusIcon className={result} />}
    </Icon>
    {result}!
  </IconWrapper>
);
