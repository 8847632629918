import { createReducer } from '@reduxjs/toolkit';
import { EmptyResponse } from 'services/pttv/api/types';
import { League } from 'store/leagues/types';
import { LeagueKickedEventPayload } from 'hocs/withLongPoll/types/league';
import { clearUser, fetchUserState } from 'store/user/actions';
import { addLeagueInvitation } from './actions';
import { LeagueInviteActions, LeagueInvitesState } from './types';

export const initialState: LeagueInvitesState = {};

const removeLeagueInviteActions = [
  LeagueInviteActions.LEAGUE_INVITE_CANCELLED,
  `${LeagueInviteActions.LEAGUE_INVITE_ACCEPT}/fulfilled`,
  `${LeagueInviteActions.LEAGUE_INVITE_DECLINE}/fulfilled`,
];

export const leagueInvitesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (_, { payload }) =>
      payload.leagueInvites.reduce(
        (acc, invite) => ({
          ...acc,
          [invite.name]: invite,
        }),
        {},
      ),
    )
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(addLeagueInvitation.fulfilled, (state, { payload }) => {
      state[payload.name] = payload;
    })
    .addMatcher<{
      payload: LeagueKickedEventPayload | League | EmptyResponse;
      type: string;
      meta: { arg: { request: string } };
    }>(
      (action) => removeLeagueInviteActions.includes(action.type),
      (state, { payload, meta }) => {
        if (state[payload.name]) {
          delete state[payload.name];
        }

        if (state[meta.arg.request]) {
          delete state[meta.arg.request];
        }
      },
    );
});
