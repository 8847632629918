import { debugLog, debugWarn } from 'utils/log';
import {
  BridgeCommand,
  BridgeCommandResult,
  BridgeMessage,
  Method,
  NativeBridgeProps,
  Subscription,
  SubscriptionHandler,
} from './types';

class NativeBridge {
  commandIdCounter: number;

  subscriptions: Subscription[];

  wrapperPromise: Promise<NativeBridgeProps>;

  wrapper: typeof window.nativeWrapper;

  constructor() {
    this.commandIdCounter = 1;
    this.subscriptions = [];

    this.wrapperPromise = new Promise((resolve) => {
      this.subscribe('Event.BridgeReady', () => {
        this.wrapper = window.nativeWrapper; // TODO: Improve

        // WV2-7860: Workaround for Android as it calls BridgeReady multiple times but potentially can be called in an order that appsflyer props are only set.
        if (window.nativeWrapper.os) {
          resolve(window.nativeWrapper);
        }
      });
    });

    // TODO: Listen to GatekeeperReady
    // this.subscribe('Event.GatekeeperReady')

    let wrapper = window.nativeWrapper;
    if (wrapper) {
      this.publish('Event.BridgeReady'); // Call BridgeReady by itself to resolve wrapper promise so AppReady message can be send.
      this.sendMessage({ type: 'Event', subject: 'AppReady' });
    } else {
      wrapper = {};
      window.nativeWrapper = wrapper;
    }

    if (wrapper.onMessage) {
      throw new Error('onMessage handler already defined');
    }

    wrapper.onMessage = this.onMessage.bind(this); // TODO: Don't use bind
  }

  publish(channel: string, message?: BridgeMessage): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription.selector === channel) {
        subscription.handler(message, subscription);
      }
    });
  }

  getWrapperPromise(): Promise<NativeBridgeProps> {
    return this.wrapperPromise;
  }

  onMessage(message: BridgeMessage): void {
    // const type = message.type || 'Event';
    const { subject, type = 'Event' } = message;
    let channel = `${type}.${subject}`;

    if (type === 'CommandResult') {
      channel += `.${(message as BridgeCommand).commandId}`;
    }

    this.publish(channel, message);
  }

  sendCommand<ReturnType, PayloadType = void>(
    subject: string,
    payload: PayloadType | Record<string, unknown> = {},
    fallback: false | Method<() => void> = false,
  ): Promise<ReturnType> {
    const commandId = (this.commandIdCounter += 1).toString();

    // Note: iOS wrapper sometimes synchronously calls onMessage so we need to start
    // listening before we send
    const resultPromise: Promise<ReturnType> = new Promise((resolve, reject) => {
      this.subscribe(`CommandResult.${subject}.${commandId}`, (message, subscription) => {
        this.unsubscribe(subscription); // Should only listen once to message.

        const { error } = message as BridgeCommandResult;

        if (error) {
          debugLog('[Native Bridge]', '', message);
          const { code } = error;

          if (fallback && code === 'UnsupportedCommand') {
            debugLog('[Native Bridge]', `Using UnsupportedCommand fallback for: ${subject}`);

            if (typeof fallback === 'function') {
              fallback();
            }
          }

          // Convert error message from wrapper to JS Error extended with wrapper error properties
          reject(new Error(error.message || `${subject} rejected: ${code}`));
        }

        return resolve((message?.payload || {}) as ReturnType);
      });
    });

    this.sendMessage({
      type: 'Command',
      subject,
      commandId,
      payload,
    });

    return resultPromise;
  }

  sendMessage(message: BridgeMessage | BridgeCommand): Promise<void> | null {
    try {
      return this.wrapperPromise
        .then((wrapper) => {
          if (wrapper.sendMessage) {
            wrapper.sendMessage(message);
          }
        })
        .catch((error) =>
          debugWarn(`Failed to sendMessage(${JSON.stringify(message)}) to wrapper!`, error),
        );
    } catch (e) {
      debugWarn('[Native Bridge Wrapper]', 'Failed to sendMessage to wrapper!', e);
    }

    return null;
  }

  subscribe<T>(selector: string, handler: SubscriptionHandler<T>): Subscription {
    const subscription: Subscription = {
      selector,
      handler,
    };

    this.subscriptions.push(subscription);

    return subscription;
  }

  unsubscribe(subscription: Subscription): void {
    const index = this.subscriptions.indexOf(subscription);
    if (index !== -1) {
      this.subscriptions.splice(index, 1);
    }
  }
}

export default NativeBridge;
