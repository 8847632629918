import { createSelector } from 'reselect';
import { AccountType, ClientData } from 'services/pttv/api/users/types';
import type { CurriedSelector, RootState } from 'store/types';
import { UserState } from './types';

export const getUser = (state: RootState): UserState => state.user;

export const getClientDataStore = (state: RootState): Record<string, string | boolean | number> =>
  state.user.clientDataStore;

export const isUserScheduleViewFirstLoad = createSelector(
  getClientDataStore,
  (clientDataStore = {}) => {
    if (ClientData.FirstLoadScheduleView in clientDataStore) {
      return clientDataStore[ClientData.FirstLoadScheduleView];
    }
    return true;
  },
);

export const getClientData = (
  key: ClientData,
  defaultValue?: string | number | boolean,
): CurriedSelector<string | number | boolean | undefined> =>
  createSelector(getClientDataStore, (clientDataStore = {}) => {
    if (key in clientDataStore) {
      return clientDataStore[key];
    }
    return defaultValue;
  });

export const isUserGuest = createSelector(
  getUser,
  (user) => user.accountType === AccountType.GUEST,
);

export const getUserTickets = createSelector(getUser, (user) => user.tickets || {});

export const getUserDollarCents = (state: RootState): number => state.user.dollarCents || 0;

export const getUserId = (state: RootState): string => state.user.userId;

export const getUserEmail = (state: RootState): string => state.user.email;

export const getUserAuthToken = (state: RootState): string => state.user.authToken;

export const getUserDisplayName = (state: RootState): string => state.user.displayName;

export const getPaidContestsJoined = (state: RootState): number => state.user.paidContestsJoined;

export const isSoundEnabled = (state: RootState): boolean => state.user.soundEnabled;

export const isUserOnLeaderboard = createSelector(
  getUser,
  (user) => user.isOnLeaderboardDetails || user.isOnLeaderboard,
);

export const userHasTicket = (ticketTypeId: string): CurriedSelector<boolean> =>
  createSelector(getUserTickets, (userTickets) => (userTickets[ticketTypeId] || 0) > 0);

export const getAffiliatedTeamBySportId = (sportId: string): CurriedSelector<string | null> =>
  createSelector(getUser, (user) => user.affiliatedTeams[sportId] || null);
