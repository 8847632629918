import { RoomTypeLeaderboard } from 'services/pttv/api/leaderboards/types';

export enum RoomTypeLeaderboardActions {
  FETCH_ROOM_TYPE_LEADERBOARD = '@roomTypeLeaderboard/FETCH_ROOM_TYPE_LEADERBOARD',
  UPDATE_ROOM_TYPE_LEADERBOARD = '@roomTypeLeaderboard/UPDATE_ROOM_TYPE_LEADERBOARD',
  UPDATE_ROOM_TYPE_AND_UPDATE_LEADERBOARD = '@roomTypeLeaderboard/UPDATE_ROOM_TYPE_AND_UPDATE_LEADERBOARD',
}

export interface UpdateRoomTypeAndUpdateLeaderboardArgs {
  contestId: string;
  roomTypeId: string;
}
export interface StoreRoomTypeLeaderboard extends RoomTypeLeaderboard {
  roomTypeId: string;
  contestId: string;
  fetched?: boolean;
}

export type RoomTypeLeaderboardState = Record<
  string,
  {
    [roomTypeId: string]: StoreRoomTypeLeaderboard;
  }
>;
