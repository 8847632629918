import React from 'react';
import { Button } from 'atoms/Button';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { CardContent, CardHeader, NoResultsContainer, Title } from './NoResultsCard.styled';

interface Props {
  title: string;
  icon: JSX.Element;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const NoResultsCard: React.FC<Props> = ({
  title,
  icon,
  buttonText,
  onButtonClick,
  children,
}) => (
  <NoResultsContainer>
    <CardHeader>
      {!!icon && icon}
      <Title>{title}</Title>
    </CardHeader>

    <CardContent>{children}</CardContent>
    {buttonText && (
      <ButtonBar>
        <Button scheme={ButtonSchemes.Secondary} onClick={onButtonClick}>
          {buttonText}
        </Button>
      </ButtonBar>
    )}
  </NoResultsContainer>
);
