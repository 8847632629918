import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setTabIndex } from 'store/tabs/actions';
import { getActiveTab } from 'store/tabs/selectors';

type MemoizedTabs = [number, (id: number) => void];

export const useMemoizedTabs = (tabsId: string): MemoizedTabs => {
  const tab = useAppSelector(getActiveTab(tabsId));
  const [activeTab, setTab] = useState(tab);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTab(tab);
  }, [tab]);

  const setActiveTab = (id: number) => {
    dispatch(
      setTabIndex({
        id,
        tabsId,
      }),
    );
  };

  return [activeTab, setActiveTab];
};
