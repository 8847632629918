export enum Colors {
  Primary = 'hsl(227, 87%, 60%)',
  PrimaryLight = 'hsl(227, 87%, 65%)',
  PrimaryDark = 'hsl(227, 87%, 55%)',
  Secondary = 'hsl(169, 100%, 38%)',
  SecondaryLight = 'hsl(169, 100%, 43%)',
  SecondaryDark = 'hsl(169, 100%, 33%)',
  Tertriary = 'hsl(20, 78%, 49%)',
  TertriaryLight = 'hsl(20, 78%, 54%)',
  TertriaryDark = 'hsl(20, 78%, 44%)',
  White = 'hsl(0, 100%, 100%)',
  Black = 'hsl(0, 0%, 0%)',
  Black90 = 'hsl(0, 0%, 12%)',
  Black80 = 'hsl(0, 0%, 16%)',
  Black75 = 'hsl(0, 0%, 27%)',
  Black70 = 'hsl(0, 0%, 38%)',
  Black50 = 'hsl(0, 0%, 55%)',
  Black40 = 'hsl(0, 0%, 63%)',
  Black30 = 'hsl(0, 0%, 70%)',
  Black20 = 'hsl(0, 0%, 79%)',
  Black05 = 'hsl(0, 0%, 98%)',
  ActiveBackground = 'hsl(229, 14%, 23%)',
  ActiveBorder = 'hsl(226, 18%, 53%)',
  InactiveBackground = 'hsl(227, 31%, 17%)',
  Text = 'hsl(0, 100%, 100%)',
  TextSub = 'hsl(228, 32%, 85%)',
  TextSub2 = 'hsl(190, 100%, 87%)',
  Error = 'hsl(0, 85%, 59%)',
  Success = 'hsl(92, 76%, 64%)',
  Twitter = 'hsl(204, 87%, 53%)',
  Facebook = 'hsl(214, 89%, 52%)',
}
export enum FontFamily {
  Regular = `'Open Sans', sans-serif`,
  Heading = `'Khand', sans-serif`,
}

export enum FontWeight {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 800,
}

export enum FontSize {
  XS = '.6875rem', // 11px
  S = '.75rem', // 12px
  MS = '.875rem', // 14px
  M = '1rem', // 16px
  ML = '1.125rem', // 18px
  L = '1.25rem', // 20px
  XL = '1.5rem', // 24px
  XXL = '1.75rem', // 28px
  XXXL = '2rem', // 32px
}

export enum Padding {
  XXXS = '.125rem', // 2px
  XXS = '.25rem', // 4px
  XS = '.5rem', // 8px
  S = '.75rem', // 12px
  Default = '1rem', // 16px
  L = '1.25rem', // 20px
  XL = '1.5rem', // 24px
  XXL = '1.75rem', // 28px
  XXXL = '2rem', // 32
}

export enum Gap {
  XXS = '.125rem',
  XS = '.25rem',
  S = '.5rem',
  Default = '.75rem',
  ML = '1rem',
  L = '1.25rem',
  XL = '1.5rem',
  XXL = '1.75rem',
  XXXL = '2rem',
}

export enum BorderRadius {
  S = '0.25rem',
  MS = '0.5rem',
  Default = '.625rem',
}

export enum BorderWidth {
  Default = '1px',
  Large = '2px',
  XL = '3px',
}

export enum ButtonBarDirections {
  Row = 'row',
  Column = 'column',
}

export enum ZIndex {
  FooterItem = 20,
  ModalPage = 30,
  Toast = 35,
  Dialog = 40,
  UserError = 50,
}

export type GlobalStyle = {
  backgroundColor: Colors;
};

export type LayoutTheme = {
  container: {
    backgroundColor: Colors;
  };
  content: {
    backgroundColor: Colors;
    color: Colors;
  };
  header: {
    backgroundColor: Colors;
  };
};

export type IconWrapTheme = {
  borderColor: Colors;
  borderWidth: BorderWidth;
};

export enum MediaQueries {
  Ratio1 = `(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-width: 415px)`,
  Ratio2 = `(-webkit-min-device-pixel-ratio: 3), (min-resolution: 384dpi), (min-width: 829px)`,
}
