import { playToTv } from 'services/pttv';
import { EmptyResponse } from '../types';
import {
  FriendInviteRequest,
  Friend,
  GetFriendsLeaderboardRequest,
  GetFriendsLeaderboardResponse,
  GetFriendsRequest,
  GetFriendsResponse,
} from './types';

export const FriendsAPI = {
  getFriends: ({ facebookFriendIds }: GetFriendsRequest): Promise<GetFriendsResponse> =>
    playToTv.get<GetFriendsResponse>(`winview/friends?facebookIds=${facebookFriendIds || ''}`),

  getFriendsLeaderboard: ({
    contestId,
    facebookFriendIds,
  }: GetFriendsLeaderboardRequest): Promise<GetFriendsLeaderboardResponse> =>
    playToTv.get<GetFriendsLeaderboardResponse>(
      `winview/friends/leaderboard/${contestId}?facebookIds=${facebookFriendIds || ''}`,
    ),

  notifyFriends: (facebookIds: string[]): Promise<Friend[]> =>
    playToTv.post<Friend[]>(`winview/friends/notify?facebookIds=${facebookIds}`, {}),

  acceptFriendInvite: (userId: string): Promise<Friend> =>
    playToTv.put<Friend, FriendInviteRequest>(`winview/friends/accept`, { userId }),

  cancelFriendInvite: (userId: string): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, FriendInviteRequest>(`winview/friends/cancel`, { userId }),

  inviteFriend: (userId: string): Promise<Friend> =>
    playToTv.post<Friend, FriendInviteRequest>(`winview/friends/invite`, { userId }),
};
