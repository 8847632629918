import React, { Suspense, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { SlideIn } from 'molecules/PageWrappers';
import { PageLoader } from 'atoms/PageLoader';
import { LazyPreLoader } from 'utils/LazyPreLoader';
import { withErrorBoundary } from 'hocs/withErrorBoundary';

const lazyLoader = new LazyPreLoader();

const LeagueDetailPage = React.lazy(() => import('.'));
const InviteNewFriendsLeaguePage = lazyLoader.lazy(() => import('./inviteNewFriends'));
const InviteFriendsLeaguePage = lazyLoader.lazy(() => import('./inviteFriends'));
const LeagueDetailEditPage = lazyLoader.lazy(() => import('./edit'));
const LeagueHistoryPage = lazyLoader.lazy(() => import('./history'));
const LeagueHistoryContestDetailPage = lazyLoader.lazy(() => import('./history/[contestId]'));

export const leaguePaths = {
  leagueHistoryDetail: '/social/:leagueName/history/:contestId',
  leagueHistory: '/social/:leagueName/history',
  leagueInviteNewFriend: '/social/:leagueName/invite-new-friend',
  leagueInviteFriend: '/social/:leagueName/invite-friend',
  editLeague: '/social/:leagueName/edit',
  league: '/social/:leagueName',
  social: '/social',
};

interface Params {
  leagueName: string;
  contestId: string;
}

const LeagueRoutes: React.FC = () => {
  const match = useRouteMatch<Params>(Object.values(leaguePaths));
  const history = useHistory();

  useEffect(() => {
    lazyLoader.preload();
  }, []);

  if (!match?.isExact) {
    return null;
  }

  return (
    <>
      <SlideIn>
        <LeagueDetailPage leagueName={match.params.leagueName} />
      </SlideIn>
      <Suspense fallback={PageLoader}>
        <SwitchTransition mode="in-out">
          <CSSTransition
            key={match.path}
            timeout={{
              appear: history.action === 'POP' ? 0 : 500,
              enter: history.action === 'POP' ? 0 : 500,
              exit: 500,
            }}
            classNames="slidein"
            unmountOnExit
          >
            <>
              {match?.path === leaguePaths.leagueHistory && (
                <SlideIn zIndex={1}>
                  <LeagueHistoryPage leagueName={match.params.leagueName} />
                </SlideIn>
              )}
              {match?.path === leaguePaths.leagueHistoryDetail && (
                <SlideIn zIndex={2}>
                  <LeagueHistoryContestDetailPage
                    leagueName={match.params.leagueName}
                    contestId={match.params.contestId}
                  />
                </SlideIn>
              )}
              {match?.path === leaguePaths.leagueInviteNewFriend && (
                <SlideIn zIndex={1}>
                  <InviteNewFriendsLeaguePage leagueName={match.params.leagueName} />
                </SlideIn>
              )}
              {match?.path === leaguePaths.leagueInviteFriend && (
                <SlideIn zIndex={1}>
                  <InviteFriendsLeaguePage leagueName={match.params.leagueName} />
                </SlideIn>
              )}
              {match?.path === leaguePaths.editLeague && (
                <SlideIn zIndex={1}>
                  <LeagueDetailEditPage leagueName={match.params.leagueName} />
                </SlideIn>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default withErrorBoundary(LeagueRoutes);
