import styled from 'styled-components';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
  Padding,
} from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const NoResultsContainer = styled.article`
  border: ${BorderWidth.Large} solid ${Colors.Black70};
  border-radius: ${BorderRadius.Default};
  text-align: center;
  padding: ${Padding.Default};

  ${ButtonBarContainer} {
    margin-top: 1rem;
  }
`;

export const Title = styled.h3`
  font-family: ${FontFamily.Heading};
  font-weight: ${FontWeight.Regular};
  font-size: ${FontSize.ML};
  text-transform: uppercase;
  margin: 0;
`;

export const CardContent = styled.section`
  font-size: ${FontSize.S};
  color: ${Colors.TextSub};
`;

export const CardHeader = styled.header`
  display: flex;
  flex-direction: column;

  svg {
    height: ${pxToRem(24)};
    color: ${Colors.Primary};
  }
`;
