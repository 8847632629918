import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';
import { LeagueChatMessage } from 'services/pttv/api/leagues/types';

export enum LeagueChatActions {
  ADD_LEAGUE_CHAT_MESSAGE = '@leagueChat/ADD_LEAGUE_CHAT_MESSAGE',
  ADD_NEW_LEAGUE_CHAT_MESSAGE = '@leagueChat/ADD_NEW_LEAGUE_CHAT_MESSAGE',
  REMOVE_LEAGUE_CHAT_MESSAGE = '@leagueChat/REMOVE_LEAGUE_CHAT_MESSAGE',
  SEND_LEAGUE_CHAT_MESSAGES = '@leagueChat/SEND_LEAGUE_CHAT_MESSAGES',
  SET_MESSAGES_SEEN_BY_LEAGUE = '@leagueChat/SET_MESSAGES_SEEN_BY_LEAGUE',
}

export interface LastChatMessage {
  message: string;
  senderId: string;
  senderName: string;
  avatarUrl: string;
  type: ChatBubbleTypes;
}

export interface ChatMessageList {
  messages: {
    id: string;
    text: string;
    isNew: boolean;
  }[];
  senderId: string;
  senderName: string;
  avatarUrl: string;
  type: ChatBubbleTypes;
}

export interface ChatMessage extends LeagueChatMessage {
  id: string;
  leagueName: string;
  isNew: boolean;
  seen: boolean;
}

export interface SendChatArgs {
  leagueName: string;
  text: string;
}

export type LeagueChatState = {
  [leagueName: string]: ChatMessage[];
};
