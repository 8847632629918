import styled from 'styled-components';
import { BorderRadius, BorderWidth, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const DefaultToastStyled = styled.section.attrs({
  tabIndex: 0,
  role: 'button',
})`
  display: flex;
  align-items: center;
  margin: ${Gap.Default};
  box-shadow: 0px ${pxToRem(10)} ${pxToRem(11)} 0px rgba(0, 0, 0, 0.5);

  background: linear-gradient(180deg, #00eabf -35.55%, #006d59 46.44%);
  border: ${BorderWidth.Default} solid #00c4a0;
  padding: ${Padding.Default} ${Padding.Default};
  border-radius: ${BorderRadius.Default};

  > svg,
  > picture {
    max-height: 2.5rem;
    margin-right: ${Padding.S};
    max-width: 2.5rem;
    background-color: black;
  }
`;

interface Props {
  warning: boolean;
}
export const ToastText = styled.p<Props>`
  border-radius: ${BorderRadius.Default};
  font-size: ${FontSize.S};
  font-weight: ${FontWeight.SemiBold};
  margin: 0;
  width: 100%;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
`;
