import { RoomTypeLeaderboard } from 'services/pttv/api/leaderboards/types';
import { Room, RoomQueueEntry } from 'services/pttv/api/rooms/types';
import { CurrentContestInfo } from 'store/user/types';
import { ContestInfoState } from './types';

export const normalizeRoomTypes = (
  roomQueueEntries: RoomQueueEntry[] | null = null,
  rooms: Room[] | null = null,
  roomTypeLeaderboards: RoomTypeLeaderboard[] | null = null,
): Record<string, boolean> => {
  const normalized: Record<string, boolean> = {};

  if (roomQueueEntries) {
    roomQueueEntries.forEach((entry) => {
      normalized[entry.roomTypeId] = true;
    });
  }

  if (rooms) {
    rooms.forEach((entry) => {
      normalized[entry.roomTypeId] = true;
    });
  }

  if (roomTypeLeaderboards) {
    roomTypeLeaderboards.forEach((entry) => {
      normalized[entry.roomTypeId] = true;
    });
  }

  return normalized;
};

export const normalize = (contests: CurrentContestInfo[]): ContestInfoState =>
  contests.reduce<ContestInfoState>(
    (acc, contest) => ({
      ...acc,
      [contest.contestId]: {
        ...contest,
        rooms: contest.rooms.map((room) => room.roomId),
        roomTypeIds: normalizeRoomTypes(
          contest.roomQueueEntries,
          contest.rooms,
          contest.roomTypeLeaderboards,
        ),
      },
    }),
    {},
  );

export const mergeRoomTypeIds = (
  normalizedRoomTypeIds: Record<string, boolean>,
  roomTypeIds: string[],
): Record<string, boolean> => {
  const normalized = { ...normalizedRoomTypeIds };

  roomTypeIds.forEach((roomTypeId) => {
    normalized[roomTypeId] = true;
  });

  return normalized;
};
