import React from 'react';
import { Bubble, BubbleLoaderContainer } from './BubbleLoader.styled';

interface Props {
  size?: string;
}

export const BubbleLoader: React.FC<Props> = ({ size = '0.75rem' }) => (
  <BubbleLoaderContainer size={size}>
    <Bubble delay=".15s" />
    <Bubble delay=".26s" />
    <Bubble delay=".37s" />
  </BubbleLoaderContainer>
);
