import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FriendModal } from 'organisms/FriendRequestModal/FriendModal';
import { FriendState } from 'services/pttv/api/constants';
import { useAppDispatch } from 'store/hooks';
import { requestFetchPlayer } from 'store/players/actions';
import { FriendListItem as ListItem } from 'molecules/UserListItem';
import { Friend } from 'services/pttv/api/friends/types';

interface Props {
  friend: Friend;
  isUser?: boolean;
}

export const FriendListItem: React.FC<Props> = ({ friend, isUser }) => {
  const { t } = useTranslation('SocialLanding');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(requestFetchPlayer(friend.userId));
  }, []);

  const showFriendInfo = () => {
    setIsModalOpen(true);
  };

  const hideFriendInfo = () => {
    setIsModalOpen(false);
  };

  const getModalTitle = (): string => {
    if (friend.state === FriendState.ACCEPTED) {
      return t('friendList.friendModal.title');
    }
    return t('friendList.sentInviteModal.title');
  };

  return (
    <>
      <ListItem friend={friend} isUser={isUser} onClick={showFriendInfo} />
      <FriendModal
        userId={friend.userId}
        title={getModalTitle()}
        isOpen={isModalOpen}
        handleCloseButtonClick={hideFriendInfo}
        handleSelectButtonClick={hideFriendInfo}
        reportPlayerCallback={hideFriendInfo}
      />
    </>
  );
};
