/* eslint-disable @typescript-eslint/no-explicit-any -- We need to use any for the React lazy typing. */
import React, { ComponentType, LazyExoticComponent } from 'react';

type Factory<T extends ComponentType<any>> = () => Promise<{ default: T }>;

export class LazyPreLoader {
  private loaders: Factory<any>[] = [];

  public lazy<T extends ComponentType<any>>(factory: Factory<T>): LazyExoticComponent<T> {
    this.loaders.push(factory);
    return React.lazy(factory);
  }

  public preload(): void {
    this.loaders.forEach((loader) => {
      loader();
    });
  }
}
