import { createReducer } from '@reduxjs/toolkit';
import { Friend } from 'services/pttv/api/friends/types';
import { clearUser } from 'store/user/actions';
import { cancelFriendInvite, fetchFriends, updateFriends } from './actions';
import { FriendsActions, FriendsState } from './types';

const addFriendActions = [
  `${FriendsActions.FRIEND_INVITE}/fulfilled`,
  `${FriendsActions.FRIEND_REQUEST_ACCEPT}/fulfilled`,
];

export const initialState: FriendsState = {};

export const friendsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(updateFriends, (state, { payload }) => {
      (payload || []).forEach((friend) => {
        state[friend.userId] = friend;
      });
    })
    .addCase(fetchFriends.fulfilled, (state, { payload }) =>
      payload.forEach((friend) => {
        state[friend.userId] = friend;
      }),
    )
    .addCase(cancelFriendInvite.fulfilled, (state, { meta }) => {
      if (state[meta.arg.request]) {
        delete state[meta.arg.request];
      }
    })
    .addMatcher<{
      payload: Friend;
      type: string;
    }>(
      (action) => addFriendActions.includes(action.type),
      (state, { payload }) => {
        state[payload.userId] = {
          ...(state[payload.userId] || {}),
          ...payload,
        };
      },
    );
});

//   { type, payload }) => {
//   switch (type) {
//     case MULTIPLE_SESSIONS_ERROR:
//     case SIGN_OUT_SUCCESS:
//       return initialState;

//     case UPDATE_FRIENDS: {
//       if (!payload.length) {
//         return state;
//       }

//       const updateFriends = { ...state };

//       payload.forEach((friend) => {
//         updateFriends[friend.userId] = friend;
//       });

//       return updateFriends;
//     }

//     case ADD_FRIENDS: {
//       if (!payload.length) {
//         return state;
//       }

//       return normalize(payload);
//     }

//     case INVITE_FRIEND_SUCCESS:
//     case ACCEPT_FRIEND_SUCCESS:
//       return {
//         ...state,
//         [payload.userId]: {
//           ...state[payload.userId],
//           ...payload,
//         },
//       };

//     case CANCEL_FRIEND_SUCCESS:
//     case REMOVE_FRIEND_SUCCESS: {
//       const nextState = { ...state };

//       delete nextState[payload.userId];

//       return nextState;
//     }

//     case REMOVE_FRIENDS:
//       return initialState;

//     default:
//       return state;
//   }
// };
