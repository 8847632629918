import React from 'react';
import { ExperienceChip } from 'atoms/ExperienceChip';
import { useAppSelector } from 'store/hooks';
import { getExperienceByUserId } from 'store/friends/selectors';
import { SkillRankWrapper, Title } from './SkillRank.styled';

interface Props {
  title: string;
  userId: string;
}

export const SkillRank: React.FC<Props> = ({ userId, title }) => {
  const experience = useAppSelector(getExperienceByUserId(userId));

  return (
    <>
      <Title>{title}</Title>
      <SkillRankWrapper>
        {experience.map((exp) =>
          exp.showExperience ? (
            <ExperienceChip
              key={exp.sportId}
              sportIcon={exp.sportAvatarUrl}
              rank={exp.rank}
              experience={exp.experience}
            />
          ) : null,
        )}
      </SkillRankWrapper>
    </>
  );
};
