import styled, { css } from 'styled-components';
import { Colors, Gap, Padding, FontFamily, FontSize, FontWeight } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface StyledProps {
  textAlign: 'flex-start' | 'center' | 'flex-end';
  sticky: boolean;
  small: boolean;
  noMargin: boolean;
  hasAction: boolean;
}

export const StyledDivider = styled.section<StyledProps>`
  align-items: center;
  justify-content: ${({ textAlign, hasAction }) => {
    if (hasAction) {
      return 'space-between';
    }
    return textAlign || 'flex-start';
  }};
  background-color: ${Colors.Black90};
  display: flex;
  height: ${({ small }) => (small ? pxToRem(32) : pxToRem(50))};
  line-height: ${({ small }) => (small ? 1 : 'initial')};
  ${({ noMargin, small }) =>
    !noMargin &&
    css`
      margin: ${small ? Gap.XS : Gap.ML} 0;
    `}
  padding: 0 ${Padding.Default};
  width: 100%;

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 2;
    `}
`;

interface DividerLabelProps {
  small: boolean;
}

export const DividerLabel = styled.h2<DividerLabelProps>`
  font-family: ${FontFamily.Heading};
  font-size: ${({ small }) => (small ? FontSize.M : FontSize.L)};
  font-weight: ${FontWeight.Regular};
  letter-spacing: 0.8px;
  line-height: ${({ small }) => (small ? 1 : 'initial')};
  margin: ${({ small }) => (small ? Padding.XXXS : Padding.XXS)} 0 0;
  text-transform: uppercase;
`;
