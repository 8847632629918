import { createReducer } from '@reduxjs/toolkit';
import { fetchGlobalState } from '../user/actions';
import { BannerState } from './types';

export const initialState = {} as BannerState;

export const bannersReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchGlobalState.fulfilled, (state, { payload }) =>
    Object.values(payload.banners).reduce<BannerState>(
      (acc, banner) => ({
        ...acc,
        [banner.zoneName]: {
          ...banner,
          currentIndex: 0,
        },
      }),
      {},
    ),
  );
});
