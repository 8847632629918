import styled, { css, keyframes } from 'styled-components';
import WinViewLogo from 'assets/images/WinViewLogoSmall.svg';

const animation = keyframes`
  0% {
    opacity: 1;
  }
  35% {
    width: 18vmin;
    height: 10vmin;
    transform: rotate(0);
  }
  50% {
    transform: rotate(180);
    width: 27vmin;
    height: 15vmin;
  }
  65% {
    width: 18vmin;
    height: 10vmin;
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface Props {
  width?: string;
  height?: string;
}

export const WinViewLoader = styled.div<Props>`
  animation: ${animation} linear 1.5s infinite;
  animation-delay: 0.2s;
  background-image: url('${WinViewLogo}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0;

  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
  `};
`;
