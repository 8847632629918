import { createReducer } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import { v4 as uuid } from 'uuid';
import {
  addNewRoomChatMessage,
  addRoomChatMessage,
  setSeenRoomChatMessagesByRoom,
} from './actions';
import { RoomChatState } from './types';

export const initialState: RoomChatState = {};

export const roomChatReducer = createReducer(initialState, (builder) => {
  builder
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .addCase(REHYDRATE, (state, { payload }: any) => ({
      ...state,
      ...(payload?.roomChat || {}),
    }))

    .addCase(addRoomChatMessage, (state, { payload }) => {
      const newMessage = {
        ...payload,
        id: uuid(),
        isNew: false,
        seen: false,
      };

      state[payload.roomId] = state[payload.roomId]
        .filter(({ isNew, text }) => !isNew && text !== payload.text)
        .concat([newMessage])
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    })

    .addCase(addNewRoomChatMessage, (state, { payload }) => {
      const newMessage = {
        ...payload,
        id: uuid(),
        isNew: true,
        seen: false,
      };

      state[payload.roomId].push(newMessage);
    })

    .addCase(setSeenRoomChatMessagesByRoom, (state, { payload }) => {
      state[payload] = (state[payload] || []).map((msg) => ({ ...msg, seen: true }));
    });
});
