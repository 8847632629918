/* eslint-disable import/no-cycle */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ContestChangedEventPayloadContest } from 'hocs/withLongPoll/types/contest';
import type { RootState } from 'store/types';
import { getRoomsByContest } from 'store/rooms/selectors';
import { canJoinLateRoomType } from 'store/roomTypes/selectors';
import { getContestInfoByContestId, getContestRoomIds } from 'store/contestInfo/selectors';
import { addRoom, updatePoints } from 'store/contestInfo/actions';
import { fetchPropositions } from 'store/propositions/actions';
import { updateRoomTypeLeaderboard } from 'store/roomTypeLeaderboard/actions';
import { debugLog } from 'utils/log';
import { addTextToast } from 'store/toast/actions';
import { getContestMetaByContestId } from 'store/contestMeta/selectors';
import { contestProblemShown } from 'store/contestMeta/actions';
import { updateUserMovement } from 'store/leaderboardMovement/actions';
import { ContestState } from 'services/pttv/api/constants';
import { RoomTypeLeaderboardCountEventPayload } from 'hocs/withLongPoll/types/roomType';
import { getContestById } from './selectors';
import {
  Contest,
  ContestActions,
  ContestProblemResponse,
  ContestRoomTypesAvailableToJoinUpdatedPayload,
  JoinContestCompleteArgs,
  UpdateRoomCountPayload,
} from './types';

export const contestRoomTypesAvailableToJoinUpdated =
  createAction<ContestRoomTypesAvailableToJoinUpdatedPayload>(
    ContestActions.CONTEST_ROOM_TYPES_AVAILABLE_TO_JOIN_UPDATED,
  );

export const removeContest = createAction<string>(ContestActions.REMOVE_CONTEST);

const joinContestSuccess = createAction<string>(ContestActions.JOIN_CONTEST_SUCCESS);

export const updateRoomTypeCount = createAction<UpdateRoomCountPayload>(
  ContestActions.UPDATE_ROOM_TYPE_COUNT,
);

export const updateRoomTypeCountByRoomTypeUpdate =
  createAction<RoomTypeLeaderboardCountEventPayload>(
    ContestActions.UPDATE_ROOM_TYPE_COUNT_BY_EVENT,
  );

export const joinContestComplete = createAsyncThunk<string, JoinContestCompleteArgs>(
  ContestActions.JOIN_CONTEST_COMPLETE,
  async ({ contestId, points, roomTypeLeaderboardUpdates }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const contest = getContestById(contestId)(state);
    const rooms = getRoomsByContest(contestId)(state);
    const contestRoomIds = getContestRoomIds(contestId)(state);

    dispatch(updatePoints({ contestId, points }));
    dispatch(fetchPropositions(contest));
    dispatch(joinContestSuccess(contestId));

    // Add rooms to contest that have been received with ROOM_UPDATED before the contest join request has completed (WV2-4768, to fix response order issue)
    const missingRooms = rooms
      .filter(({ roomId }) => !contestRoomIds.includes(roomId))
      .filter(
        ({ roomTypeId }) =>
          contest.state !== ContestState.OPEN || canJoinLateRoomType(roomTypeId)(state),
      );
    missingRooms.forEach((room) => dispatch(addRoom(room)));

    if (roomTypeLeaderboardUpdates) {
      roomTypeLeaderboardUpdates.forEach(
        ({ roomTypeId, roomTypeLeaderboardData: { top, user, totalPlayers } }) => {
          dispatch(
            updateRoomTypeLeaderboard({
              contestId,
              roomTypeId,
              top,
              user,
            }),
          );
          dispatch(
            updateUserMovement({
              contestId,
              roomTypeId,
              user,
            }),
          );
          dispatch(updateRoomTypeCount({ contestId, roomTypeId, count: totalPlayers as number }));
        },
      );
    }

    return contestId;
  },
);

export const updateContest = createAsyncThunk<Contest, ContestChangedEventPayloadContest>(
  ContestActions.UPDATE_CONTEST,
  (payload, { dispatch, getState }) => {
    const { contestId, state, roomTypeIds } = payload;
    const { contests, roomTypes } = getState() as RootState;
    const { state: prevContestState } = contests[contestId] || {};

    if (state !== ContestState.UPCOMING && prevContestState === ContestState.UPCOMING) {
      const lateJoinRoomTypeIds = (roomTypeIds || []).filter(
        (roomTypeId) =>
          !!roomTypes[roomTypeId] && !!roomTypes[roomTypeId].canJoinWhileContestIsOpen,
      );

      dispatch(
        contestRoomTypesAvailableToJoinUpdated({ contestId, roomTypeIds: lateJoinRoomTypeIds }),
      );
    }

    if (state === ContestState.FINISHING) {
      dispatch(contestRoomTypesAvailableToJoinUpdated({ contestId, roomTypeIds: [] }));
    }

    return {
      ...payload,
      roomTypeCounts: [],
    };
  },
);

export const addProblemToast = createAsyncThunk<void, ContestProblemResponse>(
  ContestActions.CONTEST_PROBLEM_TOAST,
  ({ problem, contestId, force = false }, { dispatch, getState }) => {
    const contestMeta = getContestMetaByContestId(contestId)(getState() as RootState);
    const problems: Record<string, string> = {
      generalProblem: 'This contest is currently experiencing problems',
      unresolvedPayoutProblem: 'Currently payouts/refunds are possibly delayed',
    };

    if (!problems[problem]) {
      debugLog('[Contest::addProblemToast]', `Unknown contest problem: "${problem}"`);
      return;
    }

    const isHandled = !!contestMeta && !!contestMeta[problem];
    if (isHandled && !force) {
      return;
    }

    dispatch(addTextToast({ message: problems[problem], isWarning: true }));
    dispatch(contestProblemShown({ problem, contestId }));
  },
);

export const contestProblem = createAsyncThunk<void, ContestProblemResponse>(
  ContestActions.CONTEST_PROBLEM,
  (payload, { dispatch, getState }) => {
    const contestInfo = getContestInfoByContestId(payload.contestId)(getState() as RootState);

    if (contestInfo) {
      dispatch(addProblemToast({ ...payload, force: true }));
    }
  },
);
