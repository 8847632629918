import { getUser } from 'store/user/selectors';
import type { SegmentIdentify } from '../types';

export const identify: SegmentIdentify = (action, prevState, nextState) => {
  const user = getUser(nextState);

  return {
    userId: user.userId,
    traits: {
      'User Id': user.userId,
      'Player Id': user.inviteCode,
      name: user.displayName,
      email: user.email,
      FBID: user.facebookId,
      user_cash: user.dollarCents,
    },
  };
};
