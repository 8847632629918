import styled from 'styled-components';
import { Colors, FontFamily, FontWeight } from 'style/types';

export const HtmlContentContainer = styled.section`
  color: ${Colors.Black20};
  word-break: break-word;

  p {
    font-family: ${FontFamily.Regular};
  }

  strong {
    font-weight: ${FontWeight.Bold};
  }

  b {
    font-weight: ${FontWeight.Bold};
  }

  a {
    color: ${Colors.Primary};
  }

  ul {
    padding-left: 1.25rem;
  }

  img {
    max-width: 100%;
  }

  h1 {
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${Colors.Text};
    font-family: ${FontFamily.Heading};
  }
`;
