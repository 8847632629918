import { TrackRoomProperties } from 'store/rooms/types';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { AppsFlyerEvent } from '../types';

export const roomRegister: AppsFlyerEvent = (action, prevState, nextState) => {
  const { preparedQueueEntry } = action.payload as TrackRoomProperties;

  const roomType = getRoomTypeById(preparedQueueEntry.roomTypeId)(nextState);

  return {
    name: 'room_register',
    properties: {
      room_type: roomType.name,
    },
  };
};
