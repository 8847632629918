import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContestInfoUnregisteredRoomTypes } from 'store/contestMeta/selectors';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { ModalPage } from 'atoms/ModalPage';
import { useAppSelector } from 'store/hooks';
import { getSportById } from 'store/sports/selectors';
import { getNormalizedTicketTypesByRoomTypeIds } from 'store/ticketTypes/selectors';
import { formatOrdinals } from 'utils/formatters';
import { GameHeader } from 'molecules/GameHeader';
import { RoomTypesList } from 'molecules/RoomTypesList';
import { CardDescription, CardTitle, Title } from '../Modals.styled';
import {
  ContestCancelledContent,
  ContestCancelledModalContentArea,
  ContestCancelledCard,
} from './ContestCancelledModal.styled';

interface Props {
  contestId: string;
  sportId: string;
  gameId: string;
  cancelledReason: string | undefined;
  period: number;
  closeCallback: () => void;
}

export const ContestCancelledModal: React.FC<Props> = ({
  contestId,
  sportId,
  gameId,
  cancelledReason,
  period,
  closeCallback,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sport = useAppSelector(getSportById(sportId));
  const roomTypes = useAppSelector(getContestInfoUnregisteredRoomTypes(contestId));
  const buyInTickets = useAppSelector(
    getNormalizedTicketTypesByRoomTypeIds((roomTypes || []).map(({ roomTypeId }) => roomTypeId)),
  );
  const { t } = useTranslation('ContestModals');

  if (!sport) {
    return null;
  }

  useEffect(() => {
    if (sport) {
      setIsOpen(true);
    }
  }, [sport]);

  const handleCloseButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
    closeCallback();
  };

  return (
    <>
      <ModalPage
        title={t('contestCancelled.pageTitle')}
        isOpen={isOpen}
        onClose={handleCloseButtonClick}
      >
        <ContestCancelledModalContentArea>
          <Title>
            {t('contestCancelled.cardTitle', {
              period: `${formatOrdinals(period)} ${sport.period}`,
            })}
          </Title>
          <ContestCancelledCard>
            <GameHeader gameId={gameId} />
            <ContestCancelledContent>
              <CardDescription>{t('contestCancelled.cardDescription')}</CardDescription>
              {!!cancelledReason && <CardDescription>{cancelledReason}</CardDescription>}
              {!!roomTypes.length && (
                <>
                  <CardTitle>
                    {t('contestCancelled.roomsTitle', {
                      period: `${formatOrdinals(period)} ${sport.period}`,
                    })}
                  </CardTitle>
                  <RoomTypesList roomTypes={roomTypes} buyInTickets={buyInTickets} />
                </>
              )}
            </ContestCancelledContent>
          </ContestCancelledCard>
          <Button scheme={ButtonSchemes.Secondary} onClick={handleCloseButtonClick}>
            {t('contestCancelled.backButton')}
          </Button>
        </ContestCancelledModalContentArea>
      </ModalPage>
    </>
  );
};
