import * as Sentry from '@sentry/react';
import { DataError } from './errors';

export const debugLog = (file: string, message: string, ...optionalParams: unknown[]): void => {
  if (!__DEV__) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(`%c ${file} `, 'background: #4269e2; color: #fff', message, ...optionalParams);
};

export const debugWarn = (file: string, message: string, ...optionalParams: unknown[]): void => {
  if (!__DEV__) {
    return;
  }
  // eslint-disable-next-line no-console
  console.warn(`%c ${file} `, 'background: #ff0; color: #111', message, ...optionalParams);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const captureError = (error: unknown): void => {
  Sentry.captureException(error);
};

export const trackDataError = (message: string, payload?: unknown[]): void => {
  captureError(new DataError(message, payload));
};
