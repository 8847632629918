import React, { useState, MouseEvent, useEffect } from 'react';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { useAppDispatch } from 'store/hooks';
import { requestFetchPlayer } from 'store/players/actions';
import { FriendModal } from './FriendModal';

interface Props {
  userId: string;
  title: string;
  buttonText: string;
  onSelect?: () => void;
}

export const FriendRequestModal: React.FC<Props> = ({ userId, title, onSelect, buttonText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(requestFetchPlayer(userId));
  }, []);

  const handleSelectButtonClick = () => {
    if (!onSelect) {
      return;
    }
    onSelect();
    setIsOpen(false);
  };

  const handleViewButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
  };

  const handleCloseButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleViewButtonClick}
        size={ButtonSize.Tiny}
        scheme={ButtonSchemes.Secondary}
      >
        {buttonText}
      </Button>

      <FriendModal
        userId={userId}
        title={title}
        isOpen={isOpen}
        handleCloseButtonClick={handleCloseButtonClick}
        handleSelectButtonClick={handleSelectButtonClick}
        reportPlayerCallback={() => setIsOpen(false)}
      />
    </>
  );
};
