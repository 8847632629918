import { createSelector } from 'reselect';
import { TicketType } from 'services/pttv/api/tickets/types';
import { getRoomTypesByRoomTypeIds } from 'store/roomTypes/selectors';
import { getTicketTypesMeta } from 'store/ticketTypesMeta/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import type { RoomBuyinTicket, RoomBuyinTickets, TicketTypesState } from './types';

export const getTicketTypes = (state: RootState): TicketTypesState => state.ticketTypes;

export const getTicketTypeById = (ticketTypeId: string): CurriedSelector<TicketType> =>
  createSelector(getTicketTypes, (tickets) => tickets[ticketTypeId] || {});

export const getTicketTypeIdsToFetch = (ticketTypeIds: string[]): CurriedSelector<string[]> =>
  createSelector(getTicketTypes, getTicketTypesMeta, (ticketTypes, ticketTypesMeta) =>
    ticketTypeIds.filter(
      (ticketId) => !ticketTypes[ticketId] && !(ticketTypesMeta[ticketId] || {}).loading,
    ),
  );

export const getTicketTypeIdsByRoomTypes = (roomTypeIds: string[]): CurriedSelector<string[]> =>
  createSelector(getRoomTypesByRoomTypeIds(roomTypeIds), (roomTypes) => {
    const allTicketTypeIds = roomTypes.reduce<string[]>((ticketTypeIds, roomType) => {
      const { ticketBuyIn, ticketPayoutModel } = roomType;

      if (ticketBuyIn) {
        ticketTypeIds.push(ticketBuyIn);
      }

      if (ticketPayoutModel && ticketPayoutModel.ticketPerRank) {
        ticketTypeIds.push(...Object.values(ticketPayoutModel.ticketPerRank));
      }

      return ticketTypeIds;
    }, []);

    return Array.from(new Set(allTicketTypeIds.filter((ticketType) => !!ticketType).values()));
  });

export const getTicketTypesByRoomTypeIds = (
  ticketTypeIds: string[],
): CurriedSelector<TicketType[]> =>
  createSelector(
    getRoomTypesByRoomTypeIds(ticketTypeIds),
    getTicketTypes,
    (roomTypes, ticketsTypes) => {
      const ticketsAmountHashMap = roomTypes.reduce<Record<string, number>>((acc, roomType) => {
        if (!roomType.ticketBuyIn) {
          return acc;
        }
        return {
          ...acc,
          [roomType.ticketBuyIn]: (acc[roomType.ticketBuyIn] || 0) + 1,
        };
      }, {});

      return Object.entries(ticketsAmountHashMap)
        .filter(([ticketBuyIn]) => !!ticketsTypes[ticketBuyIn])
        .map(([ticketBuyIn, count]) => ({ ...ticketsTypes[ticketBuyIn], count }));
    },
  );

export const getNormalizedTicketTypesByRoomTypeIds = (
  ticketTypeIds: string[],
): CurriedSelector<Record<string, TicketType>> =>
  createSelector(getTicketTypesByRoomTypeIds(ticketTypeIds), (ticketsTypes) =>
    ticketsTypes.reduce<Record<string, TicketType>>(
      (acc, ticketType) => ({
        ...acc,
        [ticketType.ticketTypeId]: ticketType,
      }),
      {},
    ),
  );

export const getTicketTypesToRoomBuyInByTicketTypeIds = (
  ticketsToBuyInIds: string[],
): CurriedSelector<RoomBuyinTickets> =>
  createSelector(getTicketTypes, (ticketTypes) =>
    Object.values(
      ticketsToBuyInIds.reduce<Record<string, RoomBuyinTicket>>(
        (acc, ticketTypeId) => ({
          ...acc,
          [ticketTypeId]: {
            ticketTypeId,
            count: (acc[ticketTypeId]?.count || 0) + 1,
            displayName: ticketTypes[ticketTypeId].displayName,
          },
        }),
        {},
      ),
    ),
  );
