import React from 'react';
import WinViewLogo from 'assets/icons/WinViewLogo.svg';
import { useAppSelector } from 'store/hooks';
import { Toaster } from 'molecules/Toaster';
import { Modals } from 'organisms/Modals';
import { Header } from 'molecules/Header';
import { Footer } from 'molecules/Footer';
import { UserErrorMessage } from 'molecules/UserErrorMessage';
import { useDeviceEvents } from 'hooks/useDeviceEvents';
import { getClientProperty } from 'store/clientProperties/selectors';
import { ClientProps } from 'store/clientProperties/types';
import { LayoutContainer, Content, DesktopHeader } from './Layout.styled';
import { LayoutLoader } from './LayoutLoader';

export const Layout: React.FC = ({ children }) => {
  useDeviceEvents();
  const backgroundImage = useAppSelector(getClientProperty(ClientProps.DESKTOP_BG_IMAGE));
  const autoLoginCashBet = useAppSelector((state) => state.cashBet.autoLoginCashBet);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isCashBetLoaded = useAppSelector((state) => state.cashBet.isLoaded);
  const inMaintenance = useAppSelector((state) => state.ui.inMaintenance);

  let showLoader = false;
  if ((autoLoginCashBet && !isAuthenticated) || (!autoLoginCashBet && !isCashBetLoaded)) {
    showLoader = true;
  }

  return (
    <LayoutContainer bgImage={backgroundImage ?? ''}>
      <DesktopHeader>
        <WinViewLogo />
      </DesktopHeader>
      <Content>
        <Modals />
        <Toaster />
        <UserErrorMessage />
        <Header />
        {children}
        <Footer />
        {showLoader && !inMaintenance && <LayoutLoader />}
      </Content>
    </LayoutContainer>
  );
};
