import styled from 'styled-components';
import { Colors, FontSize, FontWeight, Gap } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  color?: Colors;
  backgroundColor: Colors;
  withIcon?: boolean;
}
export const Pill = styled.div.attrs(({ color, backgroundColor }: Props) => ({
  color: color || Colors.Text,
  backgroundColor: backgroundColor || Colors.InactiveBackground,
}))<Props>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${pxToRem(10)};
  color: ${({ color }) => color};
  display: inline-flex;
  font-size: ${FontSize.XS};
  font-weight: ${FontWeight.Bold};
  height: ${pxToRem(22)};
  justify-content: center;
  line-height: 1;
  padding: ${({ withIcon }) =>
    withIcon ? `0 ${pxToRem(8)} 0 ${pxToRem(3)}` : `0 ${pxToRem(8)} 1px`};

  > svg,
  img {
    height: ${pxToRem(14)};
    margin-right: ${Gap.S};
  }
`;
