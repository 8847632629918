import { PropositionChangedEventPayloadProposition } from 'hocs/withLongPoll/types/proposition';
import { Proposition } from 'services/pttv/api/contests/types';
import { Bet } from 'store/bets/types';

export enum PropositionActions {
  FETCH_PROPOSITIONS = '@propositions/FETCH_PROPOSITIONS',
  UPDATE_PROPOSITION = '@propositions/UPDATE_PROPOSITION',
  REMOVE_PROPOSITION = '@proposition/REMOVE_PROPOSITION',
  CREATE_PROPOSITION = '@proposition/CREATE_PROPOSITION',
  ROLLBACK_PROPOSITION = '@proposition/ROLLBACK_PROPOSITION',
  RESOLVE_PROPOSITION = '@proposition/RESOLVE_PROPOSITION',
  OPEN_PROPOSITION = '@proposition/OPEN_PROPOSITION',
}

export enum PropositionBetResult {
  Correct = 'correct',
  Inorrect = 'incorrect',
  Undecided = 'undecided',
}

export interface PropositionResolvedPayload {
  proposition: Proposition;
  betResult: string;
  bet: Bet;
  payoutPointsToDisplay: number;
}

export interface OpenPropositionArgs {
  propositionId: string;
  silent: boolean;
}

export interface OpenPropositionPayload {
  propositionId: string;
  silent: boolean;
  isOpenProposition: boolean;
  openNumber: number;
}

export interface UpdatePropositionArgs {
  proposition: PropositionChangedEventPayloadProposition;
  push?: (location: string | Record<string, unknown>) => void;
}
export interface UpdatePropositionPayload {
  propositionId: string;
  contestId: string;
  gameStats: Record<string, unknown>;
}

export interface StoreProposition extends Proposition {
  contestId: string;
  sportId: string;
}

export interface PropositionListItem extends StoreProposition {
  hasBet: boolean;
  betDescription: string;
  gameId: string;
}

export type PropositionsState = Record<string, StoreProposition>;
