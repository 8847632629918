import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { loginWithCashBet } from 'store/user/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getCashBetMessageType,
  getLoginCashBetIframe,
  parseCashBetFailureEvent,
  parseCashBetLoginEvent,
  postMessageToCashBet,
  removeAutoLoginCashBetIframe,
} from 'utils/cashBet';
import { captureError, debugLog, debugWarn } from 'utils/log';
import { cashBetAccountCreated, cashBetError } from 'store/cashBet/actions';
import { AppDispatch } from 'store/types';
import { CashBetPageType } from 'store/cashBet/types';
import { signInWithFacebook } from 'store/facebook/actions';

// TODO: Should get url's from a config? PS: I believe winviewgames.com is the only relevant one.
const whitelist = ['cashbet.com', 'mgt-corp.com', 'winviewgames.com'];

const login = (type: string, event: MessageEvent, dispatch: AppDispatch) => {
  // Clean up any cashbet autologin iframes.
  removeAutoLoginCashBetIframe();

  const message = parseCashBetLoginEvent(event);
  if (!message) {
    return;
  }

  if (
    type === 'guest_register' ||
    type === 'new_account' ||
    (type === 'login' && message.message === 'social register')
  ) {
    dispatch(cashBetAccountCreated());
  }

  const { action = '' } = message;
  const { user_id: userId, token, fields } = message.result;

  dispatch(
    loginWithCashBet({
      autoLogin: fields[0].values.flag_requested_autologin === '1',
      token,
      type: action,
      userId,
    }),
  );
};

// Used for Facebook sign up and sign in.
const facebookSignUp = async (dispatch: AppDispatch) => {
  const iframe = getLoginCashBetIframe();
  const { payload: facebookToken } = await dispatch(signInWithFacebook());
  if (facebookToken && iframe) {
    postMessageToCashBet(iframe, 'facebook_token', { facebookToken });
  }
};

export const withCashBet =
  <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) =>
  (props: P): JSX.Element => {
    const { push, replace } = useHistory();
    const dispatch = useAppDispatch();
    const currentBackUrl = useAppSelector((state) => state.cashBet.currentBackUrl);
    const currentPageType = useAppSelector((state) => state.cashBet.currentPageType);

    const listener = useCallback(
      (event) => {
        const { origin } = event;
        const allow = whitelist.find((host) => origin.indexOf(host) !== -1);
        if (!allow) {
          return;
        }

        // TODO: Allow mocked data. Currently implementation calls setMockedCashBet but method doesn't seem to exist in pwa client..

        try {
          const type = getCashBetMessageType(event);

          if (!type) {
            return;
          }

          debugLog('withCashBet', `Received event ${type}`);

          switch (type) {
            case 'guest_register':
            case 'new_account':
            case 'login':
            case 'login_continue':
              login(type, event, dispatch);
              break;

            case 'hide_sdk_ui':
              if (currentBackUrl) {
                replace(decodeURIComponent(currentBackUrl));
              } else if (currentPageType !== CashBetPageType.Login) {
                push('/');
              }

              break;

            case 'facebook_signup':
              facebookSignUp(dispatch);
              break;

            case 'failure': {
              const payload = parseCashBetFailureEvent(event);
              debugLog('withCashBet', 'Failure with payload', payload);

              if (payload) {
                dispatch(cashBetError(payload));
              }

              break;
            }

            case 'analytics_identity':
              // TODO: dispatch(sendSegmentId());
              break;

            default:
              debugWarn('withCashBet', `Unknown event '${type}' from CashBet`);
              break;
          }
        } catch (error: unknown) {
          debugWarn('withCashBet', 'Event handling failed', error);
          captureError(error);
        }
      },
      [currentBackUrl, currentPageType],
    );

    useEffect(() => {
      window.addEventListener('message', listener);
      return () => window.removeEventListener('message', listener);
    }, [listener]);

    return <WrappedComponent {...props} />;
  };
