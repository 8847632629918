import { createReducer } from '@reduxjs/toolkit';
import { fetchUserState } from '../user/actions';
import { setUnreadMessageToRead } from './actions';
import { UnreadMessagesState } from './types';

export const initialState = {} as UnreadMessagesState;

export const unreadMessagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) =>
      payload.unreadMessages.reduce<UnreadMessagesState>(
        (acc, message) => ({
          ...acc,
          [message.id]: message,
        }),
        state,
      ),
    )
    .addCase(setUnreadMessageToRead.fulfilled, (state, { payload }) => {
      if (state[payload]) {
        delete state[payload];
      }
    });
});
