import styled from 'styled-components';
import { Colors, FontSize, FontWeight, Padding } from 'style/types';
import { HelpButton } from 'atoms/HelpButton';

export const StyledOverlayHeader = styled.header`
  align-items: center;
  background-color: ${Colors.Black};
  display: flex;
  justify-content: center;
  padding: ${Padding.Default};
  position: relative;

  > h1 {
    font-size: ${FontSize.M};
    font-weight: ${FontWeight.Medium};
    margin: 0;
    text-transform: uppercase;
  }

  > ${HelpButton} {
    position: absolute;
    right: 1rem;
  }
`;
