import { createReducer } from '@reduxjs/toolkit';
import { clearUser } from 'store/user/actions';
import { fetchLeagueLeaderboard } from './actions';
import { LeagueLeaderboardState } from './types';

const initialState: LeagueLeaderboardState = {};

export const leagueLeaderboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(fetchLeagueLeaderboard.fulfilled, (state, { payload, meta }) => {
      state[meta.arg.leagueName] = {
        ...state[meta.arg.leagueName],
        [meta.arg.contestId]: {
          ...payload,
          entries: payload.entries
            .sort((a, b) => b.points - a.points)
            .map((entry, index) => ({ ...entry, rank: index + 1 })),
        },
      };
    });
});
