import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { clamp } from 'popmotion';
import { useAppSelector } from 'store/hooks';
import { getPlayerAffiliatedTeams } from 'store/players/selectors';
import {
  CarouselItemWrapper,
  CarouselContainer,
  NextButton,
  PrevButton,
} from './TeamCarousel.styled';
import { CarouselTeam } from './CarouselTeam';

const ITEM_WIDTH = '6rem';
const VISIBLE_ITEMS = 3;

const swipeConfidenceThreshold = 1000;
const swipePower = (offset: number, velocity: number) => Math.abs(offset) * velocity;

interface Props {
  userId: string;
}

export const TeamCarousel: React.FC<Props> = ({ userId }) => {
  const teams = useAppSelector(getPlayerAffiliatedTeams(userId));
  const [activeIndex, setActiveIndex] = useState(0);

  const paginate = (newDirection: number) => {
    const newIndex = clamp(0, teams.length - VISIBLE_ITEMS, activeIndex + newDirection);
    setActiveIndex(newIndex);
  };

  return (
    <CarouselContainer itemWidth={ITEM_WIDTH} visibleItems={VISIBLE_ITEMS}>
      <PrevButton onClick={() => paginate(-1)} type="button" disabled={activeIndex <= 0} />
      <motion.div
        style={{
          width: `calc(${ITEM_WIDTH} * ${VISIBLE_ITEMS})`,
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragMomentum={false}
        dragElastic={0.2}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);
          if (swipe < -swipeConfidenceThreshold) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold) {
            paginate(-1);
          }
        }}
      >
        <CarouselItemWrapper
          itemCount={teams.length}
          itemWidth={ITEM_WIDTH}
          visibleItems={VISIBLE_ITEMS}
          activeIndex={activeIndex}
        >
          {teams.map((team) => (
            <CarouselTeam
              key={team.abbreviation}
              sportAvatar={team.sportAvatar}
              abbreviation={team.abbreviation}
              cityName={team.cityName}
              teamLogo={team.teamAvatar}
            />
          ))}
        </CarouselItemWrapper>
      </motion.div>
      <NextButton
        onClick={() => paginate(1)}
        type="button"
        disabled={activeIndex >= teams.length - VISIBLE_ITEMS}
      />
    </CarouselContainer>
  );
};
