import { createReducer } from '@reduxjs/toolkit';
import { clearUser } from 'store/user/actions';
import { fetchGenericContentByCategory } from './actions';
import { GenericContentState } from './types';

const initialState: GenericContentState = {};

export const genericContentReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(fetchGenericContentByCategory.fulfilled, (state, { payload, meta }) => {
      state[meta.arg] = {
        categoryBodyHtml: payload.categoryBodyHtml,
        categoryName: payload.categoryName,
        category: meta.arg,
        ownerCms: payload.ownerCms,
      };
    });
});
