import React, { useState, useEffect, RefObject } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { clamp } from 'popmotion';
import { useAppDispatch } from 'store/hooks';
import { setPropositionViewed } from 'store/propositionsMeta/actions';
import { usePrevious } from 'hooks/usePrevious';
import { Direction, PropositionTabProps } from '../types';
import {
  PropositionTabContainer,
  TabContentWrapper,
  CarouselContainer,
} from './PropositionTabs.styled';
import { TabContent } from './types';
import { PropositionTabBar } from './PropositionsTabsBar';

interface Props {
  children: React.ReactElement<PropositionTabProps>[];
  activeTab?: number;
  setActiveTab: (index: number) => void;
  contentRef: RefObject<HTMLDivElement> | undefined;
}

export const PropositionTabs: React.FC<Props> = ({
  children,
  activeTab = 0,
  setActiveTab,
  contentRef,
}) => {
  const previousTab = usePrevious(activeTab);
  const [tabs, setTabs] = useState<TabContent[]>([]);
  const [offset, setOffset] = useState(0);
  const [isInit, setInit] = useState(true);

  const dispatch = useAppDispatch();

  const handleSetPropositionViewed = (tabIndex: number) => {
    if (tabs[tabIndex]) {
      dispatch(
        setPropositionViewed({
          propositionId: tabs[tabIndex].propositionId,
          contestId: tabs[tabIndex].contestId,
        }),
      );
    }
  };

  const setNewPropositionIndex = (tabIndex: number) => {
    const newIndex = clamp(0, children.length - 1, tabIndex);
    handleSetPropositionViewed(newIndex);
    setActiveTab(newIndex);
  };

  const handleSwipeEnd = (dir: Direction) => {
    setOffset(0);
    if (
      (activeTab > 0 && dir === Direction.Right) ||
      (activeTab < tabs.length - 1 && dir === Direction.Left)
    ) {
      setNewPropositionIndex(activeTab + dir);
    }
  };

  const handleSwiping = (event: SwipeEventData) => {
    // Ignore vertical scrolling.
    // This also fixes an issue with clicking items in the swipable area
    if (event.dir === 'Down' || event.dir === 'Up') {
      return;
    }
    if (activeTab === 0 && event.dir === 'Right') {
      setOffset(Math.sqrt(event.deltaX) * 2);
      return;
    }
    if (activeTab === tabs.length - 1 && event.dir === 'Left') {
      setOffset(-1 * Math.sqrt(event.absX) * 2);
      return;
    }
    if (Math.abs(Math.abs(offset) - event.absX) > 5) {
      setOffset(event.deltaX);
    }
  };

  const handlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: () => setOffset(0),
    onSwipedLeft: () => handleSwipeEnd(Direction.Left),
    onSwipedRight: () => handleSwipeEnd(Direction.Right),
    trackMouse: false,
    delta: 10,
  });

  useEffect(() => {
    // When the offset is set to 0, the swipe has ended. In that case we want a transition.
    // In all other cases the swipe is going on and should it respond instant
    const duration = offset !== 0 || previousTab === -1 ? '0' : '0.3s';
    const left = `calc(${offset}px - ${activeTab * 80}%)`;
    if (contentRef?.current) {
      contentRef.current.style.setProperty('--propositions-left-duration', duration);
      contentRef.current.style.setProperty('--propositions-left', left);
    }
  }, [activeTab, offset]);

  useEffect(() => {
    // Initially set the proposition to viewed when there are tabs available
    if (isInit && tabs.length) {
      handleSetPropositionViewed(activeTab);
      setInit(false);
    }
  }, [tabs]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-shadow
    const content = children.map(({ props: { children, ...rest } }) => rest);
    setTabs(content);
  }, [children]);

  return (
    <PropositionTabContainer>
      <TabContentWrapper {...handlers}>
        <CarouselContainer ref={contentRef} $tabCount={children.length} activeIndex={activeTab}>
          {children}
        </CarouselContainer>
      </TabContentWrapper>
      <PropositionTabBar setIndex={setNewPropositionIndex} index={activeTab} tabs={tabs} />
    </PropositionTabContainer>
  );
};
