import { createSelector } from 'reselect';
import type { CurriedSelector, RootState } from 'store/types';
import { isPlayerLoading } from 'store/playersMeta/selectors';
import { getSports } from 'store/sports/selectors';
import { getTeams } from 'store/teams/selectors';
import { ExtendedSportExperience } from 'store/friends/types';
import type { AffiliatedTeam, Player, PlayersState } from './types';

const getPlayers = (state: RootState): PlayersState => state.players;

export const getPlayerByUserId = (userId: string): CurriedSelector<Player> =>
  createSelector(getPlayers, (players) => players[userId]);

export const getPlayerAffiliatedTeams = (userId: string): CurriedSelector<AffiliatedTeam[]> =>
  createSelector(
    getPlayerByUserId(userId),
    isPlayerLoading(userId),
    getSports,
    getTeams,
    (player, isLoading, sports, teams) => {
      if (!player || isLoading) {
        return [];
      }

      return Object.entries<string>(player.affiliatedTeams || {})
        .filter(([sportId]) => !(sports[sportId] || {}).notSelectableByClient)
        .map(([sportId, teamId]) => ({
          abbreviation: teams[teamId].abbreviation,
          cityName: teams[teamId].cityName,
          teamAvatar: teams[teamId].logoUrl,
          sportAvatar: sports[sportId].avatarUrl,
        }))
        .filter((team) => !!team);
    },
  );

export const getExperienceByUserId = (userId: string): CurriedSelector<ExtendedSportExperience[]> =>
  createSelector(getPlayerByUserId(userId), getSports, (player, sports) =>
    (player?.sportExperience || []).map((exp) => ({
      ...exp,
      sportAvatarUrl: sports[exp.sportId].avatarUrl,
      showExperience: sports[exp.sportId].showExperience,
      sportId: exp.sportId,
    })),
  );
