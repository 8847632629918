import { Colors } from 'style/types';

export enum PropositionAnswerScheme {
  Pending = 'pending',
  Selected = 'selected',
  Open = 'open',
}

export type PropositionAnswerTheme = {
  [scheme in PropositionAnswerScheme]: {
    backgroundColor: Colors | string;
    borderColor: Colors | string;
    multiplyColor: Colors | string;
  };
};

export type PropositionPointsHeaderTheme = {
  color: Colors | string;
};
