import { ClientType } from 'services/pttv/api';

export enum WrapperActions {
  UUID_UPDATED = '@wrapper/UUID_UPDATED',
  INIT_SUCCESS = '@wrapper/INIT_SUCCESS',
  PROPERTY_UPDATED = '@wrapper/PROPERTY_UPDATED',
}

export type WrapperPayload = {
  appsFlyerUID: string | null;
  os: ClientType | null | undefined;
  deviceId: string | null;
  deviceToken: string | null;
  snsEndpoint: string | null;
  uuid: string | null;
  version: number | null;
  appsFlyerInstallAttribution: string | null;
  appsFlyerOpenAttribution: string | null;
  capabilities: string[] | null;
  deviceModel: string | null;
  cashBetServerUrl: string | null;
  deviceName: string | null;
  name: string | null;
  osVersion: string | null;
  storeVersion: string | null;
};

export interface WrapperState extends WrapperPayload {
  isIOSMobile: boolean;
  isMobile: boolean;
  cashBetServerUrl: string | null;
}
