import { Ref } from 'react';

export enum Direction {
  Left = 1,
  Right = -1,
}

export enum PropositionTabState {
  Default,
  Selected,
  Pending,
  Loading,
  Loaded,
}

export interface CustomArgument {
  direction: Direction;
}

export interface DefaultTabProps {
  label: string;
  showNotice?: boolean;
  ref?: Ref<HTMLDivElement> | undefined;
}
export interface ContestTabProps {
  label: string;
  status?: string;
  children: JSX.Element[] | JSX.Element;
}

export interface PropositionTabProps {
  label: string;
  selected?: boolean;
  propositionId: string;
  contestId: string;
  children: JSX.Element[] | JSX.Element;
}
