import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { Friend } from 'services/pttv/api/friends/types';
import { CheckMark } from 'atoms/CheckMark';
import { UserName, UserOnline } from '../UserListItem.styled';
import { AddFriendListItemWrapper } from './AddFriendListItem.styled';

interface Props {
  friend: Friend;
  isSelected: boolean;
  onSelect?: () => void;
}

export const AddFriendListItem: React.FC<Props> = ({ friend, isSelected, onSelect }) => {
  const { t } = useTranslation('SocialLanding');

  const showOnlineText = () => !!friend.currentContestId;

  return (
    <AddFriendListItemWrapper onClick={onSelect}>
      <CheckMark checked={isSelected} size="1.75rem" />
      <Avatar src={friend.avatarUrl} size={AvatarSize.MS} />
      <UserName>{friend.displayName}</UserName>
      {showOnlineText() && <UserOnline>{t('friendList.item.online')}</UserOnline>}
    </AddFriendListItemWrapper>
  );
};
