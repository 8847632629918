import styled from 'styled-components';
import { BorderRadius, BorderWidth, Colors, FontFamily, Padding } from 'style/types';

export const SportIcon = styled.img`
  align-self: center;
  filter: brightness(0%) invert(1);
  grid-area: 1/1/2/2;
  justify-self: center;
  max-height: 1rem;
  max-width: 1rem;
`;

export const Experience = styled.span`
  align-self: center;
  font-family: ${FontFamily.Heading};
  grid-area: 1/2/2/3;
  justify-self: center;
  letter-spacing: 0.7px;
  margin-top: ${Padding.XXXS};
`;

export const ExperienceChipWrapper = styled.section`
  border: ${BorderWidth.Default} solid ${Colors.Primary};
  border-radius: ${BorderRadius.MS};
  display: grid;
  grid-template-columns: 2.25rem 1fr;
  height: 2rem;

  &::before {
    background-color: ${Colors.Primary};
    border-radius: calc(${BorderRadius.MS} - 2 * ${BorderWidth.Default});
    content: '';
    display: block;
    grid-area: 1/1/2/2;
  }
`;
