import styled from 'styled-components';
import { BorderRadius, BorderWidth, Colors, Gap, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const ContestCancelledModalContentArea = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Gap.XXXL};
  height: 100%;
  padding: ${Padding.Default};
`;

export const ContestCancelledCard = styled.section`
  background-color: ${Colors.Black};
  border: ${BorderWidth.Large} solid ${Colors.Black75};
  border-radius: ${BorderRadius.Default};
  overflow: hidden;
`;

export const ContestCancelledContent = styled.section`
  display: grid;
  gap: ${Gap.Default};
  padding: ${Padding.Default} ${Padding.Default} ${Padding.XXXL};
  text-align: center;

  table {
    border-top: 1px solid ${Colors.Black75};
  }

  svg {
    height: ${pxToRem(16)};
    margin-left: ${Gap.S};
  }
`;
