import styled, { css } from 'styled-components';
import { FooterStyle } from 'molecules/Footer/Footer.styled';
import { HeaderContainer } from 'molecules/Header/Header.styled';
import { PageWrapper } from 'molecules/PageWrappers/Page/Page.styled';
import { SlideInWrapper } from 'molecules/PageWrappers/SlideIn';
import { FadeInWrapper } from 'molecules/PageWrappers/FadeIn/FadeIn.styled';
import { PageLoaderWrapper } from 'atoms/PageLoader/PageLoader.styled';
import { isMobile, isTablet } from 'utils/device';

const fullscreen = isMobile() || isTablet();

export const layoutContainerStyle = css`
  display: block;

  @media (min-aspect-ratio: 10/16) {
    display: grid;
    grid-template-columns: 1fr minmax(318px, 66vh) 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'left content right';
  }
  ${!fullscreen &&
  css`
    @media (min-width: 600px) {
      display: grid;
      grid-template-columns: 1fr minmax(318px, 600px) 1fr;
      grid-template-rows: 5rem auto;
      grid-template-areas:
        'header header header'
        'left content right';
    }
  `}
`;

interface LayoutContainerProps {
  bgImage: string;
}

export const LayoutContainer = styled.section<LayoutContainerProps>`
  background-color: ${({ theme }) => theme.layout.container.backgroundColor};
  background-position: top center;
  background-size: cover;
  height: 100%;
  ${layoutContainerStyle}

  ${({ bgImage }) =>
    !fullscreen &&
    css`
      @media (min-width: 600px) {
        background-image: url(${bgImage});
      }
      @media (min-aspect-ratio: 10/16) {
        background-image: url(${bgImage});
      }
    `}
`;

export const Content = styled.main`
  background-color: ${({ theme }) => theme.layout.content.backgroundColor};
  color: ${({ theme }) => theme.layout.content.color};
  display: grid;
  grid-template-rows: var(--safe-area-inset-top) auto 1fr auto var(--safe-area-inset-bottom);
  height: 100%;
  position: relative;
  overflow: hidden;

  > ${HeaderContainer} {
    grid-area: 2/1/3/2;
  }

  > ${PageWrapper} {
    grid-area: 3/1/4/2;

    &.no-header {
      grid-area: 2/1/4/2;
    }
  }

  > ${SlideInWrapper} {
    grid-area: 2/1/4/2;
  }

  > ${FadeInWrapper} {
    grid-area: 2/1/5/2;
  }

  > ${FooterStyle} {
    grid-area: 4/1/5/2;
  }

  > ${PageLoaderWrapper} {
    grid-area: 3/1/4/2;
  }

  @media (min-aspect-ratio: 10/16) {
    grid-area: content;
  }

  ${!fullscreen &&
  css`
    @media (min-width: 600px) {
      grid-area: content;
      height: initial;
    }
  `}
`;

export const DesktopHeader = styled.section`
  display: none;

  ${!fullscreen &&
  css`
    @media (min-width: 600px) {
      align-items: center;
      background-color: ${({ theme }) => theme.layout.header.backgroundColor};
      display: flex;
      grid-area: header;
      justify-content: center;
    }
  `}

  svg {
    height: 60%;
  }
`;
