import styled from 'styled-components';
import wvTexture from 'assets/images/wvTexture.png';
import wvTexture2x from 'assets/images/wvTexture@2x.png';
import wvTexture3x from 'assets/images/wvTexture@3x.png';
import { Gap, MediaQueries, Padding } from 'style/types';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';

export const ContestFinishedModalContentArea = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${Padding.Default};

  > ${ButtonBarContainer} {
    margin-top: ${Gap.XXXL};
  }

  background-position: center;
  background-size: cover;
  background-image: url('${wvTexture}');

  @media ${MediaQueries.Ratio1} {
    background-image: url('${wvTexture2x}');
  }
  @media ${MediaQueries.Ratio2} {
    background-image: url('${wvTexture3x}');
  }
`;
