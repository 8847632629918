import React from 'react';
import { MessageContent, MessageHeader, NoResultsContainer } from './DeviceErrorMessage.styled';

interface Props {
  icon: JSX.Element;
}

export const DeviceErrorMessage: React.FC<Props> = ({ icon, children }) => (
  <NoResultsContainer>
    {!!icon && <MessageHeader>{icon}</MessageHeader>}
    <MessageContent>{children}</MessageContent>
  </NoResultsContainer>
);
