import styled from 'styled-components';
import React from 'react';
import { Colors, FontSize, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import ChevronIcon from 'assets/icons/chevron.svg';
import CrossIcon from 'assets/icons/CrossIcon.svg';

export const HeaderActionIcon = styled.button`
  background-color: transparent;
  border: none;
  color: ${Colors.Text};
  cursor: pointer;
  display: inline-block;
  font-size: ${FontSize.MS};
  height: ${pxToRem(20)};
  left: ${Padding.Default};
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(50% - ${pxToRem(10)});
  transition: transform 0.15s ease;

  &:hover,
  &:active {
    transform: scale(1.15);
  }

  > svg {
    height: 90%;
  }

  &::after {
    display: block;
    content: '';
    border-radius: 999999px;
    width: calc(100% + 2rem);
    height: calc(100% + 2rem);
    top: -1rem;
    left: -1rem;
    position: absolute;
  }
`;

export const ArrowAction = styled(HeaderActionIcon).attrs({
  children: <ChevronIcon />,
})`
  > svg {
    transform: rotate(180deg);

    path {
      fill: white;
    }
  }
`;

export const CrossAction = styled(HeaderActionIcon).attrs({
  children: <CrossIcon />,
})``;
