import React from 'react';
import { Dialog, DialogFooter } from 'atoms/Dialog';
import { Button } from 'atoms/Button';
import { ContentArea } from 'atoms/ContentArea';
import { ButtonSchemes } from 'atoms/Button/button.theme';

interface Props {
  title: string;
  isOpen: boolean;
  buttonText?: string | undefined;
  onClose: () => void;
  buttonAction?: () => void;
}

export const Alert: React.FC<Props> = ({
  title,
  isOpen,
  onClose,
  children,
  buttonAction,
  buttonText,
}) => {
  const handleOnClose = () => {
    if (buttonAction) {
      buttonAction();
    }
    onClose();
  };

  return (
    <Dialog isOpen={isOpen} onClose={handleOnClose} title={title}>
      <ContentArea>{children}</ContentArea>
      <DialogFooter>
        <Button scheme={ButtonSchemes.Secondary} onClick={onClose}>
          {buttonText || 'Ok'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
