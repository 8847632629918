import { createSelector } from 'reselect';
import { Room, RoomPlayer } from 'services/pttv/api/rooms/types';
import type { CurriedSelector, RootState } from 'store/types';
import type { RoomsState, StoreRoom } from './types';

export const getRooms = (state: RootState): RoomsState => state.rooms;

export const getRoomById = (roomId: string): CurriedSelector<StoreRoom> =>
  createSelector(getRooms, (rooms) => rooms[roomId]);

export const getRoomPlayers = (roomId: string): CurriedSelector<RoomPlayer[]> =>
  createSelector(getRooms, (rooms) => {
    const players = [...rooms[roomId].players];
    return players.sort((a, b) => (a.rank < b.rank ? -1 : 1));
  });

export const getRoomUser = (roomId: string): CurriedSelector<RoomPlayer> =>
  createSelector(getRooms, (rooms) => rooms[roomId].user);

export const getFlatRooms = createSelector(getRooms, (rooms) => Object.values(rooms));

export const getRoomsByContest = (contestId: string): CurriedSelector<Room[]> =>
  createSelector(getFlatRooms, (rooms) => rooms.filter((room) => room.contestId === contestId));

export const getRoomByContestAndRoomType = (
  contestId: string,
  roomTypeId: string,
): CurriedSelector<StoreRoom | null> =>
  createSelector(
    getFlatRooms,
    (rooms) =>
      rooms.find((room) => room.contestId === contestId && room.roomTypeId === roomTypeId) || null,
  );

export const getRoomUserByRoomId = (roomId: string): CurriedSelector<RoomPlayer> =>
  createSelector(getRoomById(roomId), (room) => room.user);
