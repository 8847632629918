import { ContestState, PaymentMethod } from 'services/pttv/api/constants';
import { RoomTypeLeaderboardUpdate } from 'services/pttv/api/rooms/types';

export enum ContestActions {
  UPDATE_CONTEST = '@contests/UPDATE_CONTEST',
  JOIN_CONTEST_COMPLETE = '@contests/JOIN_CONTEST_COMPLETE',
  JOIN_CONTEST_SUCCESS = '@contests/JOIN_CONTEST_SUCCESS',
  UPDATE_ROOM_TYPE_COUNT = '@contest/UPDATE_ROOM_TYPE_COUNT',
  UPDATE_ROOM_TYPE_COUNT_BY_EVENT = '@contest/UPDATE_ROOM_TYPE_COUNT_BY_EVENT',
  CONTEST_ROOM_TYPES_AVAILABLE_TO_JOIN_UPDATED = '@contests/CONTEST_ROOM_TYPES_AVAILABLE_TO_JOIN_UPDATED',
  CONTEST_PROBLEM = '@contests/CONTEST_PROBLEM',
  CONTEST_PROBLEM_TOAST = '@contests/CONTEST_PROBLEM_TOAST',
  REMOVE_CONTEST = '@contests/REMOVE_CONTEST',
}

export type RebuyInfoBase = {
  dollarCents: number;
  tickets: {
    [ticketId: string]: number;
  };
  notValidTickets: {
    [ticketId: string]: boolean;
  };
  roomTypeIdsToPaymentMethodMap: {
    [roomTypeId: string]: PaymentMethod;
  };
};

export type RebuyInfo = Omit<RebuyInfoBase, 'notValidTickets'> & {
  roomTypeIds: string[];
};
export interface UpdateRoomCountPayload {
  contestId: string;
  roomTypeId: string;
  count: number;
}

export interface JoinContestCompleteArgs {
  contestId: string;
  points: number;
  roomTypeLeaderboardUpdates: RoomTypeLeaderboardUpdate[];
}

export interface ContestRoomTypesAvailableToJoinUpdatedPayload {
  contestId: string;
  roomTypeIds: string[];
}

export interface ContestProblemResponse {
  problem: string;
  contestId: string;
  force: boolean;
}

export interface RoomTypeCount {
  roomTypeId: string;
  count: number;
}

export interface RoomTypeTag {
  id: string;
  name: string;
}

export interface ContestProblem {
  type: 'CASHBET_OUTAGE' | 'CASHBET_ERROR' | 'GENERAL_PROBLEM';
  description: string;
  resolved: boolean;
  resolvedAt: number;
  resolveReason: string;
}

export interface Contest {
  contestId: string;
  gameId: string;
  sportId: string;
  period: number;
  state: ContestState;
  allowedBetPoints: number[];
  oneShotPoints: number;
  initialPoints: number;
  uniqueRoomTypeTags: RoomTypeTag[];
  roomTypeIds: string[];
  lastResortPointReward: number;
  openDate: number;
  contestProblems: string[];
  version: number;
  hidden: boolean;
  producerMessages?: {
    text: string;
    timestamp: number;
    contestId: string;
    messageId: string;
  }[];
  cancelled?: boolean;
  problems?: ContestProblem[];
  roomTypeCounts: RoomTypeCount[];
}

export type ContestsState = Record<string, Contest>;
