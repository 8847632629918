import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDialog } from 'hooks/useDialog';
import WarnIcon from 'assets/icons/WarnIcon.svg';

export const ErrorModal: React.FC = () => {
  const { t } = useTranslation('Global');
  const { showAlert } = useDialog();
  const { replace } = useHistory();

  useEffect(() => {
    showAlert(
      t('mainErrorMessage.title'),
      <>
        <div>
          <WarnIcon width="5rem" />
        </div>
        {t('mainErrorMessage.description')}
      </>,
      {
        closeButtonAction: () => replace('/'),
      },
    );
  }, []);

  return null;
};
