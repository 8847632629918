import styled, { css } from 'styled-components';
import { ButtonBarDirections } from 'style/types';

interface ContainerProps {
  $justify?: string;
}

export const ButtonBarContainer = styled.section<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $justify }) => $justify || 'center'};
`;

interface ButtonGroupProps {
  $direction: ButtonBarDirections;
  $count: number;
}

export const ButtonGroup = styled.section<ButtonGroupProps>`
  display: inline-grid;
  gap: 1rem;
  ${({ $direction, $count }) =>
    $direction === ButtonBarDirections.Row
      ? css`
          grid-template-columns: repeat(${$count}, 1fr);
        `
      : css`
          grid-template-rows: repeat(${$count}, 1fr);
        `}
`;
