import { UserState } from 'store/user/types';

export enum UiActions {
  UPDATE_READY = '@ui/UPDATE_READY',
  SW_REGISTRATION_FAILED = '@ui/SW_REGISTRATION_FAILED',
  SW_REGISTRATION_SUCCESS = '@ui/SW_REGISTRATION_SUCCESS',
  UPDATE_NAMED_TAB_INDEX = '@ui/UPDATE_NAMED_TAB_INDEX',
  NAVIGATED = '@ui/NAVIGATED',
  DEPOSIT_BUTTON_CLICKED = '@ui/DEPOSIT_BUTTON_CLICKED',
  TRACK_VIEW_FROM_PATH = '@ui/TRACK_VIEW_FROM_PATH',
  PUSH_NOTIFICATION_PROMPT = '@ui/PUSH_NOTIFICATION_PROMPT',
  CLEVER_TAP_LOGGED_IN = '@ui/CLEVER_TAP_LOGGED_IN',
  INCREMENT_UI_TIMER = '@ui/INCREMENT_UI_TIMER',
}

export interface Rehydrate {
  payload: {
    user: UserState;
  };
}

export interface UiState extends Record<string, number | boolean> {
  isReady: boolean;
  updateReady: boolean;
  swRegistrationFailed: boolean;
  swRegistrationSuccess: boolean;
  isUserStateLoaded: boolean;
  inMaintenance: boolean;
  uiTimer: number;
}

export interface UiAction {
  type: UiActions;
  payload: undefined;
}

export interface UiActionTabIndex {
  type: UiActions;
  payload: {
    name: string;
    tabIndex: number;
  };
}

export interface UiActionMeta {
  type: UiActions;
  payload?: Location;
  meta: Record<string, unknown>;
}
