import React from 'react';
import DOMPurify from 'dompurify';
import { HtmlContentContainer } from './HtmlContent.styled';

interface HtmlContentProps {
  dangerousContent: string | Node;
  allowIframes?: boolean;
}

export const HtmlContent: React.FC<HtmlContentProps> = ({ dangerousContent, allowIframes }) => (
  <HtmlContentContainer
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(
        dangerousContent,
        allowIframes
          ? {
              ADD_TAGS: ['iframe'],
              ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
            }
          : {},
      ),
    }}
  />
);
