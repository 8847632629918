import { debugWarn } from 'utils/log';

export type Parameter = {
  name: string;
  value: string | number | boolean;
};

export const appendUrlParams = (
  url: string | URL,
  params: Parameter[] | Parameter,
): string | null | undefined => {
  if (!url || !params) {
    return null;
  }

  try {
    const formattedUrl = new URL(url);

    if (Array.isArray(params) && params.length) {
      params.forEach(({ name, value }) => formattedUrl.searchParams.append(name, value.toString()));
    } else {
      const { name, value } = params as Parameter;
      formattedUrl.searchParams.append(name, value.toString());
    }

    return formattedUrl.href;
  } catch (e) {
    debugWarn('[appendUrlParams]', 'Failed to transform url', e);
  }

  return undefined;
};
