import React, { ChangeEvent, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { getUniqueAcceptedMembers } from 'store/leagues/selectors';
import { LeagueMemberStatus } from 'services/pttv/api/constants';
import { Select } from 'atoms/Form';

interface Props {
  leagueName: string;
  description: string;
  passCommissionerId: (id: string) => void;
}
export const NewCommissionerDialog: React.FC<Props> = ({
  leagueName,
  passCommissionerId,
  description,
}) => {
  const [commissionerId, setCommissionerId] = useState('');
  const leagueMembers = useAppSelector(getUniqueAcceptedMembers(leagueName));

  const handleMemberChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCommissionerId(event.target.value);
    passCommissionerId(event.target.value);
  };

  return (
    <>
      <p>{description}</p>
      {leagueMembers.length && (
        <section>
          <Select name="member" value={commissionerId} onChange={handleMemberChange}>
            {leagueMembers
              .filter((member) => member.status === LeagueMemberStatus.COMMISSIONER)
              .map((member) => (
                <option key={member.userId} value={member.userId}>
                  Select a member
                </option>
              ))}
            {leagueMembers
              .filter((member) => member.status !== LeagueMemberStatus.COMMISSIONER)
              .map((member) => (
                <option value={member.userId} key={member.userId}>
                  {member.displayName}
                </option>
              ))}
          </Select>
        </section>
      )}
    </>
  );
};
