import styled from 'styled-components';
import { PlayerCountContainer } from 'atoms/PlayerCount/PlayerCount.styled';
import { Colors, Padding, FontFamily, FontSize } from 'style/types';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';

export const LeagueCard = styled.section`
  background-color: ${Colors.ActiveBackground};
  padding: ${Padding.Default};

  display: grid;

  ${PlayerCountContainer} {
    align-self: center;
    color: ${Colors.TextSub};
    font-family: ${FontFamily.Heading};
    font-size: ${FontSize.L};
    grid-area: members;
    margin: ${Padding.XS};
  }

  > ${StyledAvatar} {
    grid-area: avatar;
  }
`;
