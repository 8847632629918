import { trackEvent, identifyUser } from '@redux-beacon/segment';
import combineEvents from '@redux-beacon/combine-events';
import ensure from '@redux-beacon/ensure';
import { RoomActions } from 'store/rooms/types';
import { AnalyticsActions } from 'hooks/useAnalytics/types';
import { BetActions } from 'store/bets/types';
import { ProducerMessageActions } from 'store/producerMessage/types';
import { RoomChatActions } from 'store/roomChat/types';
import { UserActions } from 'store/user/types';
import { AppActions } from 'store/app/types';
import { chatMessageSubmitted, isValidChatMessageSubmittedEvent } from './chatMessageSubmitted';
import { failureAccountLoginCustom } from './failureAccountLogInCustom';
import { roomRegister } from './roomRegister';
import { identify } from './identify';
import { isValidPlaceBetEvent, placeBet } from './placeBet';
import { producerMessageSubmitted } from './producerMessageSubmitted';
import { successfulAccountLoginCustom } from './successfulAccountLogInCustom';
import { applicationOpenedCustom } from './applicationOpenedCustom';
import { viewSchedule } from './viewSchedule';
import { viewSocial } from './viewSocial';
import { roomRegisterType } from './roomRegisterType';

export const segmentEventsMap = {
  [UserActions.SIGN_IN_ERROR]: trackEvent(failureAccountLoginCustom),
  [UserActions.SIGN_IN_SUCCESS]: trackEvent(successfulAccountLoginCustom),
  [RoomActions.TRACK_JOIN_ROOM]: combineEvents(
    trackEvent(roomRegisterType),
    trackEvent(roomRegister),
  ),
  [AnalyticsActions.VIEW_SCHEDULE]: trackEvent(viewSchedule),
  [AnalyticsActions.VIEW_SOCIAL]: trackEvent(viewSocial),
  [`${BetActions.PLACE_OR_UPDATE_BET}/fulfilled`]: ensure(
    isValidPlaceBetEvent,
    trackEvent(placeBet),
  ),
  [`${RoomChatActions.SEND_ROOM_CHAT_MESSAGE}/fulfilled`]: ensure(
    isValidChatMessageSubmittedEvent,
    trackEvent(chatMessageSubmitted),
  ),
  [`${ProducerMessageActions.ADD_PRODUCER_MESSAGE}/fulfilled`]:
    trackEvent(producerMessageSubmitted),
  [AppActions.APP_OPENED]: identifyUser(applicationOpenedCustom),
  [AnalyticsActions.IDENTIFY]: identifyUser(identify),
  [AppActions.APP_OPENED]: trackEvent(applicationOpenedCustom),
};
