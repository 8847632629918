import { createAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { PropositionToastArgs, ToastActions, ToastPayload, ToastType } from './types';

export const TOASTS_LIMIT = 1;

export const addPropositionToast = createAction(
  ToastActions.ADD_TOAST,
  (payload: PropositionToastArgs) => ({
    payload: {
      id: uuid(),
      ...payload,
      type: ToastType.PropositionResult,
    },
  }),
);

export const addTextToast = createAction(ToastActions.ADD_TEXT_TOAST, (payload: ToastPayload) => ({
  payload: {
    id: uuid(),
    type: ToastType.Default,
    ...payload,
  },
}));

export const removeToast = createAction(ToastActions.REMOVE_TOAST);

export const clearToasts = createAction(ToastActions.CLEAR_TOAST);
