import React from 'react';
import styled, { css } from 'styled-components';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';
import { pxToRem } from 'utils/pxToRem';
import { BorderWidth, Colors, FontSize, FontWeight, Padding } from 'style/types';
import { Button } from 'atoms/Button';

interface Props {
  isUser?: boolean;
  pending?: boolean;
}

export const UserListItemWrapper = styled.li.attrs<Props>(({ children }) => ({
  children: <section>{children}</section>,
}))<Props>`
  border-bottom: ${BorderWidth.Default} solid ${Colors.Black75};
  list-style: none;

  > section {
    align-items: center;
    display: grid;
    grid-template-columns: 2rem auto 1fr auto;
    grid-template-areas: 'state avatar name action';
    height: ${pxToRem(60)};

    ${StyledAvatar} {
      grid-area: avatar;
      opacity: ${({ pending }) => (pending ? 0.6 : 1)};
    }

    ${Button} {
      grid-area: action;
      margin-right: 1.5rem;
    }

    ${({ isUser }) =>
      isUser &&
      css`
        position: relative;
        overflow: hidden;

        &::before {
          background-color: ${Colors.Primary};
          content: '';
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: ${pxToRem(4)};
        }

        &::after {
          background-color: ${Colors.Primary};
          content: '';
          display: block;
          height: ${pxToRem(8)};
          left: ${pxToRem(-2)};
          transform: translateY(-50%) rotate(45deg);
          position: absolute;
          top: 50%;
          width: ${pxToRem(8)};
        }
      `}
  }
`;

interface NameProps {
  pending?: boolean;
}

export const UserName = styled.div<NameProps>`
  align-content: center;
  color: ${({ pending }) => (pending ? Colors.Black50 : Colors.Text)};
  display: flex;
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
  grid-area: name;
  padding-left: ${Padding.L};

  > svg {
    color: ${Colors.Primary};
    margin-left: 1rem;
    max-height: 1.25rem;
    max-width: 1.25rem;
  }
`;

export const UserOnline = styled.div`
  color: ${Colors.Success};
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
  grid-area: action;
  margin-right: 1.5rem;
`;

export const OnlineDot = styled.div`
  background-color: ${Colors.Success};
  border-radius: 50%;
  height: ${pxToRem(8)};
  justify-self: center;
  width: ${pxToRem(8)};
`;
