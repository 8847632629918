import styled, { css } from 'styled-components';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  hasError?: boolean;
}

export const Input = styled.input<Props>`
  background-color: ${Colors.ActiveBackground};
  border: ${BorderWidth.Large} solid ${Colors.ActiveBorder};
  border-radius: ${BorderRadius.Default};
  color: ${Colors.Text};
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.SemiBold};
  line-height: ${pxToRem(40)};
  padding: ${Padding.XS} ${Padding.Default};
  width: 100%;

  &::placeholder {
    font-size: ${FontSize.ML};
    font-weight: ${FontWeight.SemiBold};
    color: ${Colors.ActiveBorder};
  }

  &:disabled {
    background-color: ${Colors.InactiveBackground};
    color: ${Colors.TextSub};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${Colors.Error};
    `}
`;
