import { AnimatePresence, motion } from 'framer-motion';
import React, { MouseEvent } from 'react';
import ReactModal from 'react-modal';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
// eslint-disable-next-line import/no-cycle
import { OverlayHeader } from 'molecules/OverlayHeader';
import { BackDrop, ModalPageArea } from './ModalPage.styled';

interface Props {
  title?: string;
  isOpen: boolean;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
  canCloseOnOutClick?: boolean;
  onAfterClose?: () => void;
  closeActionType?: OverlayHeaderActions;
  // onRequestClose?: () => void;
}

ReactModal.setAppElement('#modal-pages');

export const ModalPage: React.FC<Props> = ({
  title,
  children,
  isOpen,
  onClose,
  canCloseOnOutClick = true,
  closeActionType = OverlayHeaderActions.Chevron,
  onAfterClose,
}) => (
  <ReactModal
    onRequestClose={onClose}
    onAfterClose={onAfterClose}
    closeTimeoutMS={300}
    isOpen={isOpen}
    style={{ overlay: {}, content: {} }}
    shouldCloseOnEsc={canCloseOnOutClick}
    shouldCloseOnOverlayClick={canCloseOnOutClick}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    overlayElement={({ style, ...props }, contentElement) => (
      <AnimatePresence exitBeforeEnter>
        {!props.className?.includes('before-close') && (
          <BackDrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            ref={props.ref}
            onClick={props.onClick}
            onMouseDown={props.onMouseDown}
          >
            <ModalPageArea>
              <OverlayHeader type={closeActionType} onClose={onClose}>
                {title}
              </OverlayHeader>
              {contentElement}
            </ModalPageArea>
          </BackDrop>
        )}
      </AnimatePresence>
    )}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contentElement={({ style, ...props }, contentChildren) => (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(props as unknown)}
            key="modal-page"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ overflow: 'scroll' }}
            transition={{ duration: 0.3 }}
          >
            {contentChildren}
          </motion.div>
        )}
      </AnimatePresence>
    )}
  >
    {children}
  </ReactModal>
);
