export enum TabsActions {
  SET_TAB_INDEX = '@tabs/SET_TAB_INDEX',
}

export interface SetTabsArguments {
  tabsId: string;
  id: number;
}

export type TabsState = {
  [tabsName: string]: number;
};
