import { createReducer } from '@reduxjs/toolkit';
import { playToTv } from 'services/pttv';
import { clearUser, fetchUserState, signInSuccess } from 'store/user/actions';
import { toggleUserMute } from './actions';
import { UserMetaState } from './types';

export const initialState: UserMetaState = {
  authToken: '',
  sessionKey: '',
  unseenTicketsCount: 0,
  pushNotificationPromptedAt: 0,
  mutedUsers: {},
};

export const userMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) => ({
      ...state,
      unseenTicketsCount: payload.unseenTicketsCount,
    }))
    .addCase(signInSuccess, (state, { payload }) => ({
      ...state,
      authToken: payload.authToken,
      sessionKey: payload.sessionKey,
      unseenTicketsCount: 0,
    }))
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(toggleUserMute, (state, { payload }) => {
      const { mutedAt } = state.mutedUsers[payload];
      state.mutedUsers[payload] = {
        mutedAt: mutedAt ? null : playToTv.getServerTime(),
      };
    });
});
