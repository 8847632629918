export enum ProducerMessageActions {
  ADD_PRODUCER_MESSAGE = '@producerMessage/ADD_PRODUCER_MESSAGE',
  SEEN_PRODUCER_MESSAGES = '@producerMessage/SEEN_PRODUCER_MESSAGES',
}

export interface StoreProducerMessage {
  text: string;
  timestamp: number;
  contestId: string;
  messageId: string;
  id: string;
  seen: boolean;
}

export type ProducerMessageState = {
  [contestId: string]: {
    [messageId: string]: StoreProducerMessage;
  };
};
