import styled from 'styled-components';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';

export const ReportPlayerWrapper = styled.article`
  background-color: ${Colors.Black};
  border: ${BorderWidth.Large} solid ${Colors.Black75};
  border-radius: ${BorderRadius.Default};
`;

export const ReportPlayerHeader = styled.header`
  align-items: center;
  border-bottom: ${BorderWidth.Default} solid ${Colors.Black75};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${Padding.XXL} ${Padding.XXL} ${Padding.Default};
`;

export const PlayerName = styled.h4`
  font-size: ${FontSize.M};
  font-weight: ${FontWeight.SemiBold};
  grid-area: title;
  text-align: center;
`;

export const ReportPlayerForm = styled.form`
  padding: ${Padding.XXL};

  ${ButtonBarContainer} {
    margin-top: ${Gap.L};
  }
`;
