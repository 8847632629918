import React, { Suspense, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Page, SlideIn } from 'molecules/PageWrappers';
import { PageLoader } from 'atoms/PageLoader';
import { LazyPreLoader } from 'utils/LazyPreLoader';
import { withErrorBoundary } from 'hocs/withErrorBoundary';
import SocialLandingPage from '.';

const lazyLoader = new LazyPreLoader();

const CreateLeaguePage = lazyLoader.lazy(() => import('./createLeague'));
const InviteFriendsPage = lazyLoader.lazy(() => import('./inviteFriends'));
const LeagueRoutes = lazyLoader.lazy(() => import('./[leagueName]/routes'));

export const socialPaths = {
  createLeague: '/social/create-league',
  friendsInviteFriend: '/social/invite-friend',
  league: '/social/:leagueName',
  social: '/social',
};

interface Params {
  leagueName: string;
}

const SocialRoutes: React.FC = () => {
  const match = useRouteMatch<Params>(Object.values(socialPaths));
  const history = useHistory();

  useEffect(() => {
    lazyLoader.preload();
  }, []);

  return (
    <>
      <Page>
        <SocialLandingPage />
      </Page>
      <Suspense fallback={PageLoader}>
        <SwitchTransition mode="in-out">
          <CSSTransition
            key={match.path}
            timeout={{
              appear: history.action === 'POP' ? 0 : 500,
              enter: history.action === 'POP' ? 0 : 500,
              exit: 500,
            }}
            classNames="slidein"
            unmountOnExit
          >
            <>
              {match?.path === socialPaths.createLeague && match?.isExact && (
                <SlideIn zIndex={1}>
                  <CreateLeaguePage />
                </SlideIn>
              )}
              {match?.path === socialPaths.friendsInviteFriend && match?.isExact && (
                <SlideIn zIndex={1}>
                  <InviteFriendsPage />
                </SlideIn>
              )}

              {match?.path === socialPaths.league && <LeagueRoutes />}
            </>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default withErrorBoundary(SocialRoutes);
