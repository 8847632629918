import { createAsyncThunk } from '@reduxjs/toolkit';
import { UnreadMessagesAPI } from 'services/pttv/api/unreadMessages';
import { UnreadMessagesActions } from './types';

export const setUnreadMessageToRead = createAsyncThunk<string, string>(
  UnreadMessagesActions.SET_MESSAGE_TO_READ,
  async (messageId) => {
    await UnreadMessagesAPI.setMessageToRead(messageId);

    return messageId;
  },
);
