import { createReducer } from '@reduxjs/toolkit';
import { RoomResultsPayloadRoom } from 'hocs/withLongPoll/types/room';
import { RoomTypeLeaderboardResultsPayloadRoomType } from 'hocs/withLongPoll/types/roomType';
import { clearUser } from 'store/user/actions';
import { ContestResultsState, ContestResultActions } from './types';

export const initialState: ContestResultsState = {};

const addResultActions = [
  ContestResultActions.ADD_CONTEST_ROOMTYPE_LEADERBOARD_RESULTS,
  ContestResultActions.ADD_CONTEST_ROOM_RESULTS,
];

export const contestResultsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addMatcher<{
      payload: RoomResultsPayloadRoom | RoomTypeLeaderboardResultsPayloadRoomType;
      type: string;
    }>(
      (action) => addResultActions.includes(action.type),
      (state, { payload }) => {
        // TODO [EG 2021-11-25] Re-evaluate this part including the types
        const contestResults = state[payload.contestId] || {};

        if (!payload.roomTypeId || !contestResults[payload.roomTypeId]) {
          state[payload.contestId] = {
            ...contestResults,
            [(payload as RoomResultsPayloadRoom).roomId || payload.roomTypeId]: payload,
          };
        }
      },
    );
});
