import { RoomType } from 'services/pttv/api/rooms/types';
import { TrackRoomProperties } from 'store/rooms/types';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { getUser } from 'store/user/selectors';
import { SegmentEvent } from '../types';

const calculateRevenue = (roomType: RoomType): number => {
  let revenue = 0;

  if (roomType.dollarCentsRake) {
    revenue += roomType.dollarCentsRake;
  }

  return revenue / 100;
};

export const roomRegisterType: SegmentEvent = (action, prevState, nextState) => {
  const { preparedQueueEntry, latitude, longitude, accuracy, ip } =
    action.payload as TrackRoomProperties;
  const roomType = getRoomTypeById(preparedQueueEntry.roomTypeId)(nextState);
  const user = getUser(nextState);

  const rake = calculateRevenue(roomType);

  const name = roomType.dollarCentsBuyIn ? 'room_register_cash' : 'room_register_free';

  return {
    name,
    properties: {
      cost_value: roomType.dollarCentsBuyIn,
      em_winview_id: user.userId,
      em_actor_id: user.playerId,
      event_revenue: rake,
      event_revenue_cash_cents: rake * 100,
      latitude,
      longitude,
      accuracy,
      ip,
    },
  };
};
