import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { ModalPage } from 'atoms/ModalPage';
import { ContestFinishingCard } from 'organisms/ContestFinishingCard';
import { getContestById, getRebuyContest, getRebuyRoomTypeIds } from 'store/contests/selectors';
import { getNormalizedTicketTypesByRoomTypeIds } from 'store/ticketTypes/selectors';
import { useAppSelector } from 'store/hooks';
import { getSportByContestId } from 'store/sports/selectors';
import { formatOrdinals } from 'utils/formatters';
import { RoomTypesList } from 'molecules/RoomTypesList';
import { getRoomTypesByRoomTypeIds } from 'store/roomTypes/selectors';
import { RebuyButton } from 'organisms/RebuyButton';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonBarDirections } from 'style/types';
import { Title, CardTitle, CardDescription } from '../Modals.styled';
import {
  ContestFinishingContent,
  ContestFinishingModalContentArea,
} from './ContestFinishingModal.styled';

interface Props {
  contestId: string;
  closeCallback: () => void;
}

export const ContestFinishingModal: React.FC<Props> = ({ contestId, closeCallback }) => {
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const contest = useAppSelector(getContestById(contestId));
  const sport = useAppSelector(getSportByContestId(contestId));
  const rebuyContest = useAppSelector(getRebuyContest(contestId));
  const rebuyRoomTypeIds = useAppSelector(getRebuyRoomTypeIds(contestId));
  const roomTypes = useAppSelector(getRoomTypesByRoomTypeIds(rebuyRoomTypeIds || []));
  const buyInTickets = useAppSelector(
    getNormalizedTicketTypesByRoomTypeIds((roomTypes || []).map(({ roomTypeId }) => roomTypeId)),
  );

  const { t } = useTranslation('ContestModals');

  if (!contest || !sport) {
    return null;
  }

  useEffect(() => {
    if (contest && sport) {
      setIsOpen(true);
    }
  }, [contest, sport]);

  const handleCloseButtonClick = async (event?: MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    setIsOpen(false);
    closeCallback();
  };

  const openRoomSelectionAction = () => {
    if (rebuyContest) {
      push(`/games/${rebuyContest.gameId}/contests/${rebuyContest.contestId}/room-selection`);
    }
  };

  return (
    <>
      <ModalPage
        title={t('contestFinishing.PageTitle', { period: sport.period })}
        isOpen={isOpen}
        onClose={handleCloseButtonClick}
      >
        <ContestFinishingModalContentArea>
          <Title>{t('contestFinishing.modalTitle')}</Title>
          <ContestFinishingCard contestId={contestId}>
            <ContestFinishingContent>
              <CardTitle>
                {t('contestFinishing.cardTitle', {
                  period: `${formatOrdinals(contest.period)} ${sport.period}`,
                })}
              </CardTitle>
              <CardDescription>{t('contestFinishing.cardSubTitle')}</CardDescription>
              {!!rebuyRoomTypeIds?.length && (
                <>
                  <CardTitle>{t('contestFinishing.roomsTitle')}</CardTitle>
                  <RoomTypesList roomTypes={roomTypes} buyInTickets={buyInTickets} />
                </>
              )}
            </ContestFinishingContent>
          </ContestFinishingCard>
          {rebuyContest && (
            <ButtonBar direction={ButtonBarDirections.Column}>
              <RebuyButton
                contestId={contestId}
                onClose={handleCloseButtonClick}
                closeParentModal={() => {
                  setIsOpen(false);
                  closeCallback();
                }}
              >
                {t('contestFinishing.rebuyButton')}
              </RebuyButton>
            </ButtonBar>
          )}
          <ButtonBar direction={ButtonBarDirections.Row}>
            <Button scheme={ButtonSchemes.Secondary} onClick={handleCloseButtonClick}>
              {t('contestFinishing.backButton')}
            </Button>
            {rebuyContest ? (
              <Button scheme={ButtonSchemes.Secondary} onClick={openRoomSelectionAction}>
                {t('contestFinishing.roomSelectionButton')}
              </Button>
            ) : null}
          </ButtonBar>
        </ContestFinishingModalContentArea>
      </ModalPage>
    </>
  );
};
