import React from 'react';
import { useAppSelector } from 'store/hooks';
import { getUser } from 'store/user/selectors';
import { formatCurrency } from 'utils/formatters';
import { WalletContainer, ToBalance, Balance } from './wallet.styled';

const Wallet: React.FC = ({ children }) => {
  const user = useAppSelector(getUser);

  return (
    <WalletContainer>
      <Balance>{formatCurrency(user.dollarCents / 100)}</Balance>
      <ToBalance to="/profile/deposit">{children}</ToBalance>
    </WalletContainer>
  );
};

export default Wallet;
