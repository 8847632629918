import { AnimatePresence } from 'framer-motion';
import React from 'react';
import ReactModal from 'react-modal';
import { BackDrop, DialogArea, DialogCard, DialogContent, DialogTitle } from './Dialog.styled';

interface Props {
  title?: string;
  isOpen: boolean;
  onClose?: () => void;
  canCloseOnOutClick?: boolean;
  onAfterClose?: () => void;
  onRequestClose?: () => void;
  zIndex?: number;
}

ReactModal.setAppElement('#modal-dialogs');

export const Dialog: React.FC<Props> = ({
  title,
  children,
  isOpen,
  onClose,
  canCloseOnOutClick = true,
  onAfterClose,
  zIndex,
}) => (
  <ReactModal
    onRequestClose={onClose}
    onAfterClose={onAfterClose}
    closeTimeoutMS={300}
    isOpen={isOpen}
    style={{ overlay: {}, content: {} }}
    shouldCloseOnEsc={canCloseOnOutClick}
    shouldCloseOnOverlayClick={canCloseOnOutClick}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    overlayElement={({ style, ...props }, contentElement) => (
      <AnimatePresence exitBeforeEnter>
        {!props.className?.includes('before-close') && (
          <BackDrop
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            ref={props.ref}
            onClick={props.onClick}
            onMouseDown={props.onMouseDown}
            $zIndex={zIndex}
          >
            <DialogArea>
              <DialogContent>{contentElement}</DialogContent>
            </DialogArea>
          </BackDrop>
        )}
      </AnimatePresence>
    )}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contentElement={({ style, ...props }, contentChildren) => (
      <AnimatePresence>
        {isOpen && (
          <DialogCard
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(props as unknown)}
            key="test"
            initial={{ opacity: 0, y: '-100vh' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-100vh' }}
            transition={{ duration: 0.3 }}
          >
            {title && <DialogTitle>{title}</DialogTitle>}
            {contentChildren}
          </DialogCard>
        )}
      </AnimatePresence>
    )}
  >
    {children}
  </ReactModal>
);
