import styled, { css } from 'styled-components';
import { BorderRadius, BorderWidth, FontFamily, FontSize, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { ButtonSchemes, ButtonSize } from './button.theme';

const buttonPadding = {
  [ButtonSize.Tiny]: `calc(${Padding.XXS} * 1.17) ${Padding.S} calc(${Padding.XXS} * 0.83) ${Padding.S}`,
  [ButtonSize.Small]: `calc(${Padding.XS} * 1.1) ${Padding.Default} calc(${Padding.XS} * 0.90)  ${Padding.Default}`,
  [ButtonSize.Default]: `calc(${Padding.S}  * 1.08) ${Padding.XXXL} calc(${Padding.S} * 0.92)  ${Padding.XXXL}`,
  [ButtonSize.Large]: `calc(${Padding.S} * 1.07) ${Padding.XXXL} calc(${Padding.S} * 0.93)  ${Padding.XXXL}`,
  [ButtonSize.Wide]: `calc(${Padding.XS} * 1.1) ${Padding.XXXL} calc(${Padding.XS} * 0.90)  ${Padding.XXXL}`,
};

const buttonFontSize = {
  [ButtonSize.Tiny]: FontSize.MS,
  [ButtonSize.Small]: FontSize.M,
  [ButtonSize.Default]: FontSize.ML,
  [ButtonSize.Large]: FontSize.L,
  [ButtonSize.Wide]: FontSize.ML,
};

const buttonBorderRadius = {
  [ButtonSize.Tiny]: BorderRadius.MS,
  [ButtonSize.Small]: BorderRadius.Default,
  [ButtonSize.Default]: BorderRadius.Default,
  [ButtonSize.Large]: BorderRadius.Default,
  [ButtonSize.Wide]: BorderRadius.Default,
};

export interface ButtonProps {
  scheme?: ButtonSchemes;
  outline?: boolean;
  size?: ButtonSize;
}

export interface AttrsProps {
  size: ButtonSize;
}

export const Button = styled.button.attrs<ButtonProps, AttrsProps>(({ size, type }) => ({
  size: size || ButtonSize.Default,
  type: type || 'button',
}))<ButtonProps>`
  border: ${BorderWidth.Default} solid transparent;
  border-radius: ${({ size }) => buttonBorderRadius[size]};
  padding: ${({ size }) => buttonPadding[size]};
  font-family: ${FontFamily.Heading};
  font-size: ${({ size }) => buttonFontSize[size]};
  letter-spacing: 0.57px;
  line-height: 1.1;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: ${({ size }) => (size === ButtonSize.Wide ? '50vw' : 'auto')};
  max-width: ${({ size }) => (size === ButtonSize.Wide ? pxToRem(260) : 'none')};

  ${({ scheme = ButtonSchemes.Primary, theme }) => css`
    background-color: ${theme.button[scheme].default.backgroundColor};
    border-color: ${theme.button[scheme].default.backgroundColor};
    color: ${theme.button[scheme].default.color};

    &:hover {
      background-color: ${theme.button[scheme].hover.backgroundColor};
      border-color: ${theme.button[scheme].hover.backgroundColor};
      color: ${theme.button[scheme].hover.color};
    }

    &:active {
      background-color: ${theme.button[scheme].active.backgroundColor};
      border-color: ${theme.button[scheme].active.backgroundColor};
      color: ${theme.button[scheme].active.color};
    }

    &:disabled {
      background-color: ${theme.button[scheme].disabled.backgroundColor};
      border-color: ${theme.button[scheme].disabled.backgroundColor};
      color: ${theme.button[scheme].disabled.color};
      cursor: not-allowed;
    }
  `}
  ${({ scheme = ButtonSchemes.Primary, theme, outline }) =>
    outline &&
    css`
      background-color: transparent;
      border-color: ${theme.button[scheme].default.backgroundColor};
      color: ${theme.button[scheme].default.backgroundColor};

      &:hover {
        background-color: transparent;
        border-color: ${theme.button[scheme].hover.backgroundColor};
        color: ${theme.button[scheme].hover.backgroundColor};
      }

      &:active {
        background-color: transparent;
        border-color: ${theme.button[scheme].active.backgroundColor};
        color: ${theme.button[scheme].active.backgroundColor};
      }

      &:disabled {
        background-color: transparent;
        border-color: ${theme.button[scheme].disabled.backgroundColor};
        color: ${theme.button[scheme].disabled.backgroundColor};
        cursor: not-allowed;
      }
    `}

    > svg {
    height: 0.7rem;
    margin-right: 0.25rem;
  }
`;
