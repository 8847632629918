export type PttvErrorHandler = (error: PttvError, data?: Record<string, unknown>) => void;
export type PttvMessageHandler = (message: string, data?: unknown[]) => void;

export interface Pttv {
  multipleSessionHandler: PttvErrorHandler | undefined;
  userBlockedHandler: PttvErrorHandler | undefined;
  clientUnauthorizedHandler: PttvErrorHandler | undefined;
  serverFullHandler: PttvErrorHandler | undefined;
  serverMaintenanceHandler: PttvErrorHandler | undefined;
  nonHumanError400Handler: PttvErrorHandler | undefined;
  cashBetResetHandler: PttvErrorHandler | undefined;
  sessionKey: string | null;
  get: <T>(url: string) => Promise<T>;
  post: <T, S = Record<string, unknown>>(url: string, data: S) => Promise<T>;
  put: <T, S = Record<string, unknown>>(url: string, data: S) => Promise<T>;
  delete: <T>(url: string) => Promise<T>;
  onMultipleSessions: (handler: PttvErrorHandler) => void;
  onUserBlocked: (handler: PttvErrorHandler) => void;
  onClientUnauthorizedHandler: (handler: PttvErrorHandler) => void;
  onCashBetResetHandler: (handler: PttvErrorHandler) => void;
  onServerFullHandler: (handler: PttvErrorHandler) => void;
  onServerMaintenanceHandler: (handler: PttvErrorHandler) => void;
  onNonHumanError400: (handler: PttvMessageHandler) => void;
  getClientTime: (serverTime: number) => number;
  getServerTime: (date?: number) => number;
  setSessionKey: (sessionKey: string | null) => void;
}

export enum PttvErrorCode {
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  BLOCKED = 'BLOCKED',
  CASHBET_RESET = 'CASHBET_RESET',
  CLIENT_UNAUTHORIZED = 'CLIENT_UNAUTHORIZED',
  DISPLAYNAME_INVALID = 'DISPLAYNAME_INVALID',
  EMAIL_INVALID = 'EMAIL_INVALID',
  EMAIL_NOT_UNIQUE = 'EMAIL_NOT_UNIQUE',
  EMAIL_REQUIRED = 'EMAIL_REQUIRED',
  ERROR_FROM_CASHBET_9 = 'ERROR_FROM_CASHBET_9',
  ERROR_FROM_CASHBET_10 = 'ERROR_FROM_CASHBET_10',
  ERROR_FROM_CASHBET_13 = 'ERROR_FROM_CASHBET_13',
  IS_VERIFIED = 'IS_VERIFIED',
  MULTIPLE_SESSIONS = 'MULTIPLE_SESSIONS',
  SERVER_FULL = 'SERVER_FULL',
  SERVER_MAINTENANCE = 'SERVER_MAINTENANCE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  USER_IS_BLOCKED = 'USER_IS_BLOCKED',
  ERROR_FROM_CASHBET_8 = 'ERROR_FROM_CASHBET_8',
  ERROR_FROM_CASHBET_14 = 'ERROR_FROM_CASHBET_14',
  ILLEGAL_PAYMENT_METHOD = 'ILLEGAL_PAYMENT_METHOD',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
  NO_CB_TOKEN = 'NO_CB_TOKEN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  PENDING_INVITES_MAXED = 'PENDING_INVITES_MAXED',
  LEAGUE_NOT_FOUND = 'LEAGUE_NOT_FOUND',
  INVALID_TARGET = 'INVALID_TARGET',
  FRIEND_LIMIT_EXCEEDED = 'FRIEND_LIMIT_EXCEEDED',
  FRIEND_LIMIT_OTHER_EXCEEDED = 'FRIEND_LIMIT_OTHER_EXCEEDED',
  INVITE_RATELIMIT_EXCEEDED = 'INVITE_RATELIMIT_EXCEEDED',
  ILLEGAL_ARGUMENT = 'ILLEGAL_ARGUMENT',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  NOT_FRIENDS = 'NOT_FRIENDS',
  REPORTUSER_RATELIMIT_EXCEEDED = 'REPORTUSER_RATELIMIT_EXCEEDED',
  INVALID_ROOMTYPE = 'INVALID_ROOMTYPE',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  NO_ROOMTYPES_GIVEN = 'NO_ROOMTYPES_GIVEN',
  CASHBET_NOT_AVAILABLE = 'CASHBET_NOT_AVAILABLE',
  CONTEST_NOT_FOUND = 'CONTEST_NOT_FOUND',
  NO_CONTESTS_SELECTED = 'NO_CONTESTS_SELECTED',
  NOT_PRESENT = 'NOT_PRESENT',
  EMPTY_OR_NULL_PARAMETER = 'EMPTY_OR_NULL_PARAMETER',
  MAX_INVITES_EXCEEDED = 'MAX_INVITES_EXCEEDED',
  MAX_LEAGUES_REACHED = 'MAX_LEAGUES_REACHED',
  NAME_INVALID = 'NAME_INVALID',
  DESCRIPTION_INVALID = 'DESCRIPTION_INVALID',
  NAME_NOT_UNIQUE = 'NAME_NOT_UNIQUE',
  ALREADY_ACCEPTED = 'ALREADY_ACCEPTED',
  ILLEGAL_STATE = 'ILLEGAL_STATE',
}

export enum PttvErrorType {
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  BLOCKED = 'BLOCKED',
  CASHBET_RESET = 'CASHBET_RESET',
  CLIENT_UNAUTHORIZED = 'CLIENT_UNAUTHORIZED',
  ILLEGAL_STATE = 'ILLEGAL_STATE',
  ILLEGAL_ARGUMENT = 'ILLEGAL_ARGUMENT',
  MULTIPLE_SESSIONS = 'MULTIPLE_SESSIONS',
  UNAUTHORIZED = 'UNAUTHORIZED',
  USER_IS_BLOCKED = 'USER_IS_BLOCKED',
}

export interface PttvError {
  code: PttvErrorCode;
  humanError: boolean;
  message: string;
  shouldLog: boolean;
  statusCode: number;
  type: PttvErrorType;
}
