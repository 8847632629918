import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { ContestTab } from 'atoms/Tab';
import { pxToRem } from 'utils/pxToRem';
import { Colors, FontFamily, FontSize, Padding } from 'style/types';

export const TabContentWrapper = styled.div`
  max-height: 100%;
  overflow: hidden;
  position: relative;
`;

interface TabBarProps {
  tabsCount: number;
}

export const StyledTabBar = styled(motion.section).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['tabsCount'].includes(prop) && defaultValidatorFn(prop),
})<TabBarProps>`
  align-items: center;
  box-sizing: unset;
  height: ${pxToRem(50)};
  display: flex;
  padding-bottom: ${Padding.S};
  overflow: hidden;
  position: relative;
`;

interface TabProps {
  index: number;
}

export const ExtendedContestTab = styled(ContestTab)<TabProps>`
  position: absolute;

  ${({ index }) =>
    index > 0
      ? css`
          left: calc(50% + 2.5rem + ${index - 1} * 4.5rem + 0.5rem);
        `
      : css`
          left: calc(50% - 2.5rem + ${index} * 4.5rem);
        `}
`;

export const TabBarTitle = styled.section`
  color: ${Colors.Black40};
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.M};
  line-height: 1;
  padding: 0.375rem 0;
  text-align: center;
  text-transform: uppercase;
`;

export const ContestTabContainer = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
`;

interface ContainerProps {
  $tabCount: number;
}

export const CarouselContainer = styled.div<ContainerProps>`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(${({ $tabCount }) => $tabCount}, 1fr);
  position: absolute;
  height: 100%;
  justify-items: center;
  left: var(--contests-left);
  overflow: hidden;
  transition: left var(--contests-left-duration) ease-out;

  @supports (left: constant(--contests-left)) {
    left: constant(--contests-left);
    transition: left constant(--contests-left-duration) ease-out;
  }

  width: ${({ $tabCount }) => `${$tabCount * 100}%`};
`;
