import { createReducer } from '@reduxjs/toolkit';
import { resetGame } from 'store/games/actions';
import { fetchUserState } from '../user/actions';
import { fetchOrUpdateRoom, fetchOrUpdateRoomScores, fetchRoom } from './actions';
import { RoomsState } from './types';

export const initialState = {} as RoomsState;

export const roomsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) => {
      const { contests } = (payload.currentGamesInfo && payload.currentGamesInfo[0]) || {};
      let nextState = state;

      if (contests) {
        contests.forEach((contest) => {
          nextState = {
            ...nextState,
            ...contest.rooms.reduce(
              (acc, room) => ({
                ...acc,
                [room.roomId]: room,
              }),
              {},
            ),
          };
        });
      }

      return nextState;
    })
    .addCase(resetGame.fulfilled, (state, { payload }) => {
      const { contestIds } = payload;

      Object.keys(state).forEach((roomId) => {
        if (contestIds.includes(state[roomId].contestId)) {
          delete state[roomId];
        }
      });
    })
    .addCase(fetchOrUpdateRoom.fulfilled, (state, { payload }) => {
      state[payload.room.roomId] = {
        ...payload.room,
        fetched: false,
      };
    })
    .addCase(fetchRoom.fulfilled, (state, { payload }) => {
      state[payload.roomId] = {
        ...payload,
        fetched: true,
      };
    })
    .addCase(fetchOrUpdateRoomScores.fulfilled, (state, { payload }) => {
      state[payload.roomId] = {
        ...state[payload.roomId],
        players: payload.players,
        user: payload.user,
        fetched: payload.fetched || false,
      };
    });
});
