import { createReducer } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import { addServerMaintenanceModal } from 'store/modals/actions';
import {
  incrementUiTimer,
  serviceWorkerRegistrationFailed,
  serviceWorkerRegistrationSuccess,
  updateReadyAction,
  updateTabIndexAction,
} from './actions';
import { UiState } from './types';

export const initialState = {
  isReady: false,
  updateReady: false,
  swRegistrationFailed: false,
  swRegistrationSuccess: false,
  isUserStateLoaded: false,
  inMaintenance: false,
  uiTimer: 0,
} as UiState;

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(serviceWorkerRegistrationSuccess, (state) => ({
      ...state,
      swRegistrationFailed: false,
      swRegistrationSuccess: true,
    }))
    .addCase(serviceWorkerRegistrationFailed, (state) => ({
      ...state,
      swRegistrationFailed: true,
      swRegistrationSuccess: false,
    }))
    .addCase(updateReadyAction, (state) => ({
      ...state,
      updateReady: true,
    }))
    .addCase(updateTabIndexAction, (state, { payload }) => ({
      ...state,
      [payload.name]: payload.tabIndex,
    }))
    .addCase(addServerMaintenanceModal, (state) => {
      state.inMaintenance = true;
    })
    .addCase(incrementUiTimer, (state) => {
      state.uiTimer = state.uiTimer > 1000 ? 0 : state.uiTimer + 1;
    })
    // TODO: [EG: 2021-09-16] Replace any when we have the user store in place
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .addCase(REHYDRATE, (state, { payload }: Record<string, any>) => ({
      ...state,
      isReady: payload?.user ? !payload?.user.authToken : true,
    }));
});

// The cases below we have to investigate.

// import { CASH_BET_ERROR, CASH_BET_INVALID_SIGN_IN } from 'actions/cashBet';
// import { SIGN_IN_SUCCESS, SIGN_IN_ERROR } from 'actions/signIn';
// import { FETCH_USER_STATE_SUCCESS } from 'actions/user';

// case SIGN_IN_SUCCESS:
// case SIGN_IN_ERROR:
// case CASH_BET_ERROR:
// case CASH_BET_INVALID_SIGN_IN:
//   return {
//     ...state,
//     isReady: true,
//   };

// case FETCH_USER_STATE_SUCCESS:
//   if (state.isUserStateLoaded) {
//     return state;
//   }
//   return {
//     ...state,
//     isUserStateLoaded: true,
//   };
