import { createReducer } from '@reduxjs/toolkit';
import { setTabIndex } from './actions';
import { TabsState } from './types';

export const initialState: TabsState = {};

export const tabsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setTabIndex, (state, { payload }) => {
    state[payload.tabsId] = payload.id;
  });
});
