import { version as clientVersion } from '../../version';
import { formatVersion } from './formatters';
import { debugLog } from './log';

export const isAppVersionChanged = async (): Promise<boolean> => {
  // be careful and not rely on clientVersion when SW is registered
  // otherwise clientVersion may be served from cache and cause update loop
  let changed = false;
  try {
    const response = await fetch(`./version.json?t=${Date.now()}`);
    const version = (await response.json()) as unknown as string;
    changed = formatVersion(version) !== formatVersion(clientVersion);
  } catch (e: unknown) {
    debugLog('[isAppVersionChanged]', 'Could not determine AppVersion', e);
  }
  return changed;
};
