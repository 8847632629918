import React from 'react';
import { TeamBlockWrapper, TeamLogo, TeamAbbreviation, TeamName } from './Team.styled';

interface TeamBlockProps {
  logo: string | false;
  abbreviation: string;
  horizontal?: boolean;
  isHomeTeam?: boolean;
}

export const TeamBlock: React.FC<TeamBlockProps> = ({
  logo,
  abbreviation,
  children,
  horizontal = false,
  isHomeTeam = false,
}) => {
  const showAbbreviation = () => !logo || (logo && horizontal);

  return (
    <TeamBlockWrapper horizontal={horizontal} isHomeTeam={isHomeTeam}>
      {logo && (
        <TeamLogo>
          <img src={logo} alt="" />
        </TeamLogo>
      )}
      {showAbbreviation() && <TeamAbbreviation>{abbreviation}</TeamAbbreviation>}
      {!horizontal && children && <TeamName hasPadding={!!logo}>{children}</TeamName>}
    </TeamBlockWrapper>
  );
};
