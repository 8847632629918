import React from 'react';
import { DividerLabel, StyledDivider } from './Divider.styled';

interface Props {
  textAlign?: 'flex-start' | 'center' | 'flex-end';
  sticky?: boolean;
  small?: boolean;
  noMargin?: boolean;
  action?: JSX.Element;
  className?: string;
}

export const Divider: React.FC<Props> = ({
  textAlign = 'flex-start',
  noMargin = false,
  small = false,
  sticky = false,
  action,
  children,
  className,
}) => (
  <StyledDivider
    className={className}
    textAlign={textAlign}
    noMargin={noMargin}
    small={small}
    sticky={sticky}
    hasAction={!!action}
  >
    <DividerLabel small={small}>{children}</DividerLabel>
    {!!action && action}
  </StyledDivider>
);
