import { DateFormats } from './types';
import { isToday } from './utils';

const dateTimeFormatOptions: Record<DateFormats, Intl.DateTimeFormatOptions> = {
  [DateFormats.Short]: {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
};

export const formatDateTime = (date: number, type: DateFormats = DateFormats.Short): string => {
  if (!isToday(date)) {
    return new Intl.DateTimeFormat('en-US', dateTimeFormatOptions[type]).format(new Date(date));
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { weekday, ...options } = dateTimeFormatOptions[type];
  return `Today, ${new Intl.DateTimeFormat('en-US', options).format(new Date(date))}`;
};
