import styled from 'styled-components';
import { BorderRadius, BorderWidth, Colors, Gap } from 'style/types';

export const ContestFinishingCardContainer = styled.section`
  background-color: ${Colors.Black};
  border: ${BorderWidth.Large} solid ${Colors.Black75};
  border-radius: ${BorderRadius.Default};
  display: grid;
  grid-template-rows: auto 1fr;
  margin: ${Gap.XXXL} 0;
  overflow: hidden;
`;
