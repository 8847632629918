import { createSelector } from 'reselect';
import type { Sport, SportsState } from 'store/sports/types';
import type { CurriedSelector, RootState } from 'store/types';
import {
  getContestInfo,
  getContestInfoByContestId,
  getFirstUpcomingContestInfo,
  getLiveGameContestIds,
} from 'store/contestInfo/selectors';
// eslint-disable-next-line import/no-cycle
import { getAvailableGames } from 'store/games/selectors';
import type { RoomTypeState } from 'store/roomTypes/types';
import { getRoomById } from 'store/rooms/selectors';
import { ContestState, PaymentMethod } from 'services/pttv/api/constants';
import { getUserDollarCents, getUserTickets } from 'store/user/selectors';
import { ContestInfo } from 'store/contestInfo/types';
import { RoomType } from 'services/pttv/api/rooms/types';
// eslint-disable-next-line import/no-cycle
import { getFilteredRoomTypeIdsByContest } from 'store/roomTypes/selectors';
import { isEnded, isLiveOrRegistering } from './contestStateHelper';
import type { Contest, ContestsState, RebuyInfo, RebuyInfoBase } from './types';

const sortContestsByPeriod = ({ period: a }: Contest, { period: b }: Contest) => a - b;

export const getContests = (state: RootState): ContestsState => state.contests;
export const getFlatContests = (state: RootState): Contest[] => Object.values(state.contests);
export const getSports = (state: RootState): SportsState => state.sports;
export const getRoomTypes = (state: RootState): RoomTypeState => state.roomTypes;

export const getContestById = (contestId: string): CurriedSelector<Contest> =>
  createSelector(getContests, (contests) => contests[contestId]);

export const getContestByRoomId = (roomId: string): CurriedSelector<Contest> =>
  createSelector(getRoomById(roomId), getContests, (room, contests) => contests[room.contestId]);

export const getContestSports = createSelector(getFlatContests, getSports, (contests, sports) => {
  const contestSports = contests.reduce<Set<Sport>>((acc, contest) => {
    const sport = sports[contest.sportId];
    acc.add(sport);
    return acc;
  }, new Set());
  return Array.from(contestSports.values()).filter((sport) => !!sport);
});

export const getContestsByGameId = (gameId: string): CurriedSelector<Contest[]> =>
  createSelector(getFlatContests, (contests) =>
    contests.filter((contest) => contest.gameId === gameId),
  );

export const getClosedContestsByGameId = (gameId: string): CurriedSelector<Contest[]> =>
  createSelector(getContestsByGameId(gameId), (contests) =>
    contests.reduce<Contest[]>((acc, contest) => {
      if (contest.state === ContestState.CLOSED) {
        return [...acc, contest];
      }
      return acc;
    }, []),
  );

export const getSelectableContestsByGame = (gameId: string): CurriedSelector<Contest[]> =>
  createSelector(getContestsByGameId(gameId), (contests) =>
    contests.filter(({ state, hidden }) => !isEnded(state) && !hidden).sort(sortContestsByPeriod),
  );

export const getFirstOpenContestIndexByGame = (gameId: string): CurriedSelector<number> =>
  createSelector(getSelectableContestsByGame(gameId), (contests) =>
    contests.findIndex((contest) => isLiveOrRegistering(contest.state)),
  );

export const getFirstOpenContestByGame = (gameId: string): CurriedSelector<Contest | undefined> =>
  createSelector(getSelectableContestsByGame(gameId), (contests) =>
    contests.find((contest) => isLiveOrRegistering(contest.state)),
  );

export const getJoinedContests = createSelector(
  getContests,
  getContestInfo,
  (contests, contestInfo) =>
    Object.values(contests).filter(({ contestId }) => contestInfo[contestId]),
);

export const canJoinLiveContests = createSelector(
  getJoinedContests,
  getAvailableGames,
  (joinedContests, games) =>
    !Object.values(joinedContests).find(({ gameId, state }) => {
      const selectedGame = games.find((game) => game.gameId === gameId);

      return selectedGame?.gameType !== 'PRESHOW' && isLiveOrRegistering(state);
    }),
);

export const getOpenContestByGameId = (gameId: string): CurriedSelector<Contest | undefined> =>
  createSelector(getFlatContests, (contests) =>
    contests.find((contest) => contest.gameId === gameId && contest.state === 'OPEN'),
  );

export const getLiveGameContests = createSelector(
  getLiveGameContestIds,
  getContests,
  (contestIds, contests) => contestIds.map((contestId) => contests[contestId]),
);

export const getNextAvailableContests = createSelector(
  getContestInfo,
  getContests,
  (contestInfo, contests) => {
    const contestInfoValues = Object.values(contestInfo);
    const availableJoinedGameIds = contestInfoValues
      .filter(
        ({ contestId }) => contests[contestId] && isLiveOrRegistering(contests[contestId].state),
      )
      .map(({ gameId }) => gameId);

    return Object.values(contests).filter(({ contestId, gameId, state }) => {
      const isOtherGameJoined =
        availableJoinedGameIds.length > 0 && availableJoinedGameIds.indexOf(gameId) === -1;

      if (isOtherGameJoined || contestInfo[contestId]) {
        return false;
      }

      return !!contestInfoValues.find(
        (info) => gameId === info.gameId && isLiveOrRegistering(state),
      );
    });
  },
);

export const isAnyRoomAvailable = (contestId: string): CurriedSelector<boolean> =>
  createSelector(getFilteredRoomTypeIdsByContest(contestId), (rooms) => !!rooms.length);

// ******************************************
// Rebuy Selectors
// ******************************************

export const getRebuyContest = (currentContestId: string): CurriedSelector<Contest | undefined> =>
  createSelector(
    getFlatContests,
    getContestById(currentContestId),
    getContestInfo,
    (contests, contest, contestInfo) => {
      if (!contest) {
        return undefined;
      }

      const nextContest = contests
        .filter(
          ({ contestId, gameId, period }) =>
            gameId === contest.gameId && contestId !== contest.contestId && period > contest.period,
        )
        .sort((a, b) => a.period - b.period)
        .find(({ state }) => isLiveOrRegistering(state));

      if (nextContest && contestInfo[contest.contestId] && contestInfo[nextContest.contestId]) {
        const contestInfoRoomTypeIds = Object.keys(contestInfo[contest.contestId].roomTypeIds);
        const nextContestInfoRoomTypeIds = Object.keys(
          contestInfo[nextContest.contestId].roomTypeIds,
        );

        const nextContestFilteredKeysNextContest = {
          ...nextContest,
          roomTypeIds: contestInfoRoomTypeIds.filter(
            (contestInfoRoomTypeId) => !nextContestInfoRoomTypeIds.includes(contestInfoRoomTypeId),
          ),
        };

        return nextContestFilteredKeysNextContest;
      }

      return nextContest;
    },
  );

export const getRebuyRoomTypeIds = (contestId: string): CurriedSelector<string[] | null> =>
  createSelector(
    getContestInfoByContestId(contestId),
    getRebuyContest(contestId),
    (contestInfo, nextContest) => {
      if (!nextContest) {
        return null;
      }
      return nextContest.roomTypeIds.filter((roomTypeId) => !!contestInfo?.roomTypeIds[roomTypeId]);
    },
  );

const isRoomTypeJoinedInToContest = (
  roomType: RoomType,
  contestInfo: ContestInfo | undefined,
): boolean =>
  !!contestInfo?.roomQueueEntries.map((entry) => entry.roomTypeId).includes(roomType.roomTypeId);

const getPaymentMethod = (
  incrementTickets: boolean,
  dollarCentsBuyIn: number,
): PaymentMethod | undefined => {
  if (incrementTickets) {
    return PaymentMethod.TICKETS;
  }
  if (dollarCentsBuyIn > 0) {
    return PaymentMethod.DOLLARCENTS;
  }
  return undefined;
};

const incrementRebuyInfoByRoomType = (
  roomType: RoomType,
  rebuyInfo: RebuyInfoBase,
  userTickets: Record<string, number>,
  userDollarCents: number,
) => {
  const { roomTypeId, dollarCentsBuyIn = 0, ticketBuyIn } = roomType;
  const isEntryByTicketOrDollarCents = dollarCentsBuyIn > 0 && !!ticketBuyIn;

  let convertTicketToDollarCents = false;
  let isNotValidTicket = false;

  if (isEntryByTicketOrDollarCents && !rebuyInfo.notValidTickets[ticketBuyIn]) {
    const ticketsCount = (rebuyInfo.tickets[ticketBuyIn] || 0) + 1;
    // possible that we should obtain dollarCents value from ticketType
    const dollarCents = rebuyInfo.dollarCents + dollarCentsBuyIn;
    if (ticketsCount > (userTickets[ticketBuyIn] || 0)) {
      if (dollarCents <= userDollarCents) {
        convertTicketToDollarCents = true;
      } else {
        isNotValidTicket = true;
      }
    }
  }

  const incrementDollarCents = !isEntryByTicketOrDollarCents || convertTicketToDollarCents;
  const incrementTickets = !!ticketBuyIn && !convertTicketToDollarCents;
  const paymentMethod = getPaymentMethod(incrementTickets, dollarCentsBuyIn);

  return {
    dollarCents: incrementDollarCents
      ? rebuyInfo.dollarCents + dollarCentsBuyIn
      : rebuyInfo.dollarCents,
    tickets: incrementTickets
      ? { ...rebuyInfo.tickets, [ticketBuyIn]: (rebuyInfo.tickets[ticketBuyIn] || 0) + 1 }
      : rebuyInfo.tickets,
    roomTypeIdsToPaymentMethodMap: {
      ...rebuyInfo.roomTypeIdsToPaymentMethodMap,
      [roomTypeId]: paymentMethod,
    },
    notValidTickets: isNotValidTicket
      ? { ...rebuyInfo.notValidTickets, [ticketBuyIn as string]: true }
      : rebuyInfo.notValidTickets,
  };
};

export const getNextContestRebuyInfo = (contestId: string): CurriedSelector<RebuyInfo> =>
  createSelector(
    getRebuyRoomTypeIds(contestId),
    getFirstUpcomingContestInfo,
    getRoomTypes,
    getUserTickets,
    getUserDollarCents,
    (rebuyRoomTypeIds, upcomingContestInfo, roomTypes, userTickets, userDollarCents) => {
      const filteredRoomTypes = (rebuyRoomTypeIds || [])
        .map((roomTypeId) => roomTypes[roomTypeId])
        // roomtype should exist and there should either be no contestinfo yet or the room should not yet be part of the contestinfo
        .filter(
          (roomType) => !!roomType && !isRoomTypeJoinedInToContest(roomType, upcomingContestInfo),
        );

      const { notValidTickets, ...rebuyInfo } = filteredRoomTypes.reduce(
        (info, roomType) =>
          incrementRebuyInfoByRoomType(roomType, info, userTickets, userDollarCents),
        { dollarCents: 0, tickets: {}, notValidTickets: {}, roomTypeIdsToPaymentMethodMap: {} },
      );

      return {
        ...rebuyInfo,
        roomTypeIds: filteredRoomTypes.map((roomType) => roomType.roomTypeId),
      };
    },
  );

export const checkSufficientCashToRebuy = (rebuyInfo: RebuyInfo): CurriedSelector<boolean> =>
  createSelector(getUserDollarCents, (userDollarCents) => {
    if (!rebuyInfo) {
      return false;
    }

    const { dollarCents } = rebuyInfo;
    return dollarCents <= userDollarCents;
  });

export const checkSufficientTicketsToRebuy = (rebuyInfo: RebuyInfo): CurriedSelector<boolean> =>
  createSelector(getUserTickets, (userTickets) => {
    if (!rebuyInfo) {
      return false;
    }

    const { tickets } = rebuyInfo;

    if (!Object.entries(tickets).length) {
      return true;
    }

    return !Object.entries(tickets).some(
      ([key, value]) => !userTickets[key] || userTickets[key] < value,
    );
  });

export const getGameIdByContestId = (contestId: string): CurriedSelector<string | null> =>
  createSelector(getContestById(contestId), (contest) => {
    if (!contest?.gameId) {
      return null;
    }

    return contest.gameId;
  });
