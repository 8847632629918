import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
  Gap,
  Padding,
  ZIndex,
} from 'style/types';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonProps } from 'atoms/Button';
import { layoutContainerStyle } from 'molecules/Layout/Layout.styled';

export const DialogTitle = styled.h2`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XL};
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${Gap.ML};
`;

export const DialogCard = styled(motion.article)`
  border: ${BorderWidth.Large} solid ${Colors.Black70};
  border-radius: ${BorderRadius.Default};
  background-color: ${Colors.Black};
  text-align: center;
  width: 90%;
`;

export const DialogContent = styled.section`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DialogArea = styled.section`
  display: flex;
  flex-direction: column;
  grid-area: content;
  height: 100%;
  padding-bottom: var(--safe-area-inset-bottom);
  padding-top: var(--safe-area-inset-top);
`;

interface BackDropProps {
  $zIndex?: number;
}

export const BackDrop = styled(motion.div)<BackDropProps>`
  backdrop-filter: blur(1.5px);
  background-color: hsla(0, 0%, 0%, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ $zIndex }) => $zIndex || ZIndex.Dialog};

  ${layoutContainerStyle}
`;

interface FooterProps {
  children: React.ReactElement<ButtonProps>[] | React.ReactElement<ButtonProps>;
}

export const DialogFooter = styled.footer.attrs<FooterProps>(({ children }) => ({
  children: <ButtonBar>{children}</ButtonBar>,
}))`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.L};
  padding: ${Padding.Default} ${Padding.Default};
  text-align: center;
`;
