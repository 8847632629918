import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LeagueMemberListItem } from 'organisms/UserListItem/LeagueMemberListItem';
import { getUserId } from 'store/user/selectors';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import {
  getLeagueByName,
  getLeagueCommissionerId,
  getMembersGroupedByContest,
  getAcceptedMembersCount,
} from 'store/leagues/selectors';
import { useDialog } from 'hooks/useDialog';
import { leaveLeague } from 'store/leagues/actions';
import { SportIcon } from 'atoms/ExperienceChip/ExperienceChip.styled';
import { UserListContainer } from '../UserList.styled';
import { ButtonBar, ContestDivider, Period } from './LeagueMemberList.styled';
import { NewCommissionerDialog } from './NewCommissionerDialog';

type Props = {
  leagueName: string;
};

export const LeagueMemberList: React.FC<Props> = ({ leagueName }) => {
  const league = useAppSelector(getLeagueByName(leagueName));
  const leagueCommissionerId = useAppSelector(getLeagueCommissionerId(league?.name || ''));
  const leagueMembers = useAppSelector(getMembersGroupedByContest(leagueName));
  const acceptedMembersCount = useAppSelector(getAcceptedMembersCount(leagueName));
  const userId = useAppSelector(getUserId);
  const { showConfirm } = useDialog();
  const dispatch = useAppDispatch();
  const { goBack } = useHistory();
  const { t } = useTranslation('LeagueDetail');

  if (!league || !leagueCommissionerId) {
    return null;
  }

  let newCommissionerId: string | null = leagueCommissionerId;

  const handleLeaveLeague = () => {
    if (acceptedMembersCount === 1) {
      newCommissionerId = null;
    }
    goBack();
    dispatch(leaveLeague({ leagueName, newCommissionerId }));
  };

  const setNewCommissionerId = (id: string) => {
    newCommissionerId = id;
  };

  const handleLeaveButtonClick = () => {
    if (leagueCommissionerId !== userId || acceptedMembersCount === 1) {
      showConfirm(
        t('leaveDialog.member.title'),
        t('leaveDialog.member.description', { leagueName: league.name }),
        {
          confirmButtonAction: handleLeaveLeague,
        },
      );
    } else {
      showConfirm(
        t('leaveDialog.commissioner.title'),
        <NewCommissionerDialog
          leagueName={league.name}
          passCommissionerId={setNewCommissionerId}
          description={t('leaveDialog.commissioner.description', { leagueName: league.name })}
        />,
        {
          confirmButtonAction: handleLeaveLeague,
        },
      );
    }
  };

  return (
    <>
      {leagueMembers.length > 0 &&
        leagueMembers.map((leagueContest) => (
          <Fragment key={leagueContest.description}>
            <ContestDivider sticky noMargin>
              {leagueContest.avatarUrl && <SportIcon src={leagueContest.avatarUrl} />}
              {leagueContest.description ? (
                <>
                  {leagueContest.description} <Period>({leagueContest.period})</Period>
                </>
              ) : (
                t('leagueMemberList.notInContest.label')
              )}
            </ContestDivider>
            <UserListContainer>
              {leagueContest.members.map((member) => (
                <LeagueMemberListItem key={member.userId} member={member} leagueName={leagueName} />
              ))}
            </UserListContainer>
          </Fragment>
        ))}
      <ButtonBar>
        <Button
          onClick={handleLeaveButtonClick}
          size={ButtonSize.Small}
          scheme={ButtonSchemes.Grey}
        >
          {t('leagueMemberList.leaveLeagueButton')}
        </Button>
      </ButtonBar>
    </>
  );
};
