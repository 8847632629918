/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { usePrevious } from 'hooks/usePrevious';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchUserState } from 'store/user/actions';
import { captureError, debugLog } from 'utils/log';
import { EventsAPI } from 'services/pttv/api/events';
import { incrementUiTimer } from 'store/ui/actions';
import { delegatePushEvent } from './delegatePushEvent';
import { EventsPayload, Event } from './types';

let isStarted = false;
let connectionId: string | null;
let lastEventId = '';
let startedAt: number;
let handledEvents: Array<Event> = [];

export const withLongPoll =
  <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) =>
  (props: P): JSX.Element => {
    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
    const isReady = useAppSelector((state) => state.ui.isReady);
    const dispatch = useAppDispatch();
    const { push } = useHistory();
    const previousAuthenticated = usePrevious(isAuthenticated);

    useEffect(() => {
      if (isReady && isAuthenticated !== previousAuthenticated) {
        if (isAuthenticated) {
          start();
        } else {
          stop();
        }
      }
    }, [isAuthenticated, isReady]);

    const start = () => {
      if (isStarted) {
        return;
      }

      isStarted = true;
      startedAt = Date.now();
      fetchPTTV();
    };

    const stop = () => {
      connectionId = null;
      isStarted = false;
    };

    const fetchPTTV = () => {
      if (!isStarted) {
        return;
      }
      dispatch(incrementUiTimer());

      const requestStartedAt = Date.now();

      EventsAPI.getEvent({ connectionId, lastEventId })
        .then((payload) => fetchSuccess(payload, requestStartedAt))
        .catch((error) => pushError(error));
    };

    const fetchSuccess = (payload: EventsPayload, requestStartedAt: number) => {
      if (!isStarted) {
        return;
      }
      // Do not handle request from previous session
      if (startedAt && requestStartedAt < startedAt) {
        return;
      }

      const { events, error } = payload;
      // If server returned an error, stop polling
      if (error) {
        debugLog(
          '[withLongPoll]',
          'Push cancelled (server responed with an error). Reason: ',
          error,
        );
        pushError(error as string);
        return;
      }

      // Removed the falsy check fo connectionId
      if (connectionId !== payload.connectionId) {
        connectionId = payload.connectionId;

        debugLog('[withLongPoll]', 'Connection id changed!');

        // When connection id changes, update user state
        dispatch(fetchUserState());
      }

      connectionId = payload.connectionId;

      // Events should be delegated and dispatched (dont think our reducers should handle this)
      const handlesEventIds = handledEvents.map((event) => event.id);
      const eventsToDispatch = (events || []).filter(({ id }) => !handlesEventIds.includes(id));
      if (eventsToDispatch?.length) {
        const lastEvent = eventsToDispatch[events.length - 1];

        handledEvents = [...handledEvents, ...eventsToDispatch].slice(-50);

        dispatchPushEvents(eventsToDispatch);

        // Store 'latest' event id
        if (lastEvent) {
          lastEventId = lastEvent.id;
        }
      }

      fetchPTTV();
    };

    const dispatchPushEvents = (events: Event[]) => {
      debugLog('[withLongPoll]', 'Dispatching push events', events);

      events.forEach((event) => {
        try {
          delegatePushEvent(event, push);
        } catch (error) {
          debugLog('[withLongPoll | ERROR]', 'Error in dispatchPushEvents', error);
          captureError(error);
        }
      });
    };

    const pushError = (error: string) => {
      debugLog('[withLongPoll]', 'Received an error. Retying in 5 seconds', error);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setTimeout(fetchPTTV, 5000);
    };

    return <WrappedComponent {...props} />;
  };
