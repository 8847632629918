import { createSelector } from 'reselect';
import { getBetMetaByPropositionId } from 'store/betMeta/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import { Bet, BetsState } from './types';

export const getBets = (state: RootState): BetsState => state.bets;

export const getFlatBets = (state: RootState): Bet[] => Object.values(state.bets);

export const getBetByPropositionId = (id: string): CurriedSelector<Bet> =>
  createSelector(getBets, (bets) => bets[id]);

export const hasPropositionBet = (propositionId: string): CurriedSelector<boolean> =>
  createSelector(getBetByPropositionId(propositionId), (bet) => !!bet);

export const getBetsByContestId = (contestId: string): CurriedSelector<Bet[]> =>
  createSelector(getFlatBets, (bets) => bets.filter((bet) => bet.contestId === contestId));

export const isBetLoading = (propositionId: string): CurriedSelector<boolean> =>
  createSelector(
    getBetMetaByPropositionId(propositionId),
    getBetByPropositionId(propositionId),
    (betMeta, bet) => {
      if (betMeta && !bet) {
        return true;
      }
      return betMeta && bet ? betMeta.betVersion !== bet.betVersion : false;
    },
  );
