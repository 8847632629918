import styled from 'styled-components';
import { Colors, FontFamily, FontSize, Gap } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const PageNotFoundContainer = styled.section`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > h1 {
    margin: 0;
    font-family: ${FontFamily.Heading};
    font-size: ${FontSize.XL};
  }

  > svg {
    margin: ${Gap.XXXL} 0;
    height: ${pxToRem(55)};

    path {
      fill: ${Colors.Primary};
    }
  }
`;
