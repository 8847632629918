import { AnyAction, configureStore, Store } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import localforage from 'localforage';
import { createMiddleware as createBeaconMiddleware } from 'redux-beacon';
import { playToTv } from 'services/pttv';
import { appsFlyer, appsFlyerEventsMap } from 'store/analytics/appsflyer';
import rootReducer from './reducers';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import { segment, segmentEventsMap } from './analytics/segment';

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: [
    'usersMeta',
    'cashBet',
    'chat',
    'leagueChat',
    'webApp',
    'leagues',
    'leagueMeta',
    'leaderboardMovement',
    'producerMessage',
    'gamesMeta',
  ],
};

// TODO [EG: 2022-05-12] Need to find a better way to implement redux into Sentry
//                       Too much data is sent now
const persistedReducer = persistReducer(persistConfig, rootReducer);
const appsFlyerMiddleware = createBeaconMiddleware(appsFlyerEventsMap, appsFlyer());
const segmentMiddleware = createBeaconMiddleware(segmentEventsMap, segment());

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([appsFlyerMiddleware, segmentMiddleware, thunk.withExtraArgument({ playToTv })]),
});

export const persistor = persistStore(store);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const storybookStore = (defaultState: Record<string, any>): Store<any, AnyAction> =>
  configureStore({
    reducer: rootReducer,
    preloadedState: defaultState,
  });
