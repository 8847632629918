import styled from 'styled-components';
import { Padding } from 'style/types';
import { JoinedLeagueCardContainer } from 'molecules/LeagueCards/JoinedLeagueCard/JoinedLeagueCard.styled';

export const JoinedLeagueListContainer = styled.section`
  ${JoinedLeagueCardContainer} + ${JoinedLeagueCardContainer} {
    margin-top: ${Padding.Default};
  }
`;

export const List = styled.section`
  padding: ${Padding.Default};
`;
