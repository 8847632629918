import styled from 'styled-components';
import {
  BorderRadius,
  BorderWidth,
  Colors,
  FontFamily,
  FontSize,
  FontWeight,
  Gap,
  Padding,
} from 'style/types';
import { CircularProgressWrapper } from 'atoms/CircularProgress/CircularProgress.styled';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';

export const FrienRequestContainer = styled.article`
  background-color: ${Colors.Black};
  border: ${BorderWidth.Large} solid ${Colors.Black75};
  border-radius: ${BorderRadius.Default};

  ${ButtonBarContainer} {
    padding: ${Padding.Default} 0 ${Padding.XS};
  }
`;

export const FriendRequestHeader = styled.header`
  border-bottom: ${BorderWidth.Default} solid ${Colors.Black75};
  display: grid;
  gap: ${Gap.Default};
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas: 'title title title' 'playerid avatar experience' 'skillrank skillrank skillrank';
  padding: ${Padding.Default};

  > ${CircularProgressWrapper} {
    grid-area: avatar;
  }
`;

export const Title = styled.h2`
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XL};
  font-weight: ${FontWeight.SemiBold};
  grid-area: title;
  margin: 0;
  text-align: center;
`;

export const SkillRankArea = styled.section`
  grid-area: skillrank;
`;

export const TeamsTitle = styled.h4`
  color: ${Colors.Secondary};
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.M};
  font-weight: ${FontWeight.SemiBold};
  letter-spacing: 1px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
`;

export const PlayerId = styled.div`
  align-self: center;
  color: ${Colors.Black30};
  font-size: ${FontSize.S};
  font-weight: ${FontWeight.Medium};
  grid-area: playerid;
  justify-self: center;

  > span {
    color: ${Colors.Text};
    display: block;
    font-weight: ${FontWeight.SemiBold};
    margin-top: ${Gap.S};
  }
`;

export const PlayerXP = styled.div`
  align-self: center;
  align-items: center;
  color: ${Colors.Black20};
  display: flex;
  grid-area: experience;
  justify-self: center;

  > span {
    color: ${Colors.Secondary};
    font-size: ${FontSize.XXXL};
    font-weight: ${FontWeight.SemiBold};
    margin-left: ${Gap.S};
  }
`;
