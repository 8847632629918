import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ZIndex } from 'style/types';
import { layoutContainerStyle } from 'molecules/Layout/Layout.styled';

export const ModalPageArea = styled.section`
  backdrop-filter: blur(1.5px);
  background-color: hsla(0, 0%, 0%, 0.93);
  display: grid;
  grid-area: content;
  grid-template-rows: var(--safe-area-inset-top) auto 1fr var(--safe-area-inset-bottom);
  height: 100%;
  overflow: hidden;

  > *:first-child {
    grid-area: 2/1/3/2;
  }

  > *:nth-child(2) {
    grid-area: 3/1/4/2;
    outline: none;
  }
`;

export const BackDrop = styled(motion.div)`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.ModalPage};

  ${layoutContainerStyle}
`;
