import { Colors } from 'style/types';

export enum ButtonSize {
  Tiny = 'tiny',
  Small = 'small',
  Default = 'default',
  Large = 'large',
  Wide = 'wide',
}

export enum ButtonSchemes {
  Primary = 'primary',
  Secondary = 'secondary',
  Grey = 'grey',
  DarkGrey = 'darkgrey',
  Warning = 'warning',
}

export interface ButtonSettings {
  backgroundColor: Colors;
  color: Colors;
}

export type ButtonTheme = Record<'default' | 'hover' | 'active' | 'disabled', ButtonSettings>;
