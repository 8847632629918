import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { Button } from 'atoms/Button';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { PlayerCount } from 'atoms/PlayerCount';
import { OverlayHeaderAction } from 'atoms/OverlayHeaderAction';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { useAppSelector } from 'store/hooks';
import { LeagueInvite } from 'store/leagueInvites/types';
import { getAcceptedMembers, isUserCommissioner } from 'store/leagues/selectors';
import { League } from 'store/leagues/types';
import { getSportById } from 'store/sports/selectors';
import { getTeamById } from 'store/teams/selectors';
import { CurriedSelector } from 'store/types';
import PlusIcon from 'assets/icons/PlusIcon.svg';
import EditIcon from 'assets/icons/EditIcon.svg';
import { ContentArea } from 'atoms/ContentArea';
import { ModalDescription } from 'organisms/UserList/LeagueMemberList/LeagueMemberList.styled';
import { ButtonBarDirections } from 'style/types';
import { useDialog } from 'hooks/useDialog';
import {
  Description,
  Edit,
  LeagueDescription,
  LeagueName,
  Members,
  MembersLabel,
  Sport,
  SportLogo,
  TeamAbbreviation,
  TeamCity,
} from '../LeagueHeader.styled';
import { LeagueDetailHeaderContainer } from './LeagueDetailHeader.styled';

interface Props {
  selector: (leagueName: string) => CurriedSelector<League | LeagueInvite | null>;
  leagueName: string;
  showHeaderAction?: boolean;
}

export const LeagueDetailHeader: React.FC<Props> = ({
  selector,
  leagueName,
  showHeaderAction = true,
}) => {
  const league = useAppSelector(selector(leagueName));
  const userIsCommissioner = useAppSelector(isUserCommissioner(leagueName));
  const acceptedMembers = useAppSelector(getAcceptedMembers(leagueName));
  const { push } = useHistory();
  const { showAlert, closeAlert } = useDialog();
  const { t } = useTranslation('LeagueDetail');
  const sport = useAppSelector(getSportById(league?.affiliatedSportId || ''));
  const team = useAppSelector(getTeamById(league?.affiliatedTeamId || ''));

  if (!league) {
    return null;
  }

  const getPlayerCount = () => (league as LeagueInvite).activeMemberCount || acceptedMembers.length;

  const handleInviteButtonClick = () => {
    showAlert(
      t('inviteFriendModal.title'),
      <ContentArea>
        <ModalDescription>{t('inviteFriendModal.description')}</ModalDescription>
        <ButtonBar direction={ButtonBarDirections.Column}>
          <Button
            size={ButtonSize.Default}
            onClick={() => {
              push(`/social/${leagueName}/invite-new-friend`);
              closeAlert();
            }}
          >
            {t('inviteFriendModal.button.newFriend')}
          </Button>
          <Button
            size={ButtonSize.Default}
            onClick={() => {
              push(`/social/${leagueName}/invite-friend`);
              closeAlert();
            }}
          >
            {t('inviteFriendModal.button.existingFriend')}
          </Button>
        </ButtonBar>
      </ContentArea>,
      {
        closeButtonText: 'Cancel',
      },
    );
  };

  const handleEdit = () => {
    push(`/social/${leagueName}/edit`);
  };

  return (
    <LeagueDetailHeaderContainer hasHeaderAction={showHeaderAction}>
      {showHeaderAction && (
        <OverlayHeaderAction type={OverlayHeaderActions.Chevron} onClose={() => push('/social')} />
      )}
      <Members>
        <PlayerCount
          count={getPlayerCount()}
          from={userIsCommissioner ? (league as League).maxLeagueSize : undefined}
        />
        <MembersLabel>{t('detailHeader.membersLabel')}</MembersLabel>
        {userIsCommissioner && (
          <Button
            onClick={handleInviteButtonClick}
            scheme={ButtonSchemes.Secondary}
            size={ButtonSize.Tiny}
          >
            <PlusIcon width="0.5rem" />
            {t('leagueMemberList.inviteButton')}
          </Button>
        )}
      </Members>
      <Avatar src={league.avatarUrl} size={AvatarSize.XXL} border />
      <Sport>
        {!!sport && <SportLogo src={sport.avatarUrl} />}
        {!!sport && !team && <TeamAbbreviation>{sport.abbreviation}</TeamAbbreviation>}
        {!!team && <TeamAbbreviation>{team.abbreviation}</TeamAbbreviation>}
        {!!team && <TeamCity>{team.cityName}</TeamCity>}
      </Sport>
      <LeagueDescription>
        <LeagueName>{league.name}</LeagueName>
        <Description>{league.description}</Description>
        {userIsCommissioner && (
          <Edit>
            {/* TODO [EG 2022-01-10] We need the edit Icon (WV2-8076) */}
            <Button size={ButtonSize.Tiny} scheme={ButtonSchemes.Secondary} onClick={handleEdit}>
              <EditIcon width="0.75rem" />
              {t('detailHeader.editButton')}
            </Button>
          </Edit>
        )}
      </LeagueDescription>
    </LeagueDetailHeaderContainer>
  );
};
