import { createAsyncThunk } from '@reduxjs/toolkit';
import wrapperLib from 'services/wrapper';
import {
  FacebookActions,
  FacebookGetFriendsResult,
  FacebookGetPermissionResult,
  FacebookPermission,
} from './types';

export const isPermissionGranted = (name: string, permissions: FacebookPermission[]): boolean =>
  !!permissions.find(({ permission, status }) => name === permission && status === 'granted');

export const getFacebookFriends = createAsyncThunk(
  FacebookActions.FACEBOOK_GET_FRIENDS,
  async () => {
    const result = await wrapperLib.facebookGraphRequest<FacebookGetFriendsResult>({
      path: '/me/friends',
      params: {
        fields: 'id, name',
        limit: 5000,
      },
    });

    if (result.data) {
      return result.data.map(({ id }) => id); // Return list of friend ids
    }

    return [];
  },
);

export const getFacebookPermission = createAsyncThunk(
  FacebookActions.FACEBOOK_GET_PERMISSION,
  async (name: string) => {
    const result = await wrapperLib.facebookGraphRequest<FacebookGetPermissionResult>({
      path: '/me/permissions',
    });

    return isPermissionGranted(name, result.data);
  },
);

export const signInWithFacebook = createAsyncThunk(
  FacebookActions.FACEBOOK_SIGN_IN,
  async (_, { dispatch, rejectWithValue }) => {
    const result = await wrapperLib.facebookLogin({
      interactive: true,
      permissions: ['email', 'friend_list'],
    });
    const { token } = result;

    if (!token) {
      return rejectWithValue({ success: false, message: 'No facebook token' });
    }

    await dispatch(getFacebookFriends());

    return token;
  },
);
