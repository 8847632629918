import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContestAPI } from 'services/pttv/api/contests';
import {
  ContestHistoryResponse,
  ContestHistoryByContestIdResponse,
} from 'services/pttv/api/contests/types';
import { ContestHistoryActions } from './types';

export const fetchContestHistory = createAsyncThunk<ContestHistoryResponse, void>(
  ContestHistoryActions.FETCH_CONTEST_HISTORY,
  async () => {
    const result = await ContestAPI.getContestHistory();
    return result;
  },
);

export const fetchContestHistoryByContestId = createAsyncThunk<
  ContestHistoryByContestIdResponse,
  string
>(ContestHistoryActions.FETCH_CONTEST_HISTORY_BY_CONTEST_ID, async (contestId) => {
  const result = await ContestAPI.getContestHistoryByContestId(contestId);
  return result;
});
