import { ChatBubbleTypes } from 'atoms/Chat/Bubble/Bubble.theme';

export enum RoomChatActions {
  SEND_ROOM_CHAT_MESSAGE = '@roomChat/SEND_ROOM_CHAT_MESSAGE',
  ADD_ROOM_CHAT_MESSAGE = '@roomChat/ADD_ROOM_CHAT_MESSAGE',
  ADD_NEW_ROOM_CHAT_MESSAGE = '@roomChat/ADD_NEW_ROOM_CHAT_MESSAGE',
  REMOVE_ROOM_CHAT_MESSAGE = '@roomChat/REMOVE_ROOM_CHAT_MESSAGE',
  SEEN_ROOM_CHAT_MESSAGES = '@roomChat/SEEN_ROOM_CHAT_MESSAGES',
  SET_MESSAGES_SEEN_BY_ROOM = '@roomChat/SET_MESSAGES_SEEN_BY_ROOM',
}

export interface RoomChatMessageList {
  messages: {
    id: string;
    text: string;
    isNew: boolean;
  }[];
  senderId: string;
  senderName: string;
  avatarUrl: string;
  type: ChatBubbleTypes;
}

export interface LastRoomChatMessage {
  message: string;
  senderId: string;
  senderName: string;
  avatarUrl: string;
  type: ChatBubbleTypes;
}

export interface RoomChatMessage {
  senderId: string;
  roomId: string;
  text: string;
  id: string;
  contestId?: string;
  timestamp: number;
  isNew: boolean;
  seen: boolean;
}

export type RoomChatState = {
  [roomId: string]: RoomChatMessage[];
};

export interface SendRoomChatArgs {
  roomId: string;
  text: string;
}
