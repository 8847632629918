import { createAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// import { checkForSWUpdates } from 'lib/pwa';

import type { RootState } from 'store/types';
import { isAppVersionChanged } from 'utils/isAppVersionChanged';
import { UiAction, UiActions, UiActionTabIndex } from './types';

export const serviceWorkerRegistrationFailed = createAction(UiActions.SW_REGISTRATION_FAILED);
export const serviceWorkerRegistrationSuccess = createAction(UiActions.SW_REGISTRATION_SUCCESS);
export const updateReadyAction = createAction(UiActions.UPDATE_READY);

export const updateTabIndexAction = createAction(
  UiActions.UPDATE_NAMED_TAB_INDEX,
  (name: string, tabIndex: number) => ({
    payload: {
      name,
      tabIndex,
    },
  }),
);

export const updateReady =
  (checkSWUpdate: boolean): ThunkAction<void, RootState, unknown, UiAction> =>
  // TODO: [EG: 2021-09-16] When pwa is available again, make this available again
  // eslint-disable-next-line no-unused-vars
  (dispatch) => {
    if (checkSWUpdate) {
      // checkForSWUpdates(getState().wrapper);
    }

    return dispatch(updateReadyAction());
  };

export const checkForUpdate =
  (): ThunkAction<void, RootState, unknown, UiAction> =>
  async (dispatch): Promise<boolean> => {
    const isChanged = await isAppVersionChanged();
    if (isChanged === true) {
      dispatch(updateReady(false));
    }
    return isChanged;
  };

export const updateTabIndex =
  (name: string) =>
  (tabIndex: number): ThunkAction<void, RootState, unknown, UiActionTabIndex> =>
  (dispatch) =>
    dispatch(updateTabIndexAction(name, tabIndex));

export const incrementUiTimer = createAction(UiActions.INCREMENT_UI_TIMER);
