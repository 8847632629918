import { Colors } from 'style/types';

export enum ShareType {
  Twitter = 'Twitter',
  Facebook = 'Facebook',
}

export interface ShareButtonSettings {
  backgroundColor: Colors;
  color: Colors;
}

export type ShareButtonTheme = {
  [shareType in ShareType]: ShareButtonSettings;
};
