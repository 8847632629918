import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getActiveToasts } from 'store/toast/selectors';
import { DefaultToast, ToastWrapper, PropositionToast } from 'molecules/Toasts';
import { removeToast } from 'store/toast/actions';
import { Toast, ToastType, PropositionToast as PropsToast } from 'store/toast/types';
import { getSafeAreaInsetTop } from 'utils/getSafeAreaInsetTop';
import { wait } from 'utils/wait';

import toastBgCorrect from 'assets/images/toasterBgCorrect.png';
import toastBgCorrect2x from 'assets/images/toasterBgCorrect@2x.png';
import toastBgCorrect3x from 'assets/images/toasterBgCorrect@3x.png';
import toastBgIncorrect from 'assets/images/toasterBgIncorrect.png';
import toastBgIncorrect2x from 'assets/images/toasterBgIncorrect@2x.png';
import toastBgIncorrect3x from 'assets/images/toasterBgIncorrect@3x.png';
import toastBgUndecided from 'assets/images/toasterBgUndecided.png';
import toastBgUndecided2x from 'assets/images/toasterBgUndecided@2x.png';
import toastBgUndecided3x from 'assets/images/toasterBgUndecided@3x.png';

const preloadImage = (url: string): void => {
  const img = new Image();
  img.src = url;
};

export const Toaster: React.FC = () => {
  const toast = useAppSelector(getActiveToasts);
  const dispatch = useAppDispatch();
  const [activeToast, setActiveToast] = useState<Toast | PropsToast | null>();
  let timeoutId: NodeJS.Timeout;

  const closeToast = () => {
    dispatch(removeToast());
    clearTimeout(timeoutId);
  };

  useEffect(() => {
    async function load() {
      await wait(1500);
      preloadImage(toastBgCorrect);
      preloadImage(toastBgCorrect2x);
      preloadImage(toastBgCorrect3x);
      preloadImage(toastBgIncorrect);
      preloadImage(toastBgIncorrect2x);
      preloadImage(toastBgIncorrect3x);
      preloadImage(toastBgUndecided);
      preloadImage(toastBgUndecided2x);
      preloadImage(toastBgUndecided3x);
    }
    load();
  }, []);

  useEffect(() => {
    if (!toast && activeToast !== null) {
      setActiveToast(null);
      return;
    }
    if (toast.id !== activeToast?.id) {
      setActiveToast(toast);
      timeoutId = setTimeout(closeToast, 5000);
    }
  }, [toast]);

  const getToast = (toastToCreate: Toast | PropsToast): JSX.Element => {
    switch (toastToCreate.type) {
      case ToastType.PropositionResult:
        return <PropositionToast toast={toastToCreate as PropsToast} />;

      default:
        return <DefaultToast toast={toastToCreate as Toast} />;
    }
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {!!activeToast && !!toast && (
        <ToastWrapper
          key={Date.now()}
          exit={{ y: '-150%', opacity: 0 }}
          animate={{ y: getSafeAreaInsetTop() }}
          initial={{ y: '-110%' }}
          onClick={() => closeToast()}
          transition={{ type: 'easeInOut' }}
        >
          {getToast(activeToast)}
        </ToastWrapper>
      )}
    </AnimatePresence>
  );
};
