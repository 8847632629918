// Action time

import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeaguesAPI } from 'services/pttv/api/leagues';
import { GetLeagueLeaderboardResponse } from 'services/pttv/api/leagues/types';
import { FetchLeagueLeaderboardArgs, LeagueHistoryActions } from './types';

export const fetchLeagueLeaderboard = createAsyncThunk<
  GetLeagueLeaderboardResponse,
  FetchLeagueLeaderboardArgs
>(LeagueHistoryActions.FETCH_LEAGUE_LEADERBOARD, async ({ leagueName, contestId }) => {
  const leagueLeaderboard = await LeaguesAPI.getLeagueLeaderboard(leagueName, contestId);
  return leagueLeaderboard;
});
