import React, { useEffect, useState } from 'react';
import { PropositionTab } from 'atoms/Tab';
import { useAppSelector } from 'store/hooks';
import { isBetLoading, hasPropositionBet } from 'store/bets/selectors';
import { getPropositionById } from 'store/propositions/selectors';
import { PropositionState } from 'services/pttv/api/constants';
import { BubbleLoader } from 'atoms/Loader';

interface Props {
  propositionId: string;
  activeTab: boolean;
  onClick: () => void;
}

const pendingStates = [PropositionState.LOCKED, PropositionState.RESOLVED];

export const ExtendedPropositionTab: React.FC<Props> = ({
  children,
  propositionId,
  activeTab,
  onClick,
}) => {
  const hasBet = useAppSelector(hasPropositionBet(propositionId));
  const proposition = useAppSelector(getPropositionById(propositionId));
  const [init, setInit] = useState(true);
  const [finished, setFinished] = useState(false);
  const loading = useAppSelector(isBetLoading(propositionId));

  useEffect(() => {
    if (!init || loading) {
      setFinished(!loading);
    }
    setInit(false);
  }, [loading]);

  return (
    <PropositionTab
      $selected={hasBet}
      $pending={pendingStates.includes(proposition.state)}
      $activeTab={activeTab}
      onClick={onClick}
      $finished={finished && !init}
    >
      {loading ? <BubbleLoader /> : children}
    </PropositionTab>
  );
};
