import { LeagueLeaderboardEntry } from 'services/pttv/api/leagues/types';

export enum LeagueHistoryActions {
  FETCH_LEAGUE_LEADERBOARD = '@leagueLeaderboard/FETCH_LEAGUE_LEADERBOARD',
}

export interface LeagueLeaderBoardUser extends LeagueLeaderboardEntry {
  rank: number;
}

export type GetLeagueLeaderboard = {
  leagueName: string;
  contestId: string;
  sportId: string;
  period: number;
  description: string;
  entries: LeagueLeaderBoardUser[];
};

export interface FetchLeagueLeaderboardArgs {
  leagueName: string;
  contestId: string;
}

export type LeagueLeaderboardState = {
  [leagueName: string]: {
    [contestId: string]: GetLeagueLeaderboard;
  };
};
