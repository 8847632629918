import styled from 'styled-components';
import { LeagueHeaderContainer } from 'molecules/LeagueHeader/LeagueHeader.styled';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Padding } from 'style/types';
import { Paragraph } from 'atoms/Paragraph';

export const LeagueInviteCardContainer = styled.section`
  background-color: ${Colors.Black};
  border: ${BorderWidth.Large} solid ${Colors.Black75};
  border-radius: ${BorderRadius.Default};
`;

export const LeagueInviteCardHeader = styled.section`
  border-bottom: ${BorderWidth.Default} solid ${Colors.Black75};

  ${LeagueHeaderContainer} {
    border-radius: ${BorderRadius.Default};
    padding-bottom: ${Padding.XXL};
  }
`;

export const InvitedBy = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Paragraph} {
    font-size: ${FontSize.S};
  }
`;

export const InvitedByName = styled.h3`
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
`;

export const LeagueInviteCardContentArea = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: ${Padding.Default} ${Padding.Default} ${Padding.XXL};
`;
