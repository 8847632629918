import styled from 'styled-components';

export const TabContentWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

interface ScrollContainerProps {
  $maxHeight: string;
}

export const ScrollContainer = styled.section<ScrollContainerProps>`
  overflow: scroll;
  height: 100%;
  max-height: ${({ $maxHeight }) => $maxHeight};
  padding-bottom: 2rem;
  position: relative;
  width: 100%;
`;

interface ContainerProps {
  $tabCount: number;
}

export const CarouselContainer = styled.div<ContainerProps>`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(${({ $tabCount }) => $tabCount}, 1fr);
  position: absolute;
  height: 100%;
  justify-items: center;
  left: var(--schedule-left);
  overflow: hidden;
  transition: left var(--schedule-left-duration) ease-out;

  @supports (left: constant(--schedule-left)) {
    left: constant(--schedule-left);
    transition: left constant(--schedule-left-duration) ease-out;
  }

  width: ${({ $tabCount }) => `${$tabCount * 100}%`};
`;

export const StyledTabBar = styled.section`
  display: flex;
  width: 100%;

  > button {
    flex: 1;
  }
`;
