import React, { useEffect, useState } from 'react';
import { Dialog, DialogFooter } from 'atoms/Dialog';
import { Button } from 'atoms/Button';
import { ContentArea } from 'atoms/ContentArea';
import { ButtonSchemes } from 'atoms/Button/button.theme';

interface Props {
  title: string;
  cancelButtonText?: string | undefined;
  confirmButtonText?: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  cancelAction?: () => void;
  confirmAction: () => void;
  disableButtons?: boolean;
}

export const Confirm: React.FC<Props> = ({
  title,
  cancelButtonText,
  confirmButtonText,
  isOpen,
  onClose,
  children,
  cancelAction,
  confirmAction,
  disableButtons,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  const handleConfirmClick = () => {
    if (disableButtons) {
      setLoading(true);
    }
    confirmAction();
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} canCloseOnOutClick={false} title={title}>
      <ContentArea>{children}</ContentArea>
      <DialogFooter>
        <Button scheme={ButtonSchemes.Warning} onClick={cancelAction || onClose}>
          {cancelButtonText || 'Cancel'}
        </Button>
        <Button scheme={ButtonSchemes.Secondary} onClick={handleConfirmClick} disabled={loading}>
          {confirmButtonText || 'Ok'}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
