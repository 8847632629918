import { LeagueMember } from 'services/pttv/api/leagues/types';

export enum LeagueActions {
  CREATE_LEAGUE_SUCCESS = '@league/CREATE_LEAGUE_SUCCESS',
  FETCH_LEAGUE = '@league/FETCH_LEAGUE',
  FETCH_ALL_LEAGUES = '@league/FETCH_ALL_LEAGUES',
  CREATE_LEAGUE = '@league/CREATE_LEAGUE',
  UPDATE_LEAGUE = '@league/UPDATE_LEAGUE',
  LEAVE_LEAGUE = '@league/LEAVE_LEAGUE',
  LEAVE_LEAGUE_SUCCESS = '@league/LEAVE_LEAGUE_SUCCESS',
  FETCH_LEAGUE_AVATARS_SUCCESS = '@league/FETCH_LEAGUE_AVATARS_SUCCESS',
  INVITE_USERS_TO_LEAGUE_SUCCESS = '@league/INVITE_USERS_TO_LEAGUE_SUCCESS',
  LEAGUE_INVITE_CANCELLED = '@league/LEAGUE_INVITE_CANCELLED',
  KICK_LEAGUE_MEMBER_SUCCESS = '@league/KICK_LEAGUE_MEMBER_SUCCESS',
  FETCH_LEAGUE_HISTORY_LEADERBOARD_SUCCESS = '@league/FETCH_LEAGUE_HISTORY_LEADERBOARD_SUCCESS',
  FETCH_LEAGUE_HISTORY_SUCCESS = '@league/FETCH_LEAGUE_HISTORY_SUCCESS',
  LEAGUE_INVITE_ACCEPTED = '@league/LEAGUE_INVITE_ACCEPTED',
  LEAGUE_INVITE_DECLINED = '@league/LEAGUE_INVITE_DECLINED',
  UPDATE_LEAGUE_MEMBER = '@league/UPDATE_LEAGUE_MEMBER',
  TRACK_NAVIGATE_SOCIAL = '@league/TRACK_NAVIGATE_SOCIAL',
  UPDATE_LEAGUE_TOAST_BROADCAST_DATE = '@league/UPDATE_LEAGUE_TOAST_BROADCAST_DATE',
}

export interface LeaveLeagueArgs {
  leagueName: string;
  newCommissionerId: string | null;
}

export interface PromoteLeagueMemberArgs {
  leagueName: string;
  subjectId: string;
}

export type DemoteLeagueMemberArgs = PromoteLeagueMemberArgs;
export type KickLeagueMemberArgs = PromoteLeagueMemberArgs;

export interface LeagueKickedPayload {
  avatarUrl: string;
  name: string;
  leagueId: string;
  description: string;
}

export interface UpdateLeaguePayload {
  name: string;
  description: string;
  avatarId: string;
  affiliatedTeamId: string | null;
  affiliatedSportId: string | null;
}

export interface CreateLeaguePayload extends UpdateLeaguePayload {
  userIds?: string[];
}

export interface UpdateLeagueResponse extends UpdateLeaguePayload {
  avatarUrl: string;
}

interface LeagueContest {
  description: string;
  sportId: string;
  avatarUrl: string;
  period: string;
  members: LeagueMember[];
}
export type GroupedLeagueMembers = LeagueContest[];
export type GroupedLeagueMembersObject = {
  [contestId: string]: LeagueContest;
};

export interface League {
  name: string;
  leagueId: string;
  description: string;
  affiliatedTeamId: string | null;
  affiliatedSportId: string | null;
  avatarId: string;
  avatarUrl: string;
  members: LeagueMember[];
  maxLeagueSize: number;
  lastUpdatedAt: number;
}

export type LeaguesState = Record<string, League | null>;
