import { Colors } from 'style/types';

export enum ChatBubbleTypes {
  Winview = 'winview',
  Left = 'left',
  Right = 'right',
}

export type ChatBubbleTheme = {
  [type in ChatBubbleTypes]: {
    color: Colors;
    backgroundColor: Colors;
  };
};
