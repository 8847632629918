export enum RoomTypeLeaderboardMetaActions {
  UPDATE_META = '@roomTypeLeaderboardMeta/UPDATE_META',
  UPDATE_ROOMTYPELEADERBOARD_COUNT = '@roomTypeLeaderboardMeta/UPDATE_ROOMTYPELEADERBOARD_COUNT',
  UPDATE_ROOMTYPELEADERBOARD_PRIZE_PER_RANK_DOLLAR_CENTS = '@roomTypeLeaderboardMeta/UPDATE_ROOMTYPELEADERBOARD_PRIZE_PER_RANK_DOLLAR_CENTS',
}

export interface UpdateRoomTypeLeaderboardMetaArgs {
  contestId: string;
  roomTypeId: string;
  eventId: string;
}

export interface UpdateRoomTypeLeaderboardPrizePerRankDollarCentsArgs {
  contestId: string;
  roomTypeId: string;
  prizePerRankDollarCents: number[];
}

export interface RoomTypeLeaderboardMeta {
  prizePoolDollarCents: number;
  prizePerRankDollarCents: number[];
  lastUpdateId: string;
}

export type UpdateRoomTypeLeaderboardMetaState = {
  [contestId: string]: {
    [roomTypeId: string]: RoomTypeLeaderboardMeta;
  };
};
