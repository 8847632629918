import { createReducer } from '@reduxjs/toolkit';
import { cashBetResetError } from 'store/cashBet/actions';
import { captureError } from 'utils/log';
import {
  clearUser,
  fetchGlobalState,
  fetchUserState,
  signInSuccess,
  toggleTeamNotifications,
  updateActionNotifications,
  updateClientDataStore,
  updateDisplayName,
  updateDollarCents,
  updateGlobalNotifications,
  updateEmail,
  updateProfile,
  updateUser,
  updateWincoins,
  multipleSessionsError,
  userBlockedError,
  userUnauthorizedError,
  signInError,
} from './actions';
import { UserState } from './types';

export const initialState = {
  actionNotificationsEnabled: true,
  affiliatedSportId: null,
  affiliatedTeamId: null,
  campaignId: null,
  clientLogicState: {},
  isAuthenticated: false,
  mediaSource: null,
  notificationsEnabled: true,
  organic: false,
  soundEnabled: true,
  teamNotifications: {},
  wincoins: 0,
  dollarCents: 0,
  closedContestEndedDialogs: {},
  signInTimestamp: null,
  multipleSessionsError: null,
  signInError: null,
  userBlockedError: null,
  userUnauthorizedError: null,
  firstLogin: true,
  isOnLeaderboardDetails: true,
  signOutStarted: false,
} as unknown as UserState;

// TODO: Implement CashBetReset (signInTimestamp: null)

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      signInSuccess,
      (state, { payload }) =>
        ({
          ...state,
          ...payload,
          isAuthenticated: true,
          signInTimestamp: Date.now(),
          multipleSessionsError: null,
          signInError: null,
          userUnauthorizedError: null,
        } as UserState),
    )
    .addCase(fetchUserState.fulfilled, (state, { payload }) => {
      state.teamNotifications = payload.notificationSettings.enabledTeamNotifications.reduce(
        (acc, notificationId) => ({
          ...acc,
          [notificationId]: true,
        }),
        {},
      );
    })
    .addCase(fetchGlobalState.rejected, (state, { error }) => {
      captureError(error);
      return {
        ...state,
      };
    })
    .addCase(
      multipleSessionsError.fulfilled,
      (state, { payload }) =>
        ({
          ...state,
          multipleSessionsError: payload,
        } as UserState),
    )
    .addCase(
      signInError,
      (state, { payload }) =>
        ({
          ...state,
          signInError: payload,
        } as UserState),
    )
    .addCase(updateWincoins, (state, { payload }) => {
      state.wincoins = payload;
    })
    .addCase(updateDollarCents, (state, { payload }) => {
      state.dollarCents = payload;
    })
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(
      updateClientDataStore.fulfilled,
      (state, { payload }) =>
        ({
          ...state,
          ...payload,
        } as UserState),
    )
    .addCase(
      updateUser,
      (state, { payload }) =>
        ({
          ...state,
          ...payload,
        } as UserState),
    )
    .addCase(
      updateProfile.fulfilled,
      (state, { payload }) =>
        ({
          ...state,
          ...payload,
        } as UserState),
    )
    .addCase(
      updateActionNotifications.pending,
      (state, { meta }) =>
        ({
          ...state,
          actionNotificationsEnabled: meta.arg.enabled,
        } as UserState),
    )
    .addCase(
      updateActionNotifications.rejected,
      (state, { meta }) =>
        ({
          ...state,
          actionNotificationsEnabled: !meta.arg.enabled,
        } as UserState),
    )
    .addCase(
      updateGlobalNotifications.pending,
      (state, { meta }) =>
        ({
          ...state,
          actionNotificationsEnabled: meta.arg.enabled,
          notificationsEnabled: meta.arg.enabled,
        } as UserState),
    )
    .addCase(
      updateGlobalNotifications.rejected,
      (state, { meta }) =>
        ({
          ...state,
          actionNotificationsEnabled: !meta.arg.enabled,
          notificationsEnabled: !meta.arg.enabled,
        } as UserState),
    )
    .addCase(
      updateEmail.fulfilled,
      (state, { payload }) =>
        ({
          ...state,
          ...payload,
        } as UserState),
    )
    .addCase(
      updateDisplayName.fulfilled,
      (state, { payload }) =>
        ({
          ...state,
          ...payload,
        } as UserState),
    )
    .addCase(
      userBlockedError,
      (state, { payload }) =>
        ({
          ...state,
          userBlockedError: payload,
        } as UserState),
    )
    .addCase(
      userUnauthorizedError,
      (state, { payload }) =>
        ({
          ...state,
          userUnauthorizedError: payload,
        } as UserState),
    )
    .addCase(
      cashBetResetError,
      (state) =>
        ({
          ...state,
          signInTimestamp: null,
        } as UserState),
    )
    .addCase(toggleTeamNotifications, (state, { payload }) => {
      state.teamNotifications = payload.teamIds.reduce(
        (acc, teamId) => ({
          ...acc,
          [teamId]: payload.enabled,
        }),
        { ...state.teamNotifications },
      );
    });
});
