import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RoomsAPI } from 'services/pttv/api/rooms';
import { RoomType, GetRoomTypeDetailsResponse } from 'services/pttv/api/rooms/types';
import { RoomTypeActions } from './types';

export const updateRoomType = createAction<RoomType>(RoomTypeActions.UPDATE_ROOM_TYPE);

export const addActiveRoomTypes = createAction<RoomType[]>(RoomTypeActions.ADD_ACTIVE_ROOM_TYPE);

export const fetchRoomTypeDetails = createAsyncThunk<
  GetRoomTypeDetailsResponse & { roomTypeId: string },
  string
>(RoomTypeActions.FETCH_ROOM_TYPE_DETAILS, async (roomTypeId) => {
  const result = await RoomsAPI.getRoomTypeDetails({ roomTypeId });
  return { ...result, roomTypeId };
});
