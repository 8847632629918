import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import GamesIcon from 'assets/icons/GamesNavIcon.svg';
import ScheduleIcon from 'assets/icons/ScheduleIcon.svg';
import SocialIcon from 'assets/icons/SocialIcon.svg';
import { getOpenPropositionCount } from 'store/propositions/selectors';
import { useAppSelector } from 'store/hooks';
import { getJoinedGameIds } from 'store/games/selectors';
import { getFriendInvites } from 'store/friends/selectors';
import { getFlatLeagueInvites } from 'store/leagueInvites/selectors';
import { FooterStyle } from './Footer.styled';
import { FooterItem } from './FooterItem';

const gamePaths = ['/games/:gameId/contests/:contestId/proposition', '/games'];

export const Footer: React.FC = () => {
  const { t } = useTranslation('Global');
  const gameMatch = useRouteMatch(gamePaths);
  const scheduleMatch = useRouteMatch('/schedule');
  const openPropositionsCount = useAppSelector(getOpenPropositionCount);
  const joinedGames = useAppSelector(getJoinedGameIds);
  const friendInvites = useAppSelector(getFriendInvites);
  const leagueInvites = useAppSelector(getFlatLeagueInvites);

  const mustItemBounce = () => {
    if (gameMatch?.isExact) {
      return false;
    }
    return !!openPropositionsCount;
  };

  return (
    <FooterStyle>
      <FooterItem
        label={t('footer.navigation.schedule')}
        icon={<ScheduleIcon className="schedule-icon" />}
        path="/"
        active={!!scheduleMatch}
        exact
      />
      <FooterItem
        label={t('footer.navigation.my-game', { count: joinedGames.length })}
        icon={<GamesIcon className="games-icon" />}
        path="/games"
        badge={openPropositionsCount}
        counter={joinedGames.length}
        bounce={mustItemBounce()}
      />
      <FooterItem
        label={t('footer.navigation.social')}
        icon={<SocialIcon className="social-icon" />}
        path="/social"
        badge={friendInvites.length + leagueInvites.length}
      />
    </FooterStyle>
  );
};
