import styled from 'styled-components';

export const FadeInWrapper = styled.section.attrs({
  className: 'fadein',
})`
  display: grid;

  .fadein {
    z-index: 20;
  }

  &.fadein-enter {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  &.fadein-enter-done,
  &.fadein-enter-active {
    opacity: 1;
  }

  &.fadein-exit {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }

  &.fadein-exit-active {
    opacity: 0;
  }
`;
