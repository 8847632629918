import styled, { css } from 'styled-components';
import { Colors, FontFamily } from 'style/types';

interface ParagraphProps {
  noMargin?: boolean;
  $textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
}

export const ParagraphStyles = css`
  font-family: ${FontFamily.Regular};
  color: ${Colors.Black40};
`;

export const Paragraph = styled.p<ParagraphProps>`
  ${ParagraphStyles};
  text-align: ${({ $textAlign }) => $textAlign || 'initial'};

  ${({ noMargin }) =>
    !!noMargin &&
    css`
      margin: 0;
    `}
`;
