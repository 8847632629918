import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { playToTv } from 'services/pttv';
import { ClientType, LoginResponse, UsersAPI } from 'services/pttv/api';
import { enableCashBetAutoLogin, setCashBetUrls } from 'store/cashBet/actions';
import { addProblemToast } from 'store/contests/actions';
import { fetchPropositions } from 'store/propositions/actions';
import { fetchTicketTypes, fetchTicketTypesByTicketHashMap } from 'store/ticketTypes/actions';
import type { RootState } from 'store/types';
import { getUrlParam } from 'utils/url';
import { debugLog } from 'utils/log';
import { TeamNotificationsUpdatedEventPayload } from 'hocs/withLongPoll/types/team';
import { fetchRoomTypeLeaderboardAndUpdateLeaderboardMovement } from 'store/roomTypeLeaderboard/actions';
import {
  UpdateActionNotificationsRequest,
  UpdateGlobalNotificationsRequest,
  ClientDataStore,
  ForgotPasswordRequest,
  UpdateDisplayNameRequest,
  UpdateEmailRequest,
  UpdateProfileRequest,
  User,
} from 'services/pttv/api/users/types';
import { EmptyResponse } from 'services/pttv/api/types';
import { addTextToast } from 'store/toast/actions';
import { signInWithFacebook } from 'store/facebook/actions';
import { PttvError } from 'services/pttv/types';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import wrapperLib from 'services/wrapper';
import { ContestAPI } from 'services/pttv/api/contests';
import {
  contestCancelled,
  contestEnded,
  removeContestInfo,
  roomCancelled,
} from 'store/contestInfo/actions';
import { addContestRoomResults } from 'store/contestResults/actions';
import { ContestState } from 'services/pttv/api/constants';
import { UserStateAPI } from 'services/pttv/api/userState';
import { fetchFriends, notifyFriendsOnFirstLogin } from 'store/friends/actions';
import { UserStateResponse } from 'services/pttv/api/userState/types';
import {
  GlobalStateResponse,
  UserActions,
  LoginWithCashBetArgs,
  UserState,
  CurrentContestInfo,
} from './types';

export const signInError = createAction<PttvError>(UserActions.SIGN_IN_ERROR);
export const signInSuccess = createAction<LoginResponse>(UserActions.SIGN_IN_SUCCESS);
export const updateUser = createAction<Partial<UserState>>(UserActions.UPDATE_USER);
export const updateWincoins = createAction<number>(UserActions.UPDATE_WINCOINS);
export const updateDollarCents = createAction<number>(UserActions.UPDATE_DOLLAR_CENTS);
export const userBlockedError = createAction<PttvError>(UserActions.USER_BLOCKED_ERROR);
export const userUnauthorizedError = createAction<PttvError>(UserActions.USER_UNAUTHORIZED_ERROR);
export const toggleTeamNotifications = createAction<TeamNotificationsUpdatedEventPayload>(
  UserActions.TOGGLE_TEAM_NOTIFICATIONS,
);

export const clearUser = createAsyncThunk(UserActions.CLEAR_USER, () => {
  wrapperLib.removeStorageItem('authToken');
  playToTv.setSessionKey(null);
});

interface FinishProps {
  contests: CurrentContestInfo[];
  previousState: RootState;
}

export const handleFinishedOrCancelledContestsAndRooms = createAsyncThunk<void, FinishProps>(
  UserActions.FINISHED_OR_CANCELLLED,
  async ({ previousState, contests }, { dispatch, getState }) => {
    const previousContestInfoIds = Object.keys(previousState.contestInfo);
    const newContestInfoIds = contests ? contests.map((contest) => contest.contestId) : [];

    if (!previousContestInfoIds.length) {
      return;
    }

    previousContestInfoIds.forEach(async (contestId) => {
      if (!newContestInfoIds.includes(contestId)) {
        const response = await ContestAPI.getContestResult({ contestId });
        if (response.cancelled) {
          const oldContest = previousState.contestInfo[contestId];
          const roomTypeIds = oldContest.roomQueueEntries.map((entry) => entry.roomTypeId);
          dispatch(
            contestCancelled({
              contestId,
              roomTypeIds,
              cancelledReason: response.cancelledReason as string,
            }),
          );
          return;
        }

        const contestInfo = previousState.contestInfo[contestId];
        let { roomQueueEntries } = contestInfo;
        response.roomResults.forEach(({ payload }) => {
          dispatch(addContestRoomResults(payload));
          roomQueueEntries = roomQueueEntries.filter(
            (entry) => entry.roomTypeId !== payload.roomTypeId,
          );
        });

        roomQueueEntries.forEach((entry) => {
          dispatch(
            roomCancelled({
              contestId,
              roomQueueId: entry.roomQueueId,
              roomTypeId: entry.roomTypeId,
              stayOnPage: true,
              unreadMessageId: '',
            }),
          );
        });

        dispatch(contestEnded(contestId));
        if (roomQueueEntries.length === 0) {
          dispatch(removeContestInfo(contestId));
        }
      } else {
        // new state contains the contest, but some rooms may have been cancelled
        // so check if each room is still part of the contest
        const previousContest = previousState.contestInfo[contestId];
        const currentContest = contests.find((contest) => contest.contestId === contestId);

        previousContest.roomQueueEntries.forEach((roomQueueEntry) => {
          if (
            !currentContest?.rooms.find((room) => roomQueueEntry.roomTypeId === room.roomTypeId) &&
            !currentContest?.roomTypeLeaderboards.find(
              (leaderboard) => leaderboard.roomTypeId === roomQueueEntry.roomTypeId,
            ) &&
            !currentContest?.roomQueueEntries.find(
              (entry) => entry.roomTypeId === roomQueueEntry.roomTypeId,
            )
          ) {
            dispatch(
              roomCancelled({
                roomQueueId: roomQueueEntry.roomQueueId,
                contestId,
                roomTypeId: roomQueueEntry.roomTypeId,
                unreadMessageId: '',
              }),
            );
          }
        });
      }

      if (
        previousState.contests[contestId].state !== ContestState.FINISHING &&
        (getState() as RootState).contests[contestId].state === ContestState.FINISHING
      ) {
        dispatch(contestEnded(contestId));
      }
    });
  },
);

export const fetchUserState = createAsyncThunk<UserStateResponse, void>(
  UserActions.FETCH_USER_STATE,
  async (_, { getState, dispatch }) => {
    const response = await UserStateAPI.getUserState();
    const previousState = getState() as RootState;
    dispatch(fetchTicketTypes());

    const { contests } = (response.currentGamesInfo && response.currentGamesInfo[0]) || {};
    const allContests = response.contests;
    if (contests) {
      contests.forEach(({ contestId, roomTypeLeaderboards }) => {
        const currentContest = allContests.find((contest) => contest.contestId === contestId);
        if (currentContest) {
          dispatch(fetchPropositions(currentContest));
        }
        const problems = currentContest ? currentContest.contestProblems : [];
        problems.forEach((problem) =>
          dispatch(addProblemToast({ problem, contestId, force: false })),
        );

        if ((getState() as RootState).user.isOnLeaderboardDetails) {
          roomTypeLeaderboards.forEach((leaderboard) =>
            dispatch(
              fetchRoomTypeLeaderboardAndUpdateLeaderboardMovement({
                contestId,
                roomTypeId: leaderboard.roomTypeId,
              }),
            ),
          );
        }
      });
    }
    dispatch(handleFinishedOrCancelledContestsAndRooms({ previousState, contests }));

    return response;
  },
);

export const fetchGlobalState = createAsyncThunk<GlobalStateResponse, void>(
  UserActions.FETCH_GLOBAL_STATE,
  async (_, { dispatch }) => {
    const payload = await playToTv.get<GlobalStateResponse>('globaldata');
    dispatch(setCashBetUrls(payload));
    return payload;
  },
);

export const loginWithCashBet = createAsyncThunk<void, LoginWithCashBetArgs>(
  UserActions.LOGIN_WITH_CASHBET,
  async (payload, { dispatch, getState }) => {
    const { wrapper, user } = getState() as RootState;
    const { autoLogin, token, userId } = payload;
    const url = window.location.href;

    try {
      const response = await UsersAPI.loginWithCashBet({
        cbData: { userId, token },
        clientType: wrapper.os || ClientType.WEB,
        deviceId: wrapper.deviceId || '',
        deviceToken: wrapper.deviceToken || '',
        endpointArn: wrapper.snsEndpoint || '',
        externalHooks: {
          appsFlyerId: wrapper.appsFlyerUID,
          mediaSource: getUrlParam('utm_source', url),
          campaignId: getUrlParam('utm_campaign', url),
          af_sub1: getUrlParam('af_sub1', url),
          af_sub2: getUrlParam('af_sub2', url),
          af_sub3: getUrlParam('af_sub3', url),
          af_sub4: getUrlParam('af_sub4', url),
        },
      });

      if (response) {
        playToTv.setSessionKey(response.sessionKey);

        wrapperLib.locationRequestAuthorization();
        wrapperLib.setStorageItem('authToken', response.authToken);

        Sentry.configureScope((scope) => {
          scope.setUser({ id: user ? user.userId : undefined });
        });

        // Batch actions to make sure that autoLoginCashBet and isAuthenticated are set before a new render occurs.
        batch(() => {
          if (autoLogin) {
            dispatch(enableCashBetAutoLogin());
          }
          dispatch(signInSuccess(response));
        });

        // TODO: implement notifyFriendsOnFirstLogin (WV2-8084)
        dispatch(fetchTicketTypesByTicketHashMap(response.tickets));
        dispatch(fetchUserState()).then(() => {
          dispatch(fetchFriends());
          dispatch(notifyFriendsOnFirstLogin());
        });
      }
    } catch (e) {
      dispatch(signInError(e as PttvError));
    }
  },
);

export const signOut = createAsyncThunk(UserActions.SIGN_OUT, async (_, { dispatch, getState }) => {
  try {
    const { user } = getState() as RootState;
    await UsersAPI.logout();

    if (user && user.facebookId) {
      wrapperLib.facebookLogout();
    }

    // TODO: Port implementation, logout cashbet, etc.
  } catch (error) {
    debugLog('[UserActions]', 'Sign out failed', error);
  }

  dispatch(clearUser());
});

export const updateClientDataStore = createAsyncThunk<User, ClientDataStore>(
  UserActions.UPDATE_CLIENT_LOCAL_DATA_STORE,
  async (payload) => {
    const response = await UsersAPI.updateClientDataStore({
      clientDataStore: { ...payload },
    });

    return response;
  },
);

export const updateProfile = createAsyncThunk<User, UpdateProfileRequest>(
  UserActions.UPDATE_PROFILE,
  async (request) => {
    const response = await UsersAPI.updateProfile(request);

    return response;
  },
);

export const updateActionNotifications = createAsyncThunk<
  EmptyResponse,
  UpdateActionNotificationsRequest
>(UserActions.UPDATE_ACTION_NOTIFICATIONS, async (request) => {
  const response = await UsersAPI.updateActionNotifications(request);

  return response;
});

export const updateGlobalNotifications = createAsyncThunk<
  EmptyResponse,
  UpdateGlobalNotificationsRequest
>(UserActions.UPDATE_GLOBAL_NOTIFICATIONS, async (request) => {
  const response = await UsersAPI.updateGlobalNotifications(request);

  return response;
});

export const updateEmail = createAsyncThunk<User | PttvError, UpdateFieldsArgs<UpdateEmailRequest>>(
  UserActions.UPDATE_EMAIL,
  async ({ request, successMessage, errorMessage }, { dispatch, rejectWithValue }) => {
    try {
      const response = await UsersAPI.changeEmail(request);
      dispatch(
        addTextToast({
          message: successMessage('updateEmail'),
        }),
      );
      return response;
    } catch (e: unknown) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
          to: '/profile/contact-support',
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const updateDisplayName = createAsyncThunk<
  User | PttvError,
  UpdateFieldsArgs<UpdateDisplayNameRequest>
>(
  UserActions.UPDATE_DISPLAY_NAME,
  async ({ request, successMessage, errorMessage }, { dispatch, rejectWithValue }) => {
    try {
      const response = await UsersAPI.updateDisplayName(request);
      dispatch(
        addTextToast({
          message: successMessage('updatePlayerName'),
        }),
      );
      return response;
    } catch (e: unknown) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
          to: '/profile/contact-support',
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const requestUserVerification = createAsyncThunk<
  EmptyResponse | PttvError,
  UpdateFieldsArgs<null>
>(
  UserActions.REQUEST_USER_VERIFICATION,
  async ({ successMessage, errorMessage }, { dispatch, rejectWithValue }) => {
    try {
      const response = await UsersAPI.requestUserVerification();
      dispatch(
        addTextToast({
          message: successMessage('userVerification'),
        }),
      );
      return response;
    } catch (e: unknown) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
          to: '/profile/contact-support',
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const forgotPassword = createAsyncThunk<
  EmptyResponse | PttvError,
  UpdateFieldsArgs<ForgotPasswordRequest>
>(
  UserActions.FORGOT_PASSWORD,
  async ({ request, successMessage, errorMessage }, { dispatch, rejectWithValue }) => {
    try {
      const response = await UsersAPI.forgotPassword(request);
      dispatch(
        addTextToast({
          message: successMessage('resetPassword'),
        }),
      );
      return response;
    } catch (e: unknown) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
          to: '/profile/contact-support',
        }),
      );
      return rejectWithValue(e as PttvError);
    }
  },
);

export const multipleSessionsError = createAsyncThunk<PttvError | null, PttvError>(
  UserActions.MULTIPLE_SESSIONS_ERROR,
  (error, { getState }) => {
    const { user } = getState() as RootState;

    // Ignore multiple session errors when the cash bet reset has occurred recently (auto login after reset could potentially cause multiple session error for running requests)
    if (!user.signInTimestamp || Date.now() - user.signInTimestamp < 5000) {
      throw new Error('Ignore multiple sessions error');
    }

    if (user.facebookId) {
      wrapperLib.facebookLogout();
    }

    return error;
  },
);

export const linkFacebook = createAsyncThunk<boolean, UpdateFieldsArgs<null>>(
  UserActions.LINK_FACEBOOK,
  async ({ errorMessage, successMessage }, { dispatch, rejectWithValue }) => {
    const { payload: accessToken } = await dispatch(signInWithFacebook());

    if (!accessToken) {
      return rejectWithValue({ success: false });
    }

    try {
      await UsersAPI.linkFacebook({ accessToken: accessToken as string });
      dispatch(
        addTextToast({
          message: successMessage('linkFacebookAccount'),
        }),
      );
      return true;
    } catch (e: unknown) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue({ success: false });
    }
  },
);
