export enum ClientProps {
  CASH_BET_BASE_URL = 'CASH_BET_BASE_URL',
  DESKTOP_BG_IMAGE = 'DESKTOP_BG_IMAGE',
  ENABLE_DESKTOP = 'ENABLE_DESKTOP',
  SCHEDULE_FTU_CONTENT_URL = 'SCHEDULE_FTU_CONTENT_URL',
  SHARE_FRIEND_INVITE_URL = 'SHARE_FRIEND_INVITE_URL',
  SKILLRANK_LEADERBOARD = 'SKILLRANK_LEADERBOARD',
}

export enum HelpPages {
  HELP_PAGE_SCHEDULE_URL = 'HELP_PAGE_SCHEDULE_URL',
  HELP_PAGE_JOIN_CONTEST_URL = 'HELP_PAGE_JOIN_CONTEST_URL',
  HELP_PAGE_GAME_DETAIL_URL = 'HELP_PAGE_GAME_DETAIL_URL',
  HELP_PAGE_MAKE_PREDICTION_URL = 'HELP_PAGE_MAKE_PREDICTION_URL',
  HELP_PAGE_SOCIAL_LANDING_URL = 'HELP_PAGE_SOCIAL_LANDING_URL',
}

export type AllClientProps = ClientProps | HelpPages;

export type ClientPropertiesState = {
  [key in AllClientProps]: string;
};
