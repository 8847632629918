import { createSelector } from 'reselect';
import { RootState, CurriedSelector } from 'store/types';
import { ContestResultsState, ContestResult, ContestRoomResult } from './types';

export const getContestResults = (state: RootState): ContestResultsState => state.contestResults;

export const getContestResultsById = (contestId: string): CurriedSelector<ContestResult> =>
  createSelector(getContestResults, (contestResults) => contestResults[contestId]);

export const getFlatContestResultsById = (
  contestId: string,
): CurriedSelector<ContestRoomResult[]> =>
  createSelector(getContestResultsById(contestId), (contestResults) =>
    Object.values(contestResults || {}),
  );

export const getContestResultsByRoomId = (
  contestId: string,
  roomId: string,
): CurriedSelector<ContestRoomResult> =>
  createSelector(getContestResults, (contestResults) => contestResults[contestId][roomId]);
