import React, { useEffect } from 'react';
import { Table, TableCell, TableRow } from 'atoms/Table';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchTicketTypesByRoomTypeIds } from 'store/ticketTypes/actions';
import { getTicketTypesToRoomBuyInByTicketTypeIds } from 'store/ticketTypes/selectors';
import TicketsIcon from 'assets/icons/TicketsIcon.svg';
import { formatCurrency } from 'utils/formatters';
import { DialogTitle } from 'atoms/Dialog/Dialog.styled';
import { TotalEntryContainer } from './RoomTotalEntry.styled';

interface Props {
  totalDollarCents: number;
  ticketsToBuyInIds: string[];
  roomTypeIds: string[];
  title: string;
}

export const RoomTotalEntry: React.FC<Props> = ({
  totalDollarCents,
  ticketsToBuyInIds,
  roomTypeIds,
  title,
}) => {
  const ticketsToBuyIn = useAppSelector(
    getTicketTypesToRoomBuyInByTicketTypeIds(ticketsToBuyInIds),
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTicketTypesByRoomTypeIds(roomTypeIds));
  }, [roomTypeIds]);

  return (
    <TotalEntryContainer>
      <DialogTitle>{title}</DialogTitle>
      {(!!totalDollarCents || ticketsToBuyIn.length > 0) && (
        <Table>
          {!!totalDollarCents && (
            <TableRow>
              <TableCell align="center">{formatCurrency(totalDollarCents / 100)}</TableCell>
            </TableRow>
          )}
          {ticketsToBuyIn.map(({ count, displayName, ticketTypeId }) => (
            <TableRow key={`ticket-${ticketTypeId}`}>
              <TableCell align="center">
                <TicketsIcon style={{ maxHeight: '1em' }} />
                {` ${count} x ${displayName}`}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      )}
    </TotalEntryContainer>
  );
};
