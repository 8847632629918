import React from 'react';
// import WinViewLogoSmall from 'assets/icons/WinViewLogoSmall.svg';
import { useLocation } from 'react-router-dom';
import Wallet from 'molecules/Wallet';
import ProfileIcon from 'molecules/ProfileIcon';
import { PageHelpModal } from 'organisms/PageHelpModal';
import { HelpPages } from 'store/clientProperties/types';
import { HeaderContainer } from './Header.styled';

const helpPages: Record<string, HelpPages> = {
  '/': HelpPages.HELP_PAGE_SCHEDULE_URL,
  '/social': HelpPages.HELP_PAGE_SOCIAL_LANDING_URL,
};

export const Header: React.FC = () => {
  const location = useLocation();

  const getHelpPageId = (): HelpPages | null => helpPages[location.pathname] || null;

  return (
    <HeaderContainer>
      <ProfileIcon />
      {/* <WinViewLogoSmall /> */}
      <div>
        <Wallet>Deposit</Wallet>
        {getHelpPageId() && <PageHelpModal helpPageId={getHelpPageId() as HelpPages} />}
      </div>
    </HeaderContainer>
  );
};
