import { createReducer } from '@reduxjs/toolkit';
import { clearUser } from 'store/user/actions';
import { fetchPlayer } from './actions';
import { PlayersState } from './types';

const initialState: PlayersState = {};

export const playersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(fetchPlayer.rejected, (state, { meta }) => {
      if (state[meta.arg]) {
        delete state[meta.arg];
      }
    })
    .addCase(fetchPlayer.fulfilled, (state, { payload, meta }) => {
      state[payload.userId] = {
        ...payload,
        loadedAt: meta.timestamp,
      };
    });
});
