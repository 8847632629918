import { createSelector } from 'reselect';
import { RoomType } from 'services/pttv/api/rooms/types';
import { getContestInfoByContestId } from 'store/contestInfo/selectors';
// eslint-disable-next-line import/no-cycle
import { getContestById } from 'store/contests/selectors';
import { TicketTypesState } from 'store/ticketTypes/types';
import type { CurriedSelector, RootState } from 'store/types';
import { getPaidContestsJoined } from 'store/user/selectors';
import { GroupPrize, GroupPrizes, RoomTypeState, RoomTypeStateEntry } from './types';
import { getTicketTypeIdsFromPayoutModel, sortRoomtypes } from './utils';

export const getRoomTypes = (state: RootState): RoomTypeState => state.roomTypes;
const getTicketTypes = (state: RootState): TicketTypesState => state.ticketTypes;

export const getRoomTypeById = (roomTypeId: string): CurriedSelector<RoomTypeStateEntry> =>
  createSelector(getRoomTypes, (roomTypes) => roomTypes[roomTypeId] || {});

export const getRoomTypeByIds = (roomTypeIds: string[]): CurriedSelector<RoomTypeState> =>
  createSelector(getRoomTypes, (roomTypes) =>
    roomTypeIds.reduce(
      (acc, roomTypeId) => ({
        ...acc,
        [roomTypeId]: roomTypes[roomTypeId],
      }),
      {},
    ),
  );

export const canJoinLateRoomType = (roomTypeId: string): CurriedSelector<unknown> =>
  createSelector(getRoomTypeById(roomTypeId), (roomType) => !!roomType.canJoinWhileContestIsOpen);

export const getRoomTypesByRoomTypeIds = (roomTypeIds: string[]): CurriedSelector<RoomType[]> =>
  createSelector(getRoomTypes, (roomTypes) =>
    roomTypeIds
      .filter((roomTypeId) => !!roomTypes[roomTypeId])
      .map((roomTypeId) => roomTypes[roomTypeId]),
  );

export const getRoomTypesByContestId = (contestId: string): CurriedSelector<RoomType[]> =>
  createSelector(getRoomTypes, getContestInfoByContestId(contestId), (roomTypes, contest) => {
    const roomTypeIds = Object.entries(contest?.roomTypeIds || {})
      .filter(([_, active]) => active)
      .map(([id]) => id);

    return sortRoomtypes(roomTypeIds, roomTypes).map((id) => roomTypes[id]);
  });

export const getFilteredRoomTypeIdsByContest = (contestId: string): CurriedSelector<string[]> =>
  createSelector(
    getContestById(contestId),
    getContestInfoByContestId(contestId),
    getRoomTypes,
    getPaidContestsJoined,
    (contest, contestInfo, roomTypes, paidContestsJoined) => {
      if (!contest) {
        return [];
      }
      const joinedRoomTypes = contestInfo?.roomTypeIds ? contestInfo.roomTypeIds : {};

      return contest.roomTypeIds.filter((roomTypeId) => {
        const roomType = roomTypes[roomTypeId];

        if (!roomType || joinedRoomTypes[roomTypeId]) {
          return false;
        }

        if (
          roomType.paidContestsRestriction &&
          ((roomType.paidContestsJoinedMinimum || 0) > paidContestsJoined ||
            (roomType.paidContestsJoinedMaximum || 0) < paidContestsJoined)
        ) {
          return false;
        }

        if (contest.state !== 'UPCOMING' && !roomType.canJoinWhileContestIsOpen) {
          return false;
        }

        return true;
      });
    },
  );

export const getSortedRoomTypeIdsByContest = (contestId: string): CurriedSelector<string[]> =>
  createSelector(
    getRoomTypes,
    getFilteredRoomTypeIdsByContest(contestId),
    (roomTypes, roomTypeIds) => sortRoomtypes(roomTypeIds, roomTypes),
  );

const sortGroupPrizeByRank = (pool: Record<string, GroupPrize>) =>
  Object.values(pool).sort((a, b) => (a.rank > b.rank ? 1 : -1));

export const getGroupPrizesByRoomTypeId = (roomTypeId: string): CurriedSelector<GroupPrizes> =>
  createSelector(getRoomTypeById(roomTypeId), getTicketTypes, (roomType, ticketTypes) => {
    const { dollarCentsPrizePool, dollarCentsPayoutModel, ticketPayoutModel } = roomType;
    const pool: Record<string, GroupPrize> = {};

    if (dollarCentsPrizePool && dollarCentsPayoutModel) {
      const { winnerPercentages = [] } = dollarCentsPayoutModel || {};

      winnerPercentages.forEach((percentage, index) => {
        pool[(index + 1).toString()] = {
          ...(pool[(index + 1).toString()] || {}),
          dollarCents: dollarCentsPrizePool * (percentage / 100),
          rank: index + 1,
        };
      }, pool);
      return sortGroupPrizeByRank(pool);
    }

    if (ticketPayoutModel) {
      Object.entries(ticketPayoutModel.ticketPerRank || {}).forEach(([rank, ticketTypeId]) => {
        pool[rank] = {
          ...(pool[rank] || {}),
          ticket: ticketTypes[ticketTypeId]?.displayName || `Unknown: ${ticketTypeId}`,
          rank: parseInt(rank, 10),
        };
      });
      return sortGroupPrizeByRank(pool);
    }

    return [];
  });

const getRoomTypesTotalPrizePoolDollarCents = (roomTypeId: string): CurriedSelector<number> =>
  createSelector(getRoomTypeById(roomTypeId), (roomType) => {
    const { dollarCentsPrizePool } = roomType;
    return dollarCentsPrizePool || 0;
  });

const getRoomTypesTotalPrizePoolTicketsInDollarCents = (
  roomTypeId: string,
): CurriedSelector<number> =>
  createSelector(getRoomTypeById(roomTypeId), getTicketTypes, (roomType, ticketTypes) => {
    const ticketTypeIds = [...getTicketTypeIdsFromPayoutModel(roomType)].filter(
      (ticketTypeId) => !!ticketTypes[ticketTypeId],
    );

    return ticketTypeIds.reduce(
      (ticketTypesDollarCentsValue, ticketTypeId) =>
        ticketTypesDollarCentsValue + ticketTypes[ticketTypeId].valueDollarCents,
      0,
    );
  });

export const isRealMoneyPrizePoolByRoomTypeIds = (roomTypeId: string): CurriedSelector<boolean> =>
  createSelector(
    getRoomTypesTotalPrizePoolDollarCents(roomTypeId),
    getRoomTypesTotalPrizePoolTicketsInDollarCents(roomTypeId),
    (prizePoolDollarCents, ticketsPrizePoolDollarCents) =>
      prizePoolDollarCents + ticketsPrizePoolDollarCents > 0,
  );

export const getLateStartRoomTypesByRoomTypeIds = (
  roomTypeIds: string[],
): CurriedSelector<string[]> =>
  createSelector(getRoomTypesByRoomTypeIds(roomTypeIds), (roomTypes) =>
    roomTypes
      .filter((roomType) => roomType.canJoinWhileContestIsOpen)
      .map((roomType) => roomType.roomTypeId),
  );
