export enum ContestResultActions {
  ADD_CONTEST_ROOM_RESULTS = '@contestResults/ADD_CONTEST_ROOM_RESULTS',
  ADD_CONTEST_ROOMTYPE_LEADERBOARD_RESULTS = '@contestResults/ADD_CONTEST_ROOMTYPE_LEADERBOARD_RESULTS',
}

export interface ContestRoomResult {
  contestId: string;
  gameId: string;
  roomId?: string;
  roomTypeId: string;
  points: number;
  rank: number;
  dollarCentsWon: number;
  ticketWon: string;
  roomName: string;
}

export type ContestResult = {
  [roomId: string]: ContestRoomResult;
};

export type ContestResultsState = {
  [contestId: string]: ContestResult;
};
