import combineEvents from '@redux-beacon/combine-events';
import { AnalyticsActions } from 'hooks/useAnalytics/types';
import { AppActions } from 'store/app/types';
import { BetActions } from 'store/bets/types';
import { CashBetActions } from 'store/cashBet/types';
import { ContestResultActions } from 'store/contestResults/types';
import { RoomActions } from 'store/rooms/types';
import { UserActions } from 'store/user/types';
import { trackEvent } from '../trackEvent';
import { accountCreated } from './accountCreated';
import { firstLaunch } from './firstLaunch';
import { placeBet } from './placeBet';
import { resultsNextQuarter } from './resultsNextQuarter';
import { roomRegister } from './roomRegister';
import { roomRegisterType } from './roomRegisterType';
import { signInSuccess } from './signInSuccess';

export const appsFlyerEventsMap = {
  [UserActions.SIGN_IN_SUCCESS]: trackEvent(signInSuccess),
  [`${BetActions.PLACE_OR_UPDATE_BET}/fulfilled`]: trackEvent(placeBet),
  [RoomActions.TRACK_JOIN_ROOM]: combineEvents(
    trackEvent(roomRegisterType),
    trackEvent(roomRegister),
  ),
  [AnalyticsActions.CONTEST_ENDED]: trackEvent(resultsNextQuarter),
  [ContestResultActions.ADD_CONTEST_ROOM_RESULTS]: trackEvent(resultsNextQuarter),
  [CashBetActions.ACCOUNT_CREATED]: trackEvent(accountCreated),
  [AppActions.APP_FIRST_LAUNCH]: trackEvent(firstLaunch),
};
