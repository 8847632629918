import { createAction } from '@reduxjs/toolkit';
import { RoomResultsPayloadRoom } from 'hocs/withLongPoll/types/room';
import { RoomTypeLeaderboardResultsPayloadRoomType } from 'hocs/withLongPoll/types/roomType';
import { ContestResultActions } from './types';

export const addContestRoomResults = createAction<RoomResultsPayloadRoom>(
  ContestResultActions.ADD_CONTEST_ROOM_RESULTS,
);

export const addContestRoomTypeLeaderboardResults =
  createAction<RoomTypeLeaderboardResultsPayloadRoomType>(
    ContestResultActions.ADD_CONTEST_ROOMTYPE_LEADERBOARD_RESULTS,
  );
