import styled from 'styled-components';
import { FontFamily } from 'style/types';
import { TicketsWrapper } from 'atoms/Tickets/Tickets.styled';

export const DollarCentsAndTicketsWrapper = styled.div`
  align-items: center;
  display: flex;
  font-family: ${FontFamily.Heading};
  justify-content: space-between;
  line-height: 0;

  > ${TicketsWrapper} {
    margin-left: 0.5em;
  }
`;
