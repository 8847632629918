import React from 'react';
import { FooterItemStyle, IconContainerStyle, LabelStyle } from './FooterItem.styled';

export interface Props {
  badge?: string | number;
  counter?: number;
  label: string;
  icon: React.ReactNode;
  path: string;
  exact?: boolean;
  bounce?: boolean;
  active?: boolean;
}

export const FooterItem: React.FC<Props> = ({
  counter,
  label,
  icon,
  path,
  exact,
  badge,
  bounce = false,
  active = false,
}) => (
  <FooterItemStyle to={path} exact={exact} $bounce={bounce} $active={active}>
    <IconContainerStyle badge={!!badge} gameCount={counter}>
      {!!counter && <span>{counter}</span>}
      {icon}
    </IconContainerStyle>
    <LabelStyle>{label}</LabelStyle>
  </FooterItemStyle>
);
