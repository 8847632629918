import { RoomTypeLeaderboard } from 'services/pttv/api/leaderboards/types';
import { Room, RoomQueueEntry } from 'services/pttv/api/rooms/types';
import { LoginResponse } from 'services/pttv/api/users/types';
import { PttvError } from 'services/pttv/types';
import { Banner } from 'store/banners/types';
import { Bet } from 'store/bets/types';
import { ClientPropertiesState } from 'store/clientProperties/types';
import { RoomTag } from 'store/roomTags/types';
import { Sport } from 'store/sports/types';
import { Team } from 'store/teams/types';
import { WinViewSettingsState } from 'store/winviewSettings/types';

export enum UserActions {
  FETCH_USER_STATE = '@user/FETCH_USER_STATE',
  FETCH_GLOBAL_STATE = '@user/FETCH_GLOBAL_STATE',
  UPDATE_USER = '@user/UPDATE_USER',
  UPDATE_WINCOINS = '@user/UPDATE_WINCOINS',
  UPDATE_PROFILE = '@user/UPDATE_PROFILE',
  UPDATE_EMAIL = '@user/UPDATE_EMAIL',
  UPDATE_DISPLAY_NAME = '@user/UPDATE_DISPLAY_NAME',
  UPDATE_DOLLAR_CENTS = '@user/UPDATE_DOLLAR_CENTS',
  USER_BLOCKED_ERROR = '@user/USER_BLOCKED_ERROR',
  USER_UNAUTHORIZED_ERROR = '@user/USER_UNAUTHORIZED_ERROR',
  CLEAR_USER = '@user/CLEAR_USER',
  FORGOT_PASSWORD = '@user/FORGOT_PASSWORD',
  LINK_FACEBOOK = '@user/LINK_FACEBOOK',
  MULTIPLE_SESSIONS_ERROR = '@user/MULTIPLE_SESSIONS_ERROR',
  REQUEST_USER_VERIFICATION = '@user/REQUEST_USER_VERIFICATION',
  UPDATE_GLOBAL_NOTIFICATIONS = '@user/UPDATE_GLOBAL_NOTIFICATIONS',
  UPDATE_ACTION_NOTIFICATIONS = '@user/UPDATE_ACTION_NOTIFICATIONS',
  TOGGLE_AUTHENTICATION = '@user/TOGGLE_AUTHENTICATION',
  LOGIN_WITH_CASHBET = '@user/LOGIN_WITH_CASHBET',
  SIGN_IN_ERROR = '@user/SIGN_IN_ERROR',
  SIGN_IN_SUCCESS = '@user/SIGN_IN_SUCCESS',
  SIGN_OUT = '@user/SIGN_OUT',
  UPDATE_CLIENT_LOCAL_DATA_STORE = '@user/UPDATE_CLIENT_LOCAL_DATA_STORE',
  TOGGLE_TEAM_NOTIFICATIONS = '@user/TOGGLE_TEAM_NOTIFICATIONS',
  IS_ON_LEADERBOARD_DETAILS = '@user/IS_ON_LEADERBOARD_DETAILS',
  FINISHED_OR_CANCELLLED = '@user/FINISHED_OR_CANCELLLED',
}

export interface ToggleTeamNotificationPayload {
  enabled: boolean;
  teamIds: string[];
}

export interface GlobalStateResponse {
  banners: Record<string, Banner>;
  sports: Sport[];
  teams: Team[];
  clientProperties: ClientPropertiesState;
  winviewSettings: WinViewSettingsState;
  roomTags: RoomTag[];
}

export interface RoomPlayer {
  userId: string;
  inviteCode?: string;
  displayName: string;
  avatarUrl: string;
  points: number;
  hasParticipated?: boolean;
  invested?: number;
  winPercentage?: number;
  subBetWinPercentage?: number;
  dollarCentsWon: number;
  ticketWon: string;
  oneShotUsed?: boolean;
  rank: number;
  streakCount?: number;
  streakBonus?: number;
  sportExperienceRank?: number;
}

export interface CurrentContestInfo {
  gameId: string;
  contestId: string;
  rooms: Room[];
  roomQueueEntries: RoomQueueEntry[];
  points: number;
  invested: number;
  bets: Bet[];
  oneShotUsed: boolean;
  streakCount: number;
  streakBonus: number;
  roomTypeLeaderboards: RoomTypeLeaderboard[];
}

export interface CurrentGameList {
  contests: CurrentContestInfo[];
}

export type LoginWithCashBetArgs = {
  autoLogin: boolean;
  token: string;
  type: string;
  userId: string;
};

export interface RoomTypeLeaderboardState {
  contestId: string;
  roomTypeId: string;
  prizePoolDollarCents: number;
  prizePerRankDollarCents: number[];
}
export interface UserState extends LoginResponse {
  clientDataStore: Record<string, string | number | boolean>;
  dollarCents: number;
  isAuthenticated: boolean;
  isOnLeaderboard: boolean;
  isOnLeaderboardDetails: boolean;
  multipleSessionsError: PttvError | null;
  signInError: PttvError | null;
  signInTimestamp: number | null;
  soundEnabled: boolean;
  firstLogin: boolean;
  teamNotifications: Record<string, boolean>;
  userBlockedError: PttvError | null;
  userUnauthorizedError: PttvError | null;
  wincoins: number;
}
