import { ContestState } from 'services/pttv/api/constants';

export const isScheduled = (state: ContestState): boolean => [ContestState.NEW].includes(state);

export const isLiveOrRegistering = (state: ContestState): boolean =>
  [ContestState.UPCOMING, ContestState.OPEN].includes(state);

export const isStartedAndNotFinished = (state: ContestState): boolean =>
  [ContestState.UPCOMING, ContestState.OPEN, ContestState.FINISHING].includes(state);

export const isEnding = (state: ContestState): boolean => [ContestState.FINISHING].includes(state);

export const isEnded = (state: ContestState): boolean =>
  [ContestState.FINISHING, ContestState.FINISHED, ContestState.CLOSED].includes(state);

export const isArchived = (state: ContestState): boolean =>
  [ContestState.FINISHED, ContestState.CLOSED].includes(state);
