import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'hooks/useDialog';
import { getUiTimer } from 'store/ui/selectors';
import { useAppSelector } from 'store/hooks';
import { isAppVersionChanged } from 'utils/isAppVersionChanged';

export const useAutoUpdater = (): void => {
  const { showAlert } = useDialog();
  const uiTimer = useAppSelector(getUiTimer);
  const { t } = useTranslation('Global');

  const reloadApp = async () => {
    const versionChanged = await isAppVersionChanged();

    if (versionChanged) {
      showAlert(
        'There is a new version available for WinView',
        'Pressing OK mwill reload the screen to update the application',
        {
          closeButtonAction: () => {
            window.location.reload();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (uiTimer % 3 === 0) {
      reloadApp();
      // Going to check for some updates
    }
  }, [uiTimer]);
};
