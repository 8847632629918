export enum AvatarSize {
  XS = '1.5rem', // 24px
  S = '2rem', // 32px
  MS = '2.5rem', // 40px
  M = '2.875rem', // 46px
  ML = '3.625rem', // 58px
  L = '4rem', // 64px
  XL = '4.375rem', // 70px
  XXL = '5.125rem', // 82px
  XXXL = '5.625rem', // 90px
  XXXXL = '6.875rem', // 110px
}
