import styled from 'styled-components';
import { BorderRadius, Colors } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { LeagueCard } from 'atoms/Cards/LeagueCard';

export const JoinedLeagueCardContainer = styled(LeagueCard).attrs({
  role: 'button',
})`
  background-color: ${Colors.ActiveBackground};
  border-radius: ${BorderRadius.Default};

  grid-template-columns: ${pxToRem(16)} auto 1fr auto auto;
  grid-template-areas: 'sporticon avatar description members action';
`;
