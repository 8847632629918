import styled from 'styled-components';
import { Padding } from 'style/types';

export const LeaugeInviteModalContentArea = styled.section`
  align-items: center;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  padding: ${Padding.Default};
`;
