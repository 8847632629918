import { createSelector } from 'reselect';
import { PropositionState } from 'services/pttv/api/constants';
import { Proposition } from 'services/pttv/api/contests/types';
import { getBetByPropositionId, getBets } from 'store/bets/selectors';
import { Bet } from 'store/bets/types';
import { getContestInfo } from 'store/contestInfo/selectors';
import { getContestsByGameId, getGameIdByContestId } from 'store/contests/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import type { PropositionListItem, PropositionsState, StoreProposition } from './types';
import { getPropositionPayout, openPropositionFilter } from './utils';

const getBetDescription = (proposition: Proposition, bet: Bet): string => {
  const answer = proposition.answers.find((a) => a.code === bet.answerCode);

  let description = bet.oneShot
    ? 'One Shot, '
    : `${bet.betPoints.toLocaleString()} Pts, ${answer?.text}`;

  description += answer?.pointsMultiplier ? ` ${answer.pointsMultiplier}x` : '';

  description +=
    (answer?.answerStatistics?.percentage || 0) >= 0 && proposition.state !== PropositionState.OPEN
      ? ` with ${answer?.answerStatistics.percentage}% of the players`
      : '';

  return description;
};

export const getPropositions = (state: RootState): PropositionsState => state.propositions;

export const getFlatPropositions = createSelector(getPropositions, (propositions) =>
  Object.values(propositions),
);

export const getPropositionById = (propositionId: string): CurriedSelector<StoreProposition> =>
  createSelector(getPropositions, (propositions) => propositions[propositionId]);

export const getPropositionsByContestId = (
  contestId: string,
): CurriedSelector<StoreProposition[]> =>
  createSelector(getFlatPropositions, (propositions) =>
    propositions
      .filter(
        (proposition) =>
          proposition.contestId === contestId && proposition.state !== PropositionState.NEW,
      )
      .sort((a, b) => (a.openNumber < b.openNumber ? 1 : -1)),
  );

export const getPropositionListItems = (
  contestId: string,
  propositionState: PropositionState[],
): CurriedSelector<PropositionListItem[]> =>
  createSelector(
    getPropositionsByContestId(contestId),
    getBets,
    (state: RootState) => state,
    (propositions, bets, state) =>
      propositions
        .reverse()
        .filter((proposition) =>
          propositionState.length === 0 ? true : propositionState.includes(proposition.state),
        )
        .map((proposition) => ({
          ...proposition,
          hasBet: !!bets[proposition.propositionId],
          gameId: getGameIdByContestId(contestId)(state) || '',
          betDescription: bets[proposition.propositionId]
            ? getBetDescription(proposition, bets[proposition.propositionId])
            : '',
        })),
  );

export const getPropositionWithBetCount = (
  contestId: string,
  propositionState: PropositionState[],
): CurriedSelector<number> =>
  createSelector(
    getPropositionListItems(contestId, propositionState),
    (propositions) => propositions.filter(({ hasBet }) => hasBet).length,
  );
export const getPropositionResult = (propositionId: string): CurriedSelector<number> =>
  createSelector(
    getPropositionById(propositionId),
    getBetByPropositionId(propositionId),
    (proposition, bet) => getPropositionPayout(proposition, bet),
  );

export const isOpenProposition = (propositionId: string): CurriedSelector<boolean> =>
  createSelector(getPropositionById(propositionId), getBets, (proposition, bets) =>
    proposition ? openPropositionFilter(bets)(proposition) : false,
  );

export const getInvestedByContestId = (contestId: string): CurriedSelector<number> =>
  createSelector(getPropositionsByContestId(contestId), getBets, (propositions, bets) =>
    propositions
      .filter(({ state }) => state !== PropositionState.RESOLVED)
      .reduce<number>((acc, { propositionId }) => {
        const bet = bets[propositionId];
        return bet?.oneShot ? acc : acc + (bet?.betPoints || 0);
      }, 0),
  );

const getOpenPropositionsForJoinedContests = createSelector(
  getFlatPropositions,
  getBets,
  getContestInfo,
  (propositions, bets, contestInfo) =>
    propositions.filter(
      ({ contestId, state, propositionId }) =>
        contestInfo[contestId] && state === PropositionState.OPEN && !bets[propositionId],
    ),
);

export const getOpenPropositionCount = createSelector(
  getOpenPropositionsForJoinedContests,
  (propositions) => propositions.length,
);

export const getOpenPropositionCountByGame = (gameId: string): CurriedSelector<number> =>
  createSelector(
    getOpenPropositionsForJoinedContests,
    getContestsByGameId(gameId),
    (propositions, contests) =>
      propositions.filter(({ contestId }) =>
        contests.find((contest) => contest.contestId === contestId),
      ).length,
  );
