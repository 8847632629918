import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { Friend } from 'services/pttv/api/friends/types';
import { FriendState, LeagueMemberInvited } from 'services/pttv/api/constants';
import { LeagueMember } from 'services/pttv/api/leagues/types';
import { FriendRequestModal } from 'organisms/FriendRequestModal';
import { UserListItemWrapper, UserOnline, UserName, OnlineDot } from '../UserListItem.styled';

interface Props {
  friend: Friend | LeagueMember;
  isUser?: boolean;
  onClick?: () => void;
}

export const FriendListItem: React.FC<Props> = ({ friend, isUser, onClick }) => {
  const { t } = useTranslation('SocialLanding');

  const showInvite = () =>
    (!isUser && (friend as Friend).state === FriendState.PENDING) ||
    (friend as LeagueMember).invite === LeagueMemberInvited.INVITED;
  const isLeagueMember = () => !!(friend as LeagueMember).invite;
  const showOnlineDot = () => !isUser && !!friend.currentContestId;
  const showOnlineText = () => !isUser && !!friend.currentContestId && !showInvite();

  return (
    <UserListItemWrapper
      isUser={isUser}
      pending={showInvite()}
      onClick={!showInvite() ? onClick : undefined}
    >
      {showOnlineDot() && <OnlineDot />}
      <Avatar src={friend.avatarUrl} size={AvatarSize.MS} />
      <UserName pending={showInvite()}>{friend.displayName}</UserName>
      {showOnlineText() && <UserOnline>{t('friendList.item.online')}</UserOnline>}
      {showInvite() && !isLeagueMember() && (
        <FriendRequestModal
          title={t('friendList.itemModal.title')}
          userId={friend.userId}
          buttonText={t('friendList.itemModal.viewButton')}
        />
      )}
    </UserListItemWrapper>
  );
};
