import styled from 'styled-components';
import { Colors, FontSize } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const NoResultsContainer = styled.section`
  text-align: center;
`;

export const MessageContent = styled.section`
  font-size: ${FontSize.S};
  color: ${Colors.TextSub};
`;

export const MessageHeader = styled.header`
  display: flex;
  flex-direction: column;

  svg {
    height: ${pxToRem(48)};
    color: ${Colors.Primary};
  }
`;
