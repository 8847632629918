import React from 'react';
import styled from 'styled-components';
import TicketsIcon from 'assets/icons/TicketsIcon.svg';
import { TicketType } from 'services/pttv/api/tickets/types';
import { BorderWidth, Colors, Padding } from 'style/types';

interface Props {
  amount: number;
  ticketType: TicketType;
}

export const MissingTicketType = styled.section.attrs<Props>(({ amount, ticketType }) => ({
  children: (
    <>
      <TicketsIcon />
      {` ${amount} x ${ticketType.displayName}`}
    </>
  ),
}))<Props>`
  align-items: center;
  border-color: ${Colors.Black75};
  border-style: solid;
  border-width: ${BorderWidth.Default} 0;
  display: flex;
  margin-top: 1rem;
  padding: ${Padding.XS};

  + section {
    border-width: 0 0 ${BorderWidth.Default};
    margin-top: 0rem;
  }

  > svg {
    height: 1.5rem;
    margin-right: 0.5rem;
  }
`;
