import Joi from 'joi';
import { getContestById } from 'store/contests/selectors';
import { getGameByContestId, getGameShortName } from 'store/games/selectors';
import { StoreProducerMessage } from 'store/producerMessage/types';
import { getSportByContestId } from 'store/sports/selectors';
import { SegmentEvent } from '../types';

interface StoreProducerAction {
  payload: StoreProducerMessage;
}

export const producerMessageSubmitted: SegmentEvent = (action, prevState, nextState) => {
  const { payload } = action as unknown as StoreProducerAction;
  const contest = getContestById(payload.contestId)(nextState);
  const game = getGameByContestId(payload.contestId)(nextState);
  const sport = getSportByContestId(payload.contestId)(nextState);
  const gameName = getGameShortName(game?.gameId || '')(nextState);

  return {
    name: 'producer_message_submitted',
    properties: {
      producer_message: payload.text,
      game_name: gameName,
      game_id: game?.gameId,
      game_sport: sport?.abbreviation,
      game_period: contest.period,
    },
  };
};

const producerMessageSubmittedEventSchema = Joi.object({
  producer_message: Joi.string().required(),
  game_name: Joi.string().required(),
  game_id: Joi.string().required(),
  game_sport: Joi.string().required(),
  game_period: Joi.number().integer().required(),
});

export const isValidProducerMessageSubmittedEvent = (
  event: Record<string, string | number>,
): boolean => {
  const result = producerMessageSubmittedEventSchema.validate(event.properties);
  return !result.error;
};
