import { playToTv } from 'services/pttv';
import { EmptyResponse } from '../types';
import { FeedbackRequest } from './types';

export const FeedbackAPI = {
  sendFeedback: (request: FeedbackRequest): Promise<EmptyResponse> =>
    playToTv.post<EmptyResponse, FeedbackRequest>(`winview/feedback`, request),
  sendUnauthenticatedFeedback: (request: FeedbackRequest): Promise<EmptyResponse> =>
    playToTv.post<EmptyResponse, FeedbackRequest>(`winview/feedback/notAuthenticated`, request),
};
