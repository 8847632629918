import { clamp } from 'popmotion';
import React from 'react';
import { Colors } from 'style/types';
import { CircularProgressContent, CircularProgressWrapper } from './CircularProgress.styled';

interface Props {
  size: number;
  min: number;
  max: number;
  value: number;
  trackWidth: number;
}

export const CircularProgress: React.FC<Props> = ({
  size,
  min,
  max,
  value,
  trackWidth,
  children,
}) => {
  const radius = 25;
  const strokeLength = (radius - trackWidth / 2) * 2 * Math.PI;
  const trimedValue = clamp(min, max, value);
  const progressLength = strokeLength * (1 - (trimedValue - min) / (max - min));

  return (
    <CircularProgressWrapper $size={size}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 50 50"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle fill={Colors.Black} r={25} cx={25} cy={25} strokeWidth="0" />
        <circle
          r={radius - trackWidth / 2}
          cx={radius}
          cy={radius}
          fill="transparent"
          strokeWidth={trackWidth}
          stroke={Colors.Black80}
        />
        <circle
          r={radius - trackWidth / 2}
          cx={radius}
          cy={radius}
          fill="transparent"
          strokeDasharray={strokeLength}
          strokeDashoffset={progressLength}
          strokeWidth={trackWidth}
          stroke={Colors.Secondary}
        />
      </svg>
      <CircularProgressContent>{children}</CircularProgressContent>
    </CircularProgressWrapper>
  );
};
