import { AnswerCode } from 'services/pttv/api/constants';
import { PropositionBetResult } from 'store/propositions/types';

export enum ToastActions {
  ADD_TOAST = '@toast/ADD_TOAST',
  ADD_TEXT_TOAST = '@toast/ADD_TEXT_TOAST',
  REMOVE_TOAST = '@toast/REMOVE_TOAST',
  CLEAR_TOAST = '@toast/CLEAR_TOAST',
}

export enum ToastType {
  Default = 'default',
  PropositionResult = 'proposition_result',
  Extended = 'extended',
  Common = 'common',
}

export interface PropositionToastArgs {
  proposition: {
    description: string;
    answer: AnswerCode;
    points: number;
    result: PropositionBetResult;
  };
}

export interface PropositionToast extends PropositionToastArgs {
  id: string;
  type: ToastType;
}

export interface ToastPayload extends Record<string, unknown> {
  type?: ToastType;
  to?: string;
  avatarUrl?: string;
  isWarning?: boolean;
  message?: string;
  messageValues?: Record<string, string>;
}

export interface Toast extends ToastPayload {
  id: string;
}

export type ToastsState = Array<Toast | PropositionToast>;
