import { playToTv } from 'services/pttv';
import { GetRoomTypeLeaderboardRequest, GetRoomTypeLeaderboardResponse } from './types';

export const LeaderboardAPI = {
  getRoomTypeLeaderboard: ({
    contestId,
    roomTypeId,
  }: GetRoomTypeLeaderboardRequest): Promise<GetRoomTypeLeaderboardResponse> =>
    playToTv.get<GetRoomTypeLeaderboardResponse>(
      `winview/leaderboard/contest/${contestId}/roomtype/${roomTypeId}`,
    ),
};
