import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'hooks/useDialog';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  checkSufficientCashToRebuy,
  checkSufficientTicketsToRebuy,
  getNextContestRebuyInfo,
  getRebuyContest,
} from 'store/contests/selectors';
import { getSportByContestId } from 'store/sports/selectors';
import { getLateStartRoomTypesByRoomTypeIds } from 'store/roomTypes/selectors';
import { getTicketTypes } from 'store/ticketTypes/selectors';
import { RoomTotalEntry } from 'molecules/RoomTotalEntry';
import { RebuyInfo } from 'store/contests/types';
import wrapperLib from 'services/wrapper';
import { Coordinates } from 'services/wrapper/types';
import { debugLog } from 'utils/log';
import { joinContestAndRoom } from 'store/rooms/actions';
import { formatCurrency } from 'utils/formatters';
import { useMessages } from 'hooks/useMessages';
import { addTextToast } from 'store/toast/actions';
import { MissingTicketType } from './RebuyButton.styled';

interface Props {
  contestId: string;
  onClose: () => void;
  closeParentModal?: () => void;
}

const convertRebuyTicketsToArray = (rebuyInfo: RebuyInfo): string[] =>
  Object.entries(rebuyInfo?.tickets || {}).reduce<string[]>(
    (acc, [ticketTypeId, count]) => acc.concat(Array(count).fill(ticketTypeId)),
    [],
  );

export const RebuyButton: React.FC<Props> = ({ contestId, onClose, closeParentModal }) => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const ticketTypes = useAppSelector(getTicketTypes);
  const rebuyContest = useAppSelector(getRebuyContest(contestId));
  const sport = useAppSelector(getSportByContestId(contestId));
  const rebuyInfo = useAppSelector(getNextContestRebuyInfo(contestId));
  const hasSufficientCashToRebuy = useAppSelector(checkSufficientCashToRebuy(rebuyInfo));
  const hasSufficientTicketsToRebuy = useAppSelector(checkSufficientTicketsToRebuy(rebuyInfo));
  const { errorMessage, successMessage } = useMessages();
  const lateStartRoomTypes = useAppSelector(
    getLateStartRoomTypesByRoomTypeIds(rebuyInfo?.roomTypeIds || []),
  );

  let loading = false;

  const { t } = useTranslation('RebuyButton');
  const { showConfirm, showAlert } = useDialog();

  const isRebuyFree = () => {
    const ticketIdsToRebuy = convertRebuyTicketsToArray(rebuyInfo);
    return !rebuyInfo.dollarCents && !ticketIdsToRebuy.length;
  };

  const getButtonText = (): string => {
    let costsText = '';
    if (isRebuyFree()) {
      return t('buttonText.free');
    }
    costsText += rebuyInfo.dollarCents > 0 ? formatCurrency(rebuyInfo.dollarCents / 100) : '';
    const ticketCount = Object.values(rebuyInfo.tickets).reduce((sum, amount) => sum + amount, 0);
    const ticketText = t('buttonText.paid.tickets', { count: ticketCount });
    costsText += rebuyInfo.dollarCents && ticketCount > 0 ? ' & ' : '';
    costsText += ticketCount > 0 ? `${ticketCount} ${ticketText}` : '';

    return t('buttonText.paid.main', { costsText });
  };

  const rebuyRoomsSuccess = () => {
    loading = false;

    if (rebuyContest) {
      push(`/games/${rebuyContest.gameId}/contests/${rebuyContest.contestId}`);
    }

    if (onClose) {
      onClose();
    }
  };

  const rebuyRooms = async (coordinates: Coordinates) => {
    await dispatch(
      joinContestAndRoom({
        request: {
          contestId: rebuyContest?.contestId || '',
          roomTypeIdsToPaymentMethodMap: rebuyInfo.roomTypeIdsToPaymentMethodMap,
          geoPosition: coordinates.coords,
        },
        errorMessage,
        successMessage,
      }),
    );
    rebuyRoomsSuccess();
  };

  const rebuyRoomsAction = async () => {
    let coordinates: Coordinates = { coords: { latitude: null, longitude: null, accuracy: null } };
    if (!rebuyContest) {
      return;
    }

    loading = true;

    // We do not need any location service check for free games
    if (isRebuyFree()) {
      rebuyRooms(coordinates);
      return;
    }

    try {
      await wrapperLib.isLocationServiceEnabled();
      const coords = await wrapperLib.getCurrentCoordinates();
      if (coords) {
        coordinates = coords;
      }
      rebuyRooms(coordinates);
    } catch (e) {
      debugLog('[RebuyButton]', 'Location Service disabled or unavailable');
      dispatch(
        addTextToast({
          message: t('rebuyError'),
          isWarning: true,
        }),
      );
    }
  };

  const showInsufficientCashAlert = () => {
    showConfirm(t('insufficientCashAlert.title'), t('insufficientCashAlert.description'), {
      closeButtonText: t('insufficientCashAlert.closeButtonText'),
      confirmButtonText: t('insufficientCashAlert.confirmButtonText'),
      confirmButtonAction: () => push('/profile/deposit'),
    });
  };

  const showInsufficientTicketsAlert = () => {
    const totalTickets = Object.values(rebuyInfo.tickets).reduce((sum, amount) => sum + amount, 0);
    showAlert(
      t('insufficientTicketsAlert.title'),
      <>
        {t('insufficientTicketsAlert.description', { count: totalTickets })}
        {Object.entries(rebuyInfo.tickets).map(([ticketTypeId, amount]) => (
          <MissingTicketType
            key={ticketTypeId}
            ticketType={ticketTypes[ticketTypeId]}
            amount={amount}
          />
        ))}
      </>,
      {
        closeButtonText: t('insufficientTicketsAlert.closeButtonText'),
      },
    );
  };

  const closeRebuyConfirmAction = () => {
    if (loading && closeParentModal) {
      closeParentModal();
    }
  };

  const showRebuyConfirm = () => {
    showConfirm(
      t('rebuyConfirm.title'),
      <>
        {t('rebuyConfirm.description')}
        {!isRebuyFree() && (
          <RoomTotalEntry
            title={t('rebuyConfirm.buyinTitle')}
            totalDollarCents={rebuyInfo.dollarCents}
            roomTypeIds={rebuyInfo.roomTypeIds}
            ticketsToBuyInIds={convertRebuyTicketsToArray(rebuyInfo)}
          />
        )}
      </>,
      {
        closeButtonAction: closeRebuyConfirmAction,
        confirmButtonAction: rebuyRoomsAction,
        confirmButtonText: t('rebuyConfirm.confirmButton'),
        closeButtonText: t('rebuyConfirm.cancelButton'),
        disableButtons: true,
      },
    );
  };

  const showGameAlreadyStartedAlert = () => {
    showAlert(
      t('gameAlreadyStartedAlert.title'),
      t('gameAlreadyStartedAlert.description', { nextPeriodName: sport?.period }),
      {
        closeButtonText: t('gameAlreadyStartedAlert.closeButtonText'),
        closeButtonAction: () => push('/'),
      },
    );
  };

  const checkRebuy = () => {
    const canJoinRooms = !(
      rebuyContest &&
      rebuyContest.state === 'OPEN' &&
      lateStartRoomTypes.length === 0
    );

    if (!hasSufficientCashToRebuy) {
      showInsufficientCashAlert();
    } else if (!hasSufficientTicketsToRebuy) {
      showInsufficientTicketsAlert();
    } else if (canJoinRooms) {
      showRebuyConfirm();
    } else {
      showGameAlreadyStartedAlert();
    }
  };

  return (
    <Button scheme={ButtonSchemes.Primary} onClick={checkRebuy} size={ButtonSize.Wide}>
      {getButtonText()}
    </Button>
  );
};
