import React from 'react';
import { useAppSelector } from 'store/hooks';
import { isUserGuest } from 'store/user/selectors';
import { AutoLoginModal } from './AutoLoginModal';
import { UnauthorizedModal } from './UnauthorizedModal';
import { UnauthorizedErrorType, UnauthorizedType } from './UnauthorizedModal/types';

export const UserErrorMessage: React.FC = () => {
  const cashBetError = useAppSelector((state) => state.cashBet.error);
  const multipleSessionsError = useAppSelector((state) => state.user.multipleSessionsError);
  const signInError = useAppSelector((state) => state.user.signInError);
  const userBlockedError = useAppSelector((state) => state.user.userBlockedError);
  const userUnauthorizedError = useAppSelector((state) => state.user.userUnauthorizedError);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isGuest = useAppSelector(isUserGuest);

  const error =
    cashBetError ||
    multipleSessionsError ||
    userBlockedError ||
    signInError ||
    userUnauthorizedError;
  const ignoreMultipleSessionsError = multipleSessionsError && !isAuthenticated; // User is already logged out so no need to show the error (sometimes a request returns multi sessions error when users logs out).
  const showAutoLoginModal = isGuest && !!(userUnauthorizedError || cashBetError); // Only show auto login dialog for guest users and when it's authorize errors.
  const showUnauthorizedModal = !!(
    !showAutoLoginModal &&
    error?.type &&
    !ignoreMultipleSessionsError
  );

  let unauthorizedErrorType!: UnauthorizedErrorType;
  let message!: string;
  if (error?.type) {
    const { type } = error;
    message = error.message;
    unauthorizedErrorType = type;

    // Blocked users have error code 'UNAUTHORIZED'
    if (userUnauthorizedError) {
      unauthorizedErrorType = UnauthorizedType.REALLY_UNAUTHORIZED;
    } else if (message === 'autologin failed') {
      // Cash bet doesn't provide an error type so have to check on the message...
      unauthorizedErrorType = UnauthorizedType.AUTOLOGIN_FAILED;
    }
  }

  return (
    <>
      <AutoLoginModal isOpen={showAutoLoginModal} />
      <UnauthorizedModal
        isOpen={showUnauthorizedModal}
        isUserBlocked={!!userBlockedError}
        message={message}
        type={unauthorizedErrorType}
      />
    </>
  );
};
