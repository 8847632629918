import { createReducer } from '@reduxjs/toolkit';
import { fetchUserState } from '../user/actions';
import { addActiveRoomTypes, fetchRoomTypeDetails, updateRoomType } from './actions';
import { RoomTypeState } from './types';

export const initialState = {} as RoomTypeState;

export const roomTypesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) =>
      payload.roomTypes.reduce<RoomTypeState>(
        (acc, roomType) => ({
          ...acc,
          [roomType.roomTypeId]: roomType,
        }),
        {},
      ),
    )
    .addCase(updateRoomType, (state, { payload }) => {
      state[payload.roomTypeId] = payload;
    })
    .addCase(fetchRoomTypeDetails.fulfilled, (state, { payload }) => {
      const { roomTypeId, ...rest } = payload;
      state[roomTypeId] = { ...state[roomTypeId], ...rest };
    })
    .addCase(addActiveRoomTypes, (state, { payload }) => {
      payload.forEach((roomType) => {
        state[roomType.roomTypeId] = {
          ...(state[roomType.roomTypeId] || {}),
          ...roomType,
        };
      });
    });
});
