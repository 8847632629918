import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeagueMemberInvited } from 'services/pttv/api/constants';
import { LeagueMember } from 'services/pttv/api/leagues/types';
import { LeagueMemberModal } from 'organisms/LeagueMemberModal';
import { useAppDispatch } from 'store/hooks';
import { requestFetchPlayer } from 'store/players/actions';
import { LeagueMemberListItem as MemberListItem } from 'molecules/UserListItem';

interface Props {
  member: LeagueMember;
  leagueName: string;
}

export const LeagueMemberListItem: React.FC<Props> = ({ member, leagueName }) => {
  const { t } = useTranslation('SocialLanding');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(requestFetchPlayer(member.userId));
  }, []);

  const showFriendInfo = () => {
    setIsModalOpen(true);
  };

  const hideFriendInfo = () => {
    setIsModalOpen(false);
  };

  const getModalTitle = (): string => {
    if (member.invite === LeagueMemberInvited.ACCEPTED) {
      return t('memberList.memberModal.title');
    }
    return t('memberList.sentInviteModal.title');
  };

  return (
    <>
      <MemberListItem member={member} onClick={showFriendInfo} />
      <LeagueMemberModal
        member={member}
        leagueName={leagueName}
        title={getModalTitle()}
        isModalOpen={isModalOpen}
        handleCloseButtonClick={hideFriendInfo}
        reportPlayerCallback={hideFriendInfo}
      />
    </>
  );
};
