export enum FacebookActions {
  FACEBOOK_GET_FRIENDS = '@facebook/GET_FRIENDS',
  FACEBOOK_GET_PERMISSION = '@facebook/GET_PERMISSION',
  FACEBOOK_SIGN_IN = '@facebook/SIGN_IN',
}

export interface FacebookPermission {
  permission: string;
  status: 'granted' | 'declined';
}

export interface FacebookGetFriendsResult {
  data: { id: string }[];
}

export interface FacebookGetPermissionResult {
  data: FacebookPermission[];
}

export interface FacebookState {
  friendIds: string[];
  token: string | null;
}
