export enum ClientData {
  FirstLoadScheduleView = 'first-load-schedule-view-dialog',
  ContestProTipHidden = 'contest-pro-tip-hidden',
}

export enum AccountType {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  GUEST = 'GUEST',
}

export enum ClientType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export enum ReportType {
  CHAT = 'CHAT',
  LEADERBOARD = 'LEADERBOARD',
  FRIENDS = 'FRIENDS',
  FRIEND_LEADERBOARD = 'FRIEND_LEADERBOARD',
  LEAGUE = 'LEAGUE',
  LEAGUE_CHAT = 'LEAGUE_CHAT',
}

export enum ReportReason {
  OTHER = 'OTHER',
  CHEATING = 'CHEATING',
  HARASSMENT = 'HARASSMENT',
  INAPPROPRIATE_NAME_OR_AVATAR = 'INAPPROPRIATE_NAME_OR_AVATAR',
  SPAMMING = 'SPAMMING',
}

export interface LoginWithCashBetRequest {
  cbData: {
    userId: string;
    token: string;
  };
  deviceId: string;
  deviceToken: string;
  endpointArn: string;
  clientType: ClientType;
  externalHooks: Record<string, unknown>;
}

export interface User {
  userId: string;
  autoSubscribeToGames: boolean;
  avatarUrl: string;
  affiliatedTeams: Record<string, string>;
  displayName: string;
  facebookId: string;
  email: string;
  clientLogicState: {
    hasPassword: boolean;
    hasEmail: boolean;
    hasVerifiedEmail: boolean;
    hasFacebook: boolean;
    redeemAllowed: boolean;
    anyNewEmailMustBeVerified: boolean;
    kycPass: boolean;
    kycInfo: boolean;
    geoVerifyRuleSet: number;
  };
  actionNotificationsEnabled: boolean;
  notificationsEnabled: boolean;
  inviteCode: string;
  clientDataStore: Record<string, string | number | boolean>;
  isTestUser: boolean;
  experienceToNextRank: number;
  rank: number;
  percentageToNextRank: number;
  sportExperience: SportExperience[];
}

export interface LoginResponse extends User {
  playerId: number;
  sessionKey: string;
  sessionExpiration: number;
  authToken: string;
  accountType: AccountType;
  dollarCents: number;
  tickets: Record<string, number>;
  organic: boolean;
  campaignId: string;
  mediaSource: string;
  paidContestsJoined: number;
  registrationDate: number;
}

export interface SportExperience {
  sportId: string;
  rank: number;
  experience: number;
  percentageToNextRank: number;
}

export interface ReportUserRequest {
  type: ReportType;
  reason: ReportReason;
  reportedUserId: string;
}

export interface FoundUser {
  userId: string;
  inviteCode: string;
  displayName: string;
  avatarUrl: string;
}

export interface UpdateProfileRequest {
  affiliatedSportId: string;
  affiliatedTeamId: string | null;
}

export interface UpdateActionNotificationsRequest {
  enabled: boolean;
}

export type UpdateGlobalNotificationsRequest = UpdateActionNotificationsRequest;

export interface UpdateEmailRequest {
  email: string;
  password?: string;
}

export interface UpdateDisplayNameRequest {
  displayName: string;
}

export type ClientDataStore = {
  [key in ClientData]?: string | number | boolean;
};

export interface UpdateClientDataRequest {
  clientDataStore: ClientDataStore;
}

export interface ForgotPasswordRequest {
  email: string;
}

export interface LinkFacebookRequest {
  accessToken: string;
}
