const FACEBOOK_APP_VERSION = 'v2.11';

export const initFacebookSDK = (): Promise<void> => {
  const script = document.createElement('script');
  script.id = 'facebook-web-sdk';
  script.src = '//connect.facebook.net/en_US/sdk.js';
  script.async = true;

  document.body.appendChild(script);

  return new Promise((resolve) => {
    const appId = window.emconfig.settings.facebookId;

    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: FACEBOOK_APP_VERSION,
      });

      resolve();
    };
  });
};
