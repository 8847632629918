import { RoomTypeLeaderboard } from 'services/pttv/api/leaderboards/types';
import { RoomQueueEntry, RoomTypeLeaderboardUpdate, RoomType } from 'services/pttv/api/rooms/types';

export enum ContestInfoActions {
  UPDATE_CONTEST_INFO = '@contestInfo/UPDATE_CONTEST_INFO',
  ADD_ROOM = '@contestInfo/ADD_ROOM',
  ROOM_CANCELLED = '@contestInfo/ROOM_CANCELLED',
  ONE_SHOT_USED = '@contestInfo/ONE_SHOT_USED',
  CLUTCH_BONUS_USED = '@contestInfo/CLUTCH_BONUS_USED',
  UPDATE_POINTS = '@contestInfo/UPDATE_POINTS',
  CONTEST_ENDED = '@contestInfo/CONTEST_ENDED',
  REMOVE_CONTEST_INFO = '@contestInfo/REMOVE_CONTEST_INFO',
  CONTEST_CANCELLED = '@contestInfo/CONTEST_CANCELLED',
}

export type UpdatePointsPayload = {
  contestId: string;
  points: number;
};

export interface OneShotUsedArgs {
  contestId: string;
  oneShotUsed: boolean;
}

export type OneShotUsedPayload = OneShotUsedArgs | null;

export interface ContestCancelledArgs {
  contestId: string;
  cancelledReason?: string;
  version?: number;
  unreadMessageId?: string;
  roomTypeIds?: string[];
}

export interface ContestCancelledResult {
  contestId: string;
  cancelledReason?: string;
  version?: number;
  unreadMessageId?: string;
  roomTypeIds?: string[];
  unregisteredRoomTypes: RoomType[];
}

export interface RoomCancelledPayload {
  contestId: string;
  roomTypeId: string;
  roomQueueId: string;
  unreadMessageId: string;
  stayOnPage?: boolean;
}

export interface ContestInfo {
  contestId: string;
  points: number;
  invested: number;
  roomQueueEntries: RoomQueueEntry[];
  roomTypeLeaderboardUpdates?: RoomTypeLeaderboardUpdate[];
  ticketIdsPerRoomType?: Record<string, string>;
  gameId: string;
  latitude?: number | null;
  longitude?: number | null;
  accuracy?: number | null;
  ip?: string;
  streakCount?: number;
  streakBonus?: number;
  oneShotUsed?: boolean;
  rooms?: string[];
  roomTypeLeaderboards: RoomTypeLeaderboard[];
  roomTypeIds: Record<string, boolean>;
  state?: string;
}

export type ContestInfoState = Record<string, ContestInfo>;
