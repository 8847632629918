import styled from 'styled-components';
import { ButtonBarContainer } from 'atoms/ButtonBar/buttonBar.styled';
import { Colors, FontSize, Gap, Padding } from 'style/types';
import { Divider } from 'atoms/Divider';
import { DividerLabel, StyledDivider } from 'atoms/Divider/Divider.styled';

export const LeagueMemberListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ButtonBar = styled(ButtonBarContainer)`
  margin-top: ${Gap.ML};
  padding: ${Padding.Default};
`;

export const ModalDescription = styled.p`
  text-align: center;
  margin-bottom: 2rem;
`;

export const Period = styled.span`
  color: ${Colors.Black30};
  font-size: ${FontSize.S};
`;

export const ContestDivider = styled(Divider)`
  > ${DividerLabel} {
    flex: 1;

    > img {
      margin-right: ${Gap.S};
    }
  }

  ~ ${StyledDivider} {
    margin-top: ${Gap.L};
  }
`;
