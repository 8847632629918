import React from 'react';
import { clamp } from 'popmotion';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { useAppSelector } from 'store/hooks';
import { getUser } from 'store/user/selectors';
import { CircularProgress } from 'atoms/CircularProgress';
import { ProfileIconContainer } from './profileIcon.styled';

const ProfileIcon: React.FC = () => {
  const { avatarUrl, rank, percentageToNextRank } = useAppSelector(getUser);
  const { unseenTicketsCount } = useAppSelector((state) => state.userMeta);

  const xpLevelProgress = clamp(0, 100, percentageToNextRank || 0);

  return (
    <ProfileIconContainer
      to={{ pathname: '/profile', state: { prevPath: window.location.pathname } }}
      $showBadge={!!unseenTicketsCount}
    >
      <Avatar size={AvatarSize.MS} src={avatarUrl} border />
      <CircularProgress size={25} min={0} max={100} value={xpLevelProgress} trackWidth={5}>
        {rank}
      </CircularProgress>
    </ProfileIconContainer>
  );
};

export default ProfileIcon;
