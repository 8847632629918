import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ContestState, GameType } from 'services/pttv/api/constants';
import { UnreadMessagesAPI } from 'services/pttv/api/unreadMessages';
import { getContestInfoByContestId } from 'store/contestInfo/selectors';
import { ContestChangedEventPayloadContest } from 'hocs/withLongPoll/types/contest';
import type { RootState } from 'store/types';
import { PttvError } from 'services/pttv/types';
import { setUnreadMessageToRead } from 'store/unreadMessages/actions';
import { ModalArgs, ModalActions, ModalType, AddModalsFromUnreadMessagesArgs } from './types';

export const addModal = createAction(ModalActions.ADD_MODAL, (modal: ModalArgs) => {
  if (modal.unreadMessageId) {
    UnreadMessagesAPI.setMessageToRead(modal.unreadMessageId);
  }
  return {
    payload: modal,
  };
});

export const removeModal = createAction<string>(ModalActions.REMOVE_MODAL);
export const clearModals = createAction(ModalActions.CLEAR_MODALS);

export const addModalFromContestUpdatedEvent = createAsyncThunk<
  void,
  ContestChangedEventPayloadContest
>(ModalActions.ADD_MODAL_FROM_CONTEST, (contest, { dispatch, getState }) => {
  const state = getState() as RootState;
  const contestJoined = getContestInfoByContestId(contest.contestId)(state);
  const isPreGame = state.games[contest.gameId].gameType === GameType.PRESHOW;

  if (!contestJoined) {
    return;
  }

  switch (contest.state) {
    case ContestState.FINISHING:
      if (!isPreGame) {
        dispatch(
          addModal({
            type: ModalType.ContestFinishing,
            id: contest.contestId,
            expirationTime: Date.now(),
          }),
        );
      }
      break;

    case ContestState.CLOSED:
      dispatch(
        addModal({
          type: ModalType.ContestFinished,
          id: contest.contestId,
          expirationTime: Date.now(),
        }),
      );
      break;

    default:
  }
});

export const addServerFullModal = createAction<PttvError>(ModalActions.ADD_SERVER_FULL_MESSAGE);
export const addServerMaintenanceModal = createAction<PttvError>(
  ModalActions.ADD_SERVER_MAINTENANCE_MESSAGE,
);

export const addModalsFromUnreadMessages = createAsyncThunk<void, AddModalsFromUnreadMessagesArgs>(
  ModalActions.ADD_MODAL_FROM_UNREAD_MESSAGES,
  ({ previousState, unreadMessages }, { dispatch }) => {
    const { contestInfo } = previousState;
    const currentTimestamp = Date.now();

    unreadMessages.forEach((unreadMessage) => {
      if (currentTimestamp >= unreadMessage.expirationTime) {
        dispatch(setUnreadMessageToRead(unreadMessage.id));
        return;
      }

      if (
        [ModalType.ContestFinished, ModalType.ContestCancelled, ModalType.RoomCancelled].includes(
          unreadMessage.type,
        ) &&
        !contestInfo[unreadMessage.fields.contestId as string]
      ) {
        dispatch(setUnreadMessageToRead(unreadMessage.id));
        return;
      }

      dispatch(
        addModal({
          id: unreadMessage.fields.contestId as string,
          type: unreadMessage.type,
          expirationTime: unreadMessage.expirationTime,
          unreadMessageId: unreadMessage.id,
          payload: unreadMessage.fields as Record<string, string | number | boolean>,
        }),
      );
    });
  },
);
