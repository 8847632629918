import { createSelector } from 'reselect';
import { RoomType } from 'services/pttv/api/rooms/types';
import type { CurriedSelector, RootState } from 'store/types';
import { ContestMeta } from './types';

const getContestMeta = (state: RootState) => state.contestMeta;

export const getContestMetaByContestId = (contestId: string): CurriedSelector<ContestMeta> =>
  createSelector(getContestMeta, (contestMeta) => contestMeta[contestId] || {});

export const getContestInfoUnregisteredRoomTypes = (
  contestId: string,
): CurriedSelector<RoomType[]> =>
  createSelector(getContestMetaByContestId(contestId), (contestMeta) =>
    contestMeta ? contestMeta.unregisteredRoomTypes : [],
  );
