import { createSelector } from 'reselect';
import { PaymentMethod } from 'services/pttv/api/constants';
import {
  getRoomTypes,
  getRoomTypeById,
  isRealMoneyPrizePoolByRoomTypeIds,
} from 'store/roomTypes/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import { isUserGuest, getUserTickets, getUserDollarCents } from 'store/user/selectors';
import type {
  RoomSelectionState,
  SelectedRoomType,
  SelectionInvestment,
  ServerPayloadContest,
} from './types';

export const getRoomSelection = (state: RootState): RoomSelectionState => state.roomSelection;
export const getFlatRoomSelection = createSelector(getRoomSelection, (roomSelection) =>
  Object.values(roomSelection).flat(),
);

export const getTotalRoomTypesTickets = createSelector(
  getRoomSelection,
  getRoomTypes,
  (roomSelection, roomTypes) =>
    Object.values(roomSelection)
      .flat()
      .reduce((acc, selectedRoomType) => {
        const roomType = roomTypes[selectedRoomType.roomTypeId];
        if (roomType.ticketPayoutModel) {
          return acc + Object.values(roomType.ticketPayoutModel?.ticketPerRank || {}).length;
        }
        return acc;
      }, 0),
);

export const getTotalRoomTypesDollarCentsCost = createSelector(
  getFlatRoomSelection,
  getRoomTypes,
  (roomSelection, roomTypes) =>
    roomSelection.reduce((acc, selectedRoomType) => {
      const roomType = roomTypes[selectedRoomType.roomTypeId];
      return acc + (roomType.dollarCentsBuyIn || 0);
    }, 0),
);

export const getSelectedRoomsCount = createSelector(
  getFlatRoomSelection,
  (roomSelection) => roomSelection.length,
);

export const isRoomTypeSelected = ({
  contestId,
  roomTypeId,
}: Record<string, string>): CurriedSelector<boolean> =>
  createSelector(getRoomSelection, (roomSelection) => {
    if (roomSelection[contestId]) {
      return (
        roomSelection[contestId].findIndex((selection) => selection.roomTypeId === roomTypeId) !==
        -1
      );
    }
    return false;
  });

export const getDetailedSelectionInvestment = createSelector(
  getFlatRoomSelection,
  (roomSelection) =>
    roomSelection.reduce<SelectionInvestment>(
      (acc, selection) => ({
        roomTypeTotalDollarCents:
          acc.roomTypeTotalDollarCents + (selection.buyInMethod.dollarCents || 0),
        roomTypeTotalTickets: selection.buyInMethod.ticket
          ? [...acc.roomTypeTotalTickets, selection.buyInMethod.ticket]
          : acc.roomTypeTotalTickets,
      }),
      {
        roomTypeTotalDollarCents: 0,
        roomTypeTotalTickets: [],
      },
    ),
);

const canDepositTicketEntry = (roomTypeId: string): CurriedSelector<boolean> =>
  createSelector(getRoomTypeById(roomTypeId), isUserGuest, (roomType, isGuest) => {
    const { ticketBuyIn, dollarCentsBuyIn = 0 } = roomType || {};

    return !isGuest && !!ticketBuyIn && dollarCentsBuyIn > 0;
  });

export const canJoinRoomWithTickets = (
  contestId: string,
  roomTypeId: string,
): CurriedSelector<boolean> =>
  createSelector(
    getUserTickets,
    getFlatRoomSelection,
    getRoomTypeById(roomTypeId),
    canDepositTicketEntry(roomTypeId),
    (userTickets, roomSelection, roomType, canDepositTicket) => {
      const { ticketBuyIn } = roomType || {};
      if (!ticketBuyIn) {
        return true;
      }

      const selectedRoomType = roomSelection.find(
        (selection) => selection.roomTypeId === 'roomTypeId' && selection.contestId === contestId,
      );

      if (selectedRoomType) {
        return true;
      }

      const amountOfUsedTickets = roomSelection.filter(
        (selection) => selection.buyInMethod.ticket === ticketBuyIn,
      ).length;
      const amountOfAvailableTickets = userTickets[ticketBuyIn] || 0;
      const userHasNoTicket = amountOfUsedTickets >= amountOfAvailableTickets;

      return !(userHasNoTicket && !canDepositTicket);
    },
  );

export const hasUserTicketForRoom = (roomTypeId: string): CurriedSelector<boolean> =>
  createSelector(
    getRoomTypeById(roomTypeId),
    getUserTickets,
    getDetailedSelectionInvestment,
    (roomType, userTickets, investment) => {
      const { ticketBuyIn } = roomType;
      if (!ticketBuyIn) {
        return false;
      }

      const buyInUserTicketsAmount: number = userTickets[ticketBuyIn] as number;
      if (!buyInUserTicketsAmount) {
        return false;
      }

      const buyInInvestedTicketAmount = investment.roomTypeTotalTickets.filter(
        (ticket: string) => ticket === ticketBuyIn,
      ).length;

      return buyInUserTicketsAmount - buyInInvestedTicketAmount > 0;
    },
  );

export const canJoinRoomByDollarCents = (roomTypeId: string): CurriedSelector<boolean> =>
  createSelector(
    isUserGuest,
    getRoomTypeById(roomTypeId),
    isRealMoneyPrizePoolByRoomTypeIds(roomTypeId),
    (isGuest, { dollarCentsBuyIn = 0 }, isRealMoneyPrizePool) =>
      !(isGuest && (dollarCentsBuyIn > 0 || isRealMoneyPrizePool)),
  );

export const hasUserDollarCentsForRoom = (roomTypeId: string): CurriedSelector<boolean> =>
  createSelector(
    getUserDollarCents,
    getDetailedSelectionInvestment,
    getRoomTypeById(roomTypeId),
    (dollarCents, investment, roomType) => {
      const { dollarCentsBuyIn } = roomType || {};
      if (!dollarCentsBuyIn) {
        return false;
      }

      return dollarCents - investment.roomTypeTotalDollarCents >= dollarCentsBuyIn;
    },
  );

export const selectedRoomTypeIds = (
  contestId: string,
  roomTypeIds: string[],
): CurriedSelector<Record<string, boolean>> =>
  createSelector(getRoomSelection, (roomSelection) =>
    roomTypeIds.reduce(
      (acc, roomTypeId) => ({
        ...acc,
        [roomTypeId]: (roomSelection[contestId] || []).some(
          (selection) => selection.roomTypeId === roomTypeId,
        ),
      }),
      {},
    ),
  );

export const isRoomTypeLocked = (contestId: string, roomTypeId: string): CurriedSelector<boolean> =>
  createSelector(
    canJoinRoomByDollarCents(roomTypeId),
    canJoinRoomWithTickets(contestId, roomTypeId),
    hasUserDollarCentsForRoom(roomTypeId),
    isRoomTypeSelected({ contestId, roomTypeId }),
    (canJoinByDollarCents, canJoinByTickets, hasDollarCentsForRoom) => {
      const isLocked = !(!canJoinByDollarCents || (!canJoinByTickets && !hasDollarCentsForRoom));
      return !isLocked;
    },
  );

const getPaymentMethodBySelection = (selection: SelectedRoomType) => {
  if (selection.buyInMethod.ticket) {
    return PaymentMethod.TICKETS;
  }
  if (selection.buyInMethod.dollarCents) {
    return PaymentMethod.DOLLARCENTS;
  }
  return undefined;
};

export const getServerJoinRoomsPayload = createSelector(getFlatRoomSelection, (roomSelection) => {
  const payload = roomSelection.reduce<Record<string, ServerPayloadContest>>(
    (acc, selection) => ({
      ...acc,
      [selection.contestId]: {
        ...acc[selection.contestId],
        contestId: selection.contestId,
        roomTypes: [
          ...(acc[selection.contestId]?.roomTypes || []),
          {
            count: 1,
            roomTypeId: selection.roomTypeId,
            paymentMethod: getPaymentMethodBySelection(selection),
          },
        ],
      },
    }),
    {},
  );

  return Object.values(payload);
});
