import styled from 'styled-components';
import { Colors } from 'style/types';

export const LayoutLoaderWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.Black};
`;
