import styled from 'styled-components';
import { FontFamily, FontSize, FontWeight } from 'style/types';
import { ParagraphStyles } from 'atoms/Paragraph';

export const Title = styled.h1`
  font-family: ${FontFamily.Heading};
  font-weight: ${FontWeight.SemiBold};
  font-size: ${FontSize.XL};
  margin: 0;
`;

export const CardTitle = styled.h2`
  font-size: ${FontSize.M};
  margin: 0;
`;

export const CardDescription = styled.h3`
  ${ParagraphStyles};
  font-size: ${FontSize.MS};
  margin: 0;
`;
