import React, { useEffect, useState } from 'react';
import { clamp } from 'popmotion';
import { useTranslation } from 'react-i18next';
import { SkillRank } from 'molecules/SkillRank';
import { TeamCarousel } from 'molecules/TeamCarousel';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ContentArea } from 'atoms/ContentArea';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { CircularProgress } from 'atoms/CircularProgress';
import { getPlayerByUserId } from 'store/players/selectors';
import { cancelFriendInvite, inviteFriend } from 'store/friends/actions';
import { isUserFriend, userHasPendingFriendInvite } from 'store/friends/selectors';
import { LeagueMember } from 'services/pttv/api/leagues/types';
import { isUserCommissioner } from 'store/leagues/selectors';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonBarDirections } from 'style/types';
import { LeagueMemberInvited, LeagueMemberStatus } from 'services/pttv/api/constants';
import { getUserId } from 'store/user/selectors';
import { useMessages } from 'hooks/useMessages';
import { demoteLeagueMember, promoteLeagueMember, kickLeagueMember } from 'store/leagues/actions';
import { useDialog } from 'hooks/useDialog';
import {
  FrienRequestContainer,
  FriendRequestHeader,
  Title,
  SkillRankArea,
  TeamsTitle,
  PlayerId,
  PlayerXP,
} from '../UserInfo.styled';

interface Props {
  member: LeagueMember;
  leagueName: string;
}

export const LeagueMemberInfo: React.FC<Props> = ({ member, leagueName }) => {
  const { successMessage, errorMessage } = useMessages();
  const player = useAppSelector(getPlayerByUserId(member.userId));
  const isCommissioner = useAppSelector(isUserCommissioner(leagueName));
  const userId = useAppSelector(getUserId);
  const memberIsFriend = useAppSelector(isUserFriend(member.userId));
  const hasPendingInvite = useAppSelector(userHasPendingFriendInvite(member.userId));
  const [hasAffiliatedTeams, setHasAffiliatedTeams] = useState(false);
  const { showConfirm } = useDialog();
  const { t } = useTranslation('LeagueMemberModal');
  const dispatch = useAppDispatch();

  if (!player) {
    return null;
  }

  useEffect(() => {
    setHasAffiliatedTeams(Object.keys(player.affiliatedTeams).length > 0);
  }, [player]);

  const xpLevelProgress = clamp(0, 100, player.percentageToNextRank || 0);

  const isOfficer = () => member.status === LeagueMemberStatus.OFFICER;
  const isMember = () => member.status === LeagueMemberStatus.USER;
  const isInvited = () => member.invite === LeagueMemberInvited.INVITED;

  const handleRemoveFromLeagueClick = async () => {
    showConfirm(t('kickMember.confirm.title'), t('kickMember.confirm.message'), {
      confirmButtonText: t('kickMember.confirm.confirmButton'),
      confirmButtonAction: async () => {
        await dispatch(
          kickLeagueMember({
            request: {
              leagueName,
              subjectId: member.userId,
            },
            successMessage,
            errorMessage,
          }),
        );
      },
    });
  };

  const handlePromoteToOfficerClick = async () => {
    await dispatch(
      promoteLeagueMember({
        request: {
          leagueName,
          subjectId: member.userId,
        },
        successMessage,
        errorMessage,
      }),
    );
  };

  const handleDemoteToMemberClick = async () => {
    await dispatch(
      demoteLeagueMember({
        request: {
          leagueName,
          subjectId: member.userId,
        },
        successMessage,
        errorMessage,
      }),
    );
  };

  const handleCreateFriendRequestClick = async () => {
    await dispatch(
      inviteFriend({
        request: member.userId,
        successMessage,
        errorMessage,
      }),
    );
  };

  const handleUndoFriendRequestClick = async () => {
    await dispatch(
      cancelFriendInvite({
        request: member.userId,
        successMessage,
        errorMessage,
      }),
    );
  };

  return (
    <FrienRequestContainer>
      <FriendRequestHeader>
        <PlayerId>
          {t('playerId.label')}
          <span>{player.inviteCode}</span>
        </PlayerId>
        <PlayerXP>
          {t('experience.label')}
          <span>{player.rank}</span>
        </PlayerXP>
        <CircularProgress size={100} min={0} max={100} value={xpLevelProgress} trackWidth={2}>
          <Avatar src={player.avatarUrl} size={AvatarSize.XXL} />
        </CircularProgress>
        <Title>{player.displayName}</Title>
        <SkillRankArea>
          <SkillRank userId={player.userId} title={t('skillRank.label')} />
        </SkillRankArea>
      </FriendRequestHeader>
      {hasAffiliatedTeams && (
        <ContentArea>
          <TeamsTitle>{t('affiliatedTeams.label')}</TeamsTitle>
          <TeamCarousel userId={member.userId} />
        </ContentArea>
      )}
      <ContentArea as="footer">
        <ButtonBar direction={ButtonBarDirections.Column}>
          {member.userId !== userId && !memberIsFriend ? (
            <Button scheme={ButtonSchemes.Secondary} onClick={handleCreateFriendRequestClick}>
              {t('button.createFriendRequest')}
            </Button>
          ) : null}
          {member.userId !== userId && hasPendingInvite ? (
            <Button scheme={ButtonSchemes.Secondary} onClick={handleUndoFriendRequestClick}>
              {t('button.undoFriendRequest')}
            </Button>
          ) : null}
          {isCommissioner && isMember() && !isInvited() ? (
            <Button scheme={ButtonSchemes.Secondary} onClick={handlePromoteToOfficerClick}>
              {t('button.promoteToOfficer')}
            </Button>
          ) : null}
          {isCommissioner && isOfficer() && !isInvited() ? (
            <Button scheme={ButtonSchemes.Secondary} onClick={handleDemoteToMemberClick}>
              {t('button.demoteToUser')}
            </Button>
          ) : null}
          {isCommissioner ? (
            <Button scheme={ButtonSchemes.Warning} onClick={handleRemoveFromLeagueClick}>
              {t('button.removeFromLeague')}
            </Button>
          ) : null}
        </ButtonBar>
      </ContentArea>
    </FrienRequestContainer>
  );
};
