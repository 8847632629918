import { createReducer } from '@reduxjs/toolkit';
import { PlaceBetResponse } from 'services/pttv/api/contests/types';
import { endGame, resetGame } from 'store/games/actions';
import { clearUser, fetchUserState } from 'store/user/actions';
import { fetchContestHistoryByContestId } from '../contestHistory/actions';
import { clearBet, placeOrUpdateBet, removeBets, updateBet } from './actions';
import { Bet, BetsState } from './types';

const initialState: BetsState = {};

export const betsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) => {
      const { contests } = (payload.currentGamesInfo && payload.currentGamesInfo[0]) || {};
      const allBets: Bet[] = [];

      if (contests) {
        contests.forEach(({ contestId, bets }) =>
          bets.forEach((bet) => allBets.push({ ...bet, contestId })),
        );
      }

      return {
        ...state,
        ...allBets.reduce<BetsState>(
          (acc, bet) => ({
            ...acc,
            [bet.propositionId]: bet,
          }),
          {},
        ),
      };
    })
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(endGame, () => initialState)
    .addCase(resetGame.fulfilled, (state, { payload }) => {
      const { contestIds } = payload;

      Object.keys(state).forEach((propositionId) => {
        if (contestIds.includes(state[propositionId].contestId)) {
          delete state[propositionId];
        }
      });
    })
    .addCase(placeOrUpdateBet.fulfilled, (state, { payload }) => {
      const { propositionId } = payload as PlaceBetResponse;
      state[propositionId] = {
        ...state[propositionId],
        ...payload,
      };
    })
    .addCase(updateBet, (state, { payload }) => {
      state[payload.propositionId as string] = {
        ...state[payload.propositionId as string],
        ...payload,
      };
    })
    .addCase(removeBets, (state, { payload }) => {
      Object.values(state).forEach((bet) => {
        if (bet.contestId === payload) {
          delete state[bet.propositionId];
        }
      });
    })
    .addCase(clearBet.fulfilled, (state, { payload }) => {
      if (state[payload as string]) {
        delete state[payload as string];
      }
    })
    .addCase(fetchContestHistoryByContestId.fulfilled, (state, { payload }) => {
      payload.bets.forEach((bet) => {
        state[bet.propositionId] = bet;
      });
    });
});
