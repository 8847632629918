import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors, BorderWidth, FontSize } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

interface Props {
  $showBadge: boolean;
}

export const ProfileIconContainer = styled(Link)<Props>`
  align-items: center;
  color: ${Colors.Text};
  display: inline-flex;
  align-items: center;
  color: ${Colors.Text};
  display: inline-flex;
  font-size: ${FontSize.XS};
  gap: 0.25rem;
  text-decoration: none;
  position: relative;

  &::before {
    background-color: ${Colors.Tertriary};
    border-radius: 50%;
    border: ${BorderWidth.XL} solid ${Colors.Black};
    content: '';
    display: ${({ $showBadge }) => ($showBadge ? 'block' : 'none')};
    height: ${pxToRem(12)};
    position: absolute;
    left: ${pxToRem(26)};
    top: 0;
    width: ${pxToRem(12)};
  }
`;
