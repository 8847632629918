import React from 'react';
import { useHistory } from 'react-router-dom';
import WinViewLogoSmall from 'assets/icons/WinViewLogoSmall.svg';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { PageNotFoundContainer } from './PageNotFound.styled';

export const PageNotFound: React.FC = () => {
  const { push } = useHistory();

  const handleCloseButtonClick = () => {
    push('/');
  };
  return (
    <PageNotFoundContainer>
      <h1>Oops! Something went wrong</h1>
      <WinViewLogoSmall />
      <Button scheme={ButtonSchemes.Secondary} onClick={handleCloseButtonClick}>
        Back to Schedule
      </Button>
    </PageNotFoundContainer>
  );
};
