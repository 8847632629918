/* eslint-disable import/no-cycle */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ContestChangedEventPayloadContest,
  PointsUpdatedEventPayload,
} from 'hocs/withLongPoll/types/contest';
import { contestRoomTypesAvailableToJoinUpdated, removeContest } from 'store/contests/actions';
import { getRoomTypesByRoomTypeIds } from 'store/roomTypes/selectors';
import type { RootState } from 'store/types';
import { Room } from 'services/pttv/api/rooms/types';
import { addModal } from 'store/modals/actions';
import { ModalType } from 'store/modals/types';
import { getContestById } from 'store/contests/selectors';
import { getContestInfoByContestId } from './selectors';
import {
  OneShotUsedArgs,
  OneShotUsedPayload,
  ContestInfoActions,
  ContestCancelledArgs,
  ContestCancelledResult,
  RoomCancelledPayload,
} from './types';

export const updatePoints = createAction<PointsUpdatedEventPayload>(
  ContestInfoActions.UPDATE_POINTS,
);

export const contestEnded = createAction<string>(ContestInfoActions.CONTEST_ENDED);

export const removeContestInfo = createAction<string>(ContestInfoActions.REMOVE_CONTEST_INFO);

export const roomCancelled = createAction<RoomCancelledPayload>(ContestInfoActions.ROOM_CANCELLED);

export const setOneShotUsed = createAsyncThunk<OneShotUsedPayload, OneShotUsedArgs>(
  ContestInfoActions.ONE_SHOT_USED,
  ({ contestId, oneShotUsed }, { getState }) => {
    const { contestInfo } = getState() as RootState;

    if (!contestInfo[contestId]) {
      return null;
    }

    return {
      contestId,
      oneShotUsed,
    };
  },
);

export const updateContestInfo = createAsyncThunk<void, ContestChangedEventPayloadContest>(
  ContestInfoActions.UPDATE_CONTEST_INFO,
  ({ contestId, state }, { dispatch, getState }) => {
    const contestInfo = getContestInfoByContestId(contestId)(getState() as RootState);

    if (!contestInfo) {
      return;
    }

    if (contestInfo.state !== state) {
      if (state === 'FINISHING') {
        dispatch(contestEnded(contestId));
      } else if (state === 'CLOSED') {
        dispatch(contestEnded(contestId));
        dispatch(removeContestInfo(contestId));
      }
    }
  },
);

export const contestCancelled = createAsyncThunk<
  ContestCancelledResult | null,
  ContestCancelledArgs
>(ContestInfoActions.CONTEST_CANCELLED, ({ contestId, ...restPayload }, { dispatch, getState }) => {
  const state = getState() as RootState;
  const contest = getContestById(contestId)(state);
  const contestInfo = getContestInfoByContestId(contestId)(state);

  dispatch(contestRoomTypesAvailableToJoinUpdated({ contestId, roomTypeIds: [] }));

  if (!contestInfo && !restPayload.roomTypeIds) {
    // For now cancelled contests are not present in state, we can just remove it. Once implementation of cancelled history will appear seems that it should be managed in other way
    dispatch(removeContest(contestId));
    return null;
  }

  const roomTypeIds = restPayload.roomTypeIds || Object.keys(contestInfo.roomTypeIds);
  // needs to be copied for showing contest's unregistered room types
  const roomTypes = getRoomTypesByRoomTypeIds(roomTypeIds)(state);

  dispatch(
    addModal({
      id: contestId,
      type: ModalType.ContestCancelled,
      unreadMessageId: restPayload.unreadMessageId,
      expirationTime: Date.now(),
      payload: {
        sportId: contest.sportId,
        gameId: contest.gameId,
        period: contest.period,
        cancelledReason: restPayload.cancelledReason || '',
      },
    }),
  );
  dispatch(removeContestInfo(contestId));
  dispatch(removeContest(contestId));

  return {
    ...restPayload,
    contestId,
    unregisteredRoomTypes: [...roomTypes],
  };
});

export const addRoom = createAsyncThunk<Room, Room>(ContestInfoActions.ADD_ROOM, (room) => room, {
  condition: (room, { getState }) => {
    const contestInfo = getContestInfoByContestId(room.contestId)(getState() as RootState);
    if (!contestInfo) {
      return false;
    }

    const { roomQueueEntries } = contestInfo;
    if (!roomQueueEntries.find(({ roomTypeId }) => roomTypeId === room.roomTypeId)) {
      return false;
    }
    return true;
  },
});
