import { EnhancedStore } from '@reduxjs/toolkit';
import wrapperLib from 'services/wrapper';
import { AppDispatch, RootState } from 'store/types';
import { isMobile } from 'utils/device';
import { wrapperInitSuccess, wrapperPropertyUpdated } from 'store/wrapper/actions';
import {
  fetchGlobalState,
  fetchUserState,
  multipleSessionsError,
  userBlockedError,
  userUnauthorizedError,
} from 'store/user/actions';
import { WrapperPayload } from 'store/wrapper/types';
import { playToTv } from 'services/pttv';
import { autoLoginCashBet, cashBetResetError } from 'store/cashBet/actions';
import {
  addModalsFromUnreadMessages,
  addServerFullModal,
  addServerMaintenanceModal,
} from 'store/modals/actions';
import { GlobalStateResponse } from 'store/user/types';
import { appOpened } from 'store/app/actions';
import { trackDataError } from 'utils/log';
import { UserStateResponse } from 'services/pttv/api/userState/types';

interface Options {
  onAppReady: () => void;
}

export const bootstrap = async (
  store: EnhancedStore<RootState>,
  options: Options,
): Promise<void> => {
  const { dispatch, getState } = store;
  // const { user } = getState();

  wrapperLib.onReady(async (props) => {
    dispatch(wrapperInitSuccess(props as WrapperPayload));
    // NOTE: For web UUID backwards compatibility
    wrapperLib.setupUUID(dispatch, getState);

    const res = await (dispatch as AppDispatch)(fetchGlobalState());
    if (res) {
      const { onAppReady } = options;
      const { cashBet } = getState();

      if (cashBet.autoLoginCashBet) {
        // Do not call autoLoginCashBet before fetchGlobalState has finished.
        (dispatch as AppDispatch)(autoLoginCashBet());
      }

      /**
       * If client props are not undefined - we try to extract ENABLE_DESKTOP.
       * If client props = undefined, it means server under maintenance so we enable desktop
       * to be sure that client will see maintenance message
       */
      const { ENABLE_DESKTOP = 'false' } = (res?.payload as GlobalStateResponse)
        ?.clientProperties || {
        ENABLE_DESKTOP: 'true',
      };

      /**
       * JSON.parse will parse string "true" to bool true and string "false" to bool false
       */
      if (!JSON.parse(ENABLE_DESKTOP) && !isMobile()) {
        window.location.href = 'https://winviewgames.com/desktop';
        return;
      }

      if (!isMobile()) {
        document.body.classList.add('isDesktop');
      } else {
        document.body.classList.add('isMobile');
      }

      onAppReady();
    }
    // registerSW(props);
    // Workaround for Android properties that aren't always set at onReady.
    // checkForNativeWrapperPropUpdate();
  });

  wrapperLib.onPropUpdated((prop) => {
    dispatch(wrapperPropertyUpdated(prop));
  });

  dispatch(appOpened());

  // Retrieve user state to ensure state is up to date when app resumes
  // (events from the long poll could have been missed).
  wrapperLib.onAppResume(async () => {
    const previousState = { ...getState() } as RootState;
    const { user } = previousState;
    dispatch(appOpened());
    if (user.isAuthenticated) {
      const { payload } = await (dispatch as AppDispatch)(fetchUserState());
      (dispatch as AppDispatch)(
        addModalsFromUnreadMessages({
          unreadMessages: (payload as UserStateResponse).unreadMessages || [],
          previousState,
        }),
      );
    }
    // TODO [EG 2022-04-22] Implement when the Service worker is in place
    // wrapperLib.onReady((props) => {
    //   if (!checkForSWUpdates(props)) {
    //     checkForUpdateAndScheduleNext();
    //   }
    // });
  });

  // Error Handling
  playToTv.onMultipleSessions((error) => (dispatch as AppDispatch)(multipleSessionsError(error)));
  playToTv.onUserBlocked((error) => dispatch(userBlockedError(error)));
  playToTv.onClientUnauthorizedHandler((error) => dispatch(userUnauthorizedError(error)));
  playToTv.onCashBetResetHandler(() => {
    const {
      cashBet: { isServerResetting },
      user: { signInTimestamp },
    } = getState() as RootState;

    // Only handle once for all requests (all requests will receive this 401 error) and ignore if a recent sign in has been done (auto login recover could have just been done)
    if (!isServerResetting && signInTimestamp && Date.now() - signInTimestamp > 5000) {
      dispatch(cashBetResetError());
      // CashBet server is resetting and we'll try to autologin until success.
      setTimeout(() => (dispatch as AppDispatch)(autoLoginCashBet()), 5000 + Math.random() * 5000); // Auto login between the 5 to 10 seconds.
    }
  });
  playToTv.onServerFullHandler((error) => dispatch(addServerFullModal(error)));
  playToTv.onServerMaintenanceHandler((error) => dispatch(addServerMaintenanceModal(error)));
  playToTv.onNonHumanError400((message, payload) => trackDataError(message, payload));
};
