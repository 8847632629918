import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { Colors } from 'style/types';

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
  `;
const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

interface WrapperProps {
  $zIndex: number;
  $noEnter: boolean;
}

export const SlideInWrapper = styled.section.attrs<WrapperProps>({
  className: 'slidein',
})<WrapperProps>`
  display: grid;
  background-color: ${Colors.Black};
  z-index: ${({ $zIndex }) => `${10 + $zIndex}`};

  &.slidein {
    animation: ${slideIn} ${({ $noEnter }) => ($noEnter ? '0.05s' : '0.3s')} ease-out;
    animation-fill-mode: both;
    transform: translateX(100%);
  }

  &.slidein-exit {
    animation: ${slideOut} 0.3s ease-in;
    animation-fill-mode: both;
    transform: translateX(100%);
    pointer-events: none;
  }
`;

interface Props {
  zIndex?: number;
}

export const SlideIn: React.FC<Props> = ({ children, zIndex = 0 }) => {
  const history = useHistory();
  return (
    <SlideInWrapper $zIndex={zIndex} $noEnter={history.action === 'POP'}>
      {children}
    </SlideInWrapper>
  );
};
