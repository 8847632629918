import { RoomTypeLeaderboard } from 'services/pttv/api/leaderboards/types';
import {
  JoinRoomResponseItem,
  Room,
  RoomQueueEntry,
  RoomScoreRoomPlayer,
} from 'services/pttv/api/rooms/types';
import { RoomScoreUpdatedRoomPayload } from 'hocs/withLongPoll/types/room';
import { ServerPayloadContest } from 'store/roomSelection/types';
import { PaymentMethod } from 'services/pttv/api/constants';
import { PttvError } from 'services/pttv/types';

export enum RoomActions {
  JOIN_CONTEST_AND_ROOMS = '@room/JOIN_CONTEST_AND_ROOMS',
  JOIN_CONTESTS_AND_ROOMS = '@room/JOIN_CONTESTS_AND_ROOMS',
  JOIN_CONTESTS_AND_ROOMS_COMPLETED = '@room/JOIN_CONTESTS_AND_ROOMS_COMPLETED',
  JOIN_ROOMS_SUCCESS = '@room/JOIN_ROOMS_SUCCESS',
  JOIN_ROOMS_ERROR = '@room/JOIN_ROOMS_ERROR',
  LEAVE_ROOM = '@room/LEAVE_ROOM',
  UPDATE_ROOM_SCORES = '@room/UPDATE_ROOM_SCORES',
  UPDATE_ROOM = '@room/UPDATE_ROOM',
  FETCH_ROOM = '@room/FETCH_ROOM',
  TRACK_JOIN_ROOM = '@room/TRACK_JOIN_ROOM',
}

export interface GetRoomScoreResponse {
  roomId: string;
  contestId: string;
  roomTypeId: string;
  players: RoomScoreRoomPlayer[];
}
export interface JoinContestsAndRoomsArgs {
  latitude: number | null;
  longitude: number | null;
  accuracy: number | null;
}

export interface JoinContestAndRoomsCompleteArgs {
  response: JoinRoomResponseItem;
  payload: {
    request: ServerPayloadContest;
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
  };
}

export interface JoinContestAndRoomsArgs {
  contestId: string;
  roomTypeIdsToPaymentMethodMap: Record<string, PaymentMethod>;
  geoPosition: {
    latitude: number | null;
    longitude: number | null;
    accuracy: number | null;
  };
}

export interface FetchOrUpdateRoomArgs {
  room: Room;
  fromEvent: boolean;
}

export interface FetchOrUpdateRoomScoresArgs {
  room: RoomScoreUpdatedRoomPayload;
  contestId: string;
}
export type FetchOrUpdateRoomScoresPayload = RoomScoreUpdatedRoomPayload & {
  fetched?: boolean;
};

export interface LeaveRoomArgs {
  roomTypeId: string;
  contestId: string;
}

export interface LeaveRoomPayload {
  roomTypeId: string;
  contestId: string;
  roomQueueId: string;
}

export interface JoinContestPayload extends JoinRoomResponseItem {
  gameId: string;
  latitude: number | null;
  longitude: number | null;
  accuracy: number | null;
  ip: string;
}

export interface RoomTypeLeaderboardUpdates {
  roomTypeId: string;
  roomTypeLeaderboardData: RoomTypeLeaderboard;
  prizePerRankDollarCents: [number];
}
export interface JoinMultiContestResponeItem {
  contestId: string;
  roomQueueEntries: RoomQueueEntry[];
  roomTypeLeaderboardUpdates: RoomTypeLeaderboardUpdates[];
  points: number;
  invested: number;
  ticketIdsPerRoomType: Record<string, string>;
}

export type JoinMultiContestRespone = JoinMultiContestResponeItem[];

export interface StoreRoom extends Room {
  fetched?: boolean;
}

export type RoomsState = {
  [roomId: string]: StoreRoom;
};

export type TrackRoomProperties = JoinContestPayload & {
  preparedQueueEntry: RoomQueueEntry;
  error?: PttvError;
};
