import styled from 'styled-components';
import { StyledDivider } from 'atoms/Divider/Divider.styled';
import { Padding } from 'style/types';

export const SocialLandingWrapper = styled.section`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  ${StyledDivider}:first-of-type {
    margin-top: ${Padding.S};
  }
`;
