import React, { useReducer } from 'react';
import { Alert } from 'molecules/Dialogs';
import { Confirm } from 'molecules/Dialogs/Confirm';
import {
  AlertOptions,
  CloseAlert,
  ConfirmOptions,
  DialogState,
  ShowAlert,
  ShowConfirm,
} from './types';
import { initialState, DialogContext } from './context';

type Action = {
  type: string;
  payload?: {
    title: string;
    content: string | JSX.Element | null;
    options?: AlertOptions | ConfirmOptions;
  };
};

export const DialogProvider: React.FC = ({ children }) => {
  const reducer = (state: DialogState, action: Action) => {
    switch (action.type) {
      case 'showAlert':
        return {
          ...state,
          ...action.payload,
          openConfirm: false,
          openAlert: true,
        };

      case 'closeAlert':
        return {
          ...state,
          options: null,
          openConfirm: false,
          openAlert: false,
        };

      case 'showConfirm':
        return {
          ...state,
          ...action.payload,
          openAlert: false,
          openConfirm: true,
        };

      default:
        return { ...initialState };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const showAlert: ShowAlert = (title, content, options) => {
    dispatch({
      type: 'showAlert',
      payload: {
        title,
        content,
        options,
      },
    });
  };

  const closeAlert: CloseAlert = () => {
    dispatch({
      type: 'closeAlert',
    });
  };

  const showConfirm: ShowConfirm = (title, content, options) => {
    dispatch({
      type: 'showConfirm',
      payload: {
        title,
        content,
        options,
      },
    });
  };

  const callBack = async () => {
    const confirmButtonAction = (state?.options as ConfirmOptions)?.confirmButtonAction;
    if (confirmButtonAction) {
      await confirmButtonAction();
    }

    dispatch({
      type: 'hideAll',
    });
  };

  const callClose = async () => {
    if (state?.options?.closeButtonAction) {
      await state.options.closeButtonAction();
    }

    dispatch({
      type: 'hideAll',
    });
  };

  return (
    <DialogContext.Provider value={{ ...state, showAlert, showConfirm, callBack, closeAlert }}>
      {children}
      <Alert
        title={state.title}
        buttonText={state.options?.closeButtonText}
        isOpen={state.openAlert}
        onClose={callClose}
      >
        {state.content}
      </Alert>
      <Confirm
        title={state.title}
        cancelButtonText={(state.options as ConfirmOptions)?.closeButtonText}
        confirmButtonText={(state.options as ConfirmOptions)?.confirmButtonText}
        disableButtons={!!(state.options as ConfirmOptions)?.disableButtons}
        isOpen={state.openConfirm}
        confirmAction={callBack}
        onClose={callClose}
      >
        {state.content}
      </Confirm>
    </DialogContext.Provider>
  );
};
