import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { BorderRadius, FontFamily, FontWeight, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export interface TabProps {
  $activeTab?: boolean;
}

interface ExtendedTabProps {
  $activeTab?: boolean;
  scheme: 'active' | 'default';
}

export const ContestTab = styled(motion.button).attrs<TabProps, ExtendedTabProps>(
  ({ $activeTab }) => ({
    scheme: $activeTab ? 'active' : 'default',
  }),
)<TabProps>`
  background-color: ${({ theme, scheme }) => theme.contestTab[scheme].backgroundColor};
  border: 1px solid ${({ theme, scheme }) => theme.contestTab[scheme].backgroundColor};
  border-radius: ${BorderRadius.Default};
  box-sizing: border-box;
  color: ${({ theme, scheme }) => theme.contestTab[scheme].color};
  cursor: pointer;
  font-family: ${FontFamily.Heading};
  font-size: ${({ theme, scheme }) => theme.contestTab[scheme].fontSize};
  font-weight: ${FontWeight.Regular};
  height: ${({ theme, scheme }) => pxToRem(theme.contestTab[scheme].height)};
  letter-spacing: 0.77px;
  line-height: 1;
  padding: 0.375rem 0 ${Padding.XXS};
  text-transform: uppercase;
  transition: all ease 0.3s;
  width: ${({ theme, scheme }) => pxToRem(theme.contestTab[scheme].width)};

  &:hover {
    border-color: ${({ theme, scheme }) => theme.contestTab[scheme].hoverBorderColor};
  }

  div {
    color: ${({ theme, scheme }) => theme.contestTab[scheme].statusColor};
    font-size: ${({ theme, scheme }) => theme.contestTab[scheme].statusFontSize};
  }

  ${({ $activeTab }) =>
    $activeTab &&
    css`
      font-weight: ${FontWeight.SemiBold};
    `}
`;
