import React, { Suspense, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SlideIn } from 'molecules/PageWrappers';
import { PageLoader } from 'atoms/PageLoader';
import { LazyPreLoader } from 'utils/LazyPreLoader';
import { withErrorBoundary } from 'hocs/withErrorBoundary';

const lazyLoader = new LazyPreLoader();

const ContestHistoryPage = React.lazy(() => import('./contestHistory'));
const ContestHistoryInfoPage = lazyLoader.lazy(() => import('./contestHistoryInfo'));
const ContestHistoryRoomInfoPage = lazyLoader.lazy(() => import('./contestHistoryRoomInfo'));

export const contestHistoryPaths = {
  contestHistoryRoomInfo: '/profile/contest-history/game/:gameId/contest/:contestId/room/:roomId',
  contestHistoryInfo: '/profile/contest-history/game/:gameId',
  contestHistory: '/profile/contest-history',
};

interface Params {
  category: string;
  gameId: string;
  backUrl: string;
  contestId: string;
  roomId: string;
}

const ContestHistoryRoutes: React.FC = () => {
  const match = useRouteMatch<Params>(Object.values(contestHistoryPaths));
  const history = useHistory();

  useEffect(() => {
    lazyLoader.preload();
  }, []);

  if (!match?.isExact) {
    return null;
  }

  return (
    <>
      <SlideIn zIndex={0}>
        <ContestHistoryPage />
      </SlideIn>

      <Suspense fallback={<PageLoader />}>
        <SwitchTransition mode="in-out">
          <CSSTransition
            key={match.path}
            timeout={{
              appear: history.action === 'POP' ? 0 : 500,
              enter: history.action === 'POP' ? 0 : 500,
              exit: 500,
            }}
            classNames="slidein"
            unmountOnExit
          >
            <>
              {match?.path === contestHistoryPaths.contestHistoryInfo && (
                <SlideIn zIndex={1}>
                  <ContestHistoryInfoPage gameId={match.params.gameId} />
                </SlideIn>
              )}
              {match?.path === contestHistoryPaths.contestHistoryRoomInfo && (
                <SlideIn zIndex={2}>
                  <ContestHistoryRoomInfoPage
                    roomId={match.params.roomId}
                    contestId={match.params.contestId}
                    gameId={match.params.gameId}
                  />
                </SlideIn>
              )}
            </>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default withErrorBoundary(ContestHistoryRoutes);
