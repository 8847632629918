import styled from 'styled-components';
import { BorderRadius, Padding } from 'style/types';
import { ShareType } from './shareButton.theme';

interface Props {
  shareType: ShareType;
}

export const ShareButtonStyled = styled.a.attrs<Props>({
  target: '_black',
  rel: 'noopener',
})<Props>`
  background-color: ${({ shareType, theme }) => theme.shareButton[shareType].backgroundColor};
  border-radius: ${BorderRadius.Default};
  color: ${({ shareType, theme }) => theme.shareButton[shareType].color};
  padding: ${Padding.S};
  text-align: center;
  text-decoration: none;

  > svg {
    margin-right: 0.5rem;
    width: 1rem;
  }
`;
