import { createReducer } from '@reduxjs/toolkit';
import { fetchGlobalState } from '../user/actions';
import { SportsState } from './types';

export const initialState = {} as SportsState;

export const sportsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchGlobalState.fulfilled, (state, { payload }) =>
    payload.sports.reduce<SportsState>(
      (acc, sport) => ({
        ...acc,
        [sport.sportId]: sport,
      }),
      {},
    ),
  );
});
