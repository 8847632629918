import styled, { css } from 'styled-components';
import { Colors, FontFamily, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import toastBgCorrect from 'assets/images/toasterBgCorrect.png';
import toastBgCorrect2x from 'assets/images/toasterBgCorrect@2x.png';
import toastBgCorrect3x from 'assets/images/toasterBgCorrect@3x.png';

import toastBgIncorrect from 'assets/images/toasterBgIncorrect.png';
import toastBgIncorrect2x from 'assets/images/toasterBgIncorrect@2x.png';
import toastBgIncorrect3x from 'assets/images/toasterBgIncorrect@3x.png';

import toastBgUndecided from 'assets/images/toasterBgUndecided.png';
import toastBgUndecided2x from 'assets/images/toasterBgUndecided@2x.png';
import toastBgUndecided3x from 'assets/images/toasterBgUndecided@3x.png';

interface PropositionToastProps {
  result: 'correct' | 'incorrect' | 'undecided';
}

const getToastStyle = (result: string) => {
  switch (result) {
    case 'incorrect':
      return css`
        background-image: url('${toastBgIncorrect}');
        padding-bottom: ${pxToRem(60)};

        & + div {
          transform: translateY(-${pxToRem(32)});
        }

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-width: 415px) {
          background-image: url('${toastBgIncorrect2x}');
          padding-bottom: ${pxToRem(60)};
        }
        @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 384dpi), (min-width: 829px) {
          background-image: url('${toastBgIncorrect3x}');
          padding-bottom: ${pxToRem(88)};
        }
      `;

    case 'correct':
      return css`
        background-image: url('${toastBgCorrect}');
        padding-bottom: ${Padding.XXXL};
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-width: 415px) {
          background-image: url('${toastBgCorrect2x}');
        }
        @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 384dpi), (min-width: 829px) {
          background-image: url('${toastBgCorrect3x}');
          padding-bottom: ${pxToRem(60)};
        }
      `;

    default:
      return css`
        background-image: url('${toastBgUndecided}');
        padding-bottom: ${Padding.XXXL};
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-width: 415px) {
          background-image: url('${toastBgUndecided2x}');
          padding-bottom: ${Padding.XXXL};
        }
        @media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 384dpi), (min-width: 829px) {
          background-image: url('${toastBgUndecided3x}');
          padding-bottom: ${Padding.XXXL};
        }
      `;
  }
};

export const PropositionToastStyled = styled.article.attrs<PropositionToastProps>({
  tabIndex: 0,
  role: 'button',
})<PropositionToastProps>`
  background-position: bottom center;
  background-size: cover;
  display: grid;
  filter: drop-shadow(0 2px 1rem ${Colors.Black});
  gap: ${Gap.Default};
  grid-template-columns: ${pxToRem(144)} 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'icon header'
    'icon description'
    'icon footer';
  padding: ${Padding.Default};
  ${({ result }) => getToastStyle(result)}
`;

export const Description = styled.section`
  align-self: center;
  color: ${Colors.Black05};
  font-size: ${FontSize.MS};
  grid-area: description;
`;

export const Answer = styled.footer`
  grid-area: footer;
  font-style: italic;

  > span {
    font-style: initial;
    font-weight: ${FontWeight.SemiBold};
  }
`;

const resultColors: Record<string, Colors> = {
  correct: Colors.Success,
  incorrect: Colors.Error,
  undecided: Colors.Black50,
};

interface ResultProps {
  result: 'correct' | 'incorrect' | 'undecided';
}

export const Result = styled.header<ResultProps>`
  grid-area: header;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XL};
  font-weight: ${FontWeight.SemiBold};
  > span {
    color: ${({ result }) => resultColors[result]};
    text-shadow: 0 0.125rem 0.125rem ${Colors.Black};
  }
`;

interface IconProps {
  result: 'correct' | 'incorrect' | 'undecided';
}

export const IconWrapper = styled.div<IconProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.XXL};
  font-weight: ${FontWeight.SemiBold};
  grid-area: icon;
  justify-content: center;
  text-shadow: 0 0.125rem 0.125rem ${Colors.Black};
  text-transform: uppercase;
`;

export const Icon = styled.div<IconProps>`
  align-items: center;
  background-color: ${({ result }) => resultColors[result]};
  border-radius: 50%;
  box-shadow: 0 0.125rem 0.125rem ${Colors.Black};
  display: flex;
  height: ${pxToRem(40)};
  justify-content: center;
  margin-bottom: ${Gap.S};
  width: ${pxToRem(40)};

  > svg {
    filter: drop-shadow(0 1px 0.125rem hsla(0, 0%, 0%, 0.5));
  }

  > .correct,
  > .undecided {
    width: ${pxToRem(24)};
  }
  > .incorrect {
    width: ${pxToRem(20)};
  }
`;
