import { createReducer } from '@reduxjs/toolkit';
import { appFirstLaunch, appUpdateFirstLaunchDate } from './actions';
import { AppState } from './types';

const initialState = {
  deviceId: null,
  firstLaunchDate: null,
  pristine: true,
} as AppState;

export const appReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(appFirstLaunch.fulfilled, (state, action) => ({
      ...state,
      firstLaunchDate: action.payload?.payload || 0,
      pristine: false,
    }))
    .addCase(appUpdateFirstLaunchDate, (state, { payload }) => ({
      ...state,
      firstLaunchDate: payload,
      pristine: false,
    }));
});
