import styled from 'styled-components';
import React from 'react';
import { Colors, Padding } from 'style/types';
import HelpIcon from 'assets/icons/HelpIcon.svg';
import { pxToRem } from 'utils/pxToRem';

export const HelpButton = styled.button.attrs({
  type: 'button',
  children: <HelpIcon />,
})`
  background-color: transparent;
  color: ${Colors.Secondary};
  cursor: pointer;
  border: none;
  padding: ${Padding.Default} 0 ${Padding.Default} ${Padding.Default};

  > svg {
    width: ${pxToRem(18)};
  }
`;
