import React from 'react';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FadeIn } from 'molecules/PageWrappers';

interface RouteProps {
  path: string;
  exact?: boolean;
}
export const FadeInRoute: React.FC<RouteProps> = ({ path, exact, children }) => (
  <Route key={path} exact={exact} path={path}>
    {({ match }) => (
      <CSSTransition in={match != null} timeout={500} classNames="fadein" unmountOnExit>
        <FadeIn>{children}</FadeIn>
      </CSSTransition>
    )}
  </Route>
);
