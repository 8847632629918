import React from 'react';
import TwitterIcon from 'assets/icons/TwitterIcon.svg';
import FacebookIcon from 'assets/icons/FacebookIcon.svg';
import { ShareType } from './shareButton.theme';
import { ShareButtonStyled } from './ShareButton.styled';

const icons: Record<ShareType, JSX.Element> = {
  [ShareType.Twitter]: <TwitterIcon />,
  [ShareType.Facebook]: <FacebookIcon />,
};

interface Props {
  href: string;
  shareType: ShareType;
  callback: () => void;
}

export const ShareButton: React.FC<Props> = ({ children, shareType, href, callback }) => (
  <ShareButtonStyled href={href} shareType={shareType} onClick={callback}>
    {icons[shareType]}
    {children}
  </ShareButtonStyled>
);
