import React from 'react';
import TicketsIcon from 'assets/icons/TicketsIcon.svg';
import { TicketsWrapper } from './Tickets.styled';

export const Tickets: React.FC = ({ children }) => (
  <TicketsWrapper>
    <TicketsIcon />
    {children}
  </TicketsWrapper>
);
