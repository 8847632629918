import { TimeFormats } from './types';

const timeFormatOptions: Record<TimeFormats, Intl.DateTimeFormatOptions> = {
  [TimeFormats.Short]: {
    hour: 'numeric',
    minute: 'numeric',
  },
};

export const formatTime = (date: number, type: TimeFormats = TimeFormats.Short): string =>
  new Intl.DateTimeFormat('en-US', timeFormatOptions[type]).format(new Date(date));
