import type { EventDefinition } from 'redux-beacon';
import type { AppsFlyerEvent, AppsFlyerEventData } from './types';

export const trackEvent =
  (eventDef: EventDefinition<AppsFlyerEventData>): AppsFlyerEvent =>
  (action, prevState, nextState) => {
    const { name, properties } = eventDef(action, prevState, nextState);

    return {
      type: 'event',
      name,
      properties,
    };
  };
