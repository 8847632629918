import styled from 'styled-components';
import { Colors, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';
import { ProfileIconContainer } from 'molecules/ProfileIcon/profileIcon.styled';
import { WalletContainer } from 'molecules/Wallet/wallet.styled';

export const HeaderContainer = styled.header`
  align-items: center;
  background-color: ${Colors.Black};
  display: grid;
  height: ${pxToRem(64)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  padding: 0 ${Padding.Default};

  ${ProfileIconContainer} {
    justify-self: start;
  }

  > svg {
    height: ${pxToRem(26)};
  }

  ${WalletContainer} {
    justify-self: end;
  }

  > div {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
`;
