import {
  ContestHistoryByContestIdResponse,
  ContestHistoryContest,
} from 'services/pttv/api/contests/types';
import { Contest } from 'store/contests/types';
import { Game } from 'store/games/types';

export enum ContestHistoryActions {
  FETCH_CONTEST_HISTORY = '@contestHistory/`FETCH_CONTEST_HISTORY`',
  FETCH_CONTEST_HISTORY_BY_CONTEST_ID = '@contestHistory/`FETCH_CONTEST_HISTORY_BY_CONTEST_ID`',
}

export type ContestHistoryStateEntry = Omit<
  Partial<ContestHistoryByContestIdResponse>,
  'contest' | 'game' | 'bets'
> & {
  contest: Contest | ContestHistoryContest;
  game: Game;
};

export type ContestHistoryState = {
  [contestId: string]: ContestHistoryStateEntry;
};

export interface GameWithCancelledStatus extends Game {
  cancelled?: boolean;
}

export type NormalizedContestHistoryGames = {
  [gameId: string]: GameWithCancelledStatus;
};

export interface Winnings {
  tickets: boolean;
  dollarCents: number;
}
