/* eslint-disable max-len */

export const getPermissionStateForGeoLocation = (): Promise<string> =>
  new Promise((resolve, reject) => {
    // NOTE: navigator.permissions not supported by Safari
    if (!navigator.permissions) {
      navigator.geolocation.getCurrentPosition(
        () => resolve('granted'),
        (err) => {
          const { code = -1 } = err || {};
          /*
            According to https://developer.mozilla.org/en-US/docs/Web/API/PositionError#Properties
            Codes:
            1=POSITION_DENIED or 2=POSITION_UNAVAILABLE we treat it as denied it should be throw immediately by WEB API
            2=POSITION_TIMEOUT - we treat as granted - geo location started establishing position so we are granted

            NOTE:
            Calling geolocation.getCurrentPosition may also trigger permission request if permission state is "prompt"
            We can not avoid it, user will see permission request
          */
          if ([-1, 1, 2].includes(code)) {
            resolve('denied');
          } else {
            resolve('granted');
          }
        },
        {
          timeout: 1,
        },
      );
      return;
    }

    navigator.permissions.query({ name: 'geolocation' }).then(
      (permissionStatus) => resolve(permissionStatus.state),
      (e) => reject(e),
    );
  });
