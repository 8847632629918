import { AnswerCode } from 'services/pttv/api/constants';

export enum BetActions {
  REMOVE_BETS = '@bets/REMOVE_BETS',
  PLACE_OR_UPDATE_BET = '@bets/PLACE_OR_UPDATE_BET',
  UPDATE_BET = '@bets/UPDATE_BET',
  CLEAR_BET = '@bets/CLEAR_BET',
}

export interface PlaceBetArgs {
  contestId: string;
  propositionId: string;
  answerCode: AnswerCode;
  points: number;
  clientPoints: number;
  oneShot: boolean;
  isAllIn: boolean;
  nextTurn: boolean;
}
export interface ClearBetArgs {
  contestId: string;
  propositionId: string;
}
export interface Bet {
  contestId: string;
  propositionId: string;
  answerCode: AnswerCode;
  subAnswerCode: string;
  betPoints: number;
  payoutPoints: number;
  prologue: boolean;
  submittedTimestamp: number;
  penaltyTurn: number;
  nextTurn: boolean;
  oneShot: boolean;
  nextTurnActiveForLatestBet: boolean;
  betVersion: number;
}

export type BetsState = {
  [propositionId: string]: Bet;
};
