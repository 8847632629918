import { AnswerCode, PropositionState } from 'services/pttv/api/constants';
import { Proposition } from 'services/pttv/api/contests/types';
import { Bet, BetsState } from 'store/bets/types';

export const openPropositionFilter =
  (bets: BetsState) =>
  ({ propositionId, propositionType, state }: Proposition): boolean => {
    if (propositionType === 'MULTI_LEVEL') {
      return state === 'OPEN' || state === 'LOCKED';
    }

    return state === 'OPEN' && !bets[propositionId];
  };

export const getPropositionPayout = (proposition: Proposition, bet: Bet): number => {
  if (
    !bet ||
    proposition.state !== PropositionState.RESOLVED ||
    proposition.correctAnswerCode === AnswerCode.UNDECIDED
  ) {
    return 0;
  }

  const isUserCorrect = proposition.correctAnswerCode === bet.answerCode;
  const correctAnswer = proposition.answers.find(
    ({ code }) => code === proposition.correctAnswerCode,
  );

  let payoutPoints = 0;
  if (isUserCorrect) {
    payoutPoints = bet.payoutPoints || bet.betPoints * (correctAnswer?.pointsMultiplier || 1);

    if (!bet.oneShot) {
      payoutPoints -= bet.betPoints;
    }
  } else {
    payoutPoints = -bet.betPoints;
  }

  return payoutPoints;
};
