import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFriendInvites } from 'store/friends/selectors';
import { useAppSelector } from 'store/hooks';
import { SocialInvitedList } from 'molecules/SocialInvited/SocialInvitedList';
import { FriendInvitedCard } from 'molecules/SocialInvited/FriendInvitedCard';

export const FriendInvitedList: React.FC = () => {
  const friendInvites = useAppSelector(getFriendInvites);
  const { t } = useTranslation('SocialLanding');

  return (
    <>
      {friendInvites.length > 0 && (
        <SocialInvitedList title={t('friendInvitedList.title')}>
          {friendInvites.map(({ displayName, avatarUrl, userId }) => (
            <FriendInvitedCard
              key={displayName}
              name={displayName}
              avatarUrl={avatarUrl}
              userId={userId}
            />
          ))}
        </SocialInvitedList>
      )}
    </>
  );
};
