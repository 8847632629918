import { combineReducers } from 'redux';
import { appReducer } from './app';
import { contestHistoryReducer } from './contestHistory';
import { bannersReducer } from './banners';
import { betMetaReducer } from './betMeta';
import { betsReducer } from './bets';
import { cashBetReducer } from './cashBet';
import { roomChatReducer } from './roomChat';
import { clientPropertiesReducer } from './clientProperties';
import { contestInfoReducer } from './contestInfo';
import { contestMetaReducer } from './contestMeta';
import { contestResultsReducer } from './contestResults';
import { contestsReducer } from './contests';
import { deviceReducer } from './device';
import { facebookReducer } from './facebook';
import { feedbackReducer } from './feedback';
import { friendsReducer } from './friends';
import { gamesMetaReducer } from './gamesMeta';
import { gamesReducer } from './games';
import { genericContentReducer } from './genericContent';
import { leaderboardsMovementReducer } from './leaderboardMovement';
import { leagueAvatarsReducer } from './leagueAvatars';
import { leagueChatReducer } from './leagueChat';
import { leaguesReducer } from './leagues';
import { leagueHistoryReducer } from './leagueHistory';
import { leagueInvitesReducer } from './leagueInvites';
import { leagueLeaderboardReducer } from './leagueLeaderboard';
import { leaguesMetaReducer } from './leaguesMeta';
import { modalsReducer } from './modals';
import { playersReducer } from './players';
import { playersMetaReducer } from './playersMeta';
import { propositionsReducer } from './propositions';
import { propositionsMetaReducer } from './propositionsMeta';
import { roomsReducer } from './rooms';
import { roomSelectionReducer } from './roomSelection';
import { roomTagsReducer } from './roomTags';
import { roomTypeLeaderboardReducer } from './roomTypeLeaderboard';
import { roomTypesReducer } from './roomTypes';
import { roomTypeLeaderboardMetaReducer } from './roomTypeLeaderboardMeta';
import { sportsReducer } from './sports';
import { teamsReducer } from './teams';
import { tabsReducer } from './tabs';
import { ticketTypesReducer } from './ticketTypes';
import { ticketTypesMetaReducer } from './ticketTypesMeta';
import { toastReducer } from './toast';
import { uiReducer } from './ui';
import { userReducer } from './user';
import { userMetaReducer } from './userMeta';
import { wrapperReducer } from './wrapper';
import { winviewSettingsReducer } from './winviewSettings';
import { ticketsReducer } from './tickets';
import { unreadMessagesReducer } from './unreadMessages';

const rootReducer = combineReducers({
  contestHistory: contestHistoryReducer,
  banners: bannersReducer,
  betMeta: betMetaReducer,
  bets: betsReducer,
  cashBet: cashBetReducer,
  roomChat: roomChatReducer,
  clientProperties: clientPropertiesReducer,
  contests: contestsReducer,
  contestInfo: contestInfoReducer,
  contestMeta: contestMetaReducer,
  contestResults: contestResultsReducer,
  device: deviceReducer,
  facebook: facebookReducer,
  feedback: feedbackReducer,
  friends: friendsReducer,
  gamesMeta: gamesMetaReducer,
  games: gamesReducer,
  genericConests: genericContentReducer,
  leaderboardsMovement: leaderboardsMovementReducer,
  leagueAvatars: leagueAvatarsReducer,
  leagueChat: leagueChatReducer,
  leagues: leaguesReducer,
  leagueHistory: leagueHistoryReducer,
  leagueInvites: leagueInvitesReducer,
  leagueLeaderboard: leagueLeaderboardReducer,
  leaguesMeta: leaguesMetaReducer,
  modals: modalsReducer,
  players: playersReducer,
  playersMeta: playersMetaReducer,
  propositions: propositionsReducer,
  propositionsMeta: propositionsMetaReducer,
  rooms: roomsReducer,
  roomSelection: roomSelectionReducer,
  roomTags: roomTagsReducer,
  roomTypeLeaderboard: roomTypeLeaderboardReducer,
  roomTypes: roomTypesReducer,
  roomTypeLeaderboardMeta: roomTypeLeaderboardMetaReducer,
  sports: sportsReducer,
  tabs: tabsReducer,
  teams: teamsReducer,
  tickets: ticketsReducer,
  ticketTypes: ticketTypesReducer,
  ticketTypesMeta: ticketTypesMetaReducer,
  toast: toastReducer,
  ui: uiReducer,
  user: userReducer,
  userMeta: userMetaReducer,
  webApp: appReducer,
  winviewSettings: winviewSettingsReducer,
  wrapper: wrapperReducer,
  unreadMessages: unreadMessagesReducer,
});

export default rootReducer;
