import { createReducer } from '@reduxjs/toolkit';
import { fetchGlobalState } from '../user/actions';
import { ClientPropertiesState } from './types';

export const initialState = {} as ClientPropertiesState;

export const clientPropertiesReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchGlobalState.fulfilled, (state, { payload }) => ({
    ...state,
    ...payload.clientProperties,
  }));
});
