import { ToastPayload } from 'store/toast/types';

export enum LeagueInviteActions {
  ADD_LEAGUE_INVITATION = '@leagueInvite/ADD_LEAGUE_INVITATION',
  ADD_LEAGUE_TOAST = '@leagueInvite/ADD_LEAGUE_TOAST',
  LEAGUE_INVITE_CANCELLED = '@leagueInvite/LEAGUE_INVITE_CANCELLED',
  LEAGUE_INVITE_ACCEPT = '@leagueInvite/LEAGUE_INVITE_ACCEPT',
  LEAGUE_INVITE_DECLINE = '@leagueInvite/LEAGUE_INVITE_CANCEL',
}

export interface Inviter {
  userId: string;
  displayName: string;
  avatarUrl?: string;
}

export interface LeagueInvite {
  name: string;
  leagueId: string;
  description: string;
  avatarUrl: string;
  invitedBy: Inviter;
  activeMemberCount: number;
  affiliatedTeamId?: string;
  affiliatedSportId?: string;
}

export interface AddLeagueToastPayload {
  payload: ToastPayload;
  leagueName: string;
}

export type LeagueInvitesState = Record<string, LeagueInvite>;
