export enum CashBetActions {
  ACCOUNT_CREATED = '@cash-bet/ACCOUNT_CREATED',
  AUTO_LOGIN = '@cash-bet/AUTO_LOGIN',
  ENABLE_AUTO_LOGIN = '@cash-bet/ENABLE_AUTO_LOGIN',
  ERROR = '@cash-bet/error',
  IFRAME_CLOSED = '@cash-bet/IFRAME_CLOSED',
  IFRAME_LOADED = '@cash-bet/IFRAME_LOADED',
  IFRAME_OPENED = '@cash-bet/IFRAME_OPENED',
  RESET_ERROR = '@cash-bet/RESET_ERROR',
  SET_URLS = '@cash-bet/SET_URLS',
}

export interface CashBetError {
  type: string;
  message: string;
}

export interface CashBetState {
  autoLoginCashBet: boolean;
  autoLoginUrl: string | null;
  currentBackUrl: string | null;
  currentPageType: CashBetPageType | null;
  depositUrl: string | null;
  error: CashBetError | null;
  guestMigrateUrl: string | null;
  isLoaded: boolean;
  isServerResetting: boolean;
  kycUrl: string | null;
  loginUrl: string | null;
  transactionHistoryUrl: string | null;
  withdrawalUrl: string | null;
}

export enum CashBetPageType {
  AutoLogin = 'auto login',
  Deposit = 'deposit',
  Login = 'login',
  KYC = 'kyc',
  TransactionHistory = 'transaction history',
  Withdrawal = 'withdrawal',
}

export const CashBetIframeId = 'iframe-cashbet';
export const CashBetAutoLoginIframeId = `${CashBetIframeId}-${CashBetPageType.AutoLogin}`;
export const CashBetLoginIframeId = `${CashBetIframeId}-${CashBetPageType.Login}`;
