import { CashBetAutoLoginIframeId, CashBetLoginIframeId } from 'store/cashBet/types';
import { debugLog } from 'utils/log';

export interface CashBetUrlParams {
  urlPrefix: string;
  urlDestination: string;
  os: number;
  appsFlyerUID: string;
  deviceId: string;
}

interface CashBetMessageResult {
  // eslint-disable-next-line camelcase
  fields: { values: { flag_requested_autologin: string } }[];
  token: string;
  // eslint-disable-next-line camelcase
  user_id: string;
}

export type CashBetMessageType =
  | 'analytics_identity'
  | 'facebook_signup'
  | 'failure'
  | 'guest_register'
  | 'hide_sdk_ui'
  | 'login'
  | 'login_continue'
  | 'new_account';

export type CashBetMessage = {
  action: string;
};

export interface CashBetMessageFailure extends CashBetMessage {
  code: number;
  message: string;
  method: string;
  reason: string;
}
export interface CashBetMessageLogin extends CashBetMessage {
  message?: string;
  result: CashBetMessageResult;
}

export interface CashBetMessageHideUI extends CashBetMessage {
  message: string;
}

const formatUrlDestination = (urlDestination: string) =>
  `${urlDestination}${urlDestination.indexOf('?') > -1 ? '&' : '?'}`;

export const formatCashBetUrls = ({
  urlPrefix,
  urlDestination,
  os,
  appsFlyerUID,
  deviceId,
}: CashBetUrlParams): string =>
  `${urlPrefix}/${formatUrlDestination(
    urlDestination,
  )}app_id=${os}&sdk_type=html&device_identifier_type=${appsFlyerUID || ''}&device_id=${deviceId}`;

export const getLoginCashBetIframe = (): HTMLIFrameElement | null => {
  const element = document.getElementById(CashBetLoginIframeId);
  if (element) {
    return element as HTMLIFrameElement;
  }

  return null;
};

export const getCashBetMessageType = (event: MessageEvent): CashBetMessageType | null => {
  try {
    const message = JSON.parse(event.data);
    return message.status.action;
  } catch (error) {
    return null;
  }
};

export const parseCashBetLoginEvent = (event: MessageEvent): CashBetMessageLogin | null => {
  try {
    const message = JSON.parse(event.data);
    const { status, result } = message;

    if (result && result.result) {
      return {
        action: status.action,
        result: result.result,
      };
    }

    return {
      action: status.action,
      result: status.result.result,
    };
  } catch (error) {
    debugLog('utils/cashBet', `Login event parse error ${error}`);
    return null;
  }
};

export const parseCashBetFailureEvent = (event: MessageEvent): CashBetMessageFailure | null => {
  let message;

  try {
    message = JSON.parse(event.data);
  } catch (error) {
    debugLog('utils/cashBet', `Failure event parse error ${error}`);
    return null;
  }

  const {
    status: { action, code, message: errorMessage, method, reason },
  } = message;

  return {
    action,
    code,
    message: errorMessage,
    method,
    reason,
  };
};

export const postMessageToCashBet = (
  iframe: HTMLIFrameElement,
  action: string,
  payload: Record<string, unknown>,
): void => {
  const message = {
    action,
    payload,
  };

  debugLog('utils/cashBet', 'Post message to CashBet', message);

  iframe.contentWindow?.postMessage(message, '*');
};

export const removeAutoLoginCashBetIframe = (): void => {
  const cashBetIframe = document.getElementById(CashBetAutoLoginIframeId);
  if (cashBetIframe && cashBetIframe.parentNode) {
    cashBetIframe.parentNode.removeChild(cashBetIframe);
  }
};
