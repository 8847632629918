import { createReducer } from '@reduxjs/toolkit';
import { contestCancelled, roomCancelled } from 'store/contestInfo/actions';
import { contestProblemShown } from './actions';
import { ContestMetaState } from './types';

const initialState: ContestMetaState = {};

export const contestMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(contestCancelled.fulfilled, (state, { payload }) => {
      if (payload?.contestId) {
        state[payload.contestId] = {
          ...state[payload.contestId],
          ...payload,
          contestCancelledDialog: true,
        };
      }
    })
    .addCase(contestProblemShown, (state, { payload }) => {
      state[payload.contestId] = {
        ...state[payload.contestId],
        problem: payload.problem,
      };
    })
    .addCase(roomCancelled, (state, { payload }) => {
      const previousContest = state[payload.contestId];
      const previousRoomTypeIds =
        previousContest && previousContest.cancelledRoomTypeIds
          ? previousContest.cancelledRoomTypeIds
          : [];

      state[payload.contestId] = {
        ...state[payload.contestId],
        cancelledRoomTypeIds: [...previousRoomTypeIds, payload.roomTypeId],
        stayOnPage: !!payload.stayOnPage,
      };
    });
});
