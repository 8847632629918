import { createReducer } from '@reduxjs/toolkit';
import { fetchUserState } from '../user/actions';
import {
  createGame,
  endGame,
  removeGameById,
  resetGame,
  subscribeSuccess,
  unsubscribeSuccess,
  updateGame,
} from './actions';
import { GamesState } from './types';

export const initialState = {} as GamesState;

export const gamesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) =>
      payload.games.reduce<GamesState>(
        (acc, game) => ({
          ...acc,
          [game.gameId]: game,
        }),
        {},
      ),
    )
    .addCase(createGame, (state, { payload }) => {
      state[payload.gameId] = {
        ...payload,
        subscribed: false,
        rewardPenalties: [],
      };
    })
    .addCase(updateGame, (state, { payload }) => {
      state[payload.gameId] = {
        ...state[payload.gameId],
        ...payload,
      };
    })
    .addCase(endGame, (state, { payload }) => {
      delete state[payload];
    })
    .addCase(removeGameById, (state, { payload }) => {
      delete state[payload];
    })
    .addCase(resetGame.fulfilled, (state, { payload }) => {
      delete state[payload.gameId];
    })
    .addCase(subscribeSuccess, (state, { payload }) => {
      state[payload].subscribed = true;
    })
    .addCase(unsubscribeSuccess, (state, { payload }) => {
      state[payload].subscribed = false;
    });
});
