import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import {
  addModal,
  removeModal,
  clearModals,
  addServerFullModal,
  addServerMaintenanceModal,
} from './actions';
import { Modal, ModalsState, ModalType } from './types';

const sortModals = (a: Modal, b: Modal) => a.expirationTime - b.expirationTime;

export const initialState: ModalsState = [];

export const modalsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addModal, (state, { payload }) => {
      const id = uuid();

      // If the type is contest finished, we're going to check if there is also a contest
      // finishing opened for the same contest. If so, we're removing this one
      if (payload.type === ModalType.ContestFinished) {
        const contestFinishingModalIndex = state.findIndex(
          (modal) =>
            modal.type === ModalType.ContestFinishing &&
            (modal?.payload?.id as string) === payload.id,
        );
        if (contestFinishingModalIndex > -1) {
          state.splice(contestFinishingModalIndex, 1);
        }
      }

      state.push({
        id,
        type: payload.type,
        expirationTime: payload.expirationTime,
        payload: {
          ...payload.payload,
          id: payload.id,
        },
      });
      state.sort(sortModals);
    })
    .addCase(addServerFullModal, (state, { payload }) => {
      const id = uuid();
      state.push({
        id,
        type: ModalType.ServerFullMessage,
        expirationTime: Date.now(),
        payload: {
          message: payload.message,
        },
      });
      state.sort(sortModals);
    })
    .addCase(addServerMaintenanceModal, (state, { payload }) => {
      const id = uuid();
      state.push({
        id,
        type: ModalType.ServerMaintenanceMessage,
        expirationTime: Date.now(),
        payload: {
          message: payload.message,
        },
      });
      state.sort(sortModals);
    })
    .addCase(removeModal, (state, { payload }) => {
      state.splice(
        state.findIndex((modal) => modal.id === payload),
        1,
      );
    })
    .addCase(clearModals, () => initialState);
});
