import { playToTv } from 'services/pttv';
import { EmptyResponse } from '../types';
import type {
  GetRoomByIdRequest,
  GetRoomByIdResponse,
  GetRoomScoreRequest,
  GetRoomScoreResponse,
  GetRoomTypeDetailsRequest,
  GetRoomTypeDetailsResponse,
  JoinRoomRequest,
  JoinRoomResponse,
  JoinRoomResponseItem,
  JoinRoomsRequest,
  LeaveRoomRequest,
  SendMessageRequest,
} from './types';

export const RoomsAPI = {
  getRoomById: ({ roomId }: GetRoomByIdRequest): Promise<GetRoomByIdResponse> =>
    playToTv.get<GetRoomByIdResponse>(`winview/rooms/${roomId}`),
  getRoomScores: ({ roomId }: GetRoomScoreRequest): Promise<GetRoomScoreResponse> =>
    playToTv.get<GetRoomScoreResponse>(`winview/rooms/${roomId}/score`),
  joinRoom: (request: JoinRoomRequest): Promise<JoinRoomResponseItem> =>
    playToTv.post<JoinRoomResponseItem, JoinRoomRequest>(`winview/rooms/join`, request),
  joinMultiContest: (request: JoinRoomsRequest): Promise<JoinRoomResponse> =>
    playToTv.post<JoinRoomResponse, JoinRoomsRequest>(`winview/rooms/join/multiContest`, request),
  leaveRoom: (request: LeaveRoomRequest): Promise<EmptyResponse> =>
    playToTv.post<EmptyResponse, LeaveRoomRequest>(`winview/rooms/leave`, request),
  sendMessage: (roomId: string, request: SendMessageRequest): Promise<EmptyResponse> =>
    playToTv.post<EmptyResponse, SendMessageRequest>(
      `winview/rooms/${roomId}/sendmessage`,
      request,
    ),
  getRoomTypeDetails: ({
    roomTypeId,
  }: GetRoomTypeDetailsRequest): Promise<GetRoomTypeDetailsResponse> =>
    playToTv.get<GetRoomTypeDetailsResponse>(`winview/roomtype/${roomTypeId}`),
};
