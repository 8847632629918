import React, { Suspense, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Page, SlideIn } from 'molecules/PageWrappers';
import { PageLoader } from 'atoms/PageLoader';
import { LazyPreLoader } from 'utils/LazyPreLoader';
import { withErrorBoundary } from 'hocs/withErrorBoundary';

const lazyLoader = new LazyPreLoader();

const ProfileLandingPage = React.lazy(() => import('.'));
const DepositPage = lazyLoader.lazy(() => import('./deposit'));
const TransactionHistoryPage = lazyLoader.lazy(() => import('./transactionHistory'));
const KycPage = lazyLoader.lazy(() => import('./kyc'));
const TeamAffiliationPage = lazyLoader.lazy(() => import('./teamAffiliations'));
const WithdrawalPage = lazyLoader.lazy(() => import('./withdrawal'));
const ContestHistoryRoutes = lazyLoader.lazy(() => import('./contestHistory/routes'));

const SettingsPage = lazyLoader.lazy(() => import('./settings'));
const ContentPage = lazyLoader.lazy(() => import('./contentPage'));
const SkillRankLeaderboard = lazyLoader.lazy(() => import('./skillRankLeaderboard'));
const ContactSupportPage = lazyLoader.lazy(() => import('./contactSupport'));
const TicketsPage = lazyLoader.lazy(() => import('./tickets'));

export const profilePaths = {
  teamAffiliations: '/profile/team-affiliations',
  deposit: '/profile/deposit',
  withdrawal: '/profile/withdrawal',
  transactionHistory: '/profile/transaction-history',
  kyc: '/profile/kyc',
  settings: '/profile/settings',
  contestHistory: '/profile/contest-history',
  pageCategory: '/profile/page/:category',
  skillRank: '/profile/skill-rank',
  contactSupport: '/profile/contact-support',
  tickets: '/profile/tickets',
  profile: '/profile',
};

interface Params {
  category: string;
  gameId: string;
  backUrl: string;
  contestId: string;
  roomId: string;
}

const ProfileRoutes: React.FC = () => {
  const match = useRouteMatch<Params>(Object.values(profilePaths));
  const history = useHistory();

  useEffect(() => {
    lazyLoader.preload();
  }, []);

  return (
    <>
      <Page showHeader={false}>
        <ProfileLandingPage />
      </Page>
      <Suspense fallback={<PageLoader />}>
        <SwitchTransition mode="in-out">
          <CSSTransition
            key={match.path}
            timeout={{
              appear: history.action === 'POP' ? 0 : 500,
              enter: history.action === 'POP' ? 0 : 500,
              exit: 500,
            }}
            classNames="slidein"
            unmountOnExit
          >
            <>
              {match?.isExact && (
                <>
                  {match?.path === profilePaths.teamAffiliations && (
                    <SlideIn zIndex={1}>
                      <TeamAffiliationPage />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.deposit && (
                    <SlideIn zIndex={1}>
                      <DepositPage />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.withdrawal && (
                    <SlideIn zIndex={1}>
                      <WithdrawalPage />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.transactionHistory && (
                    <SlideIn zIndex={1}>
                      <TransactionHistoryPage />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.kyc && (
                    <SlideIn zIndex={1}>
                      <KycPage />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.settings && (
                    <SlideIn zIndex={1}>
                      <SettingsPage />
                    </SlideIn>
                  )}

                  {match?.path === profilePaths.pageCategory && (
                    <SlideIn zIndex={1}>
                      <ContentPage category={match.params.category} />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.skillRank && (
                    <SlideIn zIndex={1}>
                      <SkillRankLeaderboard />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.contactSupport && (
                    <SlideIn zIndex={1}>
                      <ContactSupportPage />
                    </SlideIn>
                  )}
                  {match?.path === profilePaths.tickets && (
                    <SlideIn zIndex={1}>
                      <TicketsPage />
                    </SlideIn>
                  )}
                </>
              )}

              {match?.path === profilePaths.contestHistory && <ContestHistoryRoutes />}
            </>
          </CSSTransition>
        </SwitchTransition>
      </Suspense>
    </>
  );
};

export default withErrorBoundary(ProfileRoutes);
