import { createReducer } from '@reduxjs/toolkit';
import { contestRoomTypesAvailableToJoinUpdated } from 'store/contests/actions';
import { joinContestAndRoomsComplete } from 'store/rooms/actions';
import { clearRoomTypeSelection, toggleRoomType } from './actions';
import type { RoomSelectionState } from './types';

export const initialState: RoomSelectionState = {};

export const roomSelectionReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearRoomTypeSelection, () => initialState)
    .addCase(joinContestAndRoomsComplete.fulfilled, () => initialState)
    .addCase(toggleRoomType, (state, { payload }) => {
      let index = -1;

      if (state[payload.contestId]) {
        index = state[payload.contestId].findIndex(
          (selection) => selection.roomTypeId === payload.roomTypeId,
        );
      } else {
        state[payload.contestId] = [];
      }

      if (index !== -1) {
        state[payload.contestId].splice(index, 1);
      } else {
        state[payload.contestId].push({ ...payload });
      }
    })
    .addCase(
      contestRoomTypesAvailableToJoinUpdated,
      (state, { payload: { contestId, roomTypeIds } }) => {
        if (state[contestId]) {
          state[contestId].filter(({ roomTypeId }) => roomTypeIds.includes(roomTypeId));
        }
      },
    );
});
