import React, { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalPage } from 'atoms/ModalPage';
import { ReportPlayerCard } from 'organisms/ReportPlayerCard';
import { ReportType } from 'services/pttv/api/users/types';
import ReportIcon from 'assets/icons/ReportIcon.svg';
import { ReportButton, ReportPlayerModalContentArea } from './ReportPlayerModal.styled';

interface Props {
  userId: string;
  type: ReportType;
  onReport?: () => void;
}

export const ReportPlayerModal: React.FC<Props> = ({ userId, onReport, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('ReportPlayerModal');

  const handleReportButtonClick = () => {
    if (!onReport) {
      setIsOpen(false);
      return;
    }
    onReport();
    setIsOpen(false);
  };

  const handleViewButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
  };

  const handleCloseButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
  };

  return (
    <>
      <ReportButton onClick={handleViewButtonClick}>
        <ReportIcon />
        {t('openButton')}
      </ReportButton>

      <ModalPage title={t('title')} isOpen={isOpen} onClose={handleCloseButtonClick}>
        <ReportPlayerModalContentArea
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <ReportPlayerCard userId={userId} callback={handleReportButtonClick} type={type} />
        </ReportPlayerModalContentArea>
      </ModalPage>
    </>
  );
};
