import { createGlobalStyle } from 'styled-components';
import { Colors, FontFamily } from './types';

export const GlobalStyle = createGlobalStyle`

  html,
  body,
  #root {
    background-color: ${({ theme }) => theme.global.backgroundColor};
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    :root {
      --font-size: 13px;

      @supports (padding-top: constant(safe-area-inset-top)) {
        --safe-area-inset-top: constant(safe-area-inset-top);
        --safe-area-inset-bottom: constant(safe-area-inset-bottom);
      }

      /* Check for env support (e.g. > iOS 11.2) */
      @supports (padding-top: env(safe-area-inset-top)) {
        --safe-area-inset-top: env(safe-area-inset-top);
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
      }
    }

    color: ${Colors.White};
    font-family: ${FontFamily.Regular};
    position: relative; /* FIX Horizontal scroll Paper on Nexus devices */
    font-size: 13px;


    @media (min-width: 340px) {
      :root {
        --font-size: calc((100vw / 414) * 16);
      }
      font-size: calc((100vw / 414) * 16);
    }

    @media (min-width: 540px) {
      :root {
        --font-size: 21px;
      }
      font-size: 21px;
    }

    @media (min-aspect-ratio: 10/16) and (max-width: 700px) {
      :root {
        --font-size: calc((100vh / 414) * 10);
      }
      font-size: calc((100vh / 414) * 10);
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    scrollbar-width: none;
  }

  *::-webkit-scrollbar {
    display: none;
  }
`;
