import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpButton } from 'atoms/HelpButton';
// eslint-disable-next-line import/no-cycle
import { ModalPage } from 'atoms/ModalPage';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import { Iframe } from 'atoms/IFrame';
import { HelpPages } from 'store/clientProperties/types';
import { useAppSelector } from 'store/hooks';
import { getClientProperty } from 'store/clientProperties/selectors';

interface Props {
  helpPageId: HelpPages;
}

export const PageHelpModal: React.FC<Props> = ({ helpPageId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const helpPageUrl = useAppSelector(getClientProperty(helpPageId));
  const { t } = useTranslation('Global');

  const titles = {
    [HelpPages.HELP_PAGE_GAME_DETAIL_URL]: t('helpPages.GameDetail'),
    [HelpPages.HELP_PAGE_JOIN_CONTEST_URL]: t('helpPages.JoinContest'),
    [HelpPages.HELP_PAGE_MAKE_PREDICTION_URL]: t('helpPages.MakePrediction'),
    [HelpPages.HELP_PAGE_SCHEDULE_URL]: t('helpPages.Schedule'),
    [HelpPages.HELP_PAGE_SOCIAL_LANDING_URL]: t('helpPages.SocialLanding'),
  };

  const handleHelpButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
  };

  const handleCloseButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
  };

  if (!helpPageUrl) {
    return null;
  }

  return (
    <>
      <HelpButton onClick={handleHelpButtonClick} />

      <ModalPage
        title={titles[helpPageId] as string}
        isOpen={isOpen}
        onClose={handleCloseButtonClick}
        closeActionType={OverlayHeaderActions.Cross}
      >
        <Iframe src={helpPageUrl} />
      </ModalPage>
    </>
  );
};
