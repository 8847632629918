import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

interface Props {
  showHeader: boolean;
}

export const PageWrapper = styled.section.attrs<Props>(({ showHeader }) => ({
  className: showHeader ? 'page' : 'page no-header',
}))<Props>`
  overflow: scroll;

  &.page > section {
    opacity: 0;
    animation: ${fadeIn} 0.3s ease-in;
    animation-fill-mode: both;
  }

  &.page-exit > section {
    animation: ${fadeOut} 0.3s ease-in;
    animation-fill-mode: both;
  }
`;

export const PageContent = styled.section`
  border: 2px dotted red;
  display: flex;
  flex-direction: column;
  grid-area: 2/1/3/2;
  overflow: hidden;
  justify-self: stretch;

  > * {
    margin-bottom: 1rem;
  }
`;
