import 'react-hot-loader'; // Make sure react-hot-loader is required before react and react-dom
import 'regenerator-runtime/runtime';
import 'services/pttv/lib';
import { PersistGate } from 'redux-persist/integration/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { persistor, store } from 'store';
import theme from 'style/theme';
import { GlobalStyle } from 'style/globalStyle';
import { DialogProvider } from 'hooks/useDialog';
import App from './App';
import { bootstrap } from './bootstrap';
import i18n from './i18n';

if (__PRODUCTION__ && window?.emconfig?.settings?.sentry?.dsn) {
  Sentry.init({
    dsn: window.emconfig.settings.sentry.dsn,
    integrations: [new BrowserTracing()],
    environment: window?.emconfig?.settings?.sentry?.environment || 'local',
    maxBreadcrumbs: 200,
    release: __SENTRY_VERSION__ || undefined,
    // TODO [EG 2020-04-19]: Are we going to change the tracesSampleRate?
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
  });
}

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <DialogProvider>
            <GlobalStyle />
            <HashRouter>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </HashRouter>
          </DialogProvider>
        </ThemeProvider>
      </I18nextProvider>
    </Provider>,
    document.getElementById('root'),
  );
};

// TODO: [EG: 2021-10-04] The setTimeout is needed to 'wait' until the playtotv server is available
setTimeout(() => {
  bootstrap(store, {
    onAppReady: () => {
      render();
    },
  });
}, 0);
