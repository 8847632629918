import { createSelector } from 'reselect';
import { RootState } from 'store/types';
import { TicketsState } from './types';

export const getTickets = (state: RootState): TicketsState => state.tickets;

export const getActiveTickets = createSelector(getTickets, (tickets) => tickets.ACTIVE || []);

export const getUsedTickets = createSelector(getTickets, (tickets) => tickets.USED || []);

export const getExpiredTickets = createSelector(getTickets, (tickets) => tickets.EXPIRED || []);

export const hasTickets = createSelector(
  getActiveTickets,
  getUsedTickets,
  getExpiredTickets,
  (activeTickets, usedTickets, expiredTickets) =>
    activeTickets.length || usedTickets.length || expiredTickets.length,
);
