import React, { useState, useEffect, RefObject } from 'react';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import { DefaultTab } from 'atoms/Tab';
import { useMemoizedTabs } from 'hooks/useMemoizedTabs';
import { DefaultTabProps, Direction } from '../types';
import {
  CarouselContainer,
  ScrollContainer,
  StyledTabBar,
  TabContentWrapper,
} from './DefaultTabs.styled';

interface Props {
  children: React.ReactElement<DefaultTabProps>[];
  getTabId?: (id: number) => void;
  contentRef?: RefObject<HTMLDivElement> | undefined;
  id: string;
}

interface Tab {
  label: string;
  showNotice: boolean;
}

export const DefaultTabs: React.FC<Props> = ({ children, getTabId, contentRef, id }) => {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [index, setIndex] = useMemoizedTabs(id);
  const [offset, setOffset] = useState(0);
  const [maxHeight, setMaxHeight] = useState('100%');

  const handleSwipeEnd = (dir: Direction) => {
    setOffset(0);
    if (
      (index > 0 && dir === Direction.Right) ||
      (index < tabs.length - 1 && dir === Direction.Left)
    ) {
      setIndex(index + dir);
    }
  };

  const handleSwiping = (event: SwipeEventData) => {
    // Ignore vertical scrolling.
    // This also fixes an issue with clicking items in the swipable area
    if (event.dir === 'Down' || event.dir === 'Up') {
      return;
    }
    if (index === 0 && event.dir === 'Right') {
      setOffset(Math.sqrt(event.deltaX) * 2);
      return;
    }
    if (index === tabs.length - 1 && event.dir === 'Left') {
      setOffset(-1 * Math.sqrt(event.absX) * 2);
      return;
    }
    if (Math.abs(Math.abs(offset) - event.absX) > 5) {
      setOffset(event.deltaX);
    }
  };

  const handlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: () => setOffset(0),
    onSwipedLeft: () => handleSwipeEnd(Direction.Left),
    onSwipedRight: () => handleSwipeEnd(Direction.Right),
    trackMouse: false,
    delta: 10,
  });

  useEffect(() => {
    // Setting the max-height is an iOS fix to make the content scroll
    if (contentRef?.current?.getBoundingClientRect) {
      setMaxHeight(`${contentRef?.current?.getBoundingClientRect().height}px`);
    }
  });

  useEffect(() => {
    const tabList = children.map((child) => ({
      label: child.props.label,
      showNotice: !!child.props.showNotice,
    }));
    setTabs(tabList);
  }, []);

  useEffect(() => {
    if (getTabId) {
      getTabId(index);
    }
  }, [index]);

  useEffect(() => {
    // When the offset is set to 0, the swipe has ended. In that case we want a transition.
    // In all other cases the swipe is going on and should it respond instant
    const duration = offset === 0 ? '0.3s' : '0';
    const left = `calc(${offset}px - ${index * 100}%)`;
    if (contentRef?.current) {
      contentRef.current.style.setProperty('--schedule-left-duration', duration);
      contentRef.current.style.setProperty('--schedule-left', left);
    }
  }, [index, offset]);

  const handleTabClick = (tabIndex: number) => {
    setOffset(0);
    setIndex(tabIndex);
  };

  return (
    <>
      <StyledTabBar>
        {tabs.map((tab, i) => (
          <DefaultTab
            key={tab.label}
            active={i === index}
            onClick={() => handleTabClick(i)}
            showNotice={tab.showNotice}
          >
            {tab.label}
          </DefaultTab>
        ))}
      </StyledTabBar>
      <TabContentWrapper {...handlers}>
        <CarouselContainer ref={contentRef} $tabCount={children.length}>
          {children.map((child) => (
            <ScrollContainer key={`container-${child.props.label}`} $maxHeight={maxHeight}>
              {child}
            </ScrollContainer>
          ))}
        </CarouselContainer>
      </TabContentWrapper>
    </>
  );
};
