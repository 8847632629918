/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'atoms/Avatar';
import { Select } from 'molecules/Form/Select';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getPlayerByUserId } from 'store/players/selectors';
import { AvatarSize } from 'atoms/Avatar/types';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { Button } from 'atoms/Button';
import { ReportReason, ReportType, ReportUserRequest } from 'services/pttv/api/users/types';
import { reportPlayer } from 'store/players/actions';
import { useMessages } from 'hooks/useMessages';
import {
  PlayerName,
  ReportPlayerForm,
  ReportPlayerHeader,
  ReportPlayerWrapper,
} from './ReportPlayerCard.styled';

interface Props {
  userId: string;
  type: ReportType;
  callback: () => void;
}

type ReportReasonList = {
  [reason in ReportReason]: string;
};

export const ReportPlayerCard: React.FC<Props> = ({ userId, type, callback }) => {
  const player = useAppSelector(getPlayerByUserId(userId));
  const dispatch = useAppDispatch();
  const { t } = useTranslation('ReportPlayerModal');
  const { handleSubmit, control, register } = useForm<ReportUserRequest>();
  const { successMessage, errorMessage } = useMessages();
  const [loading, setLoading] = useState(false);

  const reportReasons: ReportReasonList = {
    [ReportReason.CHEATING]: t('reason.cheating'),
    [ReportReason.HARASSMENT]: t('reason.harassment'),
    [ReportReason.SPAMMING]: t('reason.spamming'),
    [ReportReason.INAPPROPRIATE_NAME_OR_AVATAR]: t('reason.inappropriateNameOrAvatar'),
    [ReportReason.OTHER]: t('reason.other'),
  };

  const onSubmit = async (data: ReportUserRequest) => {
    setLoading(true);
    await dispatch(
      reportPlayer({
        request: data,
        successMessage,
        errorMessage,
      }),
    );
    setLoading(false);
    callback();
  };

  return (
    <ReportPlayerWrapper>
      <ReportPlayerHeader>
        <Avatar src={player.avatarUrl} size={AvatarSize.XL} />
        <PlayerName>{player.displayName}</PlayerName>
      </ReportPlayerHeader>
      <ReportPlayerForm>
        <input type="hidden" {...register('reportedUserId')} value={userId} />
        <input type="hidden" {...register('type')} value={type} />
        <Select
          label={t('inputReason.label')}
          name="reason"
          control={control}
          direction="column"
          rules={{ required: t('inputReason.requiredMessage') }}
        >
          <option value="">{t('inputReason.firstOption')}</option>
          {Object.entries(reportReasons).map(([value, label]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
        <ButtonBar>
          <Button
            type="submit"
            size={ButtonSize.Wide}
            scheme={ButtonSchemes.Secondary}
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('submitButton')}
          </Button>
        </ButtonBar>
      </ReportPlayerForm>
    </ReportPlayerWrapper>
  );
};
