import styled from 'styled-components';
import React from 'react';
import { FontWeight, FontSize, Colors, Padding } from 'style/types';

const Title = styled.h3`
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.ML};
  margin: 0;
`;

const Description = styled.span`
  font-weight: ${FontWeight.Regular};
  font-size: ${FontSize.MS};
  color: ${Colors.TextSub};
  margin: 0;
`;

const TitleArea = styled.section`
  display: flex;
  flex-direction: column;
  grid-area: description;
  justify-content: center;
  margin-left: ${Padding.S};
  overflow: hidden;

  ${Title}, ${Description} {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

interface Props {
  title?: string;
  description?: string;
}

export const LeagueCardTitle: React.FC<Props> = ({ title, description }) => (
  <TitleArea>
    {title && <Title>{title}</Title>}
    {description && <Description>{description}</Description>}
  </TitleArea>
);
