export enum PropositionsMetaActions {
  PROPOSITION_VIEWED = '@propositionsMeta/PROPOSITION_VIEWED',
}

export interface SetPropositionViewedArgs {
  propositionId: string;
  contestId: string;
}

export interface PropositionMeta {
  propositionId: string;
  contestId: string;
  viewed: boolean;
}

export type PropositionsMetaState = Record<string, PropositionMeta>;
