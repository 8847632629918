import styled, { css } from 'styled-components';
import { Gap } from 'style/types';

interface Props {
  direction?: 'row' | 'column';
}

export const FieldGroup = styled.section.attrs<Props>({
  className: 'field-group',
})<Props>`
  display: flex;
  flex-direction: ${({ direction = 'column' }) => direction};
  gap: ${Gap.S};

  ${({ direction }) =>
    direction === 'row' &&
    css`
      align-items: baseline;
      justify-content: space-between;

      > div,
      > input,
      > select {
        width: 60%;
      }
    `}

  + .field-group {
    margin-top: 1rem;
  }
`;
