import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Colors, FontFamily, FontSize, FontWeight, Gap, Padding, ZIndex } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

const bounceAnimation = keyframes`
  10% {
    transform: scale(1) translateY(0);
  }

  20% {
    transform: scale(1.20) translateY(-0.8rem);
  }

  23% {
    transform: scale(1.15) translateY(-0.6rem);
  }

  26% {
    transform: scale(1.25) translateY(-1rem);
  }

  40% {
    transform: scale(1) translateY(0);
  }
`;

interface Props {
  badge?: boolean;
  gameCount?: number;
}

export const IconContainerStyle = styled.div<Props>`
  align-items: center;
  border-radius: ${pxToRem(18)};
  background-color: ${({ gameCount }) => (gameCount ? Colors.Black90 : 'transparent')};
  display: flex;
  font-family: ${FontFamily.Heading};
  gap: 0.25rem;
  height: ${pxToRem(36)};
  justify-content: center;
  position: relative;
  width: ${pxToRem(80)};

  > span {
    margin-top: ${Gap.XS};
  }

  > svg {
    color: hsl(226, 18%, 33%);
    height: ${pxToRem(32)};
    position: relative;
    width: ${pxToRem(44)};

    path,
    rect {
      transition: all 0.2s;
    }

    circle {
      fill: ${({ badge }) => (badge ? Colors.Tertriary : 'transparent')};
    }
  }

  .games-icon {
    rect {
      fill: ${({ gameCount }) => (gameCount ? 'hsl(226, 18%, 33%)' : Colors.Black)};
      stroke: hsl(226, 18%, 33%);
    }

    rect:last-of-type {
      stroke: ${({ gameCount }) => (gameCount ? Colors.Black90 : 'hsl(226, 18%, 33%)')};
    }
  }

  .active && {
    background-color: ${({ gameCount }) => (gameCount ? Colors.Primary : 'transparent')};

    > svg {
      color: ${Colors.Primary};
    }

    .games-icon {
      rect {
        fill: ${({ gameCount }) => (gameCount ? Colors.White : Colors.Primary)};
        stroke: ${({ gameCount }) => (gameCount ? Colors.White : Colors.Primary)};
      }

      rect:last-of-type {
        stroke: ${({ gameCount }) => (gameCount ? Colors.Primary : Colors.Black)};
      }
    }

    .social-icon {
      path {
        fill: currentColor;
      }
    }
  }
`;

export const LabelStyle = styled.span`
  font-size: ${FontSize.S};
  padding-top: ${Padding.XXS};

  .active & {
    font-weight: ${FontWeight.Medium};
  }
`;

interface FooterItemProps {
  $bounce?: boolean;
  $active?: boolean;
}

const createClassName = (bounce: boolean, active: boolean): string => {
  let className = '';
  if (bounce) {
    className = 'bounce';
  }
  if (active) {
    className += ' active';
  }
  return className;
};

export const FooterItemStyle = styled(NavLink).attrs<FooterItemProps>(
  ({ $bounce = false, $active = false }) => ({
    className: createClassName($bounce, $active),
  }),
)<FooterItemProps>`
  align-items: center;
  color: ${Colors.White};
  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  text-decoration: none;
  width: ${pxToRem(100)};

  &.bounce {
    animation: ${bounceAnimation} 3s ease infinite;
    transform-origin: bottom center;
    z-index: ${ZIndex.FooterItem};
  }
`;
