/* eslint-disable class-methods-use-this */

import { ClientType } from 'services/pttv/api';
import { WrapperPayload } from 'store/wrapper/types';
import { debugLog } from 'utils/log';
import { vibrateViaWebApi } from 'utils/vibrateViaWebApi';
import NativeBridge from './native-bridge';
import {
  BridgeMessage,
  Coordinates,
  FacebookGraphRequestArgs,
  FacebookLoginArgs,
  FacebookLoginResult,
  LocationCoordinates,
  NativeBridgeProps,
  NativeProps,
  Subscription,
  SubscriptionHandler,
  WebProps,
  Wrapper,
} from './types';

interface DefaultState {
  promise: Promise<unknown> | null;
  listeners: unknown[];
  timer: number | null | NodeJS.Timeout;
  locationUpdated: DefaultState | Record<string, never>;
}

class NativeWrapper implements Wrapper<NativeProps> {
  bridge: NativeBridge;

  propUpdatedHandler: ((props: Partial<WrapperPayload>) => void) | null;

  state: DefaultState;

  static getDefaultStateForLocationUpdated(): DefaultState {
    return {
      promise: null,
      listeners: [],
      timer: null,
      locationUpdated: {},
    };
  }

  constructor() {
    this.state = {
      promise: null,
      listeners: [],
      timer: null,
      locationUpdated: {},
    };
    this.bridge = new NativeBridge();
    this.propUpdatedHandler = null;

    // Android deeplink
    window.nativeDeeplink = (route: string): void => {
      window.location.hash = route;
    };

    this.bridge.subscribe<(p: BridgeMessage) => void>(
      'Event.WrapperPropertyUpdated',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ({ payload: { name } }) => {
        // TODO: Where is this used
        if (this.propUpdatedHandler) {
          const wrapper = window.nativeWrapper;
          this.propUpdatedHandler({
            [name]: wrapper[name],
          } as Partial<WrapperPayload>);
        }
      },
    );

    this.state.locationUpdated = NativeWrapper.getDefaultStateForLocationUpdated();
  }

  props?: WebProps | undefined;

  onPropUpdated(callback: (props: Partial<WrapperPayload>) => void): void {
    this.propUpdatedHandler = callback;
  }

  onReady(callback: (settings: NativeProps) => void): void {
    this.bridge.getWrapperPromise().then((props: NativeBridgeProps) => {
      let { version = null } = props;
      // Android supplies version as string while iOS as number.
      if (typeof version === 'string') {
        version = parseInt(version, 10);
      }

      callback({
        appsFlyerUID: props.appsFlyerUID || null,
        appsFlyerInstallAttribution: props.appsFlyerInstallAttribution || null,
        appsFlyerOpenAttribution: props.appsFlyerOpenAttribution || null,
        capabilities: props.capabilities || [],
        cashBetServerUrl: props.cashBetServerURL || null,
        deviceModel: props.deviceModel || null,
        deviceName: props.deviceName || null,
        deviceToken: props.deviceToken || null,
        name: props.name || null,
        os: props.os ? (props.os.toUpperCase() as ClientType) : null,
        osVersion: props.osVersion || null,
        snsEndpoint: props.snsEndpoint || null,
        deviceId: props.uuid || null,
        version,
        storeVersion: props.storeVersion || null,
      });
    });
  }

  onAppPause(handler: SubscriptionHandler<unknown>): Subscription {
    return this.bridge.subscribe('Event.AppPause', handler);
  }

  onAppResume<T>(handler: SubscriptionHandler<T>): Subscription {
    return this.bridge.subscribe('Event.AppResume', handler);
  }

  onAppStop(handler: SubscriptionHandler<unknown>): Subscription {
    return this.bridge.subscribe('Event.AppStop', handler);
  }

  setupUUID(): void {
    debugLog('[Native Wrapper]', 'dos not support setupUUID');
  }

  removeAppPauseListener(subscription: Subscription): void {
    this.bridge.unsubscribe(subscription);
  }

  removeAppResumeListener(subscription: Subscription): void {
    this.bridge.unsubscribe(subscription);
  }

  removeAppStopListener(subscription: Subscription): void {
    this.bridge.unsubscribe(subscription);
  }

  // Android only
  onBackButton(handler: SubscriptionHandler<unknown>): Subscription {
    return this.bridge.subscribe('Event.BackButton', handler);
  }

  removeBackButtonListener(subscription: Subscription): void {
    this.bridge.unsubscribe(subscription);
  }

  appsFlyerTrackEvent(event: string, properties: Record<string, unknown> = {}): Promise<void> {
    return this.bridge.sendCommand('AppsFlyerTrackEvent', { event, properties });
  }

  appSettingsOpen(): Promise<void> {
    return this.bridge.sendCommand('AppSettingsOpen');
  }

  deviceGeoLocationSettingsOpen(): Promise<void> {
    return this.bridge.sendCommand('DeviceGeoLocationSettingsOpen');
  }

  // Get device location app permission enabled setting.
  isServiceAuthorized(): Promise<{ enabled: boolean }> {
    return this.bridge.sendCommand('IsLocationServiceAuthorized');
  }

  // Get device location service enabled setting.
  isServiceEnabled(): Promise<{ enabled: boolean }> {
    return this.bridge.sendCommand('IsLocationServiceEnabled');
  }

  isLocationServiceEnabled(): Promise<void> {
    return new Promise((resolve, reject) =>
      this.isServiceEnabled()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then(({ enabled: serviceEnabled }) => {
          if (serviceEnabled) {
            this.isServiceAuthorized()
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .then(({ enabled: serviceAuthorized }) => {
                if (serviceAuthorized) {
                  resolve();
                } else {
                  reject(new Error('permission_denied'));
                }
              });
          } else {
            reject(new Error('gps_disabled'));
          }
        }),
    );
  }

  locationRequestAuthorization(): Promise<void> {
    return this.bridge.sendCommand('LocationRequestAuthorization', { always: true });
  }

  locationStart(): Promise<void> {
    return this.bridge.sendCommand('LocationStart');
  }

  /**
   * This is temporary workaround because iOS wrapper not returning result.
   * This workaround will be removed in near future.
   */
  locationStartTmpWorkaround(): Promise<void> {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        resolve();
      }, 100);

      this.locationStart().then(() => {
        clearTimeout(timer);
        resolve();
      }, reject);
    });
  }

  locationStop(): Promise<void> {
    return this.bridge.sendCommand('LocationStop');
  }

  locationUpdated(
    listener: (
      { payload }: Record<string, LocationCoordinates>,
      subscription: Subscription,
    ) => void,
  ): void {
    if (!this.state?.locationUpdated?.promise) {
      this.state.locationUpdated.promise = new Promise((resolve, reject) => {
        this.state.locationUpdated.timer = setTimeout(() => {
          reject(Error('Timeout'));
          this.clearStateForLocationUpdated();
        }, 1000 * 20);
        this.bridge.subscribe('Event.LocationUpdated', resolve);
      });
    }

    const { promise, timer, listeners } = this.state.locationUpdated;
    listeners.push(listener);
    promise.then((response) => {
      clearTimeout(timer as number);
      this.clearStateForLocationUpdated();
      listeners.forEach((fn) => (fn as (p: unknown) => void)(response));
    });
  }

  getCurrentCoordinates(): Promise<Coordinates> {
    return new Promise((resolve, reject) => {
      this.locationStartTmpWorkaround().then(
        () => {
          this.locationUpdated(
            ({ payload }: Record<string, LocationCoordinates>, subscription: Subscription) => {
              this.locationStop();
              this.bridge.unsubscribe(subscription);

              resolve({
                /**
                 * tpm workaround: android wrapper returns coords as strings
                 */
                coords: {
                  latitude: parseFloat(payload.latitude as string),
                  longitude: parseFloat(payload.longitude),
                  accuracy: parseFloat(payload.horizontalAccuracy || '1'),
                },
              });
            },
          );
        },
        () => {
          reject(new Error('gps_disabled'));
        },
      );
    });
  }

  openUrl(url: string): Promise<void> {
    return this.bridge.sendCommand('BrowserOpen', {
      url,
    });
  }

  openOverlay(url: string): Promise<void> {
    return this.bridge.sendCommand('OverlayOpen', {
      url,
      headerHeight: 46,
    });
  }

  facebookGraphRequest<ReturnType>(params: FacebookGraphRequestArgs): Promise<ReturnType> {
    return this.bridge.sendCommand<ReturnType, FacebookGraphRequestArgs>('FacebookGraphRequest', {
      httpMethod: params.method || 'get',
      ...params,
    });
  }

  facebookLogin(params: FacebookLoginArgs): Promise<FacebookLoginResult> {
    return this.bridge.sendCommand<FacebookLoginResult, FacebookLoginArgs>('FacebookLogin', params);
  }

  facebookLogout(): Promise<void> {
    return this.bridge.sendCommand('FacebookLogout');
  }

  facebookShare(params: Record<string, string>): Promise<void> {
    return this.bridge.sendCommand('FacebookShare', params);
  }

  getNotificationStatus(): Promise<{ enabled: boolean; denied: boolean }> {
    return this.bridge.sendCommand('UserNotificationGetStatus');
  }

  getStorageItem(key: string): Promise<{ value?: unknown }> {
    return this.bridge.sendCommand('StorageGetItem', { key });
  }

  removeStorageItem(key: string): Promise<void> {
    return this.bridge.sendCommand('StorageRemoveItem', { key });
  }

  setStorageItem(key: string, value: string): Promise<void> {
    return this.bridge.sendCommand('StorageSetItem', { key, value });
  }

  closeApp(): Promise<void> {
    return this.bridge.sendCommand('Close');
  }

  getNotificationPermissionState(): Promise<string> {
    debugLog('[Native Wrapper]', 'No implementation for getNotificationPermissionState');

    return Promise.resolve('unknown');
  }

  segmentReady(): Promise<void> {
    return Promise.resolve();
  }

  segmentGetAnonymousId(): Promise<string> {
    return this.bridge
      .sendCommand<{ anonymousId: string }>('SegmentGetAnonymousId')
      .then(({ anonymousId }) => Promise.resolve(anonymousId));
  }

  /**
   * @param userId - string
   * @param props - object
   * @returns {Promise<void>}
   */
  segmentIdentify(userId: string, props: NativeProps): Promise<void> {
    return this.bridge.sendCommand('SegmentIdentify', { userId, traits: props });
  }

  /**
   * @param segmentName - string
   * @param segmentProperties - object
   * @returns {Promise<void, Error>}
   */
  segmentTrack(segmentName: string, segmentProperties: Record<string, unknown>): Promise<void> {
    return this.bridge.sendCommand('SegmentTrack', {
      name: segmentName,
      properties: segmentProperties,
    });
  }

  segmentReset(): Promise<void> {
    return this.bridge.sendCommand('SegmentReset');
  }

  socialShare(params: Record<string, string | number>): Promise<void> {
    const rect = document.querySelector('#root')?.getBoundingClientRect();
    if (!rect) {
      return Promise.resolve();
    }

    return this.bridge.sendCommand('ServiceChooserOpen', {
      ...params,
      sourceX: rect.left,
      sourceY: rect.top,
      sourceWidth: rect.width,
      sourceHeight: rect.height,
    });
  }

  vibrate(duration: Iterable<number>): void {
    // Command Vibrate is only on IOS, for Android fallback function is used to utilise WebApi
    const androidFallback = () => vibrateViaWebApi(duration);
    this.bridge.sendCommand('Vibrate', {}, androidFallback);
  }

  clearStateForLocationUpdated(): void {
    this.state.locationUpdated = NativeWrapper.getDefaultStateForLocationUpdated();
  }
}

export default NativeWrapper;
