import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { AnswerCode } from 'services/pttv/api/constants';
import { ContestAPI } from 'services/pttv/api/contests';
import { PlaceBetResponse } from 'services/pttv/api/contests/types';
import { PttvError } from 'services/pttv/types';
import { updateBetMeta, removeBetMeta } from 'store/betMeta/actions';
import { getBetMetaByPropositionId } from 'store/betMeta/selectors';
import { setOneShotUsed } from 'store/contestInfo/actions';
import type { RootState } from 'store/types';
import { wait } from 'utils/wait';
import { getBetByPropositionId } from './selectors';
import { BetActions, ClearBetArgs, PlaceBetArgs } from './types';

export const removeBets = createAction<string>(BetActions.REMOVE_BETS);

export const updateBet = createAction<Partial<PlaceBetResponse>>(BetActions.UPDATE_BET);

export const clearBet = createAsyncThunk<string | PttvError, UpdateFieldsArgs<ClearBetArgs>>(
  BetActions.CLEAR_BET,
  async ({ errorMessage, request }, { dispatch, getState, rejectWithValue }) => {
    const { propositionId, contestId } = request;
    const betMeta = getBetMetaByPropositionId(propositionId)(getState() as RootState);

    if (betMeta.oneShot) {
      dispatch(setOneShotUsed({ contestId, oneShotUsed: false }));
    }
    dispatch(removeBetMeta(propositionId));
    try {
      await ContestAPI.clearBet(contestId, propositionId, {
        answerCode: AnswerCode.PENDING,
        betVersion: betMeta.betVersion + 1,
        previousPoints: betMeta.betPoints,
        previousOneShot: betMeta.oneShot,
      });
      return propositionId;
    } catch (e) {
      updateBetMeta(betMeta);
      updateBet(betMeta);
      if (betMeta.oneShot) {
        dispatch(setOneShotUsed({ contestId, oneShotUsed: true }));
      }

      errorMessage(e as PttvError);
      return rejectWithValue(e as PttvError);
    }
  },
);

export const placeOrUpdateBet = createAsyncThunk<
  PlaceBetResponse | PttvError,
  UpdateFieldsArgs<PlaceBetArgs>
>(
  BetActions.PLACE_OR_UPDATE_BET,
  async ({ errorMessage, request }, { dispatch, getState, rejectWithValue }) => {
    const { contestId, propositionId, ...rest } = request;
    const betMeta = getBetMetaByPropositionId(propositionId)(getState() as RootState);
    let betVersion = 0;
    let previousPoints;
    let previousOneShot;

    if (betMeta) {
      betVersion = betMeta.betVersion + 1;

      if (betMeta.oneShot && !rest.oneShot) {
        // Reset one shot when user changes its one shot bet.
        dispatch(setOneShotUsed({ contestId, oneShotUsed: false }));
      }

      previousPoints = betMeta.betPoints;
      previousOneShot = betMeta.oneShot;
    }

    if (rest.oneShot) {
      // Reset one shot when user changes its one shot bet.
      dispatch(setOneShotUsed({ contestId, oneShotUsed: true }));
    }

    // Keep track of latest sent bet client side without needed confirmation from server response.
    dispatch(
      updateBetMeta({
        betPoints: rest.oneShot ? 0 : rest.points,
        betVersion,
        contestId,
        oneShot: rest.oneShot,
        answerCode: rest.answerCode,
        propositionId,
      }),
    );

    const requestPayload = {
      ...rest,
      betVersion,
      previousOneShot,
      previousPoints,
    };

    try {
      const response = await ContestAPI.placeBet(contestId, propositionId, requestPayload);
      const bet = getBetByPropositionId(response.propositionId)(getState() as RootState);
      if (bet && bet.betVersion > response.betVersion) {
        return rejectWithValue('Version collision');
      }
      // delay bet proposition handler for animation purpose
      await wait(500);
      return {
        ...response,
        contestId,
      };
    } catch (e) {
      dispatch(setOneShotUsed({ contestId, oneShotUsed: betMeta.oneShot }));
      dispatch(updateBetMeta(betMeta));
      errorMessage(e as PttvError);
      return rejectWithValue(e as PttvError); // Do not update with older version.
    }
  },
);
