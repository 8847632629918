import styled from 'styled-components';
import { Colors, FontFamily, FontSize, FontWeight, Gap } from 'style/types';

export const SkillRankWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${Gap.ML};
`;

export const Title = styled.h4`
  color: ${Colors.Secondary};
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.M};
  font-weight: ${FontWeight.SemiBold};
  letter-spacing: 1px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
`;
