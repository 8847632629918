import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { FriendRequestModal } from 'organisms/FriendRequestModal';
import { Name, FriendInvitedCardContainer } from './FriendInvitedCard.styled';

export interface FriendInvitedProps {
  userId: string;
  avatarUrl: string;
  name: string;
}

export const FriendInvitedCard: React.FC<FriendInvitedProps> = ({ avatarUrl, name, userId }) => {
  const { t } = useTranslation('SocialLanding');

  return (
    <FriendInvitedCardContainer>
      <Avatar src={avatarUrl} size={AvatarSize.MS} />
      <Name>{name}</Name>
      <FriendRequestModal
        buttonText={t('friendInvited.modal.viewButton')}
        title={t('friendInvited.modal.title')}
        userId={userId}
      />
    </FriendInvitedCardContainer>
  );
};
