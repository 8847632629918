import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { LeagueChatEventPayloadMessage } from 'hocs/withLongPoll/types/league';
import { LeaguesAPI } from 'services/pttv/api/leagues';
import { getUser } from 'store/user/selectors';
import type { RootState } from 'store/types';
import { LeagueChatActions, SendChatArgs } from './types';

export const addLeagueChatMessage = createAction<LeagueChatEventPayloadMessage>(
  LeagueChatActions.ADD_LEAGUE_CHAT_MESSAGE,
);

export const addNewLeagueChatMessage = createAction<LeagueChatEventPayloadMessage>(
  LeagueChatActions.ADD_NEW_LEAGUE_CHAT_MESSAGE,
);

export const removeLeagueChatMessage = createAction<string>(
  LeagueChatActions.REMOVE_LEAGUE_CHAT_MESSAGE,
);

export const sendLeagueChatMessage = createAsyncThunk<void, SendChatArgs>(
  LeagueChatActions.SEND_LEAGUE_CHAT_MESSAGES,
  ({ leagueName, text }, { getState, dispatch }) => {
    const user = getUser(getState() as RootState);

    dispatch(
      addNewLeagueChatMessage({
        leagueName,
        text,
        senderId: user.userId,
        systemMessage: false,
        timestamp: Date.now(),
      }),
    );
    LeaguesAPI.sendMessage(leagueName, { text });
  },
);

export const setSeenChatMessagesByLeague = createAction<string>(
  LeagueChatActions.SET_MESSAGES_SEEN_BY_LEAGUE,
);
