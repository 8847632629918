import React from 'react';
import { compose } from 'redux';
import { hot } from 'react-hot-loader/root';
import Routes from 'pages/routes';
import { Layout } from 'molecules/Layout';
import { withLongPoll } from 'hocs/withLongPoll';
import { withCashBet } from 'hocs/withCashBet';
import { useBackButton } from 'hooks/useBackButton';
import { useAnalytics } from 'hooks/useAnalytics';
import './style/fontFace.css';
import { useAutoUpdater } from 'hooks/useAutoUpdater';

const App = () => {
  useBackButton();
  useAnalytics();
  useAutoUpdater();

  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

export default hot(compose<typeof App>(withLongPoll, withCashBet)(App));
