import styled from 'styled-components';
import { Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export const LeagueCardActionWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: action;
  margin: ${Padding.XS};

  svg {
    height: ${pxToRem(18)};
  }
`;
