import { RoomType } from 'services/pttv/api/rooms/types';

export enum ContestMetaActions {
  CONTEST_PROBLEM_SHOWN = '@contestMeta/CONTEST_PROBLEM_SHOWN',
}

export interface ContestProblemShownPayload {
  contestId: string;
  problem: string;
}

export type ContestMeta = Record<string, unknown> & {
  cancelledRoomTypeIds: string[];
  stayOnPage: boolean;
  unregisteredRoomTypes: RoomType[];
};

export type ContestMetaState = {
  [contestId: string]: ContestMeta;
};
