import React from 'react';
import { Tickets } from 'atoms/Tickets';
import { formatCurrency } from 'utils/formatters';
import { DollarCentsAndTicketsWrapper } from './DollarCentsAndTickets.styled';

interface Props {
  dollarCents?: number;
  dollarCentsFractionDigits?: number;
  tickets?: boolean;
}

export const DollarCentsAndTickets: React.FC<Props> = ({
  dollarCents,
  tickets,
  dollarCentsFractionDigits: dollarCentsMaxDigits = 0,
}) => (
  <DollarCentsAndTicketsWrapper>
    {!dollarCents && !tickets && '- -'}
    {dollarCents ? (
      <span>{formatCurrency(dollarCents / 100, dollarCentsMaxDigits)}</span>
    ) : (
      <span>&nbsp;</span>
    )}
    {!!tickets && <Tickets />}
  </DollarCentsAndTicketsWrapper>
);
