import React, { MouseEvent } from 'react';
import { ModalPage } from 'atoms/ModalPage';
import { LeagueMemberInfo } from 'organisms/UserInfo';
import { ReportPlayerModal } from 'organisms/ReportPlayerModal';
import { ReportType } from 'services/pttv/api/users/types';
import { LeagueMember } from 'services/pttv/api/leagues/types';
import { LeagueMemberModalContentArea } from './LeagueMemberModal.styled';

interface Props {
  member: LeagueMember;
  leagueName: string;
  title: string;
  isModalOpen: boolean;
  handleCloseButtonClick: (event: MouseEvent<HTMLButtonElement>) => void;
  reportPlayerCallback: () => void;
}

export const LeagueMemberModal: React.FC<Props> = ({
  title,
  isModalOpen,
  handleCloseButtonClick,
  reportPlayerCallback,
  member,
  leagueName,
}) => (
  <ModalPage title={title} isOpen={isModalOpen} onClose={handleCloseButtonClick}>
    <LeagueMemberModalContentArea
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <LeagueMemberInfo member={member} leagueName={leagueName} />
      <ReportPlayerModal
        userId={member.userId}
        type={ReportType.FRIENDS}
        onReport={reportPlayerCallback}
      />
    </LeagueMemberModalContentArea>
  </ModalPage>
);
