import { createSelector } from 'reselect';
import { CurriedSelector, RootState } from 'store/types';
import { LeagueInvite } from './types';

const getLeagueInvites = (state: RootState) => state.leagueInvites;

export const getFlatLeagueInvites = (state: RootState): LeagueInvite[] =>
  Object.values(state.leagueInvites);

export const getLeagueInviteByName = (name: string): CurriedSelector<LeagueInvite | null> =>
  createSelector(getLeagueInvites, (leagueInvites) => leagueInvites[name]);
