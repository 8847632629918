import { createReducer } from '@reduxjs/toolkit';
import { signInWithFacebook, getFacebookFriends } from './actions';
import { FacebookState } from './types';

export const initialState: FacebookState = {
  friendIds: [],
  token: null,
};

export const facebookReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getFacebookFriends.fulfilled, (state, { payload }) => {
      state.friendIds = payload;
    })
    .addCase(signInWithFacebook.fulfilled, (state, { payload }) => {
      state.token = payload;
    });
});
