import { motion } from 'framer-motion';
import styled from 'styled-components';
import React from 'react';
import { ZIndex } from 'style/types';
import ChevronUpIcon from 'assets/icons/ChevronUpIcon.svg';

const CloseToaster = styled.div`
  align-items: center;
  content: '';
  display: flex;
  height: 2rem;
  justify-content: center;
  position: absolute;
  left: calc(50% - 1.5rem);
  top: 100%;
  width: 3rem;

  > svg {
    width: 1.5rem;
  }
`;

export const ToastWrapper = styled(motion.section).attrs(({ children }) => ({
  children: (
    <>
      {children}
      <CloseToaster>
        <ChevronUpIcon />
      </CloseToaster>
    </>
  ),
}))`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${ZIndex.Toast};
`;
