import { RoomPlayer } from 'store/user/types';

export enum LeaderboardMovementActions {
  UPDATE_FETCHED_PLAYERS_MOVEMENT = '@leaderboardMovement/UPDATE_FETCHED_PLAYERS_MOVEMENT',
  UPDATE_USER_MOVEMENT = '@leaderboardMovement/UPDATE_USER_MOVEMENT',
}

export enum LeaderboardMovements {
  Rising = 'rising',
  Descending = 'descending',
  Equal = 'equal',
}

export interface UpdateUserMovementArgs {
  user: RoomPlayer;
  contestId: string;
  roomTypeId: string;
}

export interface UpdateUserMovementPayload {
  isOnLeaderboard: boolean;
  userId: string;
  user: RoomPlayer;
  contestId: string;
  roomTypeId: string;
}

export interface UpdateFetchedPlayersMovementArgs extends UpdateUserMovementArgs {
  players: RoomPlayer[];
}

export interface UpdateFetchedPlayersMovementPayload extends UpdateUserMovementPayload {
  players: RoomPlayer[];
}

export interface ShouldUpdateAllPlayersArgs {
  contestId: string;
  roomTypeId: string;
  isOnLeaderboard: boolean;
}

export type NormalizeByFetchedPlayersArgs = Omit<UpdateFetchedPlayersMovementPayload, 'userId'>;
export type NormalizeByFetchedPlayersResult = {
  updateOnFetch: boolean;
  seen: boolean;
  players: {
    [userId: string]: LeaderBoardPlayer;
  };
  user: LeaderBoardPlayer;
};

export interface PrepareMovementArgs {
  nextRank: number;
  isOnLeaderboard: boolean;
  updateOnFetch: boolean;
  currentRank: number | null;
  previousRank: number | null;
  seen: boolean;
}

export interface LeaderBoardPlayer {
  currentRank: number | null;
  previousRank: number | null;
}

export interface LeaderboardMovement {
  updateOnFetch: boolean;
  seen: boolean;
  players: {
    [userId: string]: LeaderBoardPlayer;
  };
  user: LeaderBoardPlayer;
}

export type LeaderBoardMovementState = {
  [contestId: string]: {
    [roomTypeId: string]: LeaderboardMovement;
  };
};
