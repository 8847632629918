import { PayOutModel, RoomType } from 'services/pttv/api/rooms/types';
import { getContestById } from 'store/contests/selectors';
import { getGameById, getGameShortName } from 'store/games/selectors';
import { TrackRoomProperties } from 'store/rooms/types';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { getTicketTypeById, getTicketTypeIdsByRoomTypes } from 'store/ticketTypes/selectors';
import { SegmentEvent } from '../types';

const calculateRevenue = (roomType: RoomType): number => {
  let revenue = 0;

  if (roomType.dollarCentsRake) {
    revenue += roomType.dollarCentsRake;
  }

  return revenue / 100;
};

const toDateString = (timestamp: number) =>
  new Date(timestamp).toISOString().replace('T', ' ').substring(0, 19);

export const roomRegister: SegmentEvent = (action, prevState, nextState) => {
  const {
    preparedQueueEntry,
    roomQueueEntries,
    gameId,
    error,
    contestId,
    ticketIdsPerRoomType,
    latitude,
    longitude,
    accuracy,
    ip,
  } = action.payload as TrackRoomProperties;

  const game = getGameById(gameId)(nextState);
  const errorCode = error ? error.code || error.statusCode : null;
  const errorMessage = error ? error.message : null;
  const status = error ? 'failed' : 'success';

  const roomType = getRoomTypeById(preparedQueueEntry.roomTypeId)(nextState);
  const roomTags = roomType ? roomType.roomTags.map(({ name }) => name).sort() : [];
  const { dollarCentsPayoutModel = {} as PayOutModel } = roomType || {};
  const contest = getContestById(contestId)(nextState);
  const rake = calculateRevenue(roomType);

  let properties: Record<string, unknown> = {
    cost_value: roomType.dollarCentsBuyIn,
    em_winview_id: nextState.user.userId,
    em_actor_id: nextState.user.playerId,
    event_revenue: rake,
    event_revenue_cash_cents: rake * 100,
    latitude,
    longitude,
    accuracy,
    ip,
    game_name: getGameShortName(game.gameId)(nextState),
    game_id: game.gameId,
    game_schedule_time: toDateString(game.broadcastDate),
    cost_cash: roomType.dollarCentsBuyIn,
    joined_period: contest.period,
    rooms_selected_total: roomQueueEntries.length,
    room_tags: roomTags.join(', '),
    room_type: roomType.name,
    room_type_id: roomType.originalId,
    room_capacity: roomType.capacity,
    status,
    error_code: errorCode,
    error_message: errorMessage,
    payout_model_id_cash: dollarCentsPayoutModel?.payoutModelId || null,
    payout_model_name_cash: dollarCentsPayoutModel.name || null,
  };

  if (roomType.ticketBuyIn && ticketIdsPerRoomType && !errorCode) {
    const ticketType = getTicketTypeById(roomType.ticketBuyIn)(nextState);
    const roomTypeSpentTicketIds = getTicketTypeIdsByRoomTypes([roomType.roomTypeId])(nextState);

    /**
     * For now, there is always 1-element array. But in future it can happen
     * that there can be > 1 element
     */
    const roomTypeSpentTicketId =
      roomTypeSpentTicketIds && roomTypeSpentTicketIds.length > 0
        ? roomTypeSpentTicketIds[0]
        : null;

    /**
     * We want to track only cases when real money ticket was used
     */
    if (roomTypeSpentTicketId && ticketType.valueDollarCents > 0) {
      properties = {
        ...properties,
        rm_ticket_id_used: roomTypeSpentTicketId,
        rm_ticket_type_id_used: ticketType.ticketTypeId,
        rm_ticket_type_name_used: ticketType.displayName,
        rm_ticket_category_used: ticketType.category,
      };
    }
  }

  return {
    name: 'room_register',
    properties,
  };
};
