import React, { PropsWithChildren } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FieldGroup, Select as SelectAtom, ErrorMessage, Label } from 'atoms/Form';
import { SelectWrapper } from './select.styled';

interface Props<T> extends UseControllerProps<T> {
  label: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  direction?: 'column' | 'row';
  logo?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Select = <T extends Record<string, any>>({
  name,
  control,
  children,
  label,
  rules,
  disabled = false,
  defaultValue,
  logo,
  direction = 'row',
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <FieldGroup direction={direction}>
      <Label htmlFor={fieldName}>{label}</Label>
      <div>
        <SelectWrapper>
          {logo && <img src={logo} alt="" />}
          <SelectAtom
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={fieldName}
            ref={ref}
            disabled={disabled}
            hasLogo={!!logo}
            hasError={!!error}
          >
            {children}
          </SelectAtom>
        </SelectWrapper>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </FieldGroup>
  );
};
