import React from 'react';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Page } from 'molecules/PageWrappers';

interface RouteProps {
  path: string;
  exact?: boolean;
}
export const PageRoute: React.FC<RouteProps> = ({ path, exact, children }) => (
  <Route key={path} exact={exact} path={path}>
    {({ match }) => (
      <CSSTransition in={match != null} timeout={300} classNames="page" unmountOnExit>
        <Page>{children}</Page>
      </CSSTransition>
    )}
  </Route>
);
