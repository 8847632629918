import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { LeaderboardAPI } from 'services/pttv/api/leaderboards';
import { GetRoomTypeLeaderboardRequest } from 'services/pttv/api/leaderboards/types';
import { updateFetchedPlayersMovement } from 'store/leaderboardMovement/actions';
import {
  StoreRoomTypeLeaderboard,
  RoomTypeLeaderboardActions,
  UpdateRoomTypeAndUpdateLeaderboardArgs,
} from './types';

export const fetchRoomTypeLeaderboard = createAsyncThunk<
  StoreRoomTypeLeaderboard,
  GetRoomTypeLeaderboardRequest
>(
  RoomTypeLeaderboardActions.FETCH_ROOM_TYPE_LEADERBOARD,
  async ({ contestId, roomTypeId, update = false }, { dispatch }) => {
    const response = await LeaderboardAPI.getRoomTypeLeaderboard({ contestId, roomTypeId });

    if (update) {
      dispatch(
        updateFetchedPlayersMovement({
          players: response.top,
          user: response.user,
          contestId,
          roomTypeId,
        }),
      );
    }

    return {
      ...response,
      contestId,
      fetched: true,
    };
  },
);

export const updateRoomTypeLeaderboard = createAction<StoreRoomTypeLeaderboard>(
  RoomTypeLeaderboardActions.UPDATE_ROOM_TYPE_LEADERBOARD,
);

export const fetchRoomTypeLeaderboardAndUpdateLeaderboardMovement = createAsyncThunk<
  void,
  UpdateRoomTypeAndUpdateLeaderboardArgs
>(
  RoomTypeLeaderboardActions.UPDATE_ROOM_TYPE_AND_UPDATE_LEADERBOARD,
  ({ contestId, roomTypeId }, { dispatch }) => {
    dispatch(fetchRoomTypeLeaderboard({ contestId, roomTypeId, update: true }));
  },
);
