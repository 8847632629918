import { createReducer } from '@reduxjs/toolkit';
import { clearUser } from 'store/user/actions';
import { fetchLeagueHistory } from './actions';
import { LeagueHistoryState } from './types';

const initialState: LeagueHistoryState = {};

export const leagueHistoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(fetchLeagueHistory.fulfilled, (state, { payload, meta }) => {
      state[meta.arg] = payload.map(({ game, contests }) => ({
        game,
        contests: contests.sort((a, b) => b.openDate - a.openDate),
      }));
    });
});
