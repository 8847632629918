import React, { MouseEvent, useState } from 'react';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { ModalPage } from 'atoms/ModalPage';
import { LeagueInviteCard } from 'organisms/LeagueInviteCard';
import { LeaugeInviteModalContentArea } from './LeagueInviteModal.styled';

interface LeagueInviteModalProps {
  leagueName: string;
  title: string;
  buttonText: string;
}

export const LeagueInviteModal: React.FC<LeagueInviteModalProps> = ({
  leagueName,
  title,
  buttonText,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleViewButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
  };

  const handleCloseButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
  };

  const HandleAcceptOrIgnoreClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleViewButtonClick}
        size={ButtonSize.Tiny}
        scheme={ButtonSchemes.Secondary}
      >
        {buttonText}
      </Button>

      <ModalPage title={title} isOpen={isOpen} onClose={handleCloseButtonClick}>
        <LeaugeInviteModalContentArea>
          <LeagueInviteCard callback={HandleAcceptOrIgnoreClick} leagueName={leagueName} />
        </LeaugeInviteModalContentArea>
      </ModalPage>
    </>
  );
};
