export const formatNumber = (number: number, nbOfDigits = 2, signed = false): string => {
  const formattedNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: nbOfDigits,
    minimumFractionDigits: 0,
  }).format(number);

  if (number > 0 && signed) {
    return `+${formattedNumber}`;
  }
  return formattedNumber;
};
