import { createReducer } from '@reduxjs/toolkit';
import { fetchTicketTypesByIds } from 'store/ticketTypes/actions';
import { TicketTypesMetaState } from './types';

const updateLoadingState = (
  state: TicketTypesMetaState,
  ticketTypeIds: string[],
  loadingState: boolean,
) =>
  (ticketTypeIds || []).reduce(
    (hashMap, ticketTypeId) => ({
      ...hashMap,
      [ticketTypeId]: { ...state[ticketTypeId], loading: loadingState },
    }),
    { ...state },
  );

const initialState: TicketTypesMetaState = {};

export const ticketTypesMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTicketTypesByIds.pending, (state, { meta: args }) =>
      updateLoadingState(state, args.arg, true),
    )
    .addCase(fetchTicketTypesByIds.fulfilled, (state, { payload = [] }) =>
      updateLoadingState(
        state,
        payload.map(({ ticketTypeId }) => ticketTypeId),
        false,
      ),
    )
    .addCase(fetchTicketTypesByIds.rejected, (state, { meta: args }) =>
      updateLoadingState(state, args.arg, false),
    );
});
