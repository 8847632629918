import { createReducer } from '@reduxjs/toolkit';
import { ClientType } from 'services/pttv/api';
import { isIOS, isMobile } from 'utils/device';
import { wrapperInitSuccess, wrapperPropertyUpdated, wrapperUUIDUpdated } from './actions';
import { WrapperPayload, WrapperState } from './types';

const resolveIsIOSMobile = (
  { os, isIOSMobile }: WrapperState,
  { os: nextOs }: WrapperPayload | Partial<WrapperPayload>,
) => {
  if (!nextOs || os === nextOs) {
    return isIOSMobile;
  }

  return isIOS(nextOs as 'IOS' | 'WEB');
};

const createState = (state: WrapperState, payload: WrapperPayload | Partial<WrapperPayload>) => {
  let { os } = payload;
  if (os) {
    // Workaround to make sure os property is upper case.
    os = os.toUpperCase() as ClientType;
  }

  return {
    ...state,
    ...payload,
    isIOSMobile: resolveIsIOSMobile(state, payload),
    isMobile: isMobile(),
  };
};

const initialState = {
  appsFlyerUID: null,
  os: null,
  deviceId: null,
  deviceToken: null,
  snsEndpoint: null,
  isIOSMobile: false,
  isMobile: isMobile(),
  cashBetServerUrl: null,
  appsFlyerInstallAttribution: null,
  appsFlyerOpenAttribution: null,
  capabilities: null,
  deviceModel: null,
  deviceName: null,
  name: null,
  osVersion: null,
  storeVersion: null,
  uuid: null,
  version: null,
} as WrapperState;

export const wrapperReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(wrapperUUIDUpdated, (state, { payload }) => ({
      ...createState(state, payload as WrapperPayload),
    }))
    .addCase(wrapperInitSuccess, (state, { payload }) => ({
      ...createState(state, payload),
    }))
    .addCase(wrapperPropertyUpdated, (state, { payload }) => ({
      ...createState(state, payload),
    }));
});
