import { playToTv } from 'services/pttv';
import { League } from 'store/leagues/types';
import { EmptyResponse } from '../types';
import {
  CreateLeagueRequest,
  DemoteMemberRequest,
  GetLeagueAvatarsResponse,
  GetLeagueHistoryResponse,
  GetLeagueLeaderboardResponse,
  GetLeagueRequest,
  GetLeagueResponse,
  InviteFriendsRequest,
  LeaveLeagueRequest,
  PromoteMemberRequest,
  SendMessageRequest,
  UpdateLeagueRequest,
} from './types';

export const LeaguesAPI = {
  getLeague: ({ leagueName }: GetLeagueRequest): Promise<GetLeagueResponse> =>
    playToTv.get<GetLeagueResponse>(`winview/leagues/${leagueName}`),

  sendMessage: (leagueName: string, { text }: SendMessageRequest): Promise<EmptyResponse> =>
    playToTv.post<EmptyResponse>(`winview/leagues/${leagueName}/sendmessage`, {
      text,
    }),

  getLeagueAvatars: (): Promise<GetLeagueAvatarsResponse> =>
    playToTv.get<GetLeagueAvatarsResponse>(`winview/leagues/avatars`),

  leaveLeague: (leagueName: string, request: LeaveLeagueRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, LeaveLeagueRequest>(`winview/leagues/${leagueName}/leave`, request),

  createLeague: (request: CreateLeagueRequest): Promise<League> =>
    playToTv.post<League, CreateLeagueRequest>(`winview/leagues`, request),

  updateLeague: (leagueName: string, request: UpdateLeagueRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, UpdateLeagueRequest>(`winview/leagues/${leagueName}`, request),

  getLeagueHistory: (leagueName: string): Promise<GetLeagueHistoryResponse> =>
    playToTv.get<GetLeagueHistoryResponse>(`winview/leagues/${leagueName}/history`),

  getLeagueLeaderboard: (
    leagueName: string,
    contestId: string,
  ): Promise<GetLeagueLeaderboardResponse> =>
    playToTv.get<GetLeagueLeaderboardResponse>(
      `winview/leagues/${leagueName}/contests/${contestId}`,
    ),

  acceptInvite: (leagueName: string): Promise<League> =>
    playToTv.put<League, null>(`winview/leagues/${leagueName}/accept`, null),

  declineInvite: (leagueName: string): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, null>(`winview/leagues/${leagueName}/decline`, null),

  inviteFriends: (leagueName: string, request: InviteFriendsRequest): Promise<League> =>
    playToTv.put<League, InviteFriendsRequest>(`winview/leagues/${leagueName}/invite`, request),

  promoteMember: (leagueName: string, request: PromoteMemberRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, PromoteMemberRequest>(
      `winview/leagues/${leagueName}/promote`,
      request,
    ),

  demoteMember: (leagueName: string, request: DemoteMemberRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, DemoteMemberRequest>(
      `winview/leagues/${leagueName}/demote`,
      request,
    ),

  kickMember: (leagueName: string, request: DemoteMemberRequest): Promise<EmptyResponse> =>
    playToTv.put<EmptyResponse, DemoteMemberRequest>(`winview/leagues/${leagueName}/kick`, request),
};
