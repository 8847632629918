import { createSelector } from 'reselect';
import { ContestState, GameState, GameType } from 'services/pttv/api/constants';
import { getContestInfo } from 'store/contestInfo/selectors';
// eslint-disable-next-line import/no-cycle
import { getGameIdByContestId } from 'store/contests/selectors';
import type { ContestsState } from 'store/contests/types';
import { getFilteredSports } from 'store/gamesMeta/selectors';
import type { SportsState } from 'store/sports/types';
import type { TeamsState } from 'store/teams/types';
import type { CurriedSelector, RootState } from 'store/types';
import { formatDate } from 'utils/formatters';
import { Game, GamesState, GroupedByDateReducer } from './types';

export const sortGamesByBroadcastDate = (a: Game, b: Game): number =>
  a.broadcastDate > b.broadcastDate ? 1 : -1;

export const getGames = (state: RootState): GamesState => state.games;
export const getFlatGames = (state: RootState): Game[] => Object.values(state.games);
export const getContests = (state: RootState): ContestsState => state.contests;
export const getSports = (state: RootState): SportsState => state.sports;
export const getTeams = (state: RootState): TeamsState => state.teams;

export const getAvailableGames = createSelector(getGames, (games) =>
  Object.values(games)
    .filter(({ state }) => state !== GameState.CLOSED)
    .sort(sortGamesByBroadcastDate),
);

const isPreGame = (game: Game) => game?.gameType === GameType.PRESHOW;

export const getGameIdsByContestStates = (
  contestStates: ContestState[],
): CurriedSelector<string[]> =>
  createSelector(getContests, (contests): string[] => {
    const gameIds = new Set(
      Object.values(contests)
        .filter(({ state }) => contestStates.includes(state))
        .map(({ gameId }) => gameId),
    );
    return Array.from(gameIds.values());
  });

export const getLiveGameIds = createSelector(getFlatGames, (games) =>
  games.filter(({ gameType }) => gameType !== GameType.PRESHOW).map(({ gameId }) => gameId),
);

export const getGameById = (gameId: string): CurriedSelector<Game> =>
  createSelector(getGames, (games) => games[gameId]);

export const isGamePreGame = (gameId: string): CurriedSelector<boolean> =>
  createSelector(getGameById(gameId), (game) => isPreGame(game));

const getFilteredGames =
  (filters: string[]) =>
  (game: Game): boolean =>
    filters.indexOf(game.sportId) !== -1;

export const groupByDate = <T extends Game>(games: T[]): [string, T[]][] =>
  Object.entries(
    games.reduce<GroupedByDateReducer<T>>((acc, game) => {
      const date = formatDate(game.broadcastDate);
      return {
        ...acc,
        [date]: [...(acc[date] || []), game],
      };
    }, {}),
  );

export const sortByDate = (a: [string, Game[]], b: [string, Game[]]): 1 | -1 =>
  a[1][0].broadcastDate < b[1][0].broadcastDate ? -1 : 1;

export const getJoinedGameIdsWithoutFinishingContests = createSelector(
  getContestInfo,
  getContests,
  (contestInfo, contests) =>
    Object.values(contestInfo)
      .map(({ gameId, contestId }) => ({ gameId, contest: contests[contestId] }))
      .filter(({ contest }) => !!contest && contest.state !== ContestState.FINISHING)
      .map(({ gameId }) => gameId)
      .filter((elem, pos, arr) => elem && arr.indexOf(elem) === pos), // Filter duplicates.
);

export const getOpenGames = createSelector(
  getGameIdsByContestStates([ContestState.UPCOMING, ContestState.OPEN]),
  getFlatGames,
  (openGameIds, games) => games.filter((game) => openGameIds.includes(game.gameId)),
);

export const getUpcomingGames = createSelector(getFlatGames, (games) =>
  games.filter(({ state }) => state === GameState.NEW),
);

export const getPendingGames = createSelector(
  getFlatGames,
  getGameIdsByContestStates([ContestState.UPCOMING, ContestState.OPEN]),
  getJoinedGameIdsWithoutFinishingContests,
  (games, openGameIds, joinedGameIds) =>
    games.filter(
      ({ gameId, state }) =>
        state === GameState.OPEN &&
        !openGameIds.includes(gameId) &&
        !joinedGameIds.includes(gameId),
    ),
);

export const getOpenPreGames = createSelector(getOpenGames, (games): Game[] =>
  games.filter((game) => isPreGame(game)),
);

export const getFilteredOpenPreGames = createSelector(
  getOpenPreGames,
  getFilteredSports,
  (games, sportFilter): Game[] =>
    sportFilter.length > 0 ? Object.values(games).filter(getFilteredGames(sportFilter)) : games,
);

export const getFilteredOpenPreGamesGroupedByDate = createSelector(
  getFilteredOpenPreGames,
  (games) => groupByDate(games).sort(sortByDate),
);

export const getUpcomingPreGames = createSelector(getUpcomingGames, (games): Game[] =>
  games.filter((game) => isPreGame(game)),
);

export const hasUpcomingPreGames = createSelector(
  getUpcomingPreGames,
  (games): boolean => games.length > 0,
);

export const getFilteredUpcomingPreGames = createSelector(
  getUpcomingPreGames,
  getFilteredSports,
  (games, sportFilter): Game[] =>
    sportFilter.length > 0 ? Object.values(games).filter(getFilteredGames(sportFilter)) : games,
);

export const getFilteredUpcomingPreGamesGroupedByDate = createSelector(
  getFilteredUpcomingPreGames,
  (games) => groupByDate(games).sort(sortByDate),
);

export const getUpcomingLiveGames = createSelector(getUpcomingGames, (games): Game[] =>
  games.filter((game) => !isPreGame(game)),
);

export const hasUpcomingLiveGames = createSelector(
  getUpcomingLiveGames,
  (games): boolean => games.length > 0,
);

export const getOpenLiveGames = createSelector(getOpenGames, (games): Game[] =>
  games.filter((game) => !isPreGame(game)),
);

export const getPendingLiveGames = createSelector(getPendingGames, (games): Game[] =>
  games.filter((game) => !isPreGame(game)),
);

export const getFilteredUpcomingLiveGames = createSelector(
  getUpcomingLiveGames,
  getFilteredSports,
  (games, sportFilter): Game[] =>
    sportFilter.length > 0 ? Object.values(games).filter(getFilteredGames(sportFilter)) : games,
);

export const getFilteredOpenLiveGames = createSelector(
  getOpenLiveGames,
  getFilteredSports,
  (games, sportFilter): Game[] =>
    sportFilter.length > 0 ? Object.values(games).filter(getFilteredGames(sportFilter)) : games,
);

export const getFilteredPendingLiveGames = createSelector(
  getPendingLiveGames,
  getFilteredSports,
  (games, sportFilter): Game[] =>
    sportFilter.length > 0 ? Object.values(games).filter(getFilteredGames(sportFilter)) : games,
);

export const getFilteredUpcomingLiveGamesGroupedByDate = createSelector(
  getFilteredUpcomingLiveGames,
  (games) => groupByDate(games).sort(sortByDate),
);

export const getFilteredOpenLiveGamesGroupedByDate = createSelector(
  getFilteredOpenLiveGames,
  (games) => groupByDate(games).sort(sortByDate),
);

export const getFilteredPendingLiveGamesGroupedByDate = createSelector(
  getFilteredPendingLiveGames,
  (games) => groupByDate(games).sort(sortByDate),
);

export const hasPendingLiveGames = createSelector(
  getFilteredPendingLiveGames,
  (games): boolean => games.length > 0,
);

export const joinedGameContestId = (gameId: string): CurriedSelector<string | undefined> =>
  createSelector(
    getContestInfo,
    (contestInfo) =>
      Object.values(contestInfo).find((contest) => contest.gameId === gameId)?.contestId,
  );

export const getJoinedGameIds = createSelector(
  getContestInfo,
  (contestInfo) =>
    Object.values(contestInfo)
      .map(({ gameId }) => gameId)
      .filter((elem, pos, arr) => elem && arr.indexOf(elem) === pos), // Filter duplicates.
);

export const getJoinedGames = createSelector(getGames, getJoinedGameIds, (games, gameIds) =>
  gameIds
    .map((gameId) => games[gameId])
    .reduce<GamesState>(
      (acc, game) => ({
        ...acc,
        [game.gameId]: game,
      }),
      {},
    ),
);

export const getJoinedLiveGames = createSelector(getGames, getJoinedGameIds, (games, gameIds) => {
  const joinedGames = Object.values(games).filter(
    ({ gameId, gameType }) => gameType !== GameType.PRESHOW && gameIds.includes(gameId),
  );
  return groupByDate(joinedGames).sort(sortByDate);
});

export const getJoinedPreGames = createSelector(
  getOpenPreGames,
  getJoinedGameIds,
  (games, gameIds) => {
    const joinedGames = Object.values(games).filter(({ gameId }) => gameIds.includes(gameId));
    return groupByDate(joinedGames).sort(sortByDate);
  },
);

export const getAwaitingGameIds = createSelector(
  getJoinedGames,
  getGameIdsByContestStates([ContestState.FINISHING]),
  (games, PendingGameIds) => {
    const pendingGames = PendingGameIds.filter(
      (gameId) => games[gameId]?.gameType === GameType.PRESHOW,
    ).map((gameId) => games[gameId]);
    return groupByDate(pendingGames).sort(sortByDate);
  },
);

export const hasJoinedGames = createSelector(
  getJoinedLiveGames,
  getJoinedPreGames,
  (liveGames, preGames) => [...liveGames, ...preGames].length > 0,
);

export const getGameByContestId = (contestId: string): CurriedSelector<Game | null> =>
  createSelector(getGames, getGameIdByContestId(contestId), (games, gameId) => {
    if (!gameId) {
      return null;
    }

    return games[gameId];
  });

export const getGameNameByTeamsAndSports = (
  game: Game,
  teams: TeamsState,
  sports: SportsState,
  defaultName = '',
): string => {
  const awayTeam = teams[game.awayTeamId];
  const homeTeam = teams[game.homeTeamId];
  const { sportTeamSeparator: teamSeparator } = sports[game.sportId] || {};

  if (awayTeam && homeTeam) {
    switch (teamSeparator) {
      case 'AT':
        return `${awayTeam.abbreviation} @ ${homeTeam.abbreviation}`;
      case 'VERSUS':
        return `${awayTeam.abbreviation} vs. ${homeTeam.abbreviation}`;
      default:
        return `${awayTeam.abbreviation} ${homeTeam.abbreviation}`;
    }
  }
  return defaultName;
};

export const getGameShortName = (gameId: string): CurriedSelector<string> =>
  createSelector(getGameById(gameId), getTeams, getSports, (game, teams, sports) =>
    getGameNameByTeamsAndSports(game, teams, sports, game.description),
  );
