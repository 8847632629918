import { createSelector } from 'reselect';
import { LeagueMemberInvited, LeagueMemberStatus } from 'services/pttv/api/constants';
import { Friend } from 'services/pttv/api/friends/types';
import { LeagueMember } from 'services/pttv/api/leagues/types';
import { getFlatFriends } from 'store/friends/selectors';
import { getSportById } from 'store/sports/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import { getUser } from 'store/user/selectors';
import { formatOrdinals } from 'utils/formatters';
import { GroupedLeagueMembers, GroupedLeagueMembersObject, League } from './types';

const getLeagues = (state: RootState) => state.leagues;

export const getLeagueNames = createSelector(getLeagues, (leagues) => Object.keys(leagues));

export const getFilteredLeagueNames = createSelector(getLeagues, (leagues) =>
  Object.entries(leagues)
    .filter(([, value]) => !!value)
    .map(([name]) => name),
);

export const getLeagueByName = (name: string): CurriedSelector<League | null> =>
  createSelector(getLeagues, (leagues) => leagues[name]);

export const getLeagueCommissionerId = (leagueName: string): CurriedSelector<string | null> =>
  createSelector(getLeagueByName(leagueName), (league) => {
    if (!league) {
      return null;
    }
    const [commissioner] = (league as League).members.filter(
      (member) => member.status === LeagueMemberStatus.COMMISSIONER,
    );

    return commissioner.userId;
  });

export const isUserCommissioner = (leagueName: string): CurriedSelector<boolean> =>
  createSelector(
    getLeagueCommissionerId(leagueName),
    getUser,
    (commissionerId, user) => commissionerId === user.userId,
  );

export const getAcceptedMembers = (leagueName: string): CurriedSelector<LeagueMember[]> =>
  createSelector(getLeagueByName(leagueName), (league) =>
    (league?.members || []).filter((member) => member.invite === LeagueMemberInvited.ACCEPTED),
  );

export const getAcceptedMembersCount = (leagueName: string): CurriedSelector<number> =>
  createSelector(
    getAcceptedMembers(leagueName),
    (members) =>
      members.reduce<Set<string>>((acc, member) => {
        acc.add(member.userId);
        return acc;
      }, new Set()).size,
  );

export const getPendingMembers = (leagueName: string): CurriedSelector<LeagueMember[]> =>
  createSelector(getLeagueByName(leagueName), (league) =>
    (league?.members || []).filter((member) => member.invite === LeagueMemberInvited.INVITED),
  );

export const getAvailableFriends = (leagueName: string): CurriedSelector<Friend[]> =>
  createSelector(getLeagueByName(leagueName), getFlatFriends, (league, friends) => {
    if (league === null) {
      return friends;
    }

    return friends.filter(
      (friend) => !league.members.some((member) => member.userId === friend.userId),
    );
  });

export const getUniqueAcceptedMembers = (leagueName: string): CurriedSelector<LeagueMember[]> =>
  createSelector(getAcceptedMembers(leagueName), (members) =>
    Object.values(
      members.reduce<Record<string, LeagueMember>>((acc, member) => {
        if (acc[member.userId]) {
          return acc;
        }
        return {
          ...acc,
          [member.userId]: member,
        };
      }, {}),
    ),
  );

export const getMembersGroupedByContest = (
  leagueName: string,
): CurriedSelector<GroupedLeagueMembers> =>
  createSelector(
    getLeagueByName(leagueName),
    (state: RootState) => state,
    (league, state) =>
      Object.values(
        league?.members.reduce<GroupedLeagueMembersObject>((acc, member) => {
          if (member.invite !== LeagueMemberInvited.ACCEPTED) {
            return acc;
          }

          const groupedMembers = { ...acc };

          if (!groupedMembers[member.currentContestId]) {
            const sport = getSportById(member.currentContestSportId)(state);

            // eslint-disable-next-line no-param-reassign
            groupedMembers[member.currentContestId] = {
              description: member.currentContestDescription,
              sportId: member.currentContestSportId,
              avatarUrl: sport?.avatarUrl || '',
              period: `${formatOrdinals(member.currentContestPeriod)} ${sport?.period || ''}`,
              members: [],
            };
          }
          groupedMembers[member.currentContestId].members.push(member);
          return groupedMembers;
        }, {}) || {},
      ),
  );
