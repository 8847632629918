/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { ConfirmOptions, DialogState, AlertOptions } from './types';

export const initialState: DialogState = {
  openAlert: false,
  openConfirm: false,
  title: '',
  content: null,
  callBack: () => {},
  closeAlert: () => {},
  showAlert: (title: string, content: string | JSX.Element, options?: AlertOptions) => {},
  showConfirm: (title: string, content: string | JSX.Element, options: ConfirmOptions) => {},
};

export const DialogContext = createContext(initialState);
