import { RoomType } from 'services/pttv/api/rooms/types';
import { TrackRoomProperties } from 'store/rooms/types';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { AppsFlyerEvent } from '../types';

const calculatePrizePool = (roomType: RoomType) => {
  let cost = 0;
  const { dollarCentsPrizePool } = roomType;

  if (dollarCentsPrizePool) {
    cost += dollarCentsPrizePool;
  }

  return cost / 100;
};

const calculateRevenue = (roomType: RoomType): number => {
  let revenue = 0;

  if (roomType.dollarCentsRake) {
    revenue += roomType.dollarCentsRake;
  }

  return revenue / 100;
};

export const roomRegisterType: AppsFlyerEvent = (action, prevState, nextState) => {
  const { preparedQueueEntry } = action.payload as TrackRoomProperties;
  const roomType = getRoomTypeById(preparedQueueEntry.roomTypeId)(nextState);
  const cost = calculatePrizePool(roomType);
  const rake = calculateRevenue(roomType);
  const name = roomType.dollarCentsBuyIn ? 'room_register_cash' : 'room_register_free';

  return {
    name,
    properties: {
      cost,
      af_revenue: rake,
    },
  };
};
