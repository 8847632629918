import Joi from 'joi';
import { getUser } from 'store/user/selectors';
import { PlaceBetResponse } from 'services/pttv/api/contests/types';
import { getPropositionById } from 'store/propositions/selectors';
import { getGameByContestId, getGameShortName } from 'store/games/selectors';
import { getSportByContestId } from 'store/sports/selectors';
import { getContestById } from 'store/contests/selectors';
import { SegmentEvent } from '../types';

interface PlaceBetAction {
  type: string;
  payload: PlaceBetResponse;
}

export const placeBet: SegmentEvent = (action, prevState, nextState) => {
  const user = getUser(nextState);
  const { propositionId } = (action as unknown as PlaceBetAction).payload;
  const proposition = getPropositionById(propositionId || '')(nextState);
  const contest = getContestById(proposition.contestId)(nextState);
  const game = getGameByContestId(proposition.contestId)(nextState);
  const gameName = getGameShortName(game?.gameId || '')(nextState);
  const sport = getSportByContestId(proposition.contestId)(nextState);

  return {
    name: 'place_bet',
    properties: {
      em_actor_id: user.playerId,
      em_winview_id: user.userId,
      joined_game_id: game?.gameId,
      joined_game_name: gameName,
      joined_game_sport: sport?.name,
      joined_room_period: contest.period,
    },
  };
};

const placeBetEventSchema = Joi.object({
  em_actor_id: Joi.number().required(),
  em_winview_id: Joi.string().required(),
  joined_game_id: Joi.string().required(),
  joined_game_name: Joi.string().required(),
  joined_game_sport: Joi.string().required(),
  joined_room_period: Joi.number().integer().required(),
});

export const isValidPlaceBetEvent = (event: Record<string, string | number>): boolean => {
  const result = placeBetEventSchema.validate(event.properties);
  return !result.error;
};
