import { createReducer } from '@reduxjs/toolkit';
import { endGame, resetGame } from 'store/games/actions';
import { joinContestAndRoomsComplete, leaveRoom } from 'store/rooms/actions';
import { RoomActions } from 'store/rooms/types';
import { clearUser, fetchUserState } from '../user/actions';
import { addRoom, contestEnded, removeContestInfo, setOneShotUsed, updatePoints } from './actions';
import { ContestInfoActions, ContestInfoState } from './types';
import { mergeRoomTypeIds, normalize, normalizeRoomTypes } from './utils';

const initialContestInfo = {
  contestId: null,
  points: 0,
  invested: 0,
  streakCount: 0,
  streakBonus: 0,
  oneShotUsed: false,
  rooms: [],
  roomQueueEntries: [],
  roomTypeLeaderboards: [],
  // Map of joined room types
  roomTypeIds: {},
};

const removeRoomActions = [
  ContestInfoActions.ROOM_CANCELLED,
  `${RoomActions.LEAVE_ROOM}/fulfilled`,
];

export const initialState = {} as ContestInfoState;

export const contestInfoReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUserState.fulfilled, (state, { payload }) => {
      const { contests } = (payload.currentGamesInfo && payload.currentGamesInfo[0]) || {};
      if (contests?.length) {
        return normalize(contests);
      }

      return initialState;
    })
    .addCase(resetGame.fulfilled, (state, { payload }) => {
      const { contestIds } = payload;

      contestIds.forEach((contestId) => {
        if (state[contestId]) {
          delete state[contestId];
        }
      });
    })
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(endGame, () => initialState)
    .addCase(joinContestAndRoomsComplete.fulfilled, (state, { payload }) => {
      const prevContestInfo = state[payload.contestId] || {};
      let { roomQueueEntries } = prevContestInfo;

      if (payload.roomQueueEntries) {
        roomQueueEntries = [...(roomQueueEntries || []), ...payload.roomQueueEntries];
      }

      state[payload.contestId] = {
        ...prevContestInfo,
        ...payload,
        roomTypeLeaderboards: payload.roomTypeLeaderboardUpdates.map(
          ({ roomTypeId, roomTypeLeaderboardData: { user, top, totalPlayers } }) => ({
            top,
            roomTypeId,
            user,
            totalPlayers,
            fetched: true,
          }),
        ),
        rooms: prevContestInfo.rooms || [],
        roomQueueEntries: roomQueueEntries || [],
        roomTypeIds: { ...prevContestInfo.roomTypeIds, ...normalizeRoomTypes(roomQueueEntries) },
      };
    })
    .addCase(updatePoints, (state, { payload }) => {
      const { contestId, points } = payload;
      const contestInfo = state[contestId] || initialContestInfo;

      return {
        ...state,
        [contestId]: {
          ...contestInfo,
          contestId,
          points,
        },
      };
    })
    .addCase(setOneShotUsed.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      const { contestId, oneShotUsed } = payload;

      state[contestId] = {
        ...(state[contestId] || initialContestInfo),
        oneShotUsed,
      };
    })
    .addCase(contestEnded, (state, { payload }) => {
      const contestInfo = state[payload];

      state[payload] = {
        ...contestInfo,
        contestId: payload,
        roomQueueEntries: [],
      };
    })
    .addCase(removeContestInfo, (state, { payload }) => {
      if (state[payload]) {
        delete state[payload];
      }
    })
    .addCase(addRoom.fulfilled, (state, { payload }) => {
      const { contestId, roomId, roomTypeId } = payload;
      const prevContestInfo = state[payload.contestId];
      const roomQueueEntries = [...prevContestInfo.roomQueueEntries];

      const index = roomQueueEntries.findIndex(
        (queuedRoom) => queuedRoom.roomTypeId === roomTypeId,
      );
      if (index > -1) {
        roomQueueEntries.splice(index, 1);
      }

      state[contestId] = {
        ...prevContestInfo,
        roomQueueEntries,
        rooms: [...(prevContestInfo.rooms as string[]), roomId],
      };
    })
    .addCase(leaveRoom.fulfilled, (state, { payload }) => {
      const prevContestInfo = state[payload.contestId];
      if (!state[payload.contestId]) {
        return;
      }
      const index = prevContestInfo.roomQueueEntries.findIndex(
        ({ roomQueueId }) => roomQueueId === payload.roomQueueId,
      );

      if (index === -1) {
        return;
      }

      // already joined rooms should be kept
      const currentRoomTypeIds = Object.keys(prevContestInfo.roomTypeIds).filter(
        (roomTypeId) => roomTypeId !== payload.roomTypeId,
      );

      const nextRoomQueueEntries = [...prevContestInfo.roomQueueEntries];
      nextRoomQueueEntries.splice(index, 1);

      state[payload.contestId] = {
        ...prevContestInfo,
        roomQueueEntries: nextRoomQueueEntries,
        roomTypeIds: mergeRoomTypeIds(normalizeRoomTypes(nextRoomQueueEntries), currentRoomTypeIds),
      };
    })
    .addMatcher(
      (action) => removeRoomActions.includes(action.type),
      (state, { payload }) => {
        const prevContestInfo = state[payload.contestId];
        if (!prevContestInfo) {
          return;
        }

        const index = prevContestInfo.roomQueueEntries.findIndex(
          ({ roomQueueId }) => roomQueueId === payload.roomQueueId,
        );
        if (index === -1) {
          return;
        }

        // already joined rooms should be kept
        const currentRoomTypeIds = Object.keys(prevContestInfo.roomTypeIds).filter(
          (roomTypeId) => roomTypeId !== payload.roomTypeId,
        );

        const nextRoomQueueEntries = [...prevContestInfo.roomQueueEntries];
        nextRoomQueueEntries.splice(index, 1);

        state[payload.contestId] = {
          ...state[payload.contestId],
          roomQueueEntries: nextRoomQueueEntries,
          roomTypeIds: currentRoomTypeIds.reduce<Record<string, boolean>>(
            (acc, roomTypeId) => ({
              ...acc,
              [roomTypeId]: true,
            }),
            normalizeRoomTypes(nextRoomQueueEntries),
          ),
        };
      },
    );
});
