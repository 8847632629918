import { createReducer } from '@reduxjs/toolkit';
import { fetchGlobalState } from '../user/actions';
import { WinViewSettingsState } from './types';

export const initialState = {} as WinViewSettingsState;

export const winviewSettingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchGlobalState.fulfilled, (state, { payload }) => ({
    ...state,
    ...payload.winviewSettings,
  }));
});
