import React from 'react';
import { ContentArea } from 'atoms/ContentArea';
import { Pill } from 'atoms/Pill';
import { Colors } from 'style/types';
import { FriendInvitedProps } from '../FriendInvitedCard';
import { LeagueInvitedCardProps } from '../LeagueInvitedCard';
import { InvitedList, InvitedListHeader } from './invited.styled';

interface Props {
  title: string;
  children: React.ReactElement<LeagueInvitedCardProps | FriendInvitedProps>[];
}

export const SocialInvitedList: React.FC<Props> = ({ title, children }) => (
  <ContentArea>
    <InvitedListHeader>
      {title}
      <Pill backgroundColor={Colors.Tertriary}>{children.length}</Pill>
    </InvitedListHeader>
    <InvitedList>{children}</InvitedList>
  </ContentArea>
);
