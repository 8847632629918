export enum FeedbackActions {
  SEND_FEEDBACK = '@feedback/SEND_FEEDBACK',
}

export interface ContactSupportPayload {
  email: string;
  category: string;
  message: string;
}

export interface FeedbackState {
  loading: boolean;
}
