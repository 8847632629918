import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/formatters';
import { DollarCentsAndTickets } from 'atoms/DollarCentsAndTickets';
import { Table, TableCell, TableHeadCell, TableRow } from 'atoms/Table';
import { getFlatContestResultsById } from 'store/contestResults/selectors';
import { useAppSelector } from 'store/hooks';
import {
  ContestResultContainer,
  Points,
  PointsDescription,
  PointsSection,
  Rank,
  RoomName,
} from './ContestResults.styled';

interface Props {
  contestId: string;
}

export const ContestResults: React.FC<Props> = ({ contestId }) => {
  const results = useAppSelector(getFlatContestResultsById(contestId));
  const { t } = useTranslation('ContestModals');

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <ContestResultContainer>
      <PointsSection>
        <PointsDescription>{t('contestFinished.pointsDescription')}</PointsDescription>
        <Points>{formatNumber(results[0].points)}</Points>
      </PointsSection>

      <Table>
        <thead>
          <TableRow>
            <TableHeadCell width="15%" align="center">
              {t('contestFinished.resultsTable.rank')}
            </TableHeadCell>
            <TableHeadCell>{t('contestFinished.resultsTable.room')}</TableHeadCell>
            <TableHeadCell width="20%"> {t('contestFinished.resultsTable.winnings')}</TableHeadCell>
          </TableRow>
        </thead>
        <tbody>
          {results.map(({ rank, roomName, dollarCentsWon, ticketWon }) => (
            <TableRow key={roomName}>
              <TableCell align="center">
                <Rank>{rank}</Rank>
              </TableCell>
              <TableCell>
                <RoomName>{roomName}</RoomName>
              </TableCell>
              <TableCell align="center">
                <DollarCentsAndTickets dollarCents={dollarCentsWon} tickets={!!ticketWon} />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </ContestResultContainer>
  );
};
