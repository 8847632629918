import React, { useEffect, useState } from 'react';
import { clamp } from 'popmotion';
import { useTranslation } from 'react-i18next';
import { SkillRank } from 'molecules/SkillRank';
import { TeamCarousel } from 'molecules/TeamCarousel';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ContentArea } from 'atoms/ContentArea';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { CircularProgress } from 'atoms/CircularProgress';
import { getPlayerByUserId } from 'store/players/selectors';
import { getFriendByUserId } from 'store/friends/selectors';
import { acceptFriendInvite, cancelFriendInvite } from 'store/friends/actions';
import { FriendPendingSubState, FriendState } from 'services/pttv/api/constants';
import { ButtonBar } from 'atoms/ButtonBar';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { Button } from 'atoms/Button';
import { ButtonBarDirections } from 'style/types';
import { useMessages } from 'hooks/useMessages';
import {
  FrienRequestContainer,
  FriendRequestHeader,
  Title,
  SkillRankArea,
  TeamsTitle,
  PlayerId,
  PlayerXP,
} from '../UserInfo.styled';

interface Props {
  userId: string;
  callback: () => void;
}

export const FriendInfo: React.FC<Props> = ({ userId, callback }) => {
  const player = useAppSelector(getPlayerByUserId(userId));
  const friend = useAppSelector(getFriendByUserId(userId));
  const [hasAffiliatedTeams, setHasAffiliatedTeams] = useState(false);
  const { t } = useTranslation('FriendRequestModal');
  const dispatch = useAppDispatch();
  const { errorMessage, successMessage } = useMessages();

  if (!player) {
    return null;
  }

  useEffect(() => {
    setHasAffiliatedTeams(Object.keys(player.affiliatedTeams).length > 0);
  }, [player]);

  const xpLevelProgress = clamp(0, 100, player.percentageToNextRank || 0);

  const handleCancelFriendClick = async () => {
    await dispatch(
      cancelFriendInvite({
        request: userId,
        errorMessage,
        successMessage,
      }),
    );
    callback();
  };

  const handleAcceptInviteClick = async () => {
    await dispatch(acceptFriendInvite(userId));
    callback();
  };

  return (
    <FrienRequestContainer>
      <FriendRequestHeader>
        <PlayerId>
          {t('playerId.label')}
          <span>{player.inviteCode}</span>
        </PlayerId>
        <PlayerXP>
          {t('experience.label')}
          <span>{player.rank}</span>
        </PlayerXP>
        <CircularProgress size={100} min={0} max={100} value={xpLevelProgress} trackWidth={2}>
          <Avatar src={player.avatarUrl} size={AvatarSize.XXL} />
        </CircularProgress>
        <Title>{player.displayName}</Title>
        <SkillRankArea>
          <SkillRank userId={player.userId} title={t('skillRank.label')} />
        </SkillRankArea>
      </FriendRequestHeader>
      {hasAffiliatedTeams && (
        <ContentArea>
          <TeamsTitle>{t('affiliatedTeams.label')}</TeamsTitle>
          <TeamCarousel userId={userId} />
        </ContentArea>
      )}
      <ContentArea as="footer">
        {friend?.state === FriendState.ACCEPTED && (
          <ButtonBar direction={ButtonBarDirections.Row}>
            <Button
              scheme={ButtonSchemes.Grey}
              size={ButtonSize.Wide}
              onClick={handleCancelFriendClick}
            >
              {t('accepted.removeFriendButton')}
            </Button>
          </ButtonBar>
        )}
        {friend?.pendingSubState === FriendPendingSubState.INVITING && (
          <ButtonBar direction={ButtonBarDirections.Row}>
            <Button
              scheme={ButtonSchemes.Grey}
              size={ButtonSize.Wide}
              onClick={handleCancelFriendClick}
            >
              {t('inviting.cancelButton')}
            </Button>
          </ButtonBar>
        )}
        {friend?.pendingSubState === FriendPendingSubState.BEING_INVITED && (
          <ButtonBar direction={ButtonBarDirections.Row}>
            <Button scheme={ButtonSchemes.Warning} onClick={handleCancelFriendClick}>
              {t('invited.ignoreButton')}
            </Button>
            <Button scheme={ButtonSchemes.Secondary} onClick={handleAcceptInviteClick}>
              {t('invited.acceptButton')}
            </Button>
          </ButtonBar>
        )}
      </ContentArea>
    </FrienRequestContainer>
  );
};
