import styled, { css } from 'styled-components';
import { BorderWidth, Colors } from 'style/types';
import { AvatarSize } from './types';

interface Props {
  size: AvatarSize;
  border: boolean;
}

export const StyledAvatar = styled.picture<Props>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: ${({ size }) => size};
  justify-content: center;
  overflow: hidden;
  width: ${({ size }) => size};

  ${({ border }) =>
    border &&
    css`
      border: ${BorderWidth.Large} solid ${Colors.Primary};
    `}

  img {
    max-height: 100%;
    width: auto;
  }
`;
