import { createSelector } from 'reselect';
import { FriendPendingSubState, FriendState } from 'services/pttv/api/constants';
import { Friend } from 'services/pttv/api/friends/types';
import { getPlayerByUserId } from 'store/players/selectors';
import { getSports } from 'store/sports/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import { getUser } from '../user/selectors';
import { ExtendedSportExperience, FriendsState } from './types';

export const getFriends = (state: RootState): FriendsState => state.friends;
export const getFlatFriends = (state: RootState): Friend[] => Object.values(state.friends);

export const getFriendByUserId = (userId: string): CurriedSelector<Friend> =>
  createSelector(getFriends, (friends) => friends[userId]);

export const getAcceptedFriends = createSelector(getFlatFriends, (friends) =>
  friends.filter((friend) => friend.state === FriendState.ACCEPTED),
);

export const getInvitedFriends = createSelector(getFlatFriends, (friends) =>
  friends.filter(
    (friend) =>
      friend.state === FriendState.PENDING &&
      friend.pendingSubState === FriendPendingSubState.INVITING,
  ),
);

export const getFriendInvites = createSelector(getFlatFriends, (friends) =>
  friends.filter(
    (friend) =>
      friend.state === FriendState.PENDING &&
      friend.pendingSubState === FriendPendingSubState.BEING_INVITED,
  ),
);

export const getExperienceByUserId = (userId: string): CurriedSelector<ExtendedSportExperience[]> =>
  createSelector(
    getFriendByUserId(userId),
    getPlayerByUserId(userId),
    getSports,
    (friend, player, sports) =>
      ((player || friend)?.sportExperience || []).map((exp) => ({
        ...exp,
        sportAvatarUrl: sports[exp.sportId].avatarUrl,
        showExperience: sports[exp.sportId].showExperience,
        sportId: exp.sportId,
      })),
  );

export const getExperienceByUser = createSelector(getUser, getSports, (user, sports) =>
  (user?.sportExperience || []).map((exp) => ({
    ...exp,
    sportAvatarUrl: sports[exp.sportId].avatarUrl,
    showExperience: sports[exp.sportId].showExperience,
    sportId: exp.sportId,
  })),
);

export const isUserFriend = (userId: string): CurriedSelector<boolean> =>
  createSelector(getFriends, (friends) => !!friends[userId]);

export const userHasPendingFriendInvite = (userId: string): CurriedSelector<boolean> =>
  createSelector(
    getFriends,
    (friends) => !!friends[userId] && friends[userId].state === FriendState.PENDING,
  );
