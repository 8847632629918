// Action time

import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeaguesAPI } from 'services/pttv/api/leagues';
import { GetLeagueHistoryResponse } from 'services/pttv/api/leagues/types';
import { LeagueHistoryActions } from './types';

export const fetchLeagueHistory = createAsyncThunk<GetLeagueHistoryResponse, string>(
  LeagueHistoryActions.FETCH_LEAGUE_HISTORY,
  async (leagueName) => {
    const leagueHistory = await LeaguesAPI.getLeagueHistory(leagueName);
    return leagueHistory;
  },
);
