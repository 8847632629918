import React, { useEffect } from 'react';
import { fetchFriends } from 'store/friends/actions';
import { useAppDispatch } from 'store/hooks';
import { fetchLeagueAvatars } from 'store/leagueAvatars/actions';
import { fetchAllLeagues } from 'store/leagues/actions';
import { SocialLanding } from 'templates/SocialLanding';

const SocialLandingPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchFriends());
    dispatch(fetchAllLeagues());
    dispatch(fetchLeagueAvatars());
  }, []);

  return <SocialLanding />;
};

export default SocialLandingPage;
