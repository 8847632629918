import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { ButtonBar } from 'atoms/ButtonBar';
import { Paragraph } from 'atoms/Paragraph';
import { LeagueDetailHeader } from 'molecules/LeagueHeader/LeagueDetailHeader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { acceptLeagueInvite, declineLeagueInvite } from 'store/leagueInvites/actions';
import { getLeagueInviteByName } from 'store/leagueInvites/selectors';
import { useMessages } from 'hooks/useMessages';
import {
  InvitedBy,
  InvitedByName,
  LeagueInviteCardContainer,
  LeagueInviteCardContentArea,
  LeagueInviteCardHeader,
} from './LeagueInviteCard.styled';

interface Props {
  leagueName: string;
  callback: () => void;
}

export const LeagueInviteCard: React.FC<Props> = ({ leagueName, callback }) => {
  const leagueInvite = useAppSelector(getLeagueInviteByName(leagueName));
  const { errorMessage, successMessage } = useMessages();

  const { t } = useTranslation('LeagueDetail');
  const dispatch = useAppDispatch();

  if (!leagueInvite) {
    return null;
  }

  const handleIgnoreClick = async () => {
    await dispatch(
      declineLeagueInvite({
        request: leagueName,
        successMessage,
        errorMessage,
      }),
    );
    callback();
  };

  const handleAcceptClick = async () => {
    await dispatch(
      acceptLeagueInvite({
        request: leagueName,
        successMessage,
        errorMessage,
      }),
    );
    callback();
  };

  return (
    <LeagueInviteCardContainer>
      <LeagueInviteCardHeader>
        <LeagueDetailHeader
          leagueName={leagueName}
          selector={getLeagueInviteByName}
          showHeaderAction={false}
        />
      </LeagueInviteCardHeader>
      <LeagueInviteCardContentArea>
        <InvitedBy>
          <Paragraph>{t('leagueInvited.invitedBy')}</Paragraph>
          <Avatar size={AvatarSize.M} src={leagueInvite.invitedBy.avatarUrl || ''} />
          <InvitedByName>{leagueInvite.invitedBy.displayName}</InvitedByName>
        </InvitedBy>

        <ButtonBar>
          <Button scheme={ButtonSchemes.Warning} onClick={handleIgnoreClick}>
            {t('leagueInvited.ignoreButton')}
          </Button>
          <Button scheme={ButtonSchemes.Secondary} onClick={handleAcceptClick}>
            {t('leagueInvited.acceptButton')}
          </Button>
        </ButtonBar>
      </LeagueInviteCardContentArea>
    </LeagueInviteCardContainer>
  );
};
