import { RoomPlayer } from 'store/user/types';
import {
  LeaderBoardMovementState,
  LeaderBoardPlayer,
  NormalizeByFetchedPlayersArgs,
  NormalizeByFetchedPlayersResult,
  PrepareMovementArgs,
  ShouldUpdateAllPlayersArgs,
  UpdateFetchedPlayersMovementPayload,
} from './types';

export const shouldUpdateAllPlayers = (
  state: LeaderBoardMovementState,
  { contestId, roomTypeId, isOnLeaderboard }: ShouldUpdateAllPlayersArgs,
): boolean =>
  isOnLeaderboard ||
  !state[contestId] ||
  !state[contestId][roomTypeId] ||
  state[contestId][roomTypeId].updateOnFetch;

export const filterOutExitingPlayers = (
  state: LeaderBoardMovementState,
  { contestId, roomTypeId, players }: UpdateFetchedPlayersMovementPayload,
): RoomPlayer[] => {
  const contestToLeaf = state[contestId] || {};
  const roomTypeToLeaf = contestToLeaf[roomTypeId] || { players: {}, user: {} };
  const playerLeafs = roomTypeToLeaf.players;

  return players.filter(
    ({ userId, rank }) =>
      !playerLeafs[userId] || (playerLeafs[userId] && playerLeafs[userId].currentRank !== rank),
  );
};

const preparePlayerMovement = ({
  nextRank,
  isOnLeaderboard,
  updateOnFetch,
  currentRank,
  previousRank,
  seen = false,
}: PrepareMovementArgs): LeaderBoardPlayer => ({
  previousRank:
    isOnLeaderboard || seen || updateOnFetch || !previousRank ? currentRank : previousRank,
  currentRank: nextRank,
});

export const normalizeByFetchedPlayers = (
  state: LeaderBoardMovementState,
  { contestId, roomTypeId, isOnLeaderboard, players, user }: NormalizeByFetchedPlayersArgs,
): NormalizeByFetchedPlayersResult => {
  const contestToLeaf = state[contestId] || {};
  const roomTypeToLeaf = contestToLeaf[roomTypeId] || { players: {}, user: {} };
  const { updateOnFetch, seen = false } = roomTypeToLeaf;
  const playerLeafs = players.reduce<Record<string, LeaderBoardPlayer>>(
    (users, { userId, rank }) => {
      const { currentRank = null, previousRank = null } = roomTypeToLeaf.players[userId] || {};
      const nextUser = preparePlayerMovement({
        isOnLeaderboard,
        previousRank,
        currentRank,
        updateOnFetch,
        seen,
        nextRank: rank,
      });

      return { ...users, [userId]: nextUser };
    },
    {},
  );

  let nextUser = roomTypeToLeaf.user || {};
  if (user) {
    // if user among fetched players then update only current rank
    const { userId, rank } = user;
    const { currentRank, previousRank } = nextUser;
    nextUser = {
      previousRank: !previousRank && currentRank ? currentRank : previousRank,
      currentRank: rank,
    };
    playerLeafs[userId] = nextUser;
  }

  return {
    ...roomTypeToLeaf,
    user: nextUser,
    players: {
      ...roomTypeToLeaf.players,
      ...playerLeafs,
    },
    updateOnFetch: false,
    seen: !!isOnLeaderboard,
  };
};
