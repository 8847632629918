import { createSelector } from 'reselect';
import { RootState } from 'store/types';
import { TOASTS_LIMIT } from './actions';
import { ToastsState } from './types';

export const getToasts = (state: RootState): ToastsState => state.toast;

export const getPendingToastsIds = createSelector(getToasts, (toasts) =>
  toasts.slice(0, TOASTS_LIMIT).map(({ id }) => id),
);

export const getActiveToasts = createSelector(getToasts, (toasts) => toasts[0]);

// export const getActiveToasts = createSelector(
//   getToasts,
//   (state) => state.toastsMeta,
//   (toasts, toastsMeta) => toastsMeta.active.map((id) => toasts[id]).filter((toast) => !!toast),
// );
