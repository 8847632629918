import { Target } from 'redux-beacon';
import wrapper from 'services/wrapper';

export const segment = (): Target => (events) => {
  events.forEach((event) => {
    switch (event.hitType) {
      case 'event': {
        wrapper.segmentTrack(event.eventAction, event.properties);
        break;
      }
      case 'identify': {
        wrapper.segmentIdentify(event.userId, event.traits);
        break;
      }
      default:
        break;
    }
  });
};

export { segmentEventsMap } from './events';
