import React from 'react';
import { Avatar } from 'atoms/Avatar';
import { AvatarSize } from 'atoms/Avatar/types';
import CrownIcon from 'assets/icons/CrownIcon.svg';
import ShieldIcon from 'assets/icons/ShieldIcon.svg';
import { LeagueMemberInvited, LeagueMemberStatus } from 'services/pttv/api/constants';
import { LeagueMember } from 'services/pttv/api/leagues/types';
import { useAppSelector } from 'store/hooks';
import { getPlayerByUserId } from 'store/players/selectors';
import { getUserId } from 'store/user/selectors';
import { UserListItemWrapper, UserName } from '../UserListItem.styled';

interface Props {
  member: LeagueMember;
  onClick?: () => void;
}

export const LeagueMemberListItem: React.FC<Props> = ({ member, onClick }) => {
  const userId = useAppSelector(getUserId);
  const player = useAppSelector(getPlayerByUserId(member.userId));

  const showInvite = () => member.invite === LeagueMemberInvited.INVITED;

  return (
    <UserListItemWrapper isUser={userId === member.userId} pending={showInvite()} onClick={onClick}>
      <Avatar src={player?.avatarUrl} size={AvatarSize.MS} />
      <UserName pending={showInvite()}>
        {member.displayName}
        {member.status === LeagueMemberStatus.COMMISSIONER && <CrownIcon />}
        {member.status === LeagueMemberStatus.OFFICER && <ShieldIcon />}
      </UserName>
    </UserListItemWrapper>
  );
};
