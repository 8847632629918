import { createReducer } from '@reduxjs/toolkit';
import { fetchContestHistory, fetchContestHistoryByContestId } from './actions';
import { ContestHistoryState } from './types';

const initialState: ContestHistoryState = {};

export const contestHistoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchContestHistory.fulfilled, (state, { payload }) => {
      payload.forEach(({ game, contests }) => {
        // Loop over contests
        contests.forEach((contest) => {
          state[contest.contestId] = { ...state[contest.contestId], contest, game };
        });
      });
    })
    .addCase(fetchContestHistoryByContestId.fulfilled, (state, { payload }) => {
      // Because we want to exlcude bets from payload, bets is not used.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { bets, ...payloadWithoutBets } = payload;

      state[payload.contest.contestId] = {
        ...state[payload.contest.contestId],
        ...payloadWithoutBets,
      };
    });
});
