import { createReducer } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import { v4 as uuid } from 'uuid';
import { fetchLeague } from 'store/leagues/actions';
import { clearUser } from 'store/user/actions';
import {
  addLeagueChatMessage,
  addNewLeagueChatMessage,
  removeLeagueChatMessage,
  setSeenChatMessagesByLeague,
} from './actions';
import { LeagueChatState } from './types';

export const initialState: LeagueChatState = {};

export const leagueChatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(fetchLeague.fulfilled, (state, { payload }) => {
      state[payload.name] = payload.chatHistory
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .map((chat) => ({
          ...chat,
          id: uuid(),
          leagueName: payload.name,
          isNew: false,
          seen: true,
        }));
    })
    .addCase(addLeagueChatMessage, (state, { payload }) => {
      const newMessage = {
        ...payload,
        id: uuid(),
        isNew: false,
        seen: false,
      };

      state[payload.leagueName] = state[payload.leagueName]
        .filter(({ isNew, text }) => !isNew && text !== payload.text)
        .concat([newMessage])
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    })
    .addCase(addNewLeagueChatMessage, (state, { payload }) => {
      const newMessage = {
        ...payload,
        id: uuid(),
        isNew: true,
        seen: false,
      };

      state[payload.leagueName].push(newMessage);
    })
    .addCase(removeLeagueChatMessage, (state, { payload }) => {
      if (state[payload]) {
        delete state[payload];
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .addCase(REHYDRATE, (state, { payload }: any) => ({
      ...state,
      ...((payload?.leageChat || {}) as LeagueChatState),
    }))
    .addCase(setSeenChatMessagesByLeague, (state, { payload }) => {
      state[payload] = (state[payload] || []).map((msg) => ({ ...msg, seen: true }));
    });
});
