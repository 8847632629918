import React from 'react';
import { ButtonProps } from 'atoms/Button';
import { ButtonBarDirections } from 'style/types';
import { ButtonBarContainer, ButtonGroup } from './buttonBar.styled';

interface Props {
  // This way we can force the type of children we want in our component
  // In case of the ButtonBar, Buttons only please
  children: Array<React.ReactElement<ButtonProps> | null> | React.ReactElement<ButtonProps> | null;
  direction?: ButtonBarDirections;
  justify?: string;
}

export const ButtonBar: React.FC<Props> = ({
  direction = ButtonBarDirections.Row,
  children,
  justify,
}) => {
  const count = Array.isArray(children) ? children.filter((child) => !!child).length : 1;

  return (
    <ButtonBarContainer $justify={justify}>
      <ButtonGroup $direction={direction} $count={count}>
        {children}
      </ButtonGroup>
    </ButtonBarContainer>
  );
};
