import { createSelector } from 'reselect';
import { RoomScoreRoomPlayer } from 'services/pttv/api/rooms/types';
import { isStartedAndNotFinished } from 'store/contests/contestStateHelper';
import { ContestsState } from 'store/contests/types';
import { GamesState } from 'store/games/types';
import { getRooms } from 'store/rooms/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import type { ContestInfo, ContestInfoState } from './types';

export const getContestInfo = (state: RootState): ContestInfoState => state.contestInfo;
export const getContests = (state: RootState): ContestsState => state.contests;
export const getGames = (state: RootState): GamesState => state.games;

export const getFlatContestInfo = (state: RootState): ContestInfo[] =>
  Object.values(state.contestInfo);

export const getContestInfoKeys = (state: RootState): string[] => Object.keys(state.contestInfo);

export const getContestInfoByContestId = (contestId: string): CurriedSelector<ContestInfo> =>
  createSelector(getContestInfo, (contestInfo) => contestInfo[contestId]);

export const getContestRoomIds = (contestId: string): CurriedSelector<string[]> =>
  createSelector(getContestInfoByContestId(contestId), getRooms, (contestInfo, rooms) => {
    if (!contestInfo?.rooms) {
      return [];
    }

    // Filter out ids for which no room is available
    return contestInfo.rooms.filter((roomId) => rooms[roomId]);
  });

export const getContestInfoIds = createSelector(
  getContestInfoKeys,
  getContests,
  (contestInfoKeys, contests) =>
    contestInfoKeys.filter((contestId) => {
      const contest = contests[contestId];
      return contest && isStartedAndNotFinished(contest.state);
    }),
);

export const getLiveGameContestIds = createSelector(
  getContestInfoIds,
  getContestInfo,
  getGames,
  (contestIds, contests, games) =>
    contestIds.filter((contestId) => {
      const contest = contests[contestId];
      const game = games[contest.gameId];

      return game && game.gameType !== 'PRESHOW';
    }),
);

export const getPointsByContestId = (contestId: string): CurriedSelector<number> =>
  createSelector(getContestInfoByContestId(contestId), (contestInfo) => contestInfo?.points || 0);

export const getOneShotUsedByContestId = (contestId: string): CurriedSelector<boolean> =>
  createSelector(getContestInfoByContestId(contestId), (contestInfo) => !!contestInfo?.oneShotUsed);

export const getUserByContestIdAndRoomTypeId = (
  contestId: string,
  roomTypeId: string,
): CurriedSelector<RoomScoreRoomPlayer | undefined> =>
  createSelector(
    getContestInfoByContestId(contestId),
    (contestInfo) =>
      contestInfo.roomTypeLeaderboards.find((leaderboard) => leaderboard.roomTypeId === roomTypeId)
        ?.user,
  );

export const getPlayersByContestIdAndRoomTypeId = (
  contestId: string,
  roomTypeId: string,
): CurriedSelector<RoomScoreRoomPlayer[] | undefined> =>
  createSelector(
    getContestInfoByContestId(contestId),
    (contestInfo) =>
      contestInfo.roomTypeLeaderboards.find((leaderboard) => leaderboard.roomTypeId === roomTypeId)
        ?.top,
  );

export const getCurrentContestInfo = createSelector(
  getFlatContestInfo,
  getContests,
  (contestInfo, contests) =>
    contestInfo.find(({ contestId }) => {
      const contest = contests[contestId];

      return contest && isStartedAndNotFinished(contest.state);
    }),
);

export const getFirstUpcomingContestInfo = createSelector(
  getFlatContestInfo,
  getContests,
  (contestInfo, contests) =>
    contestInfo.find(({ contestId }) => {
      const contest = contests[contestId];

      return contest && contest.state === 'UPCOMING';
    }),
);
