import React from 'react';
import { useTranslation } from 'react-i18next';
import { PropositionToast as PropsToast } from 'store/toast/types';
import { PropositionIcon } from './PropositionIcon';
import { Answer, Description, PropositionToastStyled, Result } from './PropositionToast.styled';

interface Props {
  toast: PropsToast;
}

interface Prop {
  result: 'correct' | 'incorrect' | 'undecided';
  points: number;
  description: string;
  answer: string;
}

export const PropositionToast: React.FC<Props> = ({ toast }) => {
  const { result, points, description, answer } = toast.proposition as Prop;
  const { t } = useTranslation('PropositionResult');

  const resultText: Record<'correct' | 'incorrect', string> = {
    correct: t('toast.result.correct', { points }),
    incorrect: t('toast.result.incorrect', { points }),
  };

  return (
    <PropositionToastStyled result={result}>
      {result !== 'undecided' && (
        <Result result={result} dangerouslySetInnerHTML={{ __html: resultText[result] }} />
      )}
      <Description>{description}</Description>
      <Answer dangerouslySetInnerHTML={{ __html: t('toast.answer', { answer }) }} />
      <PropositionIcon result={result} />
    </PropositionToastStyled>
  );
};
