import styled, { css } from 'styled-components';
import React from 'react';
import { Colors, FontFamily, FontWeight, Padding } from 'style/types';
import { pxToRem } from 'utils/pxToRem';

export interface TabProps {
  active?: boolean;
  showNotice: boolean;
}

export const DefaultTab = styled.button.attrs<TabProps>(({ children }) => ({
  children: (
    <>
      {children}
      <span />
    </>
  ),
}))<TabProps>`
  background-color: ${Colors.Black80};
  border: none;
  color: ${Colors.White};
  font-family: ${FontFamily.Heading};
  font-size: ${pxToRem(18)};
  font-weight: ${FontWeight.Regular};
  letter-spacing: 0.64px;
  margin: 0;
  padding: 0.375rem ${Padding.Default} ${Padding.XXS};
  position: relative;
  text-transform: uppercase;
  transition: all ease 0.3s;
  ${({ active }) =>
    active &&
    css`
      background-color: ${Colors.Primary};
      > span {
        display: none;
      }
    `}

  ${({ showNotice, active }) =>
    !active &&
    showNotice &&
    css`
      > span {
        background-color: ${Colors.Tertriary};
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 0.5rem;
        margin: 0 0 ${Padding.XXXS} ${Padding.XS};
        width: 0.5rem;
      }
    `}
`;
