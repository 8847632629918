import { createAsyncThunk } from '@reduxjs/toolkit';
import { debugLog } from 'utils/log';
import type { RootState } from 'store/types';
import { getContestsByGameId } from 'store/contests/selectors';
import { getJoinedGameIds } from 'store/games/selectors';
import { TicketsAPI } from 'services/pttv/api/tickets';
import { TicketType } from 'services/pttv/api/tickets/types';
import { TicketTypesActions } from './types';
import { getTicketTypeIdsByRoomTypes, getTicketTypeIdsToFetch } from './selectors';

export const fetchTicketTypesByIds = createAsyncThunk<TicketType[], string[]>(
  TicketTypesActions.FETCH_TICKET_TYPES,
  async (ticketTypeIds, { rejectWithValue }) => {
    let payload;
    try {
      payload = await Promise.all<TicketType>(
        ticketTypeIds.map((ticketTypeId) => TicketsAPI.getTicketByTypeId({ ticketTypeId })),
      );

      return payload;
    } catch (e) {
      debugLog('[fetchTicketTypes]', 'Error while retreiving TicketTypes', e);
      return rejectWithValue(ticketTypeIds);
    }
  },
);

export const fetchTicketTypesByRoomTypeIds = createAsyncThunk<void, string[]>(
  TicketTypesActions.FETCH_TICKET_TYPES_BY_ROOMTYPES,
  (roomTypeIds, { dispatch, getState }) => {
    const state = getState() as RootState;
    const ticketTypeIds = getTicketTypeIdsByRoomTypes(roomTypeIds)(state);
    const ticketIdsToFetch = getTicketTypeIdsToFetch(ticketTypeIds)(state);

    if (ticketIdsToFetch.length) {
      dispatch(fetchTicketTypesByIds(ticketIdsToFetch));
    }
  },
);

export const fetchTicketTypesByTicketHashMap = createAsyncThunk(
  TicketTypesActions.FETCH_TICKET_TYPES_BY_HASHMAP,
  (ticketTypeHashMap: Record<string, unknown>, { dispatch, getState }) => {
    const ticketIdsToFetch = getTicketTypeIdsToFetch(Object.keys(ticketTypeHashMap))(
      getState() as RootState,
    );

    if (ticketIdsToFetch.length) {
      dispatch(fetchTicketTypesByIds(ticketIdsToFetch));
    }
  },
);

/**
 * Extract
 * - all ticketTypesIds from roomTypes by joined games
 * - user tickets
 * Request to fetch only missing ticketTypes
 */
export const fetchTicketTypes = createAsyncThunk<void, string[] | undefined>(
  TicketTypesActions.FETCH_TICKET_TYPES,
  async (extraRoomTypeIds = [], { dispatch, getState }) => {
    const state = getState() as RootState;
    const userTicketTypeIds = Object.keys(state.user.tickets || {});

    const allContests = getJoinedGameIds(state)
      .map((gameId) => getContestsByGameId(gameId)(state))
      .flat();

    const roomTypeIds = Array.from(
      new Set([
        ...userTicketTypeIds,
        ...allContests.map(({ roomTypeIds: $roomTypeIds }) => $roomTypeIds).flat(),
      ]).values(),
    );

    const ticketTypeIds = Array.from(
      new Set([...extraRoomTypeIds, ...getTicketTypeIdsByRoomTypes(roomTypeIds)(state)]).values(),
    );

    const ticketTypeIdsToFetch = getTicketTypeIdsToFetch(ticketTypeIds)(state);

    if (ticketTypeIdsToFetch.length) {
      dispatch(fetchTicketTypesByIds(ticketTypeIdsToFetch));
    }
  },
);
