import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GameState } from 'services/pttv/api/constants';
import { getFlatContestInfo } from 'store/contestInfo/selectors';
import { getContestsByGameId } from 'store/contests/selectors';
import {
  GameChangedEventPayloadGame,
  GameReminderNotificationsUpdatedEventPayload,
} from 'hocs/withLongPoll/types/game';
import type { RootState } from 'store/types';
import { fetchUserState } from 'store/user/actions';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { GamesAPI } from 'services/pttv/api/games';
import { addTextToast } from 'store/toast/actions';
import { PttvError } from 'services/pttv/types';
import { wait } from 'utils/wait';
import { GameActions, ResetGamePayload } from './types';
import { getGameById } from './selectors';

export const createGame = createAction<GameChangedEventPayloadGame>(GameActions.CREATE_GAME);
export const endGame = createAction<string>(GameActions.END_GAME);
export const updateGame = createAction<GameChangedEventPayloadGame>(GameActions.UPDATE_GAME);
export const removeGameById = createAction<string>(GameActions.REMOVE_GAME);

export const subscribeSuccess = createAction<string>(GameActions.SUBSCRIBE_SUCCESS);
export const unsubscribeSuccess = createAction<string>(GameActions.UNSUBSCRIBE_SUCCESS);

export const resetGame = createAsyncThunk<ResetGamePayload, string>(
  GameActions.RESET_GAME,
  async (gameId, { dispatch, getState }) => {
    await wait(100);
    dispatch(fetchUserState());
    const state = getState() as RootState;

    return {
      gameId,
      contestIds: getContestsByGameId(gameId)(state).map((contest) => contest.contestId),
    };
  },
);

export const pushUpdateGame = createAsyncThunk<void, GameChangedEventPayloadGame>(
  GameActions.PUSH_UPDATE_GAME,
  (game, { dispatch, getState }) => {
    const isActiveGame = getFlatContestInfo(getState() as RootState).find(
      ({ gameId }) => gameId === game.gameId,
    );

    if (!!isActiveGame && game.state === GameState.CLOSED) {
      dispatch(endGame(game.gameId));
    }

    dispatch(updateGame(game));
  },
);

export const updateSubscriptions = createAsyncThunk<
  void,
  GameReminderNotificationsUpdatedEventPayload
>(GameActions.UPDATE_SUBSCRIPTIONS, ({ gameIds, enabled }, { dispatch }) => {
  if (enabled) {
    gameIds.forEach((gameId) => dispatch(subscribeSuccess(gameId)));
  } else {
    gameIds.forEach((gameId) => dispatch(unsubscribeSuccess(gameId)));
  }
});

export const toggleGameNotification = createAsyncThunk<void, UpdateFieldsArgs<string>>(
  GameActions.TOGGLE_NOTIFICATION,
  async ({ request: gameId, successMessage, errorMessage }, { dispatch, getState }) => {
    const { subscribed } = getGameById(gameId)(getState() as RootState);
    try {
      if (subscribed) {
        dispatch(unsubscribeSuccess(gameId));
        await GamesAPI.gameUnSubscribe(gameId);
      } else {
        dispatch(subscribeSuccess(gameId));
        await GamesAPI.gameSubscribe(gameId);
      }
      dispatch(
        addTextToast({
          message: successMessage(
            subscribed ? 'gameNotificationsDisabled' : 'gameNotificationsEnabled',
          ),
        }),
      );
    } catch (e) {
      if (subscribed) {
        dispatch(subscribeSuccess(gameId));
      } else {
        dispatch(unsubscribeSuccess(gameId));
      }
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
    }
  },
);
