import React, { MouseEvent } from 'react';
import { OverlayHeaderAction } from 'atoms/OverlayHeaderAction';
import { OverlayHeaderActions } from 'atoms/OverlayHeaderAction/types';
import type { HelpPages } from 'store/clientProperties/types';
// eslint-disable-next-line import/no-cycle
import { PageHelpModal } from 'organisms/PageHelpModal';
import { StyledOverlayHeader } from './OverlayHeader.styled';

interface Props {
  type: OverlayHeaderActions;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
  helpPageId?: HelpPages;
}

export const OverlayHeader: React.FC<Props> = ({ children, type, onClose, helpPageId }) => (
  <StyledOverlayHeader>
    <OverlayHeaderAction type={type} onClose={onClose} />
    {typeof children === 'string' ? <h1>{children}</h1> : children}
    {helpPageId && <PageHelpModal helpPageId={helpPageId} />}
  </StyledOverlayHeader>
);
