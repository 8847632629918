import { createReducer } from '@reduxjs/toolkit';
import { fetchLeagueAvatars } from './actions';
import { LeagueAvatarsState } from './types';

export const initialState: LeagueAvatarsState = {};

export const leagueAvatarsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchLeagueAvatars.fulfilled, (_, { payload }) =>
    payload.reduce(
      (acc, avatar) => ({
        ...acc,
        [avatar.avatarId]: avatar,
      }),
      {},
    ),
  );
});
