import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PlusIcon from 'assets/icons/PlusIcon.svg';
import { Button } from 'atoms/Button';
import { ButtonSchemes, ButtonSize } from 'atoms/Button/button.theme';
import { Divider } from 'atoms/Divider';
import { JoinedLeagueCard } from 'molecules/LeagueCards/JoinedLeagueCard';
import { useAppSelector } from 'store/hooks';
import { getFilteredLeagueNames } from 'store/leagues/selectors';
import { JoinedLeagueListContainer, List } from './JoinedLeagueList.styled';

export const JoinedLeagueList: React.FC = () => {
  const leagueNames = useAppSelector(getFilteredLeagueNames);
  const { push } = useHistory();
  const { t } = useTranslation('SocialLanding');

  const handleCreateClick = () => {
    push('/social/create-league');
  };

  return (
    <JoinedLeagueListContainer>
      <Divider
        noMargin
        sticky
        action={
          <Button
            onClick={handleCreateClick}
            scheme={ButtonSchemes.Secondary}
            size={ButtonSize.Tiny}
          >
            <PlusIcon width="0.5rem" />
            {t('joinedLeagueList.createButton')}
          </Button>
        }
      >
        {t('joinedLeagueList.joinedLeagueCount.label', { count: leagueNames.length })}
      </Divider>
      <List>
        {leagueNames.map((leagueName) => (
          <JoinedLeagueCard leagueName={leagueName} key={leagueName} />
        ))}
      </List>
    </JoinedLeagueListContainer>
  );
};
