import { RoomType, RoomTypeDetails } from 'services/pttv/api/rooms/types';

export enum RoomTypeActions {
  UPDATE_ROOM_TYPE = '@roomType/UPDATE_ROOM_TYPE',
  ADD_ACTIVE_ROOM_TYPE = '@roomType/ADD_ACTIVE_ROOM_TYPE',
  FETCH_ROOM_TYPE_DETAILS = '@roomType/FETCH_ROOM_TYPE_DETAILS',
}

export interface GroupPrize {
  rank: number;
  dollarCents?: number;
  ticket?: string;
}

export type GroupPrizes = GroupPrize[];

type RoomTypeDetailsPartial = Partial<
  Omit<RoomTypeDetails, keyof RoomType> & Omit<RoomType, keyof RoomTypeDetails>
>;

export type RoomTypeStateEntry = RoomType & RoomTypeDetailsPartial;

export type RoomTypeState = Record<string, RoomTypeStateEntry>;
