import { User } from 'services/pttv/api/users/types';

export enum PlayerActions {
  FETCH_PLAYER = '@player/FETCH_PLAYER',
  REPORT_PLAYER = '@player/REPORT_PLAYER',
  REQUEST_FETCH_PLAYER = '@player/REQUEST_FETCH_PLAYER',
}

export interface Player extends User {
  loadedAt: number;
}

export interface AffiliatedTeam {
  abbreviation: string;
  cityName: string;
  teamAvatar: string;
  sportAvatar: string;
}

export type PlayersState = {
  [userId: string]: Player;
};
