import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareType } from 'atoms/ShareButton/shareButton.theme';
import { ShareButton } from 'atoms/ShareButton';
import { useDialog } from 'hooks/useDialog';
import { ShareButtonList } from './SharesList.styled';

interface Props {
  shareMessage: {
    url: string;
    text: string;
  };
}

export const SharesList: React.FC<Props> = ({ shareMessage: { url, text } }) => {
  const { t } = useTranslation('ShareModal');
  const { closeAlert } = useDialog();

  const shareOptions = {
    [ShareType.Twitter]: {
      link: `https://twitter.com/intent/tweet?text=${text}&url=${url}`,
      buttonText: t('button.twitter'),
    },
    [ShareType.Facebook]: {
      link: `https://www.facebook.com/dialog/share?app_id=${window.emconfig.settings.facebookId}&display=popup&href=${url}&quote=${text}`,
      buttonText: t('button.facebook'),
    },
  };

  return (
    <ShareButtonList>
      {Object.entries(shareOptions).map(([account, settings]) => (
        <ShareButton
          href={settings.link}
          shareType={account as ShareType}
          callback={closeAlert}
          key={account}
        >
          {settings.buttonText}
        </ShareButton>
      ))}
    </ShareButtonList>
  );
};
