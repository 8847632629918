import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'molecules/Dialogs';
import { HtmlContent } from 'atoms/HtmlContent';
import { isNative } from 'utils/device';
import { Paragraph } from 'atoms/Paragraph';

interface Props {
  closeCallback: () => void;
  message?: string;
}

export const ServerFullModal: React.FC<Props> = ({ closeCallback, message }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation('ServerModals');

  if (isNative()) {
    return null;
  }

  const onClose = () => {
    setIsOpen(false);
    closeCallback();
    window.location.reload();
  };

  return (
    <Alert title={t('serverFull.title')} isOpen={isOpen} onClose={onClose}>
      <Paragraph>{t('serverFull.message')}</Paragraph>
      {message && <HtmlContent dangerousContent={message} />}
    </Alert>
  );
};
