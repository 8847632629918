import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store/types';
import { GlobalStateResponse } from 'store/user/types';
import { CashBetMessageFailure, formatCashBetUrls } from 'utils/cashBet';
import { CashBetActions, CashBetAutoLoginIframeId, CashBetPageType, CashBetState } from './types';

type OS = 'IOS' | 'WEB' | 'ANDROID';

export const cashBetAccountCreated = createAction(CashBetActions.ACCOUNT_CREATED);
export const cashBetError = createAction<CashBetMessageFailure>(CashBetActions.ERROR);
export const cashBetIframeClosed = createAction(CashBetActions.IFRAME_CLOSED);
export const cashBetIframeLoaded = createAction(CashBetActions.IFRAME_LOADED);
export const cashBetIframeOpened = createAction<{ backUrl?: string; pageType: CashBetPageType }>(
  CashBetActions.IFRAME_OPENED,
);
export const enableCashBetAutoLogin = createAction(CashBetActions.ENABLE_AUTO_LOGIN);
export const cashBetResetError = createAction(CashBetActions.RESET_ERROR);

export const autoLoginCashBet = createAsyncThunk<void, void>(
  CashBetActions.AUTO_LOGIN,
  (_, { getState }) => {
    const { cashBet } = getState() as RootState;
    const { autoLoginUrl } = cashBet;

    if (!autoLoginUrl) {
      return;
    }

    let iframe = document.getElementById(CashBetAutoLoginIframeId) as HTMLIFrameElement;
    if (iframe) {
      // Reuse iframe when it already exists to prevent having multiple cash bet iframe loaded.
      iframe.src = autoLoginUrl;
      return;
    }

    iframe = document.createElement('iframe');
    iframe.id = CashBetAutoLoginIframeId;
    iframe.src = autoLoginUrl;
    iframe.style.display = 'none';

    document.body.appendChild(iframe);
  },
);

export const setCashBetUrls = createAsyncThunk<Partial<CashBetState>, GlobalStateResponse>(
  CashBetActions.SET_URLS,
  async ({ clientProperties }, { getState }) => {
    const { CASH_BET_BASE_URL } = clientProperties;
    const {
      wrapper: { cashBetServerUrl, appsFlyerUID, deviceId, os },
    } = getState() as RootState;

    const osType = {
      IOS: 6401,
      WEB: 6402,
      ANDROID: 6403,
    };

    const cashBetUrl = {
      urlPrefix: (cashBetServerUrl as string) || (CASH_BET_BASE_URL as string),
      os: osType[os as OS],
      appsFlyerUID: appsFlyerUID as string,
      deviceId: deviceId as string,
    };

    return {
      autoLoginUrl: formatCashBetUrls({ ...cashBetUrl, urlDestination: 'autologin' }),
      depositUrl: formatCashBetUrls({ ...cashBetUrl, urlDestination: 'main_menu' }),
      loginUrl: formatCashBetUrls({ ...cashBetUrl, urlDestination: 'start' }),
      kycUrl: formatCashBetUrls({ ...cashBetUrl, urlDestination: 'main_menu?page=enter_kyc_info' }),
      transactionHistoryUrl: formatCashBetUrls({
        ...cashBetUrl,
        urlDestination: 'main_menu?page=account_history',
      }),
      withdrawalUrl: formatCashBetUrls({
        ...cashBetUrl,
        urlDestination: 'main_menu?page=withdraw',
      }),
      guestMigrateUrl: formatCashBetUrls({
        ...cashBetUrl,
        urlDestination: 'login_and_store_cookies?username={authToken}&password={authToken}',
      }),
    };
  },
);
