import { createSelector } from 'reselect';
import { getContestById } from 'store/contests/selectors';
// eslint-disable-next-line import/no-cycle
import { getGameById } from 'store/games/selectors';
import type { CurriedSelector, RootState } from 'store/types';
import type { Sport, SportsState } from './types';

export const getSports = (state: RootState): SportsState => state.sports;

export const getFlatSports = (state: RootState): Sport[] => Object.values(state.sports);

export const getSportById = (sportId: string): CurriedSelector<Sport> =>
  createSelector(getSports, (sports) => sports[sportId]);

export const getSportByGameId = (gameId: string): CurriedSelector<Sport | undefined> =>
  createSelector(getGameById(gameId), getSports, (game, sports) => !!game && sports[game.sportId]);

export const getSportByContestId = (contestId: string): CurriedSelector<Sport | undefined> =>
  createSelector(
    getSports,
    getContestById(contestId),
    (sports, sport) => !!sport && sports[sport.sportId],
  );

export const getSportIdsSelectableByClient = createSelector(getFlatSports, (sports) =>
  sports
    .filter(({ notSelectableByClient }) => !notSelectableByClient)
    .map((sport) => sport.sportId),
);
