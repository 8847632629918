import styled from 'styled-components';
import { SportTeamSeparator } from 'store/sports/types';
import { Colors, Gap } from 'style/types';

interface Props {
  seperator: SportTeamSeparator;
  color?: Colors;
}

export const SportSeparator = styled.span.attrs(({ seperator }: Props) => ({
  children: seperator === 'VERSUS' ? 'vs' : '@',
}))<Props>`
  color: ${({ color = Colors.TextSub }) => color};
  font-style: italic;
  margin: 0 ${Gap.Default};
`;
