import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { LeaguesAPI } from 'services/pttv/api/leagues';
import { EmptyResponse } from 'services/pttv/api/types';
import { PttvError } from 'services/pttv/types';
import { League } from 'store/leagues/types';
import { updateLeagueToastBroadcastDate } from 'store/leaguesMeta/actions';
import { getLeaguesMetaByLeagueName } from 'store/leaguesMeta/selectors';
import {
  LeagueInvitationEventPayload,
  LeagueKickedEventPayload,
} from 'hocs/withLongPoll/types/league';
import { addTextToast } from 'store/toast/actions';
import type { RootState } from 'store/types';
import { AddLeagueToastPayload, LeagueInvite, LeagueInviteActions } from './types';

export const leagueInviteCancelled = createAction<LeagueKickedEventPayload>(
  LeagueInviteActions.LEAGUE_INVITE_CANCELLED,
);

export const addLeagueToast = createAsyncThunk<void, AddLeagueToastPayload>(
  LeagueInviteActions.ADD_LEAGUE_TOAST,
  ({ leagueName, payload }, { dispatch, getState }) => {
    const now = Date.now();
    const yesterday = now - 86400000; // Subtracting 24 hours from today.
    const leaguesMeta = getLeaguesMetaByLeagueName(leagueName)(getState() as RootState);

    if (!leaguesMeta || leaguesMeta.lastToastBroadcastDate < yesterday) {
      // Update the last broadcast date, then display the toast.
      dispatch(
        updateLeagueToastBroadcastDate({
          leagueName,
          lastToastBroadcastDate: now,
        }),
      );
      dispatch(addTextToast(payload));
    }
  },
);

export const addLeagueInvitation = createAsyncThunk<LeagueInvite, LeagueInvitationEventPayload>(
  LeagueInviteActions.ADD_LEAGUE_INVITATION,
  (payload, { dispatch }) => {
    const {
      invitedBy: { displayName },
      name,
    } = payload;
    dispatch(
      addLeagueToast({
        leagueName: payload.name,
        payload: {
          message: i18n.t('LeagueDetail:leagueInviteToast.message', {
            displayName,
            leagueName: name,
          }),
          to: '/social/invites',
        },
      }),
    );
    return payload;
  },
);

export const acceptLeagueInvite = createAsyncThunk<League | PttvError, UpdateFieldsArgs<string>>(
  LeagueInviteActions.LEAGUE_INVITE_ACCEPT,
  async ({ request, successMessage, errorMessage }, { dispatch, rejectWithValue }) => {
    try {
      const league = await LeaguesAPI.acceptInvite(request);

      dispatch(
        addTextToast({
          message: successMessage('leagueInviteAccepted'),
        }),
      );
      return league;
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e);
    }
  },
);

export const declineLeagueInvite = createAsyncThunk<
  EmptyResponse | PttvError,
  UpdateFieldsArgs<string>
>(
  LeagueInviteActions.LEAGUE_INVITE_ACCEPT,
  async ({ request, successMessage, errorMessage }, { dispatch, rejectWithValue }) => {
    try {
      const response = await LeaguesAPI.declineInvite(request);

      dispatch(
        addTextToast({
          message: successMessage('leagueInviteDeclined'),
        }),
      );
      return response;
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue(e);
    }
  },
);
