import { createSelector } from 'reselect';
import { CurriedSelector, RootState } from 'store/types';
import { getUser } from 'store/user/selectors';
import { AllClientProps, ClientProps } from './types';

const getClientProperties = (state: RootState) => state.clientProperties;

export const getClientProperty = (clientProp: AllClientProps): CurriedSelector<string | null> =>
  createSelector(getClientProperties, (properties) => properties[clientProp] || null);

export const getPopulatedUrl = (clientProp: ClientProps): CurriedSelector<string> =>
  createSelector(getClientProperty(clientProp), getUser, (property, user) => {
    const { userId, inviteCode, authToken, playerId } = user;
    const replaceValues: Record<string, string> = {
      playerId: inviteCode,
      userId,
      actorId: playerId.toString(),
      token: authToken,
    };

    return (property || '').replace(/{(\w+)}/gi, (_, key) => replaceValues[key] || '');
  });
