import { createReducer } from '@reduxjs/toolkit';
import { clearSportsFilter, toggleSportsFilter } from './actions';
import { GamesMetaState } from './types';

export const initialState = {
  activeSportsFilter: [],
} as GamesMetaState;

export const gamesMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleSportsFilter, (state, { payload }) => {
      const index = state.activeSportsFilter.indexOf(payload);
      const newFilter = [...state.activeSportsFilter];
      if (index === -1) {
        newFilter.push(payload);
      } else {
        newFilter.splice(index, 1);
      }

      return {
        ...state,
        activeSportsFilter: newFilter,
      };
    })
    .addCase(clearSportsFilter, (state) => ({
      ...state,
      activeSportsFilter: [],
    }));
});
