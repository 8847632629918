import { playToTv } from 'services/pttv';
import {
  ClearBetRequest,
  ContestHistoryByContestIdResponse,
  ContestHistoryResponse,
  GetContestResultResponse,
  GetPropositionsResponse,
  GetPropositionsRequest,
  PlaceBetRequest,
  PlaceBetResponse,
} from './types';

export const ContestAPI = {
  getPropositions: ({ contestId }: GetPropositionsRequest): Promise<GetPropositionsResponse> =>
    playToTv.get<GetPropositionsResponse>(`winview/contests/${contestId}/propositions`),

  getContestResult: ({ contestId }: GetPropositionsRequest): Promise<GetContestResultResponse> =>
    playToTv.get<GetContestResultResponse>(`winview/contests/${contestId}/contestResult`),

  placeBet: (
    contestId: string,
    propositionId: string,
    request: PlaceBetRequest,
  ): Promise<PlaceBetResponse> =>
    playToTv.post<PlaceBetResponse, PlaceBetRequest>(
      `winview/contests/${contestId}/propositions/${propositionId}/bet`,
      request,
    ),

  clearBet: (
    contestId: string,
    propositionId: string,
    request: ClearBetRequest,
  ): Promise<PlaceBetResponse> =>
    playToTv.post<PlaceBetResponse, ClearBetRequest>(
      `winview/contests/${contestId}/propositions/${propositionId}/bet`,
      request,
    ),

  getContestHistory: (): Promise<ContestHistoryResponse> =>
    playToTv.get<ContestHistoryResponse>('winview/contests/history'),

  getContestHistoryByContestId: (contestId: string): Promise<ContestHistoryByContestIdResponse> =>
    playToTv.get<ContestHistoryByContestIdResponse>(`winview/contests/history/${contestId}`),
};
