import styled from 'styled-components';

export const TicketsWrapper = styled.div`
  display: inline-block;

  svg {
    height: 0.75em;
    margin-right: 0.15em;
  }
`;
