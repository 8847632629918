import React from 'react';
import { useTranslation } from 'react-i18next';
import wrapperLib from 'services/wrapper';
import { useDialog } from 'hooks/useDialog';
import { SharesList } from 'molecules/SharesList';

interface Props {
  shareButton: (text: string, callback: () => void) => JSX.Element;
  title: string;
  shareMessage: {
    text: string;
    url: string;
  };
}

export const ShareDialog: React.FC<Props> = ({ shareButton, title, shareMessage }) => {
  const { t } = useTranslation('ShareModal');
  const { showAlert } = useDialog();

  const handleButtonClick = async () => {
    wrapperLib.socialShare(shareMessage, () => {
      showAlert(title, <SharesList shareMessage={shareMessage} />, {
        closeButtonText: t('button.closeModal'),
      });
    });
  };

  return <>{shareButton(t('shareButton'), handleButtonClick)}</>;
};
