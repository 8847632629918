import { createAction } from '@reduxjs/toolkit';
import { RoomTypeLeaderboardCountEventPayload } from 'hocs/withLongPoll/types/roomType';
import {
  RoomTypeLeaderboardMetaActions,
  UpdateRoomTypeLeaderboardMetaArgs,
  UpdateRoomTypeLeaderboardPrizePerRankDollarCentsArgs,
} from './types';

export const updateRoomTypeLeaderboardMeta = createAction<UpdateRoomTypeLeaderboardMetaArgs>(
  RoomTypeLeaderboardMetaActions.UPDATE_META,
);

export const updateRoomTypeLeaderboardPrizePerRankDollarCents =
  createAction<UpdateRoomTypeLeaderboardPrizePerRankDollarCentsArgs>(
    RoomTypeLeaderboardMetaActions.UPDATE_ROOMTYPELEADERBOARD_PRIZE_PER_RANK_DOLLAR_CENTS,
  );

export const updateRoomTypeLeaderboardCount = createAction<RoomTypeLeaderboardCountEventPayload>(
  RoomTypeLeaderboardMetaActions.UPDATE_ROOMTYPELEADERBOARD_COUNT,
);
