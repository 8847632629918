import styled from 'styled-components';
import React from 'react';
import { BorderRadius, BorderWidth, Colors, FontSize, FontWeight, Gap, Padding } from 'style/types';
import { StyledAvatar } from 'atoms/Avatar/Avatar.styled';
import { PlayerCountContainer } from 'atoms/PlayerCount/PlayerCount.styled';
import { Button } from 'atoms/Button';

export const LeagueName = styled.h3`
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.M};
  grid-area: name;
  margin: 0;
`;

export const LeagueDescription = styled.h5`
  color: ${Colors.TextSub};
  font-weight: ${FontWeight.Regular};
  font-size: ${FontSize.MS};
  grid-area: description;
  margin: 0;
`;

export const LeagueInfo = styled.section`
  align-items: center;
  display: grid;
  gap: ${Gap.XXS} ${Gap.L};
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'avatar name button'
    'avatar description button'
    'avatar membercount button';
  padding: ${Padding.S} ${Padding.Default};

  > ${StyledAvatar} {
    border: ${BorderWidth.Large} solid ${Colors.Primary};
    grid-area: avatar;
  }

  > ${PlayerCountContainer} {
    color: ${Colors.TextSub};
    grid-area: membercount;
  }

  > ${Button} {
    grid-area: button;
  }
`;

export const InvitedBy = styled.footer`
  align-items: center;
  border-top: ${BorderWidth.Default} solid ${Colors.Black75};
  display: flex;
  font-size: ${FontSize.MS};
  font-weight: ${FontWeight.SemiBold};
  padding: ${Padding.S} ${Padding.Default};

  > ${StyledAvatar} {
    margin: 0 ${Padding.Default};
  }
`;

export const InvitedByLabel = styled.span`
  color: ${Colors.Black30};
  font-size: ${FontSize.S};
  font-weight: ${FontWeight.Regular};
`;

interface Props {
  children: JSX.Element;
}

export const LeagueInvitedCardContainer = styled.li.attrs<Props>(({ children }) => ({
  children: <article>{children}</article>,
}))`
  list-style: none;

  > article {
    border: ${BorderWidth.Large} solid ${Colors.Black75};
    border-radius: ${BorderRadius.Default};
  }
`;
