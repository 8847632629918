import styled from 'styled-components';
import { Colors, FontFamily, FontSize, FontWeight, Gap, Padding } from 'style/types';

export const InvitedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li + li {
    margin-top: ${Gap.Default};
  }
`;

export const InvitedListHeader = styled.h2`
  align-items: center;
  color: ${Colors.Secondary};
  display: flex;
  font-family: ${FontFamily.Heading};
  font-size: ${FontSize.ML};
  font-weight: ${FontWeight.SemiBold};
  justify-content: space-between;
  letter-spacing: 1.1px;
  margin: 0 0 ${Padding.S} 0;
  text-transform: uppercase;
`;
