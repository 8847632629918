import { PaymentMethod } from 'services/pttv/api/constants';

export enum RoomSelectionActions {
  TOGGLE_ROOM_TYPE = '@roomSelection/TOGGLE_ROOM_TYPE',
  CLEAR_ROOM_TYPE_SELECTION = '@roomSelection/CLEAR_ROOM_TYPE_SELECTION',
}

export type ToggleRoomTypeInput = {
  contestId: string;
  roomTypeId: string;
  buyInMethod: {
    dollarCents: number;
    ticket: string;
  };
};

export type SelectionInvestment = {
  roomTypeTotalDollarCents: number;
  roomTypeTotalTickets: string[];
};

export interface SelectedRoomType {
  contestId: string;
  roomTypeId: string;
  buyInMethod: {
    dollarCents: number;
    ticket: string;
  };
}

export type RoomSelectionState = {
  [contestId: string]: SelectedRoomType[];
};

export type ServerPayloadContest = {
  contestId: string;
  roomTypes: {
    roomTypeId: string;
    roomQueueId?: string;
    paymentMethod: PaymentMethod | undefined;
    count: number;
  }[];
};
