type UTCRegexGroup = {
  year: string;
  month: string;
  date: string;
  hours: string;
  minutes: string;
  seconds: string;
};

interface RegExpExecArrayGroups extends RegExpExecArray {
  groups?: UTCRegexGroup;
}

const utcRegex =
  /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<date>[0-9]{2})\s(?<hours>[0-9]{2}):(?<minutes>[0-9]{2}):(?<seconds>[0-9]{2})\sUTC/;

export const utcToTimestamp = (utcString: string): number | null => {
  const result = utcRegex.exec(utcString) as RegExpExecArrayGroups;

  if (!result?.groups) {
    return null;
  }

  const {
    groups: { year, month, date, hours, minutes, seconds },
  } = result;

  return Date.UTC(
    parseInt(year, 10),
    parseInt(month, 10),
    parseInt(date, 10),
    parseInt(hours, 10),
    parseInt(minutes, 10),
    parseInt(seconds, 10),
  );
};
