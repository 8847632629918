import styled from 'styled-components';
import { Colors, Padding } from 'style/types';

export const PlayerCountContainer = styled.div`
  color: ${Colors.TextSub2};

  svg {
    padding-left: ${Padding.XXS};
    height: 1rem;
  }
`;
