import { createReducer } from '@reduxjs/toolkit';
import { clearUser } from 'store/user/actions';
import { updateLeagueToastBroadcastDate } from './actions';
import { LeaguesMetaState } from './types';

export const initialState: LeaguesMetaState = {};

export const leaguesMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(updateLeagueToastBroadcastDate, (state, { payload }) => {
      state[payload.leagueName] = {
        lastToastBroadcastDate: payload.lastToastBroadcastDate || Date.now(),
      };
    });
});
