import React, { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'atoms/Button';
import { ButtonSchemes } from 'atoms/Button/button.theme';
import { ModalPage } from 'atoms/ModalPage';
import { useAppSelector } from 'store/hooks';
import { getSportByContestId } from 'store/sports/selectors';
import { formatOrdinals } from 'utils/formatters';
import { GameHeader } from 'molecules/GameHeader';
import { Table, TableCell, TableRow } from 'atoms/Table';
import { getContestById } from 'store/contests/selectors';
import { getRoomTypeById } from 'store/roomTypes/selectors';
import { BuyInText } from 'molecules/BuyInText';
import { CardDescription, CardTitle, Title } from '../Modals.styled';
import {
  ContestCancelledContent,
  ContestCancelledModalContentArea,
  ContestCancelledCard,
} from './RoomCancelledModal.styled';

interface Props {
  contestId: string;
  roomTypeId: string;
  closeCallback: () => void;
}

export const RoomCancelledModal: React.FC<Props> = ({ contestId, roomTypeId, closeCallback }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sport = useAppSelector(getSportByContestId(contestId));
  const contest = useAppSelector(getContestById(contestId));
  const roomType = useAppSelector(getRoomTypeById(roomTypeId));
  const { t } = useTranslation('ContestModals');

  if (!sport) {
    return null;
  }

  useEffect(() => {
    if (roomType) {
      setIsOpen(true);
    }
  }, [roomType]);

  const handleCloseButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(false);
    closeCallback();
  };

  return (
    <>
      <ModalPage
        title={t('roomCancelled.pageTitle')}
        isOpen={isOpen}
        onClose={handleCloseButtonClick}
      >
        <ContestCancelledModalContentArea>
          <Title>
            {t('roomCancelled.cardTitle', {
              roomName: roomType.name,
            })}
          </Title>
          <ContestCancelledCard>
            <GameHeader gameId={contest.gameId} />
            <ContestCancelledContent>
              <CardDescription>{t('roomCancelled.cardDescription')}</CardDescription>
              {!!roomType && (
                <>
                  <CardTitle>
                    {t('roomCancelled.roomTitle', {
                      period: `${formatOrdinals(contest.period)} ${sport.period}`,
                    })}
                  </CardTitle>
                  <Table>
                    <TableRow>
                      <TableCell>{roomType.name}</TableCell>
                      <TableCell width="30">
                        <BuyInText roomTypeId={roomTypeId} />
                      </TableCell>
                    </TableRow>
                  </Table>
                </>
              )}
            </ContestCancelledContent>
          </ContestCancelledCard>
          <Button scheme={ButtonSchemes.Secondary} onClick={handleCloseButtonClick}>
            {t('roomCancelled.backButton')}
          </Button>
        </ContestCancelledModalContentArea>
      </ModalPage>
    </>
  );
};
