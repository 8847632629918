import { createAsyncThunk } from '@reduxjs/toolkit';
import { UsersAPI } from 'services/pttv/api';
import { ReportUserRequest, User } from 'services/pttv/api/users/types';
import type { RootState } from 'store/types';
import { isPlayerLoading } from 'store/playersMeta/selectors';
import { EmptyResponse } from 'services/pttv/api/types';
import { UpdateFieldsArgs } from 'hooks/useMessages/types';
import { PttvError } from 'services/pttv/types';
import { addTextToast } from 'store/toast/actions';
import { PlayerActions } from './types';
import { getPlayerByUserId } from './selectors';

export const FETCH_PLAYER = '@player/FETCH_PLAYER';

export const fetchPlayer = createAsyncThunk<User, string, { fulfilledMeta: { timestamp: number } }>(
  PlayerActions.FETCH_PLAYER,
  async (userId, { rejectWithValue, fulfillWithValue }) => {
    let player;

    try {
      player = await UsersAPI.getUser(userId);
    } catch (e) {
      return rejectWithValue({ userId });
    }
    if (!player) {
      return rejectWithValue({ userId });
    }
    return fulfillWithValue(player, { timestamp: Date.now() });
  },
);

export const requestFetchPlayer = createAsyncThunk(
  PlayerActions.REQUEST_FETCH_PLAYER,
  (userId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isLoading = isPlayerLoading(userId)(state);
    const player = getPlayerByUserId(userId)(state);
    const FIVE_MINUTES_INTERVAL = 5 * 60 * 1000;
    const isOutdated = !!(
      player &&
      player.loadedAt &&
      Date.now() - player.loadedAt > FIVE_MINUTES_INTERVAL
    );

    if (!isLoading && (!player || isOutdated)) {
      dispatch(fetchPlayer(userId));
    }
  },
);

export const reportPlayer = createAsyncThunk<EmptyResponse, UpdateFieldsArgs<ReportUserRequest>>(
  PlayerActions.REPORT_PLAYER,
  async ({ errorMessage, successMessage, request }, { dispatch, rejectWithValue }) => {
    let response;

    try {
      response = await UsersAPI.reportUser(request);
      dispatch(
        addTextToast({
          message: successMessage('reportUser'),
        }),
      );
    } catch (e) {
      dispatch(
        addTextToast({
          message: errorMessage(e as PttvError),
          isWarning: true,
        }),
      );
      return rejectWithValue({ request });
    }
    return response;
  },
);
