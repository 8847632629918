import { createReducer } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';
import { clearUser, signInSuccess } from 'store/user/actions';
import {
  cashBetError,
  cashBetIframeClosed,
  cashBetIframeLoaded,
  cashBetIframeOpened,
  cashBetResetError,
  enableCashBetAutoLogin,
  setCashBetUrls,
} from './actions';
import { CashBetState } from './types';

export const initialState = {
  autoLoginCashBet: false,
  autoLoginUrl: null,
  currentBackUrl: null,
  currentPageType: null,
  depositUrl: null,
  error: null,
  guestMigrateUrl: null,
  isLoaded: false,
  isServerResetting: false,
  kycUrl: null,
  loginUrl: null,
  transactionHistoryUrl: null,
  withdrawalUrl: null,
} as CashBetState;

export const cashBetReducer = createReducer(initialState, (builder) => {
  builder
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .addCase(REHYDRATE, (_, { payload }: any) => ({
      ...initialState,
      autoLoginCashBet: payload?.cashBet?.autoLoginCashBet || false,
    }))
    .addCase(cashBetError, (state, { payload }) => ({
      ...state,
      error: {
        type: payload.reason,
        message: payload.message,
      },
    }))
    .addCase(clearUser.fulfilled, (state) => ({
      ...state,
      autoLoginCashBet: false,
      error: null,
    }))
    .addCase(cashBetIframeClosed, (state) => ({
      ...state,
      currentBackUrl: null,
      currentPageType: null,
      isLoaded: false,
    }))
    .addCase(cashBetIframeLoaded, (state) => ({
      ...state,
      isLoaded: true,
    }))
    .addCase(cashBetIframeOpened, (state, { payload }) => ({
      ...state,
      currentBackUrl: payload.backUrl || null,
      currentPageType: payload.pageType,
      isLoaded: false,
    }))
    .addCase(cashBetResetError, (state) => ({
      ...state,
      isServerResetting: true,
    }))
    .addCase(enableCashBetAutoLogin, (state) => ({
      ...state,
      autoLoginCashBet: true,
    }))
    .addCase(setCashBetUrls.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }))
    .addCase(signInSuccess, (state) => ({
      ...state,
      isServerResetting: false,
    }));
});
