import { createReducer } from '@reduxjs/toolkit';
import { Bet } from 'store/bets/types';
import { endGame, resetGame } from 'store/games/actions';
import { clearUser, fetchUserState } from 'store/user/actions';
import { removeBetMeta, updateBetMeta } from './actions';
import { BetMetaState } from './types';

const initialState: BetMetaState = {};

export const betMetaReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(clearUser.fulfilled, () => initialState)
    .addCase(endGame, () => initialState)
    .addCase(resetGame.fulfilled, (state, { payload }) => {
      const { contestIds } = payload;

      Object.keys(state).forEach((propositionId) => {
        if (contestIds.includes(state[propositionId].contestId)) {
          delete state[propositionId];
        }
      });
    })
    .addCase(fetchUserState.fulfilled, (state, { payload }) => {
      const { contests } = (payload.currentGamesInfo && payload.currentGamesInfo[0]) || {};
      let allBets: Bet[] = [];

      if (contests) {
        contests.forEach(({ contestId, bets }) => {
          const betsPerContest: Bet[] = [];
          // add contestId to bets
          bets.forEach((bet) => {
            betsPerContest.push({ ...bet, contestId });
          });
          allBets = [...allBets, ...betsPerContest];
        });
      }

      return {
        ...state,
        ...allBets.reduce<BetMetaState>(
          (acc, bet) => ({
            ...acc,
            [bet.propositionId]: bet,
          }),
          {},
        ),
      };
    })
    .addCase(removeBetMeta, (state, { payload }) => {
      if (state[payload]) {
        delete state[payload];
      }
    })
    .addCase(updateBetMeta, (state, { payload }) => {
      state[payload.propositionId] = {
        ...state[payload.propositionId],
        ...payload,
      };
    });
});
