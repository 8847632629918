import { Friend } from 'services/pttv/api/friends/types';
import { SportExperience } from 'services/pttv/api/users/types';

export enum FriendsActions {
  FETCH_FRIENDS_LEADERBOARD = '@friends/FETCH_FRIENDS_LEADERBOARD',
  UPDATE_FRIENDS = '@friends/UPDATE_FRIENDS',
  ADD_FRIENDS = '@friends/ADD_FRIENDS',
  FRIEND_REQUEST_RECEIVED = '@friends/FRIEND_REQUEST_RECEIVED',
  FRIEND_REQUEST_ACCEPTED = '@friends/FRIEND_REQUEST_ACCEPTED',
  FRIEND_REQUEST_CANCELLED = '@friends/FRIEND_REQUEST_CANCELLED',
  FRIEND_REQUEST_DECLINED = '@friends/FRIEND_REQUEST_DECLINED',
  FRIEND_DELETED = '@friends/FRIEND_DELETED',
  FRIEND_REQUEST_ACCEPT = '@friends/FRIEND_REQUEST_ACCEPT',
  FRIEND_REQUEST_CANCEL = '@friends/FRIEND_REQUEST_CANCEL',
  FRIEND_INVITE = '@friends/FRIEND_INVITE',
  NOTIFY_FRIENDS = '@friends/NOTIFY_FRIENDS',
}

export interface ExtendedSportExperience extends SportExperience {
  sportAvatarUrl: string;
  showExperience: boolean;
}

export type FriendsState = {
  [friendsId: string]: Friend;
};
