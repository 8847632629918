import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'hooks/useDialog';
import wrapperLib from 'services/wrapper';
import { Subscription } from 'services/wrapper/types';
import { debugLog } from 'utils/log';
import { isMobile, isNative } from 'utils/device';

export const useBackButton = (): void => {
  const history = useHistory();
  const { t } = useTranslation('Global');
  const { showConfirm } = useDialog();

  let subscription: Subscription;

  const backAction = () => {
    const path = window.location.hash.replace(/^#/, '');

    if (path === '/') {
      showConfirm(t('closeAppModal.title'), t('closeAppModal.description'), {
        confirmButtonAction: () => wrapperLib.closeApp(),
        confirmButtonText: t('closeAppModal.button'),
      });
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (!isNative() || !isMobile()) {
      return undefined;
    }

    subscription = wrapperLib.onBackButton(backAction) as Subscription;

    return () => {
      debugLog('[useBackButton]', 'Unsubscribre backButton');
      wrapperLib.removeBackButtonListener(subscription);
    };
  }, []);
};
