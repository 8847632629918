import { useContext } from 'react';
import { DialogContext } from './context';
import { DialogState } from './types';

export const useDialog = (): DialogState => {
  const context = useContext(DialogContext);

  return {
    ...context,
  };
};
